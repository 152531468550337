// LSAT Exam Data
import { ExamData } from "../exam-data-interface";

export const lsatExamData: ExamData = {
  examId: 'LSAT_2024',
  displayName: 'LSAT',
  description:
    'The Law School Admission Test (LSAT) is a standardized test administered by the Law School Admission Council (LSAC) for prospective law school candidates. The LSAT assesses skills considered essential for success in law school, including reading comprehension, logical reasoning, and argumentative writing.',
  logo: 'assets/images/exams/lsat_logo.png',
  link: 'lsat',
  zoom: 1.1,
  priceId: 'LSAT_2024_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel on the LSAT with Comprehensive Preparation',
  marketingSubtitles: [
    'Tailored practice to enhance your LSAT performance.',
    'Extensive question bank covering all LSAT sections.',
    'Adaptive learning to focus on areas needing improvement.',
    'Build confidence with effective practice and detailed feedback.',
  ],
  pricingTitle: 'Premium LSAT Prep at a Competitive Price',
  pricingSubtitle: [
    'Achieve your law school goals with comprehensive support and resources.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'LSAT_2024_READING_COMPREHENSION',
      displayName: 'LSAT Reading Comprehension',
      description:
        'Measures the ability to read, understand, and analyze complex texts similar to those encountered in law school. Passages are drawn from subjects such as law, humanities, social sciences, and natural sciences.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading comprehension
      categories: [
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_MAIN_IDEA',
          displayName: 'Main Idea',
          description:
            'Questions that ask about the primary purpose or main point of the passage.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_MAIN_IDEA_IDENTIFY',
              displayName: 'Identify Main Idea',
              description:
                'Questions that require identifying the main idea of the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description:
            'Questions that ask about specific details stated in the passage.',
          icon: 'remixListUnordered',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_SUPPORTING_DETAILS_LOCATE',
              displayName: 'Locate Supporting Details',
              description:
                'Questions that require locating specific details within the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_INFERENCE',
          displayName: 'Inference',
          description:
            'Questions that require making logical deductions based on information in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_INFERENCE_DRAW',
              displayName: 'Draw Inferences',
              description:
                'Drawing logical inferences from the information presented.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_STRUCTURE',
          displayName: 'Structure and Organization',
          description:
            'Questions about how the passage is organized and the logical structure.',
          icon: 'remixLayoutLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_STRUCTURE_ANALYZE',
              displayName: 'Analyze Structure',
              description:
                'Questions that require analyzing the structure of the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_TONE',
          displayName: 'Author’s Tone and Attitude',
          description:
            'Questions about the author’s tone, attitude, or opinion in the passage.',
          icon: 'remixUserVoiceLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_TONE_DETERMINE',
              displayName: 'Determine Tone',
              description:
                'Questions that require determining the author’s tone in the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_FUNCTION',
          displayName: 'Function/Purpose',
          description:
            'Questions that ask about the purpose or function of a particular part of the passage.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_FUNCTION_IDENTIFY',
              displayName: 'Identify Function',
              description:
                'Questions that require identifying the function of specific parts of the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_ANALOGIES',
          displayName: 'Analogies',
          description:
            'Questions that require identifying analogies or similar relationships.',
          icon: 'remixAttachmentLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_ANALOGIES_IDENTIFY',
              displayName: 'Identify Analogies',
              description:
                'Questions that require identifying analogies within the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_APPLICATION',
          displayName: 'Application',
          description:
            'Questions that ask to apply information or ideas from the passage to a new context.',
          icon: 'remixApplicationLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_APPLICATION_APPLY_CONCEPTS',
              displayName: 'Apply Concepts',
              description:
                'Questions that require applying concepts from the passage to new situations.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_ARGUMENT_EVALUATION',
          displayName: 'Argument Evaluation',
          description:
            'Questions that ask to evaluate arguments or reasoning within the passage.',
          icon: 'remixBarChartLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_ARGUMENT_EVALUATION_EVALUATE_REASONING',
              displayName: 'Evaluate Reasoning',
              description:
                'Questions that require evaluating the reasoning used in the passage.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_READING_COMPREHENSION_COMPARATIVE_READING',
          displayName: 'Comparative Reading',
          description:
            'Questions that require comparing and contrasting two related passages.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_READING_COMPREHENSION_COMPARATIVE_READING_COMPARE_PASSAGES',
              displayName: 'Compare Passages',
              description:
                'Questions that require comparing information between two passages.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'LSAT_2024_LOGICAL_REASONING',
      displayName: 'LSAT Logical Reasoning',
      description:
        'Assesses the ability to analyze, critically evaluate, and complete arguments. Questions are based on short passages from a variety of sources, including newspapers, magazines, scholarly publications, and advertisements.',
      icon: 'remixFunctionLine', // ng-icons Remix Icon for logical reasoning
      categories: [
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_MUST_BE_TRUE',
          displayName: 'Must Be True / Most Strongly Supported',
          description:
            'Questions that ask for conclusions that can be logically inferred from the given information.',
          icon: 'remixQuestionAnswerLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_MUST_BE_TRUE_INFERENCE_SUPPORT',
              displayName: 'Inference Support',
              description:
                'Questions that require supporting inferences based on the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_MAIN_POINT',
          displayName: 'Main Point',
          description:
            'Questions that ask for the main conclusion or primary argument of the passage.',
          icon: 'remixTargetLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_MAIN_POINT_IDENTIFY_CONCLUSION',
              displayName: 'Identify Conclusion',
              description:
                'Questions that require identifying the main conclusion of the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_STRENGTHEN',
          displayName: 'Strengthen',
          description:
            'Questions that ask for information that would strengthen the argument.',
          icon: 'remixArrowUpLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_STRENGTHEN_ENHANCE_ARGUMENT',
              displayName: 'Enhance Argument',
              description:
                'Questions that require enhancing the argument with additional information.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_WEAKEN',
          displayName: 'Weaken',
          description:
            'Questions that ask for information that would weaken the argument.',
          icon: 'remixArrowDownLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_WEAKEN_UNDERMINE_ARGUMENT',
              displayName: 'Undermine Argument',
              description:
                'Questions that require undermining the argument with counter-evidence.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_ASSUMPTION',
          displayName: 'Assumption',
          description:
            'Questions that ask for unstated premises or assumptions required by the argument.',
          icon: 'remixQuestionFill',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_ASSUMPTION_IDENTIFY_ASSUMPTIONS',
              displayName: 'Identify Assumptions',
              description:
                'Questions that require identifying the underlying assumptions of the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_JUSTIFY_CONCLUSION',
          displayName: 'Justify the Conclusion',
          description:
            'Questions that ask for information that would guarantee the conclusion.',
          icon: 'remixShieldCheckLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_JUSTIFY_CONCLUSION_GUARANTEE',
              displayName: 'Guarantee Conclusion',
              description:
                'Questions that require providing information to ensure the conclusion is valid.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_PARALLEL_REASONING',
          displayName: 'Parallel Reasoning',
          description:
            'Questions that ask to identify arguments that are structurally similar to the given argument.',
          icon: 'remixAttachmentLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_PARALLEL_REASONING_FIND_SIMILAR_STRUCTURE',
              displayName: 'Find Similar Structure',
              description:
                'Questions that require finding arguments with similar logical structures.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_METHOD_OF_REASONING',
          displayName: 'Method of Reasoning',
          description:
            'Questions that ask about the technique or method used in the argument.',
          icon: 'remixToolsLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_METHOD_OF_REASONING_ANALYZE_TECHNIQUE',
              displayName: 'Analyze Reasoning Technique',
              description:
                'Questions that require analyzing the reasoning technique used in the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_FLAW_IN_REASONING',
          displayName: 'Flaw in the Reasoning',
          description:
            'Questions that ask to identify errors or flaws in the argument.',
          icon: 'remixErrorWarningLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_FLAW_IN_REASONING_IDENTIFY_LOGICAL_FLAW',
              displayName: 'Identify Logical Flaw',
              description:
                'Questions that require identifying logical flaws in the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_EVALUATE_ARGUMENT',
          displayName: 'Evaluate the Argument',
          description:
            'Questions that ask to identify information needed to evaluate the argument.',
          icon: 'remixCheckboxCircleLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_EVALUATE_ARGUMENT_NECESSARY_INFORMATION',
              displayName: 'Necessary Information',
              description:
                'Questions that require identifying necessary information to evaluate the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_POINT_AT_ISSUE',
          displayName: 'Point at Issue',
          description:
            'Questions that ask to identify the point of disagreement between two speakers.',
          icon: 'remixUserVoiceLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_POINT_AT_ISSUE_IDENTIFY_DISAGREEMENT',
              displayName: 'Identify Disagreement',
              description:
                'Questions that require identifying points of disagreement between speakers.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_INFERENCE',
          displayName: 'Inference',
          description:
            'Questions that require drawing an inference from the premises given.',
          icon: 'remixInferenceLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_INFERENCE_DRAW_CONCLUSIONS',
              displayName: 'Draw Conclusions',
              description:
                'Questions that require drawing conclusions based on the argument.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_PRINCIPLE',
          displayName: 'Principle Questions',
          description:
            'Questions that involve applying a principle to a situation or identifying a principle that supports or underlies the argument.',
          icon: 'remixBriefcaseLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_PRINCIPLE_APPLY_PRINCIPLE',
              displayName: 'Apply Principle',
              description:
                'Questions that require applying a general principle to a specific situation.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_LOGICAL_REASONING_PARADOX',
          displayName: 'Resolve the Paradox',
          description:
            'Questions that ask for information that resolves an apparent contradiction.',
          icon: 'remixContrastLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_LOGICAL_REASONING_PARADOX_RESOLVE_CONTRADICTION',
              displayName: 'Resolve Contradiction',
              description:
                'Questions that require resolving contradictions within the argument.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'LSAT_2024_ARGUMENTATIVE_WRITING',
      displayName: 'LSAT Argumentative Writing',
      description:
        'Assesses the ability to construct a clear and logical argument based on a given prompt. This section is unscored but sent to law schools as a sample of your writing and reasoning abilities.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for essay
      categories: [
        {
          categoryId: 'LSAT_2024_ARGUMENTATIVE_WRITING_ARGUMENTATION',
          displayName: 'Argumentation',
          description:
            'Assessing the ability to construct a clear and logical argument.',
          icon: 'remixTargetLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_ARGUMENTATIVE_WRITING_ARGUMENTATION_CLARITY_OF_POSITION',
              displayName: 'Clarity of Position',
              description:
                'Evaluating how clearly the test-taker states their chosen position.',
            },
            {
              subcategoryId:
                'LSAT_2024_ARGUMENTATIVE_WRITING_ARGUMENTATION_LOGICAL_REASONING',
              displayName: 'Logical Reasoning',
              description:
                'Assessing the strength and validity of the arguments presented.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_ARGUMENTATIVE_WRITING_USE_OF_EVIDENCE',
          displayName: 'Use of Evidence',
          description:
            'Evaluating the ability to use evidence effectively to support arguments.',
          icon: 'remixEvidenceLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_ARGUMENTATIVE_WRITING_USE_OF_EVIDENCE_SUPPORTING_REASONS',
              displayName: 'Supporting Reasons',
              description:
                'Assessing how well reasons are used to support the main argument.',
            },
            {
              subcategoryId:
                'LSAT_2024_ARGUMENTATIVE_WRITING_USE_OF_EVIDENCE_ADDRESS_COUNTERARGUMENTS',
              displayName: 'Addressing Counterarguments',
              description:
                'Evaluating the ability to anticipate and refute opposing views.',
            },
          ],
        },
        {
          categoryId: 'LSAT_2024_ARGUMENTATIVE_WRITING_WRITING_MECHANICS',
          displayName: 'Writing Mechanics',
          description:
            'Assessing the command of language, including grammar, syntax, and style.',
          icon: 'remixGrammarLine',
          subcategories: [
            {
              subcategoryId:
                'LSAT_2024_ARGUMENTATIVE_WRITING_WRITING_MECHANICS_GRAMMAR_SYNTAX',
              displayName: 'Grammar and Syntax',
              description:
                'Evaluating the correctness of grammar and sentence structure.',
            },
            {
              subcategoryId:
                'LSAT_2024_ARGUMENTATIVE_WRITING_WRITING_MECHANICS_STYLE_TONE',
              displayName: 'Style and Tone',
              description:
                'Assessing the appropriateness of style and tone for an academic argument.',
            },
          ],
        },
      ],
    },
  ],
};
