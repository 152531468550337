<div class="flex flex-col space-y-4 max-w-lg mx-auto">
  <!-- Instruction -->
  <p class="text-gray-700 text-center">Select all that apply:</p>

  <!-- Error Message -->
  <div *ngIf="showError" class="text-red-500 text-center">
    {{ errorMessage }}
  </div>

  <!-- Multiple Answer Options -->
  <div *ngFor="let option of questionOptions?.options" class="p-2">
    <label
      class="flex items-center select-none p-4 rounded-lg transition-colors shadow-sm border cursor-pointer"
      [ngClass]="getOptionCss(option)"
      role="checkbox"
      [attr.aria-disabled]="isOptionDisabled() ? true : null"
    >
      <!-- Hidden Checkbox Input -->
      <input
        type="checkbox"
        [value]="option.id"
        class="hidden"
        (change)="onCheckboxChange(option.id, $event)"
        [checked]="selectedOptions.includes(option.id)"
        [disabled]="isOptionDisabled()"
      />

      <!-- Custom Checkbox Button -->
      <div
        class="min-w-[2rem] min-h-[2rem] w-8 h-8 flex items-center justify-center rounded border-2 transition-colors"
        [ngClass]="getCheckboxCss(option)"
      >
        <!-- Icon or Empty -->
        <ng-container [ngSwitch]="getIconName(option)">
          <ng-icon
            *ngSwitchCase="'faSolidCheck'"
            name="faSolidCheck"
            class="text-white w-4 h-4"
          ></ng-icon>
          <ng-icon
            *ngSwitchCase="'faSolidXmark'"
            name="faSolidXmark"
            class="text-white w-4 h-4"
          ></ng-icon>
          <ng-icon
            *ngSwitchCase="'faSolidExclamation'"
            name="faSolidExclamation"
            class="text-white w-4 h-4"
          ></ng-icon>
          <!-- Empty space if no icon -->
          <span *ngSwitchDefault></span>
        </ng-container>
      </div>

      <!-- Option Text -->
      <span class="ml-4 text-gray-700">{{ option.text }}</span>
    </label>
  </div>
</div>
