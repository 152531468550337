import {Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {trimAndMinLengthValidator} from "../../shared/validation/trim-and-min-length.validator";
import {AuthenticationError} from "../../shared/util/error-types";
import {passwordValidator} from "../../shared/validation/password.validator";
import {NavigationService} from "../../services/navigation-service";
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {
  TermsConditionsModalComponent
} from "../../shared/components/terms-conditions-modal/terms-conditions-modal.component";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgOptimizedImage,
    NgIf,
    TermsConditionsModalComponent,
    RouterLink
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent implements OnInit {

  public form = new FormGroup({
    //uname: new FormControl('', [Validators.required, Validators.minLength(6)]),
    email: new FormControl('', [Validators.required, trimAndMinLengthValidator(5), Validators.email]),
    password: new FormControl('', [Validators.required, trimAndMinLengthValidator(8), passwordValidator(8)]),
    firstName : new FormControl('', [Validators.required, trimAndMinLengthValidator(2)]),
    lastName : new FormControl('', [Validators.required, trimAndMinLengthValidator(2)]),
    terms: new FormControl(false, [Validators.requiredTrue]) // Checkbox for terms & conditions
  });

  isSubmitting = false;
  showTermsModal = false; // Track the modal visibility
  errorMessage: string = '';

  constructor(private route: ActivatedRoute, private metaService: MetaService,
              private router: Router, private authService: AuthService, private navigationService: NavigationService) {}

  ngOnInit(): void {
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);

    // Check if the user is already logged in
    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        // Redirect to exams page if user is already logged in
        this.router.navigate(['/exams']);
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  onRegisterClick() {
    if (this.isSubmitting) {
      return; // Prevent multiple submissions
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched(); // Mark all controls as touched to show validation errors
      return;
    }

    const { email, password, firstName, lastName } = this.form.value;

    // Handle case where email or password are empty (should not happen with form validation)
    if (!email || !password || !firstName || !lastName) {
      this.errorMessage = ' Fields are invalid.';
      this.form.get('email')?.markAsTouched();
      this.form.get('password')?.markAsTouched();
      this.form.get('firstName')?.markAsTouched();
      this.form.get('lastName')?.markAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.authService.firebaseRegisterWithEmail(email!, password!, firstName!, lastName!).subscribe({
      next: (response) => {
        this.errorMessage = '';
        const redirectUrl = this.navigationService.getPreviousUrl() || '/exams';
        this.router.navigateByUrl(redirectUrl);
      },
      error: (error) => {
        console.error('Registration error:', error);
        this.isSubmitting = false; // Stop submitting state
        this.setErrorMessageFromError(error); // Set appropriate error message
      },
      complete: () => {
        this.isSubmitting = false; // Reset flag after submission
      }
    });
  }

  // Google login handler
  // Google login method
  onGoogleLoginClick(): void {
    // if (this.isSubmitting) {
    //   return; // Prevent multiple submissions
    // }
    this.isSubmitting = true;
    this.authService.firebaseSignInWithGoogle().subscribe(
      (response) => {
        // Successful login
        this.errorMessage = '';
        const redirectUrl = this.navigationService.getPreviousUrl() || '/exams';
        this.router.navigateByUrl(redirectUrl);
      },
      (error) => {
        console.error('Google sign-in error:', error);
        this.setErrorMessageFromError(error); // Handle and set error message
      },
      () => {
        this.isSubmitting = false; // Reset the flag after completion
      }
    );
  }

  // Error message handling
  setErrorMessageFromError(error: any): void {
    if (error instanceof AuthenticationError) {
      this.errorMessage = error.message; // Directly use the message from the error
    } else {
      this.errorMessage = 'An error occurred while logging in. Please try again later.';
    }
  }

  // Open the modal
  openTermsModal() {
    this.showTermsModal = true;
  }

  // Close the modal
  closeTermsModal() {
    this.showTermsModal = false;
  }



}
