<div class="min-h-screen relative overflow-hidden">

  <!-- White Background Layer -->
  <div class="absolute inset-0 bg-white z-0"></div>

  <img class="z-2 absolute top-0 left-0" src="assets/images/home/gradient4-top.svg" alt="">

  <div class="relative z-10 min-h-screen flex flex-col">
    <app-landing-navigation class="w-full"></app-landing-navigation>

    <div class="w-full flex-1">
      <router-outlet></router-outlet>
    </div>
    <app-landing-footer></app-landing-footer>
  </div>
</div>
