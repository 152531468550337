<!-- Filters Menu Section -->
<div class="flex justify-center space-x-4 items-center mb-4 px-6">

  <!-- Exam Title (formatted with label, no white box) -->
  <div *ngIf="examName() !== null" class="min-w-0 mr-5">
    <label class="block text-sm font-medium text-gray-700">Exam</label>
    <div class="text-xl font-bold text-gray-900 truncate">
      <!-- Exam Name as static title -->
      {{ examName() }}
    </div>
  </div>

  <!-- Section Filter -->
  <div class="relative inline-block text-left" style="min-width: 300px;">
    <label for="section-menu-button" class="block text-sm font-medium text-gray-700">Section</label>
    <div>
      <button (click)="toggleSectionMenu()" type="button"
              class="inline-flex justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full truncate"
              id="section-menu-button" aria-expanded="true" aria-haspopup="true" style="max-width: 300px;">
        <!-- Selected item is truncated -->
        <span class="truncate text-left">{{ selectedExamSection.examSectionDisplayName || 'Auto' }}</span>
        <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M19 9l-7 7-7-7" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>

    <!-- Dropdown Menu for Section Filter -->
    <div *ngIf="isSectionMenuOpen" class="absolute left-0 z-10 mt-2 w-80 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="section-menu-button" tabindex="-1">
      <div class="py-1" role="none">
        <!-- Full width items inside the dropdown -->
        <a *ngFor="let examSection of examSections"
           (click)="selectSection(examSection); isSectionMenuOpen = false;"
           class="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 text-left" role="menuitem" tabindex="-1">
          {{ examSection.examSectionDisplayName }}
        </a>
      </div>
    </div>
  </div>

  <!-- Difficulty Filter -->
  <div class="relative inline-block text-left" style="min-width: 200px;">
    <label for="difficulty-menu-button" class="block text-sm font-medium text-gray-700">Difficulty</label>
    <div>
      <button (click)="toggleDifficultyMenu()" type="button"
              class="inline-flex justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full truncate"
              id="difficulty-menu-button" aria-expanded="true" aria-haspopup="true" style="max-width: 200px;">
        <span class="truncate text-left">{{ selectedDifficultyLevel.difficultyDisplayName || 'Auto' }}</span>
        <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M19 9l-7 7-7-7" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>

    <!-- Dropdown Menu for Difficulty Filter -->
    <div *ngIf="isDifficultyMenuOpen" class="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="difficulty-menu-button" tabindex="-1">
      <div class="py-1" role="none">
        <a *ngFor="let difficulty of difficulties"
           (click)="selectDifficulty(difficulty); isDifficultyMenuOpen = false;"
           class="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 text-left" role="menuitem" tabindex="-1">
          {{ difficulty.difficultyDisplayName }}
        </a>
      </div>
    </div>
  </div>

  <!-- Apply Button -->
  <div class="ml-auto">
    <button class="inline-flex justify-center gap-x-1.5 rounded-md bg-custom-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-custom-blue-600" (click)="applyFilters()">
      Apply Filters
    </button>
  </div>
</div>

<div *ngIf="this.questionState == QuestionState.LOADING" class="flex justify-center items-center h-32">
  <!-- Tailwind CSS Loading Spinner -->
  <svg class="animate-spin h-8 w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
  </svg>
</div>

<!-- Display Error Message -->
<div
  *ngIf="questionState === QuestionState.ERROR"
  class="max-w-xl mx-auto p-8 bg-white shadow-lg rounded-lg border border-gray-200 flex flex-col justify-center items-center text-center space-y-6"
>
  <!-- Error Icon -->
  <ng-icon
    name="remixErrorWarningLine"
    size="4rem"
    class="text-red-500"
  ></ng-icon>

  <!-- Error Message -->
  <p class="text-black text-xl font-semibold">
    {{ errorMessage || 'An error occurred while loading the question.' }}
  </p>

  <!-- Action Buttons -->
  <div class="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
    <button
      (click)="reloadQuestion()"
      class="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded"
    >
      Reload
    </button>
    <button
      (click)="nextQuestion()"
      class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-6 rounded"
    >
      Next Question
    </button>
  </div>
</div>

<!-- Display question normally -->
<div class="flex flex-wrap justify-center" *ngIf="this.questionState!=QuestionState.LOADING && this.questionState!=QuestionState.ERROR">
  <div [ngClass]="{
        'flex flex-col items-center': !twoPanelLayout(),
        'flex flex-row justify-center space-x-4': twoPanelLayout()
      }"
       class="w-full">

    <!-- Left Panel (Question Panel) -->
    <div [ngClass]="{
          'w-full max-w-[65%] mx-auto': !twoPanelLayout(),
          'w-full lg:w-1/2': twoPanelLayout()
        }"
         class="p-4 transition-width min-w-[400px] lg:max-w-[50%]">
      <div class="card bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="px-6 py-3">

          <!-- Section Display Name Badge -->
<!--          <div *ngIf="questionResponse?.examSectionDisplayName" class="mt-2">-->
<!--            <span class="inline-flex items-center px-2 py-1 rounded text-sm font-medium bg-blue-100 text-blue-800">-->
<!--              <ng-icon name="faSolidBook" class="w-4 h-4 mr-1"></ng-icon>-->
<!--              {{ questionResponse?.examSectionDisplayName }}-->
<!--            </span>-->
<!--          </div>-->
          <!-- Section Display Name -->
          <div *ngIf="questionResponse?.examSectionDisplayName" class="text-sm text-gray-500 mb-2">
            {{ questionResponse?.examSectionDisplayName }}
          </div>

          <!-- Title with Bookmark Icon on the left -->
          <div class="flex items-center space-x-2 mb-1">
            <button (click)="toggleBookmark()" aria-label="Bookmark" class="hover:text-gray-600 transition-colors">
              <!-- Conditionally render the Heroicon Bookmark based on isBookmarked -->
              <svg *ngIf="!isBookmarked" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5v16l7-5 7 5V5z" />
              </svg>
              <svg *ngIf="isBookmarked" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-500" fill="currentColor" viewBox="0 0 24 24" stroke="none">
                <path d="M5 5v16l7-5 7 5V5z" />
              </svg>
            </button>
            <h2 class="text-sm font-medium leading-5 text-gray-900 sm:text-base sm:leading-6">
              {{questionResponse?.title}}
            </h2>
          </div>

          <!-- Chips Section -->
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:space-x-2">
            <span class="chip bg-gray-100 text-gray-700 px-2 py-1 rounded-full" [ngClass]="getDifficultyCss()">
              {{questionResponse?.difficultyLevelResponse?.difficultyDisplayName | titlecase}}
            </span>

            <span class="chip bg-gray-100 text-gray-700 px-2 py-1 rounded-full" *ngIf="hasCategories">
              {{firstCategoryDisplayName | titlecase}}
            </span>

            <span class="chip bg-gray-100 text-gray-700 px-2 py-1 rounded-full" *ngIf="hasSubcategories">
              {{firstSubcategoryDisplayName | titlecase}}
            </span>
          </div>
        </div>

        <!-- Prompt Section -->
        <div class="px-6 pt-3">
          <div class="text-justify">
            <div class="no-select no-right-click" [innerHTML]="sanitizedPrompt" *ngIf="hasPrompt"></div>
          </div>
        </div>

        <!-- Supported Context Message -->
        <div *ngIf="questionResponse?.questionFormatResponse?.supportedContext" class="px-6 pt-3">
          <div class="p-4 bg-blue-50 border-l-4 border-blue-400 text-blue-700 mb-4 flex items-center">
            <ng-icon name="faSolidCircleInfo" class="text-blue-400 mr-2 w-5 h-5 flex-shrink-0"></ng-icon>
            <p class="text-sm">
              {{questionResponse?.questionFormatResponse?.supportedContext}}
            </p>
          </div>
        </div>

        <!-- the display of the user input depends on the option-type-->
        <div [ngSwitch]="questionResponse?.questionFormatResponse?.type" class="px-6 py-4">

          <!-- multiple choice types -->
          <multiple-choice *ngSwitchCase="QuestionFormatType.MULTIPLE_CHOICE"
                   [questionId]="questionResponse?.questionId ?? null"
                   [questionOptions]="questionResponse?.options ?? null"
                   [questionState]="questionState"
                   [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                   (onValidate)="handleValidation($event)"/>

          <multiple-choice *ngSwitchCase="QuestionFormatType.DATA_SUFFICIENCY_MULTIPLE_CHOICE"
                   [questionId]="questionResponse?.questionId ?? null"
                   [questionOptions]="questionResponse?.options ?? null"
                   [questionState]="questionState"
                   [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                   (onValidate)="handleValidation($event)"/>

          <multiple-choice *ngSwitchCase="QuestionFormatType.QUANT_COMPARISON_MULTIPLE_CHOICE"
                   [questionId]="questionResponse?.questionId ?? null"
                   [questionOptions]="questionResponse?.options ?? null"
                   [questionState]="questionState"
                   [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                   (onValidate)="handleValidation($event)"/>

          <multiple-choice *ngSwitchCase="QuestionFormatType.PASSAGE_BASED_MULTIPLE_CHOICE"
                           [questionId]="questionResponse?.questionId ?? null"
                           [questionOptions]="questionResponse?.options ?? null"
                           [questionState]="questionState"
                           [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                           (onValidate)="handleValidation($event)"/>

          <multiple-choice *ngSwitchCase="QuestionFormatType.VIGNETTE_MULTIPLE_CHOICE"
                           [questionId]="questionResponse?.questionId ?? null"
                           [questionOptions]="questionResponse?.options ?? null"
                           [questionState]="questionState"
                           [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                           (onValidate)="handleValidation($event)"/>

          <multiple-answer *ngSwitchCase="QuestionFormatType.MULTIPLE_ANSWER"
                           [questionId]="questionResponse?.questionId ?? null"
                           [questionOptions]="questionResponse?.options ?? null"
                           [questionState]="questionState"
                           [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                           (onValidate)="handleValidation($event)"/>

          <!-- essay types -->
          <app-essay *ngSwitchCase="QuestionFormatType.ESSAY"
                     [questionId]="questionResponse?.questionId ?? null"
                     [questionState]="questionState"
                     [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                     (onValidate)="handleValidation($event)"/>

          <app-essay *ngSwitchCase="QuestionFormatType.FREE_RESPONSE"
                     [questionId]="questionResponse?.questionId ?? null"
                     [questionState]="questionState"
                     [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                     (onValidate)="handleValidation($event)"/>

          <app-essay *ngSwitchCase="QuestionFormatType.SHORT_ANSWER"
                     [questionId]="questionResponse?.questionId ?? null"
                     [questionState]="questionState"
                     [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                     (onValidate)="handleValidation($event)"/>

          <!-- Other cases -->
          <grid-in *ngSwitchCase="QuestionFormatType.GRID_IN"
                   [questionId]="questionResponse?.questionId ?? null"
                   [questionState]="questionState"
                   [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                   (onValidate)="handleValidation($event)"/>

          <grid-in *ngSwitchCase="QuestionFormatType.FILL_IN_NUMERICAL"
                   [questionId]="questionResponse?.questionId ?? null"
                   [questionState]="questionState"
                   [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                   (onValidate)="handleValidation($event)"/>

          <app-essay *ngSwitchCase="QuestionFormatType.SPEAKING_TASK"
                     [questionId]="questionResponse?.questionId ?? null"
                     [questionState]="questionState"
                     [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                     (onValidate)="handleValidation($event)"/>

          <app-essay *ngSwitchCase="QuestionFormatType.WRITING_TASK"
                     [questionId]="questionResponse?.questionId ?? null"
                     [questionState]="questionState"
                     [userQuestionInteractionResponse]="answerQuestionResponse?.userQuestionInteractionResponse ?? null"
                     (onValidate)="handleValidation($event)"/>
        </div>

        <div class="button-container px-6 py-4 flex justify-between items-center w-full space-x-4">

          <!-- Spinner while loading (centered) -->
          <div class="flex justify-center w-full" *ngIf="this.questionState === QuestionState.VALIDATE || this.questionState === QuestionState.VALIDATE_SUCCESS || this.questionState === QuestionState.SUBMIT || this.questionState === QuestionState.SKIP || this.questionState === QuestionState.TRY_AGAIN">
            <svg class="animate-spin h-6 w-6 text-grey-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8 8 8 0 01-8-8z"></path>
            </svg>
          </div>

          <!-- Buttons for question actions (aligned to the right) -->
          <div class="flex space-x-4 ml-auto" *ngIf="this.questionState == QuestionState.SHOW_QUESTION">
            <button (click)="submitAnswer()" class="bg-custom-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-custom-blue-600 transition-colors duration-300">
              Submit
            </button>
            <button (click)="skipQuestion()" class="border border-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-100 hover:border-gray-400 transition-colors duration-300 font-medium">
              Skip
            </button>
          </div>

          <!-- Buttons for review actions (aligned to the right) -->
          <div class="flex space-x-4 ml-auto" *ngIf="this.questionState == 'REVIEW_INCORRECT'">
            <!-- Try Again Button -->
            <button (click)="tryAgain()" class="bg-gray-100 text-gray-700 px-4 py-2 rounded-lg shadow hover:bg-gray-200 transition-colors duration-300 font-medium">
              Try Again
            </button>
            <!-- Show Solution Button -->
            <button (click)="reviewQuestion()" class="bg-custom-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-custom-blue-600 transition-colors duration-300 font-medium">
              Show Solution
            </button>
          </div>
        </div>
      </div>

      <app-question-hint-stats-component
        [questionResponse]="questionResponse">
      </app-question-hint-stats-component>
    </div>

    <!-- The solution panel, if the question was submitted -->
    <div *ngIf="twoPanelLayout()" class="p-4 transition-width w-full lg:w-1/2 min-w-[400px]">
      <div class="card bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="px-6 py-4">
          <!-- ngSwitch to display based on question type -->
          <div [ngSwitch]="questionResponse?.questionFormatResponse?.type">

            <!-- Multiple choice or multiple answer types -->
            <ng-container *ngSwitchCase="QuestionFormatType.MULTIPLE_CHOICE">
              <choice-explanation-solution [answerQuestionResponse]="answerQuestionResponse">
              </choice-explanation-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.DATA_SUFFICIENCY_MULTIPLE_CHOICE">
              <choice-explanation-solution [answerQuestionResponse]="answerQuestionResponse">
              </choice-explanation-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.QUANT_COMPARISON_MULTIPLE_CHOICE">
              <choice-explanation-solution [answerQuestionResponse]="answerQuestionResponse">
              </choice-explanation-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.PASSAGE_BASED_MULTIPLE_CHOICE">
              <choice-explanation-solution [answerQuestionResponse]="answerQuestionResponse">
              </choice-explanation-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.VIGNETTE_MULTIPLE_CHOICE">
              <choice-explanation-solution [answerQuestionResponse]="answerQuestionResponse">
              </choice-explanation-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.MULTIPLE_ANSWER">
              <choice-explanation-solution [answerQuestionResponse]="answerQuestionResponse">
              </choice-explanation-solution>
            </ng-container>

            <!-- Essay types -->
            <ng-container *ngSwitchCase="QuestionFormatType.ESSAY">
              <essay-solution [answerQuestionResponse]="answerQuestionResponse">
              </essay-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.FREE_RESPONSE">
              <essay-solution [answerQuestionResponse]="answerQuestionResponse">
              </essay-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.SHORT_ANSWER">
              <essay-solution [answerQuestionResponse]="answerQuestionResponse">
              </essay-solution>
            </ng-container>

            <!-- Other types -->
            <ng-container *ngSwitchCase="QuestionFormatType.GRID_IN">
              <grid-in-solution [answerQuestionResponse]="answerQuestionResponse">
              </grid-in-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.FILL_IN_NUMERICAL">
              <grid-in-solution [answerQuestionResponse]="answerQuestionResponse">
              </grid-in-solution>
            </ng-container>


            <ng-container *ngSwitchCase="QuestionFormatType.SPEAKING_TASK">
              <essay-solution [answerQuestionResponse]="answerQuestionResponse">
              </essay-solution>
            </ng-container>

            <ng-container *ngSwitchCase="QuestionFormatType.WRITING_TASK">
              <essay-solution [answerQuestionResponse]="answerQuestionResponse">
              </essay-solution>
            </ng-container>


            <!-- Fallback if the type is not recognized -->
            <div *ngSwitchDefault> Solution not available for this question type.</div>
          </div>

          <!-- Button Container -->
          <div class="button-container py-4 flex justify-end space-x-4">
            <!-- Back to Dashboard Button -->
            <button class="bg-gray-100 text-gray-700 px-4 py-2 rounded-lg shadow hover:bg-gray-200 transition-colors duration-300 font-medium border border-gray-300" (click)="navigateToDashboard()">
              Back To Dashboard
            </button>
            <!-- Next Question Button -->
            <button class="bg-custom-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-custom-blue-600 transition-colors duration-300 font-medium border border-custom-blue-700" (click)="nextQuestion()">
              Next Question
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-upgrade-modal *ngIf="showUpgradeModal" (close)="closeModal()" [examData]="examData"></app-upgrade-modal>
