
// ACT Exam Data
import {ExamData} from "../exam-data-interface";

export const actExamData: ExamData = {
  examId: 'ACT_2024',
  displayName: 'ACT',
  description:
    'The ACT is a standardized test for college admissions, assessing English, mathematics, reading, and science reasoning to evaluate academic readiness for college.',
  logo: 'assets/images/exams/act_logo.svg',
  link: 'act',
  zoom: 0.8,
  priceId: 'ACT_2024_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Ace the ACT with Personalized Learning',
  marketingSubtitles: [
    'Our adaptive platform targets your weaknesses to maximize your ACT score.',
    'Gain access to over 40,000 ACT practice questions with detailed explanations.',
    'Improve your ACT score by 5+ points with tailored learning plans',
    'Pinpoint your weakest ACT subjects and boost your confidence',
  ],
  pricingTitle: 'Unlimited Practice with In-Depth Solutions',
  pricingSubtitle: [
    'Boost your scores with high-quality questions and personalized insights at an affordable price.',
  ],
  pricingDescription: '',
  otherTitles: [],

  sections: [
    // ACT Sections and Categories
    {
      examSectionId: 'ACT_2024_ENGLISH',
      displayName: 'ACT English Section',
      description:
        'Assesses a student’s ability to revise and improve written texts, focusing on grammar, punctuation, sentence structure, and overall clarity. Evaluates how well students can identify and correct errors to ensure writing is clear and grammatically correct. Scores range from 1 to 36 based on correct answers.',
      icon: 'remixQuillPenLine', // ng-icons Remix Icon representing writing or English
      categories: [
        {
          categoryId: 'ACT_2024_ENGLISH_USAGE_AND_MECHANICS',
          displayName: 'Usage and Mechanics',
          description:
            'Covers grammar, punctuation, sentence structure, and conventions of standard written English.',
          icon: 'remixSettingsLine', // ng-icons Remix Icon representing mechanics
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_ENGLISH_USAGE_AND_MECHANICS_PUNCTUATION',
              displayName: 'Punctuation',
              description:
                'Understanding and correctly using punctuation marks such as commas, apostrophes, colons, and semicolons.',
            },
            {
              subcategoryId:
                'ACT_2024_ENGLISH_USAGE_AND_MECHANICS_GRAMMAR_AND_USAGE',
              displayName: 'Grammar and Usage',
              description:
                'Understanding and applying rules of grammar and usage in writing.',
            },
            {
              subcategoryId:
                'ACT_2024_ENGLISH_USAGE_AND_MECHANICS_SENTENCE_STRUCTURE',
              displayName: 'Sentence Structure',
              description:
                'Understanding sentence formation and structure, including modifiers, clauses, and parallelism.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_ENGLISH_RHETORICAL_SKILLS',
          displayName: 'Rhetorical Skills',
          description:
            'Evaluates writing strategy, organization, and style.',
          icon: 'remixChat3Line', // ng-icons Remix Icon representing rhetorical skills
          subcategories: [
            {
              subcategoryId: 'ACT_2024_ENGLISH_RHETORICAL_SKILLS_STRATEGY',
              displayName: 'Strategy',
              description:
                'Understanding and improving the effectiveness of a text through strategic choices.',
            },
            {
              subcategoryId:
                'ACT_2024_ENGLISH_RHETORICAL_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description:
                'Enhancing the logical flow and coherence of a text.',
            },
            {
              subcategoryId: 'ACT_2024_ENGLISH_RHETORICAL_SKILLS_STYLE',
              displayName: 'Style',
              description:
                'Improving the tone, clarity, and effectiveness of language in writing.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ACT_2024_MATH',
      displayName: 'ACT Math Section',
      description:
        'Evaluates mathematical skills and problem-solving abilities across topics like algebra, geometry, trigonometry, and basic statistics. Tests the ability to apply concepts and solve problems efficiently, involving calculations, data interpretation, and formula application. Scores range from 1 to 36.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon representing math
      categories: [
        {
          categoryId: 'ACT_2024_MATH_PREPARING_FOR_HIGHER_MATH',
          displayName: 'Preparing for Higher Math',
          description:
            'Tests knowledge in number and quantity, algebra, functions, geometry, and statistics and probability.',
          icon: 'remixLineChartLine', // ng-icons Remix Icon representing advanced math preparation
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_MATH_PREPARING_FOR_HIGHER_MATH_NUMBER_AND_QUANTITY',
              displayName: 'Number and Quantity',
              description:
                'Understanding and working with different types of numbers and performing operations on them.',
            },
            {
              subcategoryId:
                'ACT_2024_MATH_PREPARING_FOR_HIGHER_MATH_ALGEBRA',
              displayName: 'Algebra',
              description:
                'Solving equations, inequalities, and systems of equations; interpreting algebraic expressions.',
            },
            {
              subcategoryId:
                'ACT_2024_MATH_PREPARING_FOR_HIGHER_MATH_FUNCTIONS',
              displayName: 'Functions',
              description:
                'Understanding and interpreting functions, their properties, and graphs.',
            },
            {
              subcategoryId:
                'ACT_2024_MATH_PREPARING_FOR_HIGHER_MATH_GEOMETRY',
              displayName: 'Geometry',
              description:
                'Solving problems involving geometric shapes, properties, and theorems.',
            },
            {
              subcategoryId:
                'ACT_2024_MATH_PREPARING_FOR_HIGHER_MATH_STATISTICS_AND_PROBABILITY',
              displayName: 'Statistics and Probability',
              description:
                'Applying concepts of statistics and probability to interpret and analyze data.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_MATH_INTEGRATING_ESSENTIAL_SKILLS',
          displayName: 'Integrating Essential Skills',
          description:
            'Tests ability to integrate skills from various topics to solve complex problems.',
          icon: 'remixPuzzleLine', // ng-icons Remix Icon representing integration of skills
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_MATH_INTEGRATING_ESSENTIAL_SKILLS_ARITHMETIC',
              displayName: 'Arithmetic',
              description:
                'Solving complex problems requiring a combination of basic arithmetic skills.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_MATH_MODELING',
          displayName: 'Modeling',
          description:
            'Ability to model real-world situations using mathematical concepts and solve problems using these models.',
          icon: 'remixOrganizationChart', // ng-icons Remix Icon representing modeling
          subcategories: [
            {
              subcategoryId: 'ACT_2024_MATH_MODELING_APPLIED_MATHEMATICS',
              displayName: 'Applied Mathematics',
              description:
                'Creating and using mathematical models to solve real-world problems.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ACT_2024_READING',
      displayName: 'ACT Reading Section',
      description:
        'Assesses the ability to read and comprehend various texts, including literature, social studies, and natural sciences. Tests understanding of main ideas, details, inferences, and author’s intent and tone. Scores range from 1 to 36.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon representing reading
      categories: [
        {
          categoryId: 'ACT_2024_READING_KEY_IDEAS_AND_DETAILS',
          displayName: 'Key Ideas and Details',
          description:
            'Tests ability to read closely, determine central ideas and themes, summarize information, and understand relationships.',
          icon: 'remixKeyLine', // ng-icons Remix Icon representing key ideas
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_READING_KEY_IDEAS_AND_DETAILS_CLOSE_READING',
              displayName: 'Close Reading',
              description:
                'Reading closely to determine explicit content and make logical inferences.',
            },
            {
              subcategoryId:
                'ACT_2024_READING_KEY_IDEAS_AND_DETAILS_CENTRAL_IDEAS_AND_THEMES',
              displayName: 'Central Ideas and Themes',
              description:
                'Determining central ideas or themes and analyzing their development.',
            },
            {
              subcategoryId:
                'ACT_2024_READING_KEY_IDEAS_AND_DETAILS_RELATIONSHIPS',
              displayName: 'Relationships',
              description:
                'Understanding how characters, events, and ideas interact and develop.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_READING_CRAFT_AND_STRUCTURE',
          displayName: 'Craft and Structure',
          description:
            'Analyzes text structure and interprets the meaning of words and phrases as used.',
          icon: 'remixToolsLine', // ng-icons Remix Icon representing craft
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_READING_CRAFT_AND_STRUCTURE_TEXT_STRUCTURE',
              displayName: 'Text Structure',
              description:
                'Analyzing how specific parts of a text relate to each other and the whole.',
            },
            {
              subcategoryId:
                'ACT_2024_READING_CRAFT_AND_STRUCTURE_VOCABULARY_AND_WORD_CHOICE',
              displayName: 'Vocabulary and Word Choice',
              description:
                'Interpreting word meanings and understanding their impact on meaning and tone.',
            },
          ],
        },
        {
          categoryId:
            'ACT_2024_READING_INTEGRATION_OF_KNOWLEDGE_AND_IDEAS',
          displayName: 'Integration of Knowledge and Ideas',
          description:
            'Integrates and evaluates content from diverse media and formats; analyzes arguments and claims.',
          icon: 'remixLightbulbLine', // ng-icons Remix Icon representing ideas
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_READING_INTEGRATION_OF_KNOWLEDGE_AND_IDEAS_DIVERSE_MEDIA_AND_FORMATS',
              displayName: 'Diverse Media and Formats',
              description:
                'Integrating and evaluating content presented in different formats.',
            },
            {
              subcategoryId:
                'ACT_2024_READING_INTEGRATION_OF_KNOWLEDGE_AND_IDEAS_ARGUMENTS_AND_CLAIMS',
              displayName: 'Arguments and Claims',
              description:
                'Analyzing how authors build arguments and evaluating the validity of claims.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ACT_2024_SCIENCE',
      displayName: 'ACT Science Section',
      description:
        'Evaluates ability to interpret, analyze, and evaluate scientific information, including experiments and data interpretation across biology, chemistry, and physics. Scores range from 1 to 36.',
      icon: 'remixFlaskLine', // ng-icons Remix Icon representing science
      categories: [
        {
          categoryId: 'ACT_2024_SCIENCE_DATA_REPRESENTATION',
          displayName: 'Data Representation',
          description:
            'Tests ability to read and interpret data in tables, graphs, and diagrams.',
          icon: 'remixBarChartLine', // ng-icons Remix Icon representing data
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_SCIENCE_DATA_REPRESENTATION_UNDERSTANDING_GRAPHS_AND_TABLES',
              displayName: 'Understanding Graphs and Tables',
              description:
                'Interpreting information presented in visual formats.',
            },
            {
              subcategoryId:
                'ACT_2024_SCIENCE_DATA_REPRESENTATION_INTERPRETING_EXPERIMENTAL_DATA',
              displayName: 'Interpreting Experimental Data',
              description:
                'Analyzing experimental data to draw conclusions and make inferences.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_SCIENCE_RESEARCH_SUMMARIES',
          displayName: 'Research Summaries',
          description:
            'Tests ability to understand and interpret descriptions of scientific experiments and studies.',
          icon: 'remixFileTextLine', // ng-icons Remix Icon representing summaries
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_SCIENCE_RESEARCH_SUMMARIES_EXPERIMENTAL_DESIGN',
              displayName: 'Experimental Design',
              description:
                'Understanding the design and methodology of scientific experiments.',
            },
            {
              subcategoryId:
                'ACT_2024_SCIENCE_RESEARCH_SUMMARIES_INTERPRETING_RESULTS',
              displayName: 'Interpreting Results',
              description:
                'Analyzing experimental results and drawing conclusions.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_SCIENCE_CONFLICTING_VIEWPOINTS',
          displayName: 'Conflicting Viewpoints',
          description:
            'Tests ability to understand and compare different scientific viewpoints or hypotheses.',
          icon: 'remixExchangeLine', // ng-icons Remix Icon representing conflicting views
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_SCIENCE_CONFLICTING_VIEWPOINTS_COMPARING_HYPOTHESES',
              displayName: 'Comparing Hypotheses',
              description:
                'Evaluating and comparing different scientific hypotheses or viewpoints.',
            },
            {
              subcategoryId:
                'ACT_2024_SCIENCE_CONFLICTING_VIEWPOINTS_ANALYZING_ARGUMENTS',
              displayName: 'Analyzing Arguments',
              description:
                'Understanding and analyzing arguments in scientific passages.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ACT_2024_WRITING',
      displayName: 'ACT Writing Section',
      description:
        'Assesses ability to construct a coherent and effective essay, articulating a viewpoint, analyzing perspectives, and demonstrating clear reasoning. Scored on a scale of 2 to 12 based on essay quality.',
      icon: 'remixPencilLine', // ng-icons Remix Icon representing writing
      categories: [
        {
          categoryId: 'ACT_2024_WRITING_IDEAS_ANALYSIS',
          displayName: 'Ideas and Analysis',
          description:
            'Evaluates ability to generate productive ideas and engage critically with multiple perspectives on an issue.',
          icon: 'remixLightbulbLine', // ng-icons Remix Icon representing ideas
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_WRITING_IDEAS_ANALYSIS_GENERATING_IDEAS',
              displayName: 'Generating Ideas',
              description:
                'Creating relevant ideas well-suited to the topic.',
            },
            {
              subcategoryId:
                'ACT_2024_WRITING_IDEAS_ANALYSIS_ENGAGING_WITH_PERSPECTIVES',
              displayName: 'Engaging with Perspectives',
              description:
                'Considering and analyzing multiple viewpoints on the issue.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_WRITING_DEVELOPMENT_SUPPORT',
          displayName: 'Development and Support',
          description:
            'Assesses ability to develop ideas and support them with reasoning and examples.',
          icon: 'remixHandHeartLine', // ng-icons Remix Icon representing support
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_WRITING_DEVELOPMENT_SUPPORT_LOGICAL_REASONING',
              displayName: 'Logical Reasoning',
              description:
                'Using logical reasoning to support ideas and arguments.',
            },
            {
              subcategoryId:
                'ACT_2024_WRITING_DEVELOPMENT_SUPPORT_EXAMPLE_INTEGRATION',
              displayName: 'Example Integration',
              description:
                'Effectively integrating examples to illustrate and support ideas.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_WRITING_ORGANIZATION',
          displayName: 'Organization',
          description:
            'Evaluates ability to organize ideas and information coherently and logically.',
          icon: 'remixOrganizationChart', // ng-icons Remix Icon representing organization
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_WRITING_ORGANIZATION_LOGICAL_STRUCTURE',
              displayName: 'Logical Structure',
              description:
                'Arranging ideas and arguments in a clear, logical order.',
            },
            {
              subcategoryId:
                'ACT_2024_WRITING_ORGANIZATION_COHERENT_PARAGRAPHS',
              displayName: 'Coherent Paragraphs',
              description:
                'Writing coherent paragraphs that support the main argument.',
            },
          ],
        },
        {
          categoryId: 'ACT_2024_WRITING_LANGUAGE_USE_CONVENTIONS',
          displayName: 'Language Use and Conventions',
          description:
            'Assesses command of language, including grammar, syntax, and mechanics.',
          icon: 'remixCheckLine', // ng-icons Remix Icon representing language conventions
          subcategories: [
            {
              subcategoryId:
                'ACT_2024_WRITING_LANGUAGE_USE_CONVENTIONS_GRAMMAR_SYNTAX',
              displayName: 'Grammar and Syntax',
              description:
                'Using correct grammar and syntax in writing.',
            },
            {
              subcategoryId:
                'ACT_2024_WRITING_LANGUAGE_USE_CONVENTIONS_MECHANICS',
              displayName: 'Mechanics',
              description:
                'Using correct spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
  ],
};
