import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {formatToDollars} from "../../shared/util/string-utils";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private metaService: MetaService,
              private router: Router) {}

  ngOnInit(): void {
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);
  }

  navigateToExams() {
    this.router.navigate(['/exams'], {fragment: 'exams-list'});
  }

  getPriceDisplay() {
    const priceUnitAmount = 999;
    const currency = 'USD';
    if (!priceUnitAmount || !currency) {
      return "";
    }

    if (currency == 'USD') {
      return formatToDollars(priceUnitAmount / 100.);
    }
    return "";
  }

  onFreePlanClick() {
    this.navigateToExams()
  }

  onPremiumPlanClick() {
    this.navigateToExams()
  }

}
