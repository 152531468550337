<div class="flex flex-col items-center space-y-6 max-w-md mx-auto">
  <!-- Error message -->
  <div *ngIf="showError" class="text-red-500 text-center">
    {{ errorMessage }}
  </div>


  <div class="relative w-full flex justify-center items-center">
    <!-- Input Box -->
    <input
      type="text"
      class="w-48 h-12 px-4 text-center rounded-lg shadow-sm border focus:outline-none"
      [ngClass]="getInputBoxCss()"
      [disabled]="isReviewOrTransitState()"
      [value]="gridInAnswer"
      (input)="onInputChange($event)"
      (keypress)="onKeyPress($event)"
      maxlength="8"
      placeholder="Enter Answer..."
      aria-label="Grid In Answer Input"
    />

    <!-- Correct/Incorrect Indicator (positioned to the right of the input box) -->
    <div *ngIf="isReviewState()" class="ml-2 flex items-center space-x-1">
    <span *ngIf="isCorrect()" class="flex items-center space-x-1 text-green-500">
      <i class="fas fa-check fa-lg"></i>
      <span class="text-lg">Correct</span>
    </span>
      <span *ngIf="!isCorrect()" class="flex items-center space-x-1 text-red-500">
      <i class="fas fa-times fa-lg"></i>
      <span class="text-lg">Incorrect</span>
    </span>
    </div>
  </div>

  <!-- Answer Preview (Hidden in Review States) -->
  <div class="flex items-center space-x-2" *ngIf="!isReviewState()">
    <span class="font-semibold text-gray-700 text-sm">Answer Preview:</span>
    <span [ngClass]="getPreviewCss()" class="text-lg">
      {{ gridInAnswer }}
    </span>
  </div>
</div>
