import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { QuestionOption } from '../../../../models/question-option';
import { CommonModule } from '@angular/common';
import {QuestionState} from "../../question-state.enum";
import {CorrectChoiceResponse} from "../../../../models/correct-choice-response";
import {QuestionOptions} from "../../../../models/question-options";
import {UserQuestionInteractionResponse} from "../../../../models/user-question-interaction-response";
import {IconsModule} from "../../../../shared/icons/icons.module";

@Component({
  selector: 'multiple-answer',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './multiple-answer.component.html',
  styleUrl: './multiple-answer.component.scss',
})
export class MultipleAnswerComponent implements OnChanges {
  @Input() questionId: string | null = null;
  @Input() questionOptions: QuestionOptions | null = null;
  @Input() questionState: QuestionState | null = null;
  @Input() userQuestionInteractionResponse: UserQuestionInteractionResponse | null = null;
  @Output() onValidate = new EventEmitter<boolean>();

  selectedOptions: string[] = [];
  showError: boolean = false;
  errorMessage: string = '';

  validate(): void {
    if (this.selectedOptions.length > 0) {
      this.onValidate.emit(true);
      this.showError = false;
    } else {
      this.showError = true;
      this.errorMessage = 'Please select at least one option.';
      this.onValidate.emit(false);
    }
  }

  resetVariables() {
    this.selectedOptions = [];
    this.showError = false;
    this.errorMessage = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['questionId'] || changes['questionOptions']) {
      this.resetVariables();
    }

    if (changes['userQuestionInteractionResponse']) {
      if (this.isReviewState() && this.userQuestionInteractionResponse?.choiceResponse) {
        this.selectedOptions = [...this.userQuestionInteractionResponse.choiceResponse.choice];
      }
    }
  }

  isOptionDisabled(): boolean {
    const disabledStates = [
      QuestionState.SUBMIT,
      QuestionState.TRY_AGAIN,
      QuestionState.SKIP,
      QuestionState.NEXT,
      QuestionState.REVIEW_INCORRECT,
      QuestionState.REVIEW,
    ];
    return disabledStates.includes(this.questionState as QuestionState);
  }

  isReviewState(): boolean {
    return (
      this.questionState === QuestionState.REVIEW ||
      this.questionState === QuestionState.REVIEW_INCORRECT
    );
  }

  onCheckboxChange(optionId: string, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const checked = inputElement.checked;
    if (!this.isOptionDisabled()) {
      if (checked) {
        if (!this.selectedOptions.includes(optionId)) {
          this.selectedOptions.push(optionId);
        }
      } else {
        this.selectedOptions = this.selectedOptions.filter((id) => id !== optionId);
      }
      this.showError = false;
    }
  }

  getOptionCss(option: QuestionOption): string {
    if (this.isOptionDisabled()) {
      if (this.isReviewState()) {
        const isSelected = this.selectedOptions.includes(option.id);
        const isCorrect = this.userQuestionInteractionResponse?.correctChoiceResponse.correct_choice.includes(option.id);

        if (isSelected && isCorrect) {
          return 'bg-green-100 border-green-300 cursor-not-allowed';
        } else if (isSelected && !isCorrect) {
          return 'bg-red-100 border-red-300 cursor-not-allowed';
        } else if (!isSelected && isCorrect) {
          return 'bg-yellow-100 border-yellow-300 cursor-not-allowed';
        } else {
          return 'bg-gray-100 border-gray-200 cursor-not-allowed';
        }
      } else {
        return 'bg-gray-200 border-gray-200 cursor-not-allowed';
      }
    }

    if (this.selectedOptions.includes(option.id)) {
      return 'border-black bg-blue-100';
    }

    return 'bg-white border-gray-300 hover:border-blue-500 hover:bg-blue-50';
  }

  getCheckboxCss(option: QuestionOption): string {
    if (this.isOptionDisabled()) {
      if (this.isReviewState()) {
        const isSelected = this.selectedOptions.includes(option.id);
        const isCorrect = this.userQuestionInteractionResponse?.correctChoiceResponse.correct_choice.includes(option.id);

        if (isSelected && isCorrect) {
          return 'bg-green-700 border-green-700';
        } else if (isSelected && !isCorrect) {
          return 'bg-red-700 border-red-700';
        } else if (!isSelected && isCorrect) {
          return 'bg-yellow-700 border-yellow-700';
        } else {
          return 'bg-gray-300 border-gray-300';
        }
      } else {
        return 'bg-gray-300 border-gray-300';
      }
    }

    if (this.selectedOptions.includes(option.id)) {
      return 'border-blue-700 bg-blue-700';
    }

    return 'bg-white border-gray-300 hover:border-blue-500 hover:bg-blue-50';
  }

  getTextColor(option: QuestionOption): string {
    if (this.isOptionDisabled()) {
      return 'text-white font-bold';
    }

    if (this.selectedOptions.includes(option.id)) {
      return 'text-white font-bold';
    }

    return 'text-black';
  }

  getIconName(option: QuestionOption): string | null {
    if (this.isReviewState() && this.userQuestionInteractionResponse) {
      const isSelected = this.selectedOptions.includes(option.id);
      const isCorrect = this.userQuestionInteractionResponse.correctChoiceResponse.correct_choice.includes(option.id);

      if (isSelected && isCorrect) {
        return 'faSolidCheck';
      } else if (isSelected && !isCorrect) {
        return 'faSolidXmark';
      } else if (!isSelected && isCorrect) {
        return 'faSolidExclamation';
      } else {
        return null;
      }
    }

    if (this.selectedOptions.includes(option.id)) {
      return 'faCheck';
    }

    return null;
  }
}
