import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {CommonModule} from '@angular/common';
import {QuestionOption} from '../../../../models/question-option';
import {FormsModule} from '@angular/forms';
import {UserQuestionInteractionResponse} from '../../../../models/user-question-interaction-response';
import {QuestionState} from "../../question-state.enum";
import {QuestionOptions} from "../../../../models/question-options";

@Component({
  selector: 'multiple-choice',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './multiple-choice.component.html',
  styleUrl: './multiple-choice.component.scss',
})
export class MultipleChoiceComponent implements OnChanges {
  @Input() questionId: string | null = null;
  @Input() questionOptions: QuestionOptions | null = null;
  @Input() questionState: QuestionState | null = null;  // Accept state as input, to disable selecting the options after submit.
  @Input() userQuestionInteractionResponse: UserQuestionInteractionResponse | null = null; // to show inline styles if this was correct.
  @Output() onValidate = new EventEmitter<boolean>();

  selectedOption: string | null = null;  // Track the selected option
  showError: boolean = false;
  errorMessage: string = '';

  // Validate the multiple choice selection
  validate(): void {
    if (this.selectedOption) {
      this.onValidate.emit(true);
      this.showError = false;
    } else {
      this.showError = true;
      this.errorMessage = 'Please select an option.';
      this.onValidate.emit(false);
    }
  }

  resetVariables() {
    this.selectedOption = null;  // Track the selected option
    this.showError = false;
    this.errorMessage = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the questionId or questionOptions has changed
    if (changes['questionId'] || changes['questionOptions']) {
      this.resetVariables();
    }

    // When entering the review state, set selectedOption from userQuestionInteractionResponse
    if (changes['userQuestionInteractionResponse']) {
      if (this.isReviewState() && this.userQuestionInteractionResponse?.choiceResponse) {
        this.selectedOption = this.userQuestionInteractionResponse.choiceResponse.choice[0];
      }
    }
  }

  // Determine if the current state should disable option selection
  isOptionDisabled(): boolean {
    const disabledStates = [
      QuestionState.SUBMIT,
      QuestionState.TRY_AGAIN,
      QuestionState.SKIP,
      QuestionState.NEXT,
      QuestionState.REVIEW_INCORRECT,
      QuestionState.REVIEW,
    ];
    return disabledStates.includes(this.questionState as QuestionState);
  }

  isReviewState(): boolean {
    return this.questionState === QuestionState.REVIEW
      || this.questionState === QuestionState.REVIEW_INCORRECT;
  }

  // Handle radio button changes
  onRadioChange(value: string): void {
    if (!this.isOptionDisabled()) {
      this.selectedOption = value;
      this.showError = false;
    }
  }

  // Get CSS classes for the option container
  getOptionCss(option: QuestionOption): string {
    if (this.isOptionDisabled()) {
      if (this.isReviewState()) {
        // In review states, disable all options but highlight selected with inline status
        if (this.selectedOption === option.id) {
          return this.userQuestionInteractionResponse?.correct
            ? 'bg-green-100 border-green-300 cursor-not-allowed'
            : 'bg-red-100 border-red-300 cursor-not-allowed';
        } else if ( this.questionState === QuestionState.REVIEW && this.userQuestionInteractionResponse?.correctChoiceResponse.correct_choice[0] === option.id) {
          return 'bg-green-100 border-green-300 cursor-not-allowed';
        }

        return 'bg-gray-100 border-gray-200 cursor-not-allowed';
      } else {
        // Other disabled states: all options greyed out
        return 'bg-gray-200 border-gray-200 cursor-not-allowed';
      }
    }

    // This if statement should not trigger since it this should be covered by before, shoudl not be
    // in review state but not option disabled.
    if (this.selectedOption === option.id && this.isReviewState()) {
      return this.userQuestionInteractionResponse?.correct
        ? 'bg-green-700 border-black text-white'
        : 'bg-red-700 border-black text-white';
    }

    if (this.selectedOption === option.id) {
      return 'border-black bg-blue-100';
    }

    return 'bg-white border-gray-300 hover:border-blue-500 hover:bg-blue-50';
  }

  // Get CSS classes for the custom radio button
  getRadioCss(option: QuestionOption): string {
    if (this.isOptionDisabled()) {
      if (this.isReviewState()) {
        if (this.selectedOption === option.id ) {
          return this.userQuestionInteractionResponse?.correct
            ? 'bg-green-700 border-green-700'
            : 'bg-red-700 border-red-700';
        } else if ( this.questionState === QuestionState.REVIEW && this.userQuestionInteractionResponse?.correctChoiceResponse.correct_choice[0] === option.id) {
          return 'bg-green-700 border-green-700';
        }
      }

      return 'bg-gray-300 border-gray-300';
    }

    if (this.selectedOption === option.id && this.isReviewState()) {
      return this.userQuestionInteractionResponse?.correct
        ? 'bg-green-700 border-green-700'
        : 'bg-red-700 border-red-700';
    }

    if (this.selectedOption === option.id) {
      return 'border-blue-700 bg-blue-700';
    }

    return 'bg-white border-gray-300 hover:border-blue-500 hover:bg-blue-50';
  }

  // Get text color classes based on the option state
  getTextColor(option: QuestionOption): string {
    if (
      this.selectedOption === option.id &&
      this.isReviewState() &&
      this.userQuestionInteractionResponse != null
    ) {
      return 'text-white';
    }

    if (this.selectedOption === option.id) {
      return 'text-white font-bold';
    }

    return 'text-black';
  }

  // Get the label content, such as icons or option ID
  getItemLabel(option: QuestionOption): string {
    // Check if the question is in the review state and the option is selected
    if (this.isReviewState() &&
      this.userQuestionInteractionResponse != null
    ) {

      if (this.selectedOption === option.id) {
        // If the answer is correct, show a check mark
        if (this.userQuestionInteractionResponse.correct) {
          return '<i class="fas fa-check text-white"></i>'; // Check mark icon
        }
        // If the answer is incorrect, show an X mark
        else {
          return '<i class="fas fa-times text-white"></i>'; // X mark icon
        }
      }

      // If the user got it wrong, we still display the correct answer in review mode
      if (this.questionState === QuestionState.REVIEW && this.userQuestionInteractionResponse.correct === false &&
        option.id === this.userQuestionInteractionResponse.correctChoiceResponse.correct_choice[0]) {
        return '<i class="fas fa-check text-white"></i>'; // Check mark icon
      }
    }
    // If not in the review state, show the option ID or other label
    return option.id;
  }
}




//
// getRadioCss(option: QuestionOption): string {
//   if (this.selectedOption === option.id && this.isReviewState()) {
//     // Correct or incorrect answer (deeper blue or red)
//     return this.userQuestionInteractionResponse?.correct ?
//       'bg-green-700 border-green-700 text-white' : 'bg-red-700 border-red-700 text-white';
//   }
//
//   if (this.selectedOption === option.id) {
//     // Selected option with deeper blue custom radio button
//     return 'border-blue-700 bg-blue-700';
//   }
//
//   // Default state for unselected radio button
//   return 'bg-white border-gray-300 hover:border-blue-500 hover:bg-blue-50';
// }
//
// getOptionCss(option: QuestionOption): string {
//   if (this.selectedOption === option.id && this.isReviewState()) {
//     // Correct or Incorrect option with black border and slight shadow
//     return this.userQuestionInteractionResponse?.correct ?
//       'border-black shadow-sm bg-green-100' : 'border-black shadow-sm bg-red-100';
//   }
//
//   if (this.selectedOption === option.id) {
//     // Selected option with black border and slight shadow
//     return 'border-black shadow-sm bg-blue-50';
//   }
//
//   // Default state with lighter border and shadow
//   return 'border-gray-300 shadow-sm hover:bg-gray-100';
// }

// getTextColor(option: QuestionOption): string {
//   if (this.selectedOption === option.id && this.isReviewState()) {
//     // Ensure text is always visible against the background
//     return this.userQuestionInteractionResponse?.correct ? 'text-black' : 'text-black';
//   }
//
//   return 'text-black';  // Default text color
// }

// getItemLabel(option: QuestionOption): string {
//   if (this.selectedOption === option.id
//     && this.isReviewState()
//     && this.userQuestionInteractionResponse != null) {
//     if (this.userQuestionInteractionResponse?.correct) {
//       return '<i class="fa fa-solid fa-check text-white"></i>';  // Correct icon
//     } else {
//       return '<i class="fa fa-solid fa-times text-white"></i>';  // Incorrect icon
//     }
//   }
//
//   // Default to showing the option ID
//   return option.id;
// }
