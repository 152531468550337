<div class="min-h-screen flex items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div class="text-center">
      <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
        Verify Your Email Address
      </h2>
      <p class="mt-2 text-sm text-gray-600">
        We've sent a verification email to
        <span class="font-medium text-gray-800">{{ userEmail }}</span>.
        Please check your inbox and click the verification link.
      </p>
    </div>

    <div *ngIf="emailSent" class="mt-4">
      <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
        Verification email has been resent. Please check your inbox.
      </div>
    </div>

    <div *ngIf="errorMessage" class="mt-4">
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
        {{ errorMessage }}
      </div>
    </div>

    <div class="mt-6 flex items-center justify-between">
      <button
        (click)="resendVerificationEmail()"
        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
      >
        Resend Verification Email
      </button>
    </div>

    <div class="mt-6 text-center">
      <p class="text-sm text-gray-600">
        Once verified, click the button below to continue.
      </p>
      <button
        (click)="ngOnInit()"
        class="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custom-blue-500 bg-white hover:bg-gray-50 focus:outline-none border-gray-600"
      >
        I have verified my email
      </button>
    </div>
  </div>
</div>
