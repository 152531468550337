import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';  // Import the root module
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import 'alpinejs';
import 'reflect-metadata';

// Enable production mode if in a production environment
if (environment.production) {
  enableProdMode();
}

// Bootstrap the root module (AppModule) to start the application
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
