<div class="mt-4 p-4 bg-gray-50 rounded-lg shadow relative">

  <!-- Modal for "Link copied to clipboard" -->
  <div *ngIf="showCopiedModal" class="fixed top-4 left-1/2 transform -translate-x-1/2 bg-white text-gray-700 border border-gray-300 rounded-lg shadow-lg px-6 py-3 z-50">
    <p>Link copied to clipboard!</p>
  </div>

  <!-- Collapsible Hint Section -->
  <div class="flex justify-between items-center cursor-pointer" (click)="toggleHint()">
    <h3 class="text-lg font-semibold text-gray-800">Hint</h3>
    <i class="ri-arrow-down-s-line text-gray-600 transition-transform duration-300"
       [ngClass]="{'transform rotate-180': showHint}"></i>
  </div>

  <!-- Hint Content -->
  <div *ngIf="showHint" class="mt-2">
    <div *ngIf="questionResponse?.hints?.hints?.[0]?.hint; else noHint" [innerHTML]="questionResponse?.hints?.hints?.[0]?.hint" class="no-select no-right-click"></div>
    <ng-template #noHint>
      <p class="text-gray-600">No hint available.</p>
    </ng-template>
  </div>

  <!-- Grey Line Separator -->
  <div class="my-4 border-t border-gray-200"></div>

  <!-- Statistics Row -->
  <div class="flex items-center justify-between w-full max-w-4xl mx-auto mb-4 relative">
    <div class="flex space-x-8 items-center w-full justify-center">
      <!-- Submitted -->
      <div class="flex flex-col items-center text-center">
        <span class="text-sm font-normal text-gray-600">Submitted</span>
        <span class="text-lg font-bold text-gray-700">{{ formatNumber(numSubmitted) }}</span>
      </div>

      <!-- Thin Grey Separator -->
      <div class="h-8 border-l border-gray-300"></div>

      <!-- Correct -->
      <div class="flex flex-col items-center text-center">
        <span class="text-sm font-normal text-gray-600">Correct</span>
        <span class="text-lg font-bold text-gray-700">{{ formatNumber(numCorrect) }}</span>
      </div>

      <!-- Thin Grey Separator -->
      <div class="h-8 border-l border-gray-300"></div>

      <!-- % Correct -->
      <div class="flex flex-col items-center text-center">
        <span class="text-sm font-normal text-gray-600">% Correct</span>
        <span class="text-lg font-bold text-gray-700">{{ Math.round(percentCorrect) }}%</span>
      </div>
    </div>

    <!-- Information Icon -->
    <button (click)="toggleInfo()" class="absolute top-0 right-0 text-gray-500 hover:text-gray-700 focus:outline-none">
      <i class="ri-information-line text-sm"></i>
    </button>

    <!-- Info Tooltip -->
    <div *ngIf="showInfo" class="absolute top-full right-0 mt-2 w-80 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
      <p class="text-gray-600 text-sm">
        Statistics are updated on a daily basis and calculated using users' first attempt of the question.
      </p>
      <button (click)="toggleInfo()" class="mt-2 text-blue-500 hover:underline text-sm">Close</button>
    </div>
  </div>

  <!-- Grey Line Separator -->
  <div class="my-4 border-t border-gray-200"></div>

  <!-- Action Buttons Row -->
  <div class="flex items-center justify-between w-full max-w-4xl mx-auto">
    <!-- Thumbs Up and Down Section -->
    <div class="flex items-center bg-gray-200 py-2 px-4 rounded-lg">
      <!-- Like Button -->
      <button (click)="likeQuestion()" class="flex items-center space-x-1 text-gray-700 hover:text-blue-600 focus:outline-none">
        <!-- Show filled thumb up icon if liked, otherwise show the outlined thumb up icon -->
        <i [ngClass]="isLiked ? 'ri-thumb-up-fill text-blue-600' : 'ri-thumb-up-line'" class="text-xl"></i>

        <span *ngIf="questionResponse?.likes && questionResponse?.likes ?? 0 > 100" class="font-medium pl-2">{{ formatNumber(questionResponse?.likes ?? null) }}</span>
      </button>

      <!-- Thin White Separator -->
      <div class="h-6 w-px bg-white mx-3"></div>

      <!-- Dislike Button -->
      <button (click)="dislikeQuestion()" class="flex items-center space-x-1 text-gray-700 hover:text-red-600 focus:outline-none">
        <i [ngClass]="isDisliked ? 'ri-thumb-down-fill text-red-600' : 'ri-thumb-down-line'" class="text-xl"></i>
      </button>
    </div>

    <!-- Share Button with hover effect -->
    <button (click)="shareLink()" class="flex items-center space-x-2 text-gray-600 hover:bg-gray-200 hover:text-green-600 focus:outline-none px-3 py-2 rounded-lg transition">
      <i class="ri-share-line text-xl"></i>
      <span class="font-medium">Share</span>
    </button>
  </div>

</div>
