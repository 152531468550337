import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ExamService} from "../../services/exam.service";
import {AnalyticsService} from "../../services/analytics.service";
import {ApiState} from "../../services/api-state.interface";
import {UserAnalyticsResponse} from "../../models/user-analytics-response";
import {RequestState} from "../../services/request-state.enum";
import {DecimalPipe, NgClass, NgForOf, NgIf, SlicePipe} from "@angular/common";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexYAxis,
  ApexPlotOptions,
  ApexDataLabels,
  ApexLegend,
  NgApexchartsModule, ApexFill
} from "ng-apexcharts";
import {CohortStats} from "../../models/cohort-stats";
import {SectionsStatisticsComponent} from "./sections-statistics/sections-statistics.component";
import {CategoriesStatisticsComponent} from "./categories-statistics/categories-statistics.component";
import {QuestionHistoryComponent} from "./question-history/question-history.component";
import {ActionedQuestionsComponent} from "./actioned-questions/actioned-questions.component";
import {NextQuestionRequest} from "../../models/next-question-request";
import {NextQuestionResponse} from "../../models/next-question-response";
import {QuestionService} from "../../services/question-service";
import {ExamData} from "../../services/exam-data-interface";
import {UserService} from "../../services/user.service";
import {IconsModule} from "../../shared/icons/icons.module";
import {AuthService} from "../../services/auth.service";
import {PlanService} from "../../services/plan.service";
import {CheckoutService} from "../../services/checkout.service";
import {MetaService} from "../../services/meta.service";


export type ChartOptions = {
  series: ApexAxisChartSeries;    // Defines the series data (Correct, Wrong, Skipped)
  chart: ApexChart;               // General chart configuration (type, height, etc.)
  xaxis: ApexXAxis;               // X-axis configuration (categories, labels)
  yaxis: ApexYAxis;               // Y-axis configuration (title, labels, etc.)
  stroke?: ApexStroke;            // Optional stroke (used for line charts, etc.)
  fill?: ApexFill;
  title: ApexTitleSubtitle;       // Title configuration for the chart
  tooltip: ApexTooltip;           // Tooltip configuration (shared, custom formatting)
  plotOptions?: ApexPlotOptions;  // Bar chart-specific options (stacked bars, etc.)
  legend?: ApexLegend;            // Legend configuration (positioning, etc.)
  colors?: string[];              // Custom colors for the series (Correct, Wrong, Skipped)
  dataLabels?: ApexDataLabels;    // Data labels to display values directly on bars
};

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgForOf,
    NgApexchartsModule,
    DecimalPipe,
    SectionsStatisticsComponent,
    CategoriesStatisticsComponent,
    QuestionHistoryComponent,
    ActionedQuestionsComponent,
    SlicePipe,
    IconsModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit{
  examId: string | null = null;
  errorMessage: string | null = null;
  userAnalyticsResponse : UserAnalyticsResponse | null= null;
  requestState: RequestState = RequestState.LOADING;

  examData: ExamData | null = null;

  isPremium: boolean = false;
  isLoggedIn: boolean = false;

  // Charts data
  public weeklyScoreChartOptions: Partial<ChartOptions> = {
    series:  [{
      name: 'No Data',
      data: []  // Initialize as empty array
    }],
    chart: {
      type: "line",
      height: '100%'
    },
    title: {
      text: "No Data Available",
      align: 'center',
      style: {
        fontSize: '16px',
        color: '#777'
      }
    },
    xaxis: {
      categories: [],
      labels: {
        show: false
      }
    },
    yaxis: {
      min: undefined,  // Let the chart auto-calculate the min value
      max: undefined,  // Let the chart auto-calculate the max value
      labels: {
        show: false
      }
    },
    stroke: {
      show: false
    }
  };

  public questionsBreakdownChartOptions: Partial<ChartOptions> = {
    series: [], // This will be populated dynamically in initializeQuestionsBreakdownChart
    chart: {
      type: 'bar',
      // height: 320,
      height: '100%', // Set chart height to fill the container, but doesn't work
      stacked: true, // Enable stacked bars
      toolbar: {
        show: false // Hide the hamburger menu on the top right
      }
    },
    plotOptions: {
      bar: {
        horizontal: false, // Vertical bars
        borderRadius: 4, // Rounded corners for bars
      },
    },
    xaxis: {
      categories: [], // Dates will be populated dynamically
      labels: {
        style: {
          fontSize: '12px',
          colors: '#9CA3AF', // Grey color for labels
        },
        formatter: function (val: string) {
          const date = new Date(val);
          // Format as "MM/DD" (Month and Day only)
          return `${date.getMonth() + 1}/${date.getDate()}`;
        }
      }
    },
    yaxis: {
      title: {
        text: 'Number of Questions', // Y-axis label
        style: {
          fontSize: '14px',
          color: '#4B5563', // Grey color for the title
          fontWeight: 'bold' // Make it bold
        }
      },
      labels: {
        style: {
          fontSize: '12px',
          colors: '#9CA3AF', // Grey color for y-axis labels
        },
        formatter: function (value: number) {
          return Number.isInteger(value) ? value.toFixed(0) : '';
        }
      },
      forceNiceScale: true, // Ensure the y-axis scales nicely with integer values
      min: 0, // Start y-axis from 0
    },
    tooltip: {
      shared: true, // Tooltip shows data for all series
      intersect: false,
      y: {
        formatter: (val: number, opts: any) => {
          const seriesName = opts.w.config.series[opts.seriesIndex].name; // Correctly capture the series name
          return `${val} questions`;
        }
      },
    },
    dataLabels: {
      enabled: true, // Display data labels on bars
      style: {
        colors: ['#fff'], // White text on bars for better visibility
      },
      formatter: (val: number) => {
        return val >= 0 ? val.toString() : '';
      },
    },
    legend: {
      show: false // No need for a legend
    },
    colors: ['#4CAF50', '#FF5722', '#FFC107'], // Green for correct, Red for wrong, Yellow for skipped
  };

  constructor(
    private sanitizer: DomSanitizer,
    private metaService: MetaService,
    private questionService: QuestionService,
    private examService: ExamService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private authService: AuthService,
    private planService: PlanService,
    private checkoutService: CheckoutService,
    private router: Router,  // Inject Router for navigation
    private route: ActivatedRoute  // Inject ActivatedRoute to access the route parameters
  ) {}

  ngOnInit(): void {
    // Get the query params
    this.route.queryParams.subscribe(params => {
      const sessionId = params['session_id'];
      if (sessionId) {
        // If session_id is present, call the backend to refresh the cache
        this.checkoutService.refreshSubscriptionCache();
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    // Extract the questionId from the route and load the question
    this.route.paramMap.subscribe({
      next: params => {
        const examLinkName = params.get('exam-link-name');

        if (examLinkName == null) {
          this.router.navigate(['/error']);
          return;
        }

        this.examData = this.examService.getExamDataFromExamLinkName(examLinkName);

        if (this.examData == null) {
          this.router.navigate(['/error']);
          return;
        }

        // Dynamically set the tags
        const displayName = this.examData.displayName;
        const canonicalUrl = this.metaService.generateCanonicalUrl();
        const baseMetaData = this.route.snapshot.data;
        const metaData = {
          ...baseMetaData,
          title: `${displayName} Exam Prep | ${displayName} Study Guide | Studious`,
          description: `Get the best practice questions and exam prep for the ${displayName} exam. Use AI adaptive learning to improve your ${displayName} score.`,
          keywords: `${displayName} prep, ${displayName} practice tests, ${displayName} questions, ${displayName} free`,
          canonical: canonicalUrl
        };
        this.metaService.updateMetaTags(metaData);

        this.examId = this.examData.examId;

        if (this.examId) {
          // this.resetVariables()
          const sub2 = this.userService.isUserSubscribedToExam(this.examId)
            .subscribe(isSubscribed => {
              this.isPremium = isSubscribed;
            });
          this.requestState = RequestState.LOADING;
          this.examService.updateCurrentExamId(this.examId);
          this.loadData(this.examId);
        } else {
          this.errorMessage = 'Invalid question ID';
          this.router.navigate(['/error']);
          this.requestState = RequestState.ERROR;
        }
      },
      error : err => {
        // set fallback metadata
        const metaData = this.route.snapshot.data;
        this.metaService.updateMetaTags(metaData);
      }
    });
  }

  ngOnInit2(): void {
    // Simulating data load with a timeout, replace with your real data loading logic
    this.route.paramMap.subscribe(params => {
      this.examId = params.get(':exam-display-name');
      if (this.examId) {
        // this.resetVariables()
        this.requestState = RequestState.LOADING;
        this.examService.updateCurrentExamId(this.examId);
        setTimeout(() => {
          import('../../models/mock/mock-user-analytics-response').then(module => {
            this.userAnalyticsResponse = module.mockUserAnalyticsResponse as UserAnalyticsResponse;

            // Initialize the chart with actual data
            this.initializeWeeklyScoreChart();
            this.initializeQuestionsBreakdownChart();
          });
        }, 2000); // Mock delay, replace with actual data fetching logic
      }
    });
  }

  initializeQuestionsBreakdownChart() {
    if (this.userAnalyticsResponse?.dailySeriesStats && this.userAnalyticsResponse.dailySeriesStats.length > 0) {
      // Get the last 8 days of data from dailySeriesStats
      const last8Days = this.userAnalyticsResponse.dailySeriesStats.slice(-8);

      // console.log('last8Days', last8Days);

      // Extract dates and number of questions for each category
      const dates = last8Days.map(stat => stat.aggregationPeriodRange.startDate);
      const correct = last8Days.map(stat => stat.numberQuestionsCorrect);
      const wrong = last8Days.map(stat => stat.numberQuestionsIncorrect);
      const skipped = last8Days.map(stat => stat.numberQuestionsSkipped);

      // const correct = [32, 35, 27, 44, 57, 61, 40, 55];
      // const wrong = [25, 34, 27, 25, 18, 10, 22, 8];
      // const skipped = [19, 10, 14, 9, 11, 7, 6, 13];

      // Set the chart options based on extracted data
      this.questionsBreakdownChartOptions = {
        ...this.questionsBreakdownChartOptions, // Spread existing options
        series: [
          { name: 'Correct', data: correct },
          { name: 'Wrong', data: wrong },
          { name: 'Skipped', data: skipped },
        ],
        xaxis: {
          categories: dates, // Dates for x-axis labels
          labels: {
            style: {
              fontSize: '12px',
              colors: '#9CA3AF', // Grey
            },
            formatter: function(val: string) {
              const date = new Date(val);
              // Format as "MM/DD" (Month and Day only)
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }
          }
        },
      };
    }
  }

  initializeWeeklyScoreChart() {
    if (
      this.userAnalyticsResponse?.weeklySeriesStats &&
      this.userAnalyticsResponse.weeklySeriesStats.length > 0
    ) {

      const maxElements = 15;

      const weeklyScores = this.userAnalyticsResponse.weeklySeriesStats.map(
        (stat: CohortStats) => stat.score
      ).slice(-maxElements);

      // const weeklyScores = [46.1, 60.5, 41.5, 29.1, 40.2, 45.1, 57.4, 51.4, 67.7, 60.7, 47.8, 76.4, 79.5, 66.5, 80.1];
      const weeklyDates = this.userAnalyticsResponse.weeklySeriesStats.map(
        (stat: CohortStats) => {
          const date = new Date(stat.aggregationPeriodRange.startDate);
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }); // e.g., "Aug 5"
        }
      ).slice(-maxElements);

      // Round each score to the nearest decimal point
      const roundedWeeklyScores = weeklyScores.map(score => {
        return Math.round(score * 10) / 10; // Round to 1 decimal place
      });

      this.weeklyScoreChartOptions = {
        // ...this.weeklyScoreChartOptions, // Spread existing options
        series: [
          {
            name: 'Exam Mastery Score',
            data: roundedWeeklyScores,
          },
        ],
        chart: {
          type: 'area',
          //height: 225, // Reduced height to align with other charts
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: weeklyDates,
          labels: {
            style: {
              fontSize: '12px',
              colors: '#6B7280', // Slightly darker gray
            },
          },
        },
        yaxis: {
          title: {
            text: 'Score (%)',
            style: {
              fontSize: '14px',
              color: '#4B5563',
              fontWeight: 'bold',
            },
          },
          min: 0,
          tickAmount: 5,
          labels: {
            formatter: (value: number) => value.toFixed(0), // Integer labels
            style: {
              fontSize: '12px',
              colors: '#6B7280',
            },
            // Adjust the horizontal offset to prevent cutting off
            offsetX: 10, // You can tweak this value as needed
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: ['#3B82F6'], // Blue color
            opacityFrom: 0.7,
            opacityTo: 0.2,
            stops: [0, 90, 100],
          },
        },
        colors: ['#3B82F6'], // Line color
        title: {
          text: 'Weekly Mastery Progress',
          align: 'left',
          style: {
            fontSize: '16px',
            color: '#1F2937', // Darker gray
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val: number) => {
              return val % 1 === 0 ? `${val.toFixed(0)}%` : `${val.toFixed(1)}%`;  // Max 1 decimal point in tooltip
            },
          },
        },
        dataLabels: {
          enabled: true,  // Enable data labels on the line
          formatter: (val: number) => {
            return val % 1 === 0 ? `${val.toFixed(0)}%` : `${val.toFixed(1)}%`;  // Max 1 decimal point for data labels
          },
          style: {
            fontSize: '12px',
            colors: ['#333'], // Color for the data labels
          },
        },
      };
    }
  }

  // In percentage from 0 to 100.
  examMasteryScore() {
    let score = 0.0;
    if (this.userAnalyticsResponse?.allTimeTotalStats?.score) {
      score = this.userAnalyticsResponse?.allTimeTotalStats?.score;
    }
    return `${score.toFixed(1)}%`;
  }

  // Helper function to format all-time questions diff with "+" sign
  formatDiffNumber(diff: number): string {
    return diff > 0 ? `+${diff.toFixed(1)}%` : `${diff.toFixed(1)}%`;
  }

  // Exam Mastery Difference Calculation
  examMasteryDiff(): number {
    let diff = 0.0;
    if (this.userAnalyticsResponse?.allTimeTotalStats?.score) {
      diff = this.userAnalyticsResponse?.allTimeTotalStats?.score;
      if (this.userAnalyticsResponse?.weeklySeriesStats && this.userAnalyticsResponse.weeklySeriesStats.length >= 2) {
        const secondToLastElement = this.userAnalyticsResponse.weeklySeriesStats[this.userAnalyticsResponse.weeklySeriesStats.length - 2];
        diff = diff - secondToLastElement.score;
      }
    }
    return diff;
  }

  // Weekly Number of Questions
  weeklyNumQuestions(): number {
    if (this.userAnalyticsResponse?.weeklySeriesStats) {
      const latestWeek = this.userAnalyticsResponse.weeklySeriesStats[this.userAnalyticsResponse.weeklySeriesStats.length - 1];
      return latestWeek.numberQuestionsTotal;
    }
    return 0;
  }

  // Weekly Number of Questions Difference
  weeklyNumQuestionsDiff(): number {
    if (this.userAnalyticsResponse?.weeklySeriesStats && this.userAnalyticsResponse.weeklySeriesStats.length >= 2) {
      const latestWeek = this.userAnalyticsResponse.weeklySeriesStats[this.userAnalyticsResponse.weeklySeriesStats.length - 1];
      const previousWeek = this.userAnalyticsResponse.weeklySeriesStats[this.userAnalyticsResponse.weeklySeriesStats.length - 2];

      if (previousWeek.numberQuestionsTotal < 1) {
        return 0;
      }

      return ((latestWeek.numberQuestionsTotal - previousWeek.numberQuestionsTotal) / previousWeek.numberQuestionsTotal) * 100;
    }
    return 0;
  }

  // All-Time Number of Questions
  allTimeNumQuestions(): number {
    if (this.userAnalyticsResponse?.allTimeTotalStats) {
      return this.userAnalyticsResponse.allTimeTotalStats.numberQuestionsTotal;
    }
    return 0;
  }

  // All-Time Number of Questions Difference
  allTimeNumQuestionsDiff(): number {
    if (this.userAnalyticsResponse?.allTimeTotalStats && this.userAnalyticsResponse.weeklySeriesStats.length >= 2) {
      const totalQuestions = this.userAnalyticsResponse.allTimeTotalStats.numberQuestionsTotal;
      const lastWeekQuestions = this.userAnalyticsResponse.weeklySeriesStats[this.userAnalyticsResponse.weeklySeriesStats.length - 1].numberQuestionsTotal;
      if (totalQuestions < 1) {
        return 1000.0;
      }
      return (lastWeekQuestions / totalQuestions) * 100;
    }
    return 0;
  }

  getRecommendationsMessage() {
    if (this.userAnalyticsResponse?.allTimeTotalStats == null || this.userAnalyticsResponse?.allTimeTotalStats?.numberQuestionsTotal == 0) {
      return 'Get started with practice questions to get your AI generated recommendations!'
    } else if (this.userAnalyticsResponse?.recommendations == null || this.userAnalyticsResponse?.recommendations.length == 0) {
      return 'Keep on answering practice questions to get your AI generated recommendations.'
    }


    return 'Welcome back! Keep on answering practice questions to get your AI generated recommendations.';
  }

  loadData(examId: string): void {
    this.analyticsService.getUserAnalytics(examId).subscribe( {
      next: (userAnalyticsState: ApiState<UserAnalyticsResponse>) => {
        if (userAnalyticsState.state === RequestState.SUCCESS) {
          this.requestState = RequestState.LOADING;
          this.userAnalyticsResponse = userAnalyticsState.data;

          // if (this.userAnalyticsResponse) {
          //   this.userAnalyticsResponse.recommendations =
          //     [
          //       {
          //         createdTimestamp: '2024-09-27T14:45:30Z',
          //         text: 'You have incorrectly answered 5 out of 8 questions on "Manipulating Polynomials" in the past week. Review the solutions to these questions.'
          //       },
          //       {
          //         createdTimestamp: '2024-09-27T14:45:30Z',
          //         text: 'Your average response time of 85 seconds per question in the Reading and Writing Section is above the recommended pace. Consider improving your pacing by reviewing our lesson on speed reading strategies.'
          //       },
          //       {
          //         createdTimestamp: '2024-09-27T14:45:30Z',
          //         text: 'You have been skipping too many questions in the "Standard English Conventions" category. We recommend reviewing the lesson on using the process of elimination to make informed guesses.'
          //       },
          //     ];
          // }

          console.log("userAnalyticsResponse", this.userAnalyticsResponse);
          this.initializeWeeklyScoreChart();
          this.initializeQuestionsBreakdownChart();
          this.requestState = RequestState.SUCCESS;
        } else if (userAnalyticsState.state === RequestState.ERROR) {
            this.requestState = RequestState.ERROR;
        }
      },
      error: err => {
        this.requestState = RequestState.ERROR;
        this.errorMessage = err;
      }
    });

  }

  getDisplayName() {
    return this.userAnalyticsResponse?.examDisplayName;
  }

  getLastActivityTime() {
    if (!this.userAnalyticsResponse?.lastActivityTimestamp) {
      return null;
    }
    // Parse the ISO string into a Date object
    const date = new Date(this.userAnalyticsResponse?.lastActivityTimestamp);

    // Format the date into a custom format
    // Example: "MM/DD/YYYY HH:mm:ss"
    const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
    return formattedDate;
  }

  goToPracticeQuestions() {
    if (this.examId) {
      this.questionService.nextQuestionAndNavigateToPractice(this.examId);
    } else {
      this.router.navigate(['/exams']);
    }

  }

  // Make this an arrow function to allow for binding.
  onPremiumPlanClick = (): void => {
    this.planService.onPremiumPlanClick(
      this.examId,
      this.isLoggedIn,
      this.isPremium,
      this.examData?.priceId
    );
  }


  protected readonly RequestState = RequestState;
}
