import {LandingNavigationComponent} from "../../components/landing-navigation/landing-navigation.component";
import {RouterOutlet} from "@angular/router";
import {LandingFooterComponent} from "../../components/landing-footer/landing-footer.component";
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Data } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import {NgIf, NgStyle} from "@angular/common";

@Component({
  selector: 'app-landing-layout',
  standalone: true,
  imports: [
    LandingNavigationComponent,
    RouterOutlet,
    LandingFooterComponent,
    NgIf,
    NgStyle
  ],
  templateUrl: './landing-layout.component.html',
})
export class LandingLayoutComponent {

}
