import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;
    if (!password) {
      return null; // If no password provided, validation should pass
    }

    // Check if password meets minimum length requirement
    if (password.length < minLength) {
      return { 'minLength': true }; // Return error if password is too short
    }

    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return { 'noUppercase': true }; // Return error if no uppercase letter found
    }

    // Check if password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return { 'noLowercase': true }; // Return error if no lowercase letter found
    }

    if(!/\d/.test(password)) {
      return { 'noNumber': true };
    }

    // Check if password contains at least one special character
    // if (!/[@$!%*?&]/.test(password)) {
    //   return { 'noSpecialCharacter': true }; // Return error if no special character found
    // }

    // Check if password contains any invalid characters
    if (/[^A-Za-z0-9@$!%*?&]/.test(password)) {
      return { 'invalidCharacter': true }; // Return error if any invalid character found
    }

    return null; // Return null if validation passes
  };
}
