import { ErrorBase } from './error-base';

export type AuthenticationErrorName =
  | 'EMAIL_ALREADY_IN_USE'
  | 'INVALID_EMAIL'
  | 'USER_DISABLED'
  | 'USER_NOT_FOUND'
  | 'WRONG_PASSWORD'
  | 'NO_CREDENTIALS'
  | 'POPUP_CLOSED_BY_USER'
  | 'CANCELLED_POPUP_REQUEST'
  | 'INVALID_API_KEY'
  | 'NETWORK_REQUEST_FAILED'
  | 'UNAUTHENTICATED_USER'
  | 'CREDENTIAL_ERROR_GOOGLE'
  | 'WEAK_PASSWORD'
  | 'OPERATION_NOT_ALLOWED'
  | 'UNKNOWN';

export class AuthenticationError extends ErrorBase<AuthenticationErrorName> {}

type PostRequestErrorName =
  | 'NO_CONNECTION'
  | 'REQUEST_LEVEL';

export class PostRequestError extends ErrorBase<PostRequestErrorName> {}
