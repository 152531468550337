import {ExamData} from "../exam-data-interface";


// ISEE Lower Level Exam Data
export const iseeLowerExamData: ExamData = {
  examId: 'ISEE_LOWER_2025',
  displayName: 'ISEE Lower Level',
  description:
    'The ISEE Lower Level is designed for students applying for admission to grades 5 and 6. It assesses reading, verbal reasoning, mathematical ability, and essay writing.',
  logo: 'assets/images/exams/isee_lower_logo.png',
  link: 'isee-lower',
  zoom: 1.1,
  priceId: 'ISEE_LOWER_2025_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Prepare for Success on the ISEE Lower Level',
  marketingSubtitles: [
    'Comprehensive practice tailored to the ISEE Lower Level exam.',
    'Extensive question bank covering all test sections.',
    'Adaptive learning to strengthen your weak areas.',
    'Build confidence with effective practice and feedback.',
  ],
  pricingTitle: 'Affordable ISEE Prep for Your Academic Journey',
  pricingSubtitle: [
    'Invest in your future with quality preparation and support.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'ISEE_LOWER_VERBAL',
      displayName: 'Verbal Reasoning',
      description:
        'Tests vocabulary and reasoning skills through synonyms and sentence completion questions.',
      icon: 'remixChat3Line', // ng-icons Remix Icon for verbal reasoning
      categories: [
        {
          categoryId: 'ISEE_LOWER_VERBAL_SYNONYMS',
          displayName: 'Synonyms',
          description:
            'Testing vocabulary knowledge by identifying words with similar meanings.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_VERBAL_SYNONYMS_BASIC_VOCABULARY',
              displayName: 'Basic Vocabulary',
              description:
                'Common words appropriate for lower level students.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_VERBAL_SYNONYMS_ADVANCED_VOCABULARY',
              displayName: 'Advanced Vocabulary',
              description:
                'Challenging words to test higher-level vocabulary.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_VERBAL_SENTENCE_COMPLETION',
          displayName: 'Sentence Completion',
          description:
            'Testing ability to complete sentences by understanding context and relationships.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_VERBAL_SENTENCE_COMPLETION_CONTEXT_CLUES',
              displayName: 'Context Clues',
              description:
                'Using surrounding text to determine the meaning of missing words.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_VERBAL_SENTENCE_COMPLETION_LOGICAL_RELATIONSHIPS',
              displayName: 'Logical Relationships',
              description:
                'Understanding the logical flow and relationships within the sentence.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_LOWER_QUANT',
      displayName: 'Quantitative Reasoning',
      description:
        'Measures mathematical thinking and problem-solving skills, focusing on understanding concepts and applying logic to solve problems.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'ISEE_LOWER_QUANT_WORD_PROBLEMS',
          displayName: 'Word Problems',
          description:
            'Solving mathematical problems presented in real-world contexts.',
          icon: 'remixQuestionAnswerLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_QUANT_WORD_PROBLEMS_NUMBER_OPERATIONS',
              displayName: 'Number Operations',
              description:
                'Applying basic arithmetic operations to solve problems.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_QUANT_WORD_PROBLEMS_ALGEBRAIC_REASONING',
              displayName: 'Algebraic Reasoning',
              description: 'Using simple algebra to solve problems.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_QUANT_QUANT_COMPARISONS',
          displayName: 'Quantitative Comparisons',
          description:
            'Comparing two quantities and determining the relationship between them.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_QUANT_QUANT_COMPARISONS_GEOMETRY_COMPARISONS',
              displayName: 'Geometry Comparisons',
              description:
                'Comparing geometric figures and measurements.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_QUANT_QUANT_COMPARISONS_DATA_ANALYSIS',
              displayName: 'Data Analysis',
              description:
                'Interpreting and comparing data from graphs and charts.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_LOWER_READING',
      displayName: 'Reading Comprehension',
      description:
        'Assesses the ability to understand and interpret passages from various subjects, including literature, science, and social studies.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading comprehension
      categories: [
        {
          categoryId: 'ISEE_LOWER_READING_MAIN_IDEA',
          displayName: 'Main Idea and Theme',
          description:
            'Identifying the central message or theme of the passage.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_READING_MAIN_IDEA_IDENTIFYING_MAIN_IDEA',
              displayName: 'Identifying Main Idea',
              description:
                'Determining the main idea of the passage.',
            },
            {
              subcategoryId: 'ISEE_LOWER_READING_MAIN_IDEA_THEME',
              displayName: 'Identifying Theme',
              description:
                'Understanding the theme or message conveyed by the author.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_READING_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description:
            'Recognizing specific information and details in the passage.',
          icon: 'remixListUnordered',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_READING_SUPPORTING_DETAILS_LOCATING_DETAILS',
              displayName: 'Locating Details',
              description:
                'Finding specific details within the text.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_READING_SUPPORTING_DETAILS_UNDERSTANDING_EVIDENCE',
              displayName: 'Understanding Evidence',
              description:
                'Interpreting evidence provided to support arguments or points.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_READING_INFERENCE',
          displayName: 'Inference',
          description:
            'Drawing conclusions based on information implied in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_READING_INFERENCE_DRAWING_INFERENCES',
              displayName: 'Drawing Inferences',
              description:
                'Making logical inferences based on text.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_READING_INFERENCE_PREDICTING_OUTCOMES',
              displayName: 'Predicting Outcomes',
              description:
                'Predicting future events based on information given.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_READING_VOCABULARY_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Determining the meaning of words based on context.',
          icon: 'remixBookletLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_READING_VOCABULARY_CONTEXT_CONTEXTUAL_MEANINGS',
              displayName: 'Contextual Meanings',
              description:
                'Determining word meanings based on context clues.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_READING_VOCABULARY_CONTEXT_SYNONYMS_ANTONYMS',
              displayName: 'Synonyms and Antonyms',
              description:
                'Identifying synonyms and antonyms within the context.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_LOWER_MATH',
      displayName: 'Mathematics Achievement',
      description:
        'Measures knowledge of mathematical concepts and skills, focusing on problem-solving and the application of mathematical principles.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'ISEE_LOWER_MATH_NUMBER_OPERATIONS',
          displayName: 'Number Operations',
          description:
            'Understanding and applying arithmetic operations with whole numbers, fractions, and decimals.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_MATH_NUMBER_OPERATIONS_WHOLE_NUMBERS',
              displayName: 'Whole Numbers',
              description: 'Operations with whole numbers.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_MATH_NUMBER_OPERATIONS_FRACTIONS_DECIMALS',
              displayName: 'Fractions and Decimals',
              description: 'Operations with fractions and decimals.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_MATH_ALGEBRA',
          displayName: 'Algebra',
          description:
            'Understanding variables, expressions, and simple equations.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_MATH_ALGEBRA_VARIABLES_EXPRESSIONS',
              displayName: 'Variables and Expressions',
              description:
                'Understanding variables and algebraic expressions.',
            },
            {
              subcategoryId: 'ISEE_LOWER_MATH_ALGEBRA_EQUATIONS',
              displayName: 'Simple Equations',
              description: 'Solving simple equations.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_MATH_GEOMETRY',
          displayName: 'Geometry',
          description:
            'Understanding basic geometric shapes and their properties.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_MATH_GEOMETRY_SHAPES_PROPERTIES',
              displayName: 'Shapes and Properties',
              description:
                'Understanding geometric shapes and their properties.',
            },
            {
              subcategoryId: 'ISEE_LOWER_MATH_GEOMETRY_ANGLES',
              displayName: 'Angles',
              description:
                'Understanding angles and angle relationships.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_MATH_MEASUREMENT',
          displayName: 'Measurement',
          description:
            'Understanding units of measure and calculating perimeter, area, and volume.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_MATH_MEASUREMENT_UNITS_MEASUREMENT',
              displayName: 'Units of Measurement',
              description:
                'Understanding and converting units of measurement.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_MATH_MEASUREMENT_PERIMETER_AREA_VOLUME',
              displayName: 'Perimeter, Area, and Volume',
              description:
                'Calculating perimeter, area, and volume.',
            },
          ],
        },
        {
          categoryId: 'ISEE_LOWER_MATH_DATA_ANALYSIS',
          displayName: 'Data Analysis and Probability',
          description:
            'Interpreting data from graphs and understanding basic probability.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_MATH_DATA_ANALYSIS_INTERPRETING_GRAPHS',
              displayName: 'Interpreting Graphs',
              description:
                'Reading and interpreting data from graphs.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_MATH_DATA_ANALYSIS_BASIC_PROBABILITY',
              displayName: 'Basic Probability',
              description:
                'Understanding and calculating basic probabilities.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_LOWER_ESSAY',
      displayName: 'Essay',
      description:
        'Requires students to write an essay in response to a prompt. This section is unscored but sent to schools as a sample of the student’s writing ability.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for essay
      categories: [
        {
          categoryId: 'ISEE_LOWER_ESSAY_WRITING_SKILLS',
          displayName: 'Writing Skills',
          description:
            'Assessment of the student’s ability to write coherently and effectively.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_LOWER_ESSAY_WRITING_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Logical structure and flow of the essay.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_ESSAY_WRITING_SKILLS_CONTENT_DEVELOPMENT',
              displayName: 'Content Development',
              description:
                'Development of ideas and use of supporting details.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_ESSAY_WRITING_SKILLS_LANGUAGE_USE',
              displayName: 'Language Use',
              description: 'Grammar, vocabulary, and sentence structure.',
            },
            {
              subcategoryId:
                'ISEE_LOWER_ESSAY_WRITING_SKILLS_MECHANICS',
              displayName: 'Mechanics',
              description: 'Spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
  ],
};

// ISEE Middle Level Exam Data
export const iseeMiddleExamData: ExamData = {
  examId: 'ISEE_MIDDLE_2025',
  displayName: 'ISEE Middle Level',
  description:
    'The ISEE Middle Level is designed for students applying for admission to grades 7 and 8. It assesses reading, verbal reasoning, quantitative reasoning, mathematics achievement, and essay writing.',
  logo: 'assets/images/exams/isee_middle_logo.png',
  link: 'isee-middle',
  zoom: 1.1,
  priceId: 'ISEE_MIDDLE_2025_month_subscription_price',
  priceUnitAmount: 1099,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel in the ISEE Middle Level with Customized Prep',
  marketingSubtitles: [
    'Our adaptive platform targets your child’s needs to elevate their ISEE score.',
    'Access extensive practice questions tailored for middle-level students.',
    'Boost confidence with engaging and effective practice sessions.',
    'Identify and strengthen areas where your child needs improvement.',
  ],
  pricingTitle: 'Comprehensive ISEE Prep at a Competitive Price',
  pricingSubtitle: [
    'Provide your child with quality practice and personalized feedback to achieve their academic goals.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'ISEE_MIDDLE_VERBAL',
      displayName: 'Verbal Reasoning Section',
      description:
        'Tests vocabulary and reasoning skills through synonyms and sentence completion questions.',
      icon: 'remixChat3Line', // ng-icons Remix Icon for verbal
      categories: [
        {
          categoryId: 'ISEE_MIDDLE_VERBAL_SYNONYMS',
          displayName: 'Synonyms',
          description:
            'Testing vocabulary knowledge by identifying words with similar meanings.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_VERBAL_SYNONYMS_INTERMEDIATE_VOCABULARY',
              displayName: 'Intermediate Vocabulary',
              description: 'Words appropriate for middle-level students.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_VERBAL_SYNONYMS_ADVANCED_VOCABULARY',
              displayName: 'Advanced Vocabulary',
              description: 'Challenging words to test higher-level vocabulary.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_VERBAL_SENTENCE_COMPLETION',
          displayName: 'Sentence Completion',
          description:
            'Testing ability to complete sentences by understanding context and relationships.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_VERBAL_SENTENCE_COMPLETION_CONTEXT_CLUES',
              displayName: 'Context Clues',
              description:
                'Using surrounding text to determine the meaning of missing words.',
            },
            {
              subcategoryId:
                'ISEE_MIDDLE_VERBAL_SENTENCE_COMPLETION_LOGICAL_RELATIONSHIPS',
              displayName: 'Logical Relationships',
              description:
                'Understanding the logical flow and relationships within the sentence.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_MIDDLE_QUANT',
      displayName: 'Quantitative Reasoning Section',
      description:
        'Measures mathematical thinking and problem-solving skills, focusing on concepts and logic.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'ISEE_MIDDLE_QUANT_WORD_PROBLEMS',
          displayName: 'Word Problems',
          description:
            'Solving mathematical problems presented in real-world contexts.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_QUANT_WORD_PROBLEMS_NUMBER_OPERATIONS',
              displayName: 'Number Operations',
              description:
                'Applying arithmetic operations to solve problems.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_QUANT_WORD_PROBLEMS_ALGEBRAIC_REASONING',
              displayName: 'Algebraic Reasoning',
              description: 'Using algebra to solve problems.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_QUANT_QUANT_COMPARISONS',
          displayName: 'Quantitative Comparisons',
          description:
            'Comparing two quantities and determining the relationship between them.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_MIDDLE_QUANT_QUANT_COMPARISONS_GEOMETRY_COMPARISONS',
              displayName: 'Geometry Comparisons',
              description:
                'Comparing geometric figures and measurements.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_QUANT_QUANT_COMPARISONS_DATA_ANALYSIS',
              displayName: 'Data Analysis',
              description:
                'Interpreting and comparing data from graphs and charts.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_MIDDLE_READING',
      displayName: 'Reading Comprehension Section',
      description:
        'Assesses the ability to understand and interpret passages from various subjects.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading
      categories: [
        {
          categoryId: 'ISEE_MIDDLE_READING_MAIN_IDEA',
          displayName: 'Main Idea and Theme',
          description:
            'Identifying the central message or theme of the passage.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_READING_MAIN_IDEA_CENTRAL_IDEA',
              displayName: 'Central Idea',
              description:
                'Determining the main point or central idea of the passage.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_READING_MAIN_IDEA_THEME',
              displayName: 'Theme',
              description: 'Identifying overarching themes and messages.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_READING_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description:
            'Recognizing specific information and details in the passage.',
          icon: 'remixListUnordered',
          subcategories: [
            {
              subcategoryId:
                'ISEE_MIDDLE_READING_SUPPORTING_DETAILS_FACT_RECALL',
              displayName: 'Fact Recall',
              description: 'Recalling specific facts stated in the passage.',
            },
            {
              subcategoryId:
                'ISEE_MIDDLE_READING_SUPPORTING_DETAILS_DETAIL_INTERPRETATION',
              displayName: 'Detail Interpretation',
              description:
                'Interpreting details to understand their significance.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_READING_INFERENCE',
          displayName: 'Inference',
          description:
            'Drawing conclusions based on information implied in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_READING_INFERENCE_LOGICAL_CONCLUSIONS',
              displayName: 'Logical Conclusions',
              description: 'Making logical deductions from the text.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_READING_INFERENCE_PREDICTION',
              displayName: 'Prediction',
              description:
                'Predicting outcomes based on information given.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_READING_VOCABULARY_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Determining the meaning of words based on context.',
          icon: 'remixBookletLine',
          subcategories: [],
        },
        {
          categoryId: 'ISEE_MIDDLE_READING_AUTHORS_PURPOSE',
          displayName: "Author's Purpose and Tone",
          description:
            'Understanding why the author wrote the passage and identifying tone.',
          icon: 'remixUserVoiceLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_READING_AUTHORS_PURPOSE_PURPOSE',
              displayName: "Author's Purpose",
              description:
                'Determining the author’s intent in writing the passage.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_READING_AUTHORS_PURPOSE_TONE',
              displayName: "Author's Tone",
              description:
                'Identifying the author’s attitude or feelings conveyed in the passage.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_MIDDLE_MATH',
      displayName: 'Mathematics Achievement Section',
      description:
        'Measures knowledge of mathematical concepts and skills, focusing on problem-solving and application.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'ISEE_MIDDLE_MATH_NUMBER_OPERATIONS',
          displayName: 'Number Operations',
          description:
            'Understanding and applying arithmetic operations with various numbers.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_NUMBER_OPERATIONS_INTEGERS',
              displayName: 'Integers',
              description:
                'Operations involving positive and negative integers.',
            },
            {
              subcategoryId:
                'ISEE_MIDDLE_MATH_NUMBER_OPERATIONS_RATIONAL_NUMBERS',
              displayName: 'Rational Numbers',
              description:
                'Understanding and operating with fractions and decimals.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_MATH_ALGEBRA',
          displayName: 'Algebra',
          description:
            'Understanding variables, expressions, equations, and inequalities.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_ALGEBRA_EXPRESSIONS_EQUATIONS',
              displayName: 'Expressions and Equations',
              description:
                'Simplifying expressions and solving equations.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_ALGEBRA_INEQUALITIES',
              displayName: 'Inequalities',
              description: 'Solving and graphing inequalities.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_MATH_GEOMETRY',
          displayName: 'Geometry',
          description:
            'Understanding geometric shapes, properties, and relationships.',
          icon: 'remixShapeLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_GEOMETRY_LINES_ANGLES',
              displayName: 'Lines and Angles',
              description:
                'Properties of lines, angles, and their relationships.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_GEOMETRY_TRIANGLES',
              displayName: 'Triangles',
              description:
                'Properties of triangles, including the Pythagorean theorem.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_MATH_MEASUREMENT',
          displayName: 'Measurement',
          description:
            'Understanding units of measure and calculating perimeter, area, and volume.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_MEASUREMENT_PERIMETER_AREA',
              displayName: 'Perimeter and Area',
              description:
                'Calculating the perimeter and area of various shapes.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_MEASUREMENT_VOLUME',
              displayName: 'Volume',
              description: 'Calculating the volume of solids.',
            },
          ],
        },
        {
          categoryId: 'ISEE_MIDDLE_MATH_DATA_ANALYSIS',
          displayName: 'Data Analysis and Probability',
          description:
            'Interpreting data from graphs and understanding basic probability.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_DATA_ANALYSIS_STATISTICS',
              displayName: 'Statistics',
              description:
                'Understanding mean, median, mode, and range.',
            },
            {
              subcategoryId: 'ISEE_MIDDLE_MATH_DATA_ANALYSIS_PROBABILITY',
              displayName: 'Probability',
              description: 'Calculating probabilities of simple events.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_MIDDLE_ESSAY',
      displayName: 'Essay Section',
      description:
        'Requires students to write an essay in response to a prompt. This section is unscored but sent to schools as a sample of the student\'s writing ability.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'ISEE_MIDDLE_ESSAY_WRITING_SKILLS',
          displayName: 'Writing Skills',
          description:
            'Assessment of the student’s ability to write coherently and effectively.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_MIDDLE_ESSAY_WRITING_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Logical structure and flow of the essay.',
            },
            {
              subcategoryId:
                'ISEE_MIDDLE_ESSAY_WRITING_SKILLS_CONTENT_DEVELOPMENT',
              displayName: 'Content Development',
              description:
                'Development of ideas and use of supporting details.',
            },
            {
              subcategoryId:
                'ISEE_MIDDLE_ESSAY_WRITING_SKILLS_LANGUAGE_USE',
              displayName: 'Language Use',
              description: 'Grammar, vocabulary, and sentence structure.',
            },
            {
              subcategoryId:
                'ISEE_MIDDLE_ESSAY_WRITING_SKILLS_MECHANICS',
              displayName: 'Mechanics',
              description: 'Spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
  ],
};


// ISEE Upper Level Exam Data
export const iseeUpperExamData: ExamData = {
  examId: 'ISEE_UPPER_2025',
  displayName: 'ISEE Upper Level',
  description:
    'The ISEE Upper Level is designed for students applying for admission to grades 9 through 12. It assesses reading, verbal reasoning, quantitative reasoning, mathematics achievement, and essay writing.',
  logo: 'assets/images/exams/isee_upper_logo.png',
  link: 'isee-upper',
  zoom: 1.1,
  priceId: 'ISEE_UPPER_2025_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Master the ISEE Upper Level with Expert Guidance',
  marketingSubtitles: [
    'Our adaptive platform tailors your prep to maximize your ISEE score.',
    'Access comprehensive practice questions designed for upper-level students.',
    'Enhance your skills with targeted practice sessions.',
    'Identify and focus on areas needing improvement for optimal results.',
  ],
  pricingTitle: 'Premium ISEE Prep at an Affordable Price',
  pricingSubtitle: [
    'Achieve your academic aspirations with quality practice and personalized feedback.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'ISEE_UPPER_VERBAL',
      displayName: 'Verbal Reasoning Section',
      description:
        'Tests advanced vocabulary and reasoning skills through synonyms and sentence completion questions.',
      icon: 'remixChat3Line', // ng-icons Remix Icon for verbal
      categories: [
        {
          categoryId: 'ISEE_UPPER_VERBAL_SYNONYMS',
          displayName: 'Synonyms',
          description:
            'Testing advanced vocabulary knowledge by identifying words with similar meanings.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_VERBAL_SYNONYMS_ADVANCED_VOCABULARY',
              displayName: 'Advanced Vocabulary',
              description:
                'Challenging words appropriate for upper-level students.',
            },
            {
              subcategoryId: 'ISEE_UPPER_VERBAL_SYNONYMS_NUANCED_MEANINGS',
              displayName: 'Nuanced Meanings',
              description:
                'Understanding subtle differences between similar words.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_VERBAL_SENTENCE_COMPLETION',
          displayName: 'Sentence Completion',
          description:
            'Testing ability to complete sentences by understanding complex context and relationships.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_VERBAL_SENTENCE_COMPLETION_CONTEXT_CLUES',
              displayName: 'Context Clues',
              description:
                'Using surrounding text to determine the meaning of missing words.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_VERBAL_SENTENCE_COMPLETION_LOGICAL_RELATIONSHIPS',
              displayName: 'Logical Relationships',
              description:
                'Understanding complex logical flow and relationships within the sentence.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_UPPER_QUANT',
      displayName: 'Quantitative Reasoning Section',
      description:
        'Measures advanced mathematical thinking and problem-solving skills, focusing on complex concepts.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'ISEE_UPPER_QUANT_WORD_PROBLEMS',
          displayName: 'Word Problems',
          description:
            'Solving complex mathematical problems presented in real-world contexts.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_QUANT_WORD_PROBLEMS_ADVANCED_ALGEBRA',
              displayName: 'Advanced Algebra',
              description:
                'Applying advanced algebraic concepts to solve problems.',
            },
            {
              subcategoryId: 'ISEE_UPPER_QUANT_WORD_PROBLEMS_GEOMETRY_APPLICATION',
              displayName: 'Geometry Application',
              description: 'Using geometric principles in problem-solving.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_QUANT_QUANT_COMPARISONS',
          displayName: 'Quantitative Comparisons',
          description:
            'Comparing two quantities and determining the relationship between them.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_UPPER_QUANT_QUANT_COMPARISONS_FUNCTIONS',
              displayName: 'Functions',
              description:
                'Comparing values of functions and their properties.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_QUANT_QUANT_COMPARISONS_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description:
                'Interpreting and comparing data from complex graphs and charts.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_UPPER_READING',
      displayName: 'Reading Comprehension Section',
      description:
        'Assesses the ability to understand and interpret complex passages from various subjects.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading
      categories: [
        {
          categoryId: 'ISEE_UPPER_READING_MAIN_IDEA',
          displayName: 'Main Idea and Theme',
          description:
            'Identifying the central message or theme of complex passages.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_READING_MAIN_IDEA_CENTRAL_IDEA',
              displayName: 'Central Idea',
              description:
                'Determining the main point or central idea of the passage.',
            },
            {
              subcategoryId: 'ISEE_UPPER_READING_MAIN_IDEA_THEME',
              displayName: 'Theme',
              description: 'Identifying overarching themes and messages.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_READING_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description:
            'Recognizing specific information and details in the passage.',
          icon: 'remixListUnordered',
          subcategories: [
            {
              subcategoryId:
                'ISEE_UPPER_READING_SUPPORTING_DETAILS_FACT_RECALL',
              displayName: 'Fact Recall',
              description: 'Recalling specific facts stated in the passage.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_READING_SUPPORTING_DETAILS_DETAIL_INTERPRETATION',
              displayName: 'Detail Interpretation',
              description:
                'Interpreting details to understand their significance.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_READING_INFERENCE',
          displayName: 'Inference',
          description:
            'Drawing conclusions based on information implied in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_READING_INFERENCE_LOGICAL_CONCLUSIONS',
              displayName: 'Logical Conclusions',
              description: 'Making logical deductions from the text.',
            },
            {
              subcategoryId: 'ISEE_UPPER_READING_INFERENCE_PREDICTION',
              displayName: 'Prediction',
              description:
                'Predicting outcomes based on information given.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_READING_VOCABULARY_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Determining the meaning of words based on context.',
          icon: 'remixBookletLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_UPPER_READING_VOCABULARY_CONTEXT_CONTEXTUAL_MEANINGS',
              displayName: 'Contextual Meanings',
              description:
                'Determining word meanings based on context clues.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_READING_VOCABULARY_CONTEXT_NUANCED_VOCABULARY',
              displayName: 'Nuanced Vocabulary',
              description:
                'Understanding subtle differences in word meanings within context.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_READING_AUTHORS_PURPOSE',
          displayName: "Author's Purpose and Tone",
          description:
            'Understanding why the author wrote the passage and identifying tone.',
          icon: 'remixUserVoiceLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_READING_AUTHORS_PURPOSE_PURPOSE',
              displayName: "Author's Purpose",
              description:
                'Determining the author’s intent in writing the passage.',
            },
            {
              subcategoryId: 'ISEE_UPPER_READING_AUTHORS_PURPOSE_TONE',
              displayName: "Author's Tone",
              description:
                'Identifying the author’s attitude or feelings conveyed in the passage.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_READING_CRITICAL_ANALYSIS',
          displayName: 'Critical Analysis',
          description:
            'Evaluating arguments, reasoning, and effectiveness of the passage.',
          icon: 'remixBarChartLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_UPPER_READING_CRITICAL_ANALYSIS_ARGUMENT_EVALUATION',
              displayName: 'Argument Evaluation',
              description:
                'Assessing the strength and validity of arguments presented.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_READING_CRITICAL_ANALYSIS_LOGICAL_FALLACIES',
              displayName: 'Logical Fallacies',
              description:
                'Identifying errors in reasoning or logical fallacies.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_UPPER_MATH',
      displayName: 'Mathematics Achievement Section',
      description:
        'Measures knowledge of advanced mathematical concepts and skills, focusing on problem-solving and application.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'ISEE_UPPER_MATH_NUMBER_OPERATIONS',
          displayName: 'Number Operations',
          description:
            'Understanding and applying operations with real numbers, including rational and irrational numbers.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_MATH_NUMBER_OPERATIONS_RATIONAL_NUMBERS',
              displayName: 'Rational Numbers',
              description:
                'Operations with fractions, decimals, and percentages.',
            },
            {
              subcategoryId: 'ISEE_UPPER_MATH_NUMBER_OPERATIONS_IRRATIONAL_NUMBERS',
              displayName: 'Irrational Numbers',
              description:
                'Understanding and approximating irrational numbers.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_MATH_ALGEBRA',
          displayName: 'Algebra',
          description:
            'Understanding advanced algebraic concepts, including functions, polynomials, and systems of equations.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_MATH_ALGEBRA_FUNCTIONS',
              displayName: 'Functions',
              description:
                'Understanding and manipulating functions and their graphs.',
            },
            {
              subcategoryId: 'ISEE_UPPER_MATH_ALGEBRA_POLYNOMIALS',
              displayName: 'Polynomials',
              description:
                'Operations with polynomials, including factoring and expanding.',
            },
            {
              subcategoryId: 'ISEE_UPPER_MATH_ALGEBRA_SYSTEMS_EQUATIONS',
              displayName: 'Systems of Equations',
              description:
                'Solving systems of equations algebraically and graphically.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_MATH_GEOMETRY',
          displayName: 'Geometry',
          description:
            'Understanding geometric shapes, properties, theorems, and proofs.',
          icon: 'remixShapeLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_MATH_GEOMETRY_COORDINATE_GEOMETRY',
              displayName: 'Coordinate Geometry',
              description:
                'Graphing and analyzing geometric shapes in the coordinate plane.',
            },
            {
              subcategoryId: 'ISEE_UPPER_MATH_GEOMETRY_SOLID_GEOMETRY',
              displayName: 'Solid Geometry',
              description:
                'Understanding properties of three-dimensional figures.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_MATH_MEASUREMENT',
          displayName: 'Measurement',
          description:
            'Calculating perimeter, area, surface area, and volume of various shapes.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_MATH_MEASUREMENT_PERIMETER_AREA',
              displayName: 'Perimeter and Area',
              description:
                'Calculating perimeter and area of two-dimensional figures.',
            },
            {
              subcategoryId: 'ISEE_UPPER_MATH_MEASUREMENT_SURFACE_AREA_VOLUME',
              displayName: 'Surface Area and Volume',
              description:
                'Calculating surface area and volume of three-dimensional figures.',
            },
          ],
        },
        {
          categoryId: 'ISEE_UPPER_MATH_DATA_ANALYSIS',
          displayName: 'Data Analysis and Probability',
          description:
            'Interpreting data from graphs and understanding probability and statistics.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'ISEE_UPPER_MATH_DATA_ANALYSIS_STATISTICS',
              displayName: 'Statistics',
              description:
                'Understanding mean, median, mode, range, and standard deviation.',
            },
            {
              subcategoryId: 'ISEE_UPPER_MATH_DATA_ANALYSIS_PROBABILITY',
              displayName: 'Probability',
              description:
                'Calculating probabilities of complex events.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'ISEE_UPPER_ESSAY',
      displayName: 'Essay Section',
      description:
        'Requires students to write an essay in response to a prompt. This section is unscored but sent to schools as a sample of the student\'s writing ability.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'ISEE_UPPER_ESSAY_WRITING_SKILLS',
          displayName: 'Writing Skills',
          description:
            'Assessment of the student’s ability to write coherently and effectively.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId:
                'ISEE_UPPER_ESSAY_WRITING_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Logical structure and flow of the essay.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_ESSAY_WRITING_SKILLS_CONTENT_DEVELOPMENT',
              displayName: 'Content Development',
              description:
                'Development of ideas and use of supporting details.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_ESSAY_WRITING_SKILLS_LANGUAGE_USE',
              displayName: 'Language Use',
              description: 'Grammar, vocabulary, and sentence structure.',
            },
            {
              subcategoryId:
                'ISEE_UPPER_ESSAY_WRITING_SKILLS_MECHANICS',
              displayName: 'Mechanics',
              description: 'Spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
  ],
};
