import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, of, timeout} from 'rxjs';
import { catchError, finalize, map, startWith, tap } from 'rxjs/operators';
import { RequestState } from './request-state.enum';  // Import the RequestState enum
import { ApiState } from './api-state.interface';
import {environment} from "../../environments/environment";    // Import the ApiState interface

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private baseUrl = environment.apiBaseUrl;  // Get the API URL from environment.ts

  constructor(private http: HttpClient) {}

  // GET request (the token will automatically be added by the interceptor)
  simpleGetData<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${endpoint}`);
  }

  // POST request (the token will automatically be added by the interceptor)
  simplePostData<T>(endpoint: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, data);
  }

  // Create a method to track the state of a GET request with BehaviorSubject
  getData<T>(endpoint: string): Observable<ApiState<T>> {
    const url = `${this.baseUrl}/${endpoint}`;
    console.log('getData url=' + url);

    // Perform the GET request and return the observable chain
    return this.http.get<T>(url).pipe(
      map((response: T) => ({
        state: RequestState.SUCCESS,
        data: response,
        error: null
      })),
      catchError((error) => of({
        state: RequestState.ERROR,
        data: null,
        error: error
      })),
      startWith({
        state: RequestState.LOADING,
        data: null,
        error: null
      })
    );
  }

  // Create a method to track the state of a POST request with BehaviorSubject
  postData<T>(endpoint: string, body: any): Observable<ApiState<T>> {
    const url = `${this.baseUrl}/${endpoint}`;
    console.log('postData url=' + url);

    // Perform the POST request and return the observable chain
    return this.http.post<T>(url, body).pipe(
      map((response: T) => ({
        state: RequestState.SUCCESS,
        data: response,
        error: null
      })),
      catchError((error) => of({
        state: RequestState.ERROR,
        data: null,
        error: error
      })),
      startWith({
        state: RequestState.LOADING,
        data: null,
        error: null
      })
    );
  }
}
