import {Component, Input} from '@angular/core';
import {CohortStats} from "../../../models/cohort-stats";
import {DecimalPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {NgIcon} from "@ng-icons/core";
import {RequestState} from "../../../services/request-state.enum";

@Component({
  selector: 'app-categories-statistics',
  standalone: true,
  imports: [
    DecimalPipe,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgIf,
    NgIcon
  ],
  templateUrl: './categories-statistics.component.html',
  styleUrl: './categories-statistics.component.css'
})
export class CategoriesStatisticsComponent {
  @Input() categoryStats: CohortStats[] = []; // Input data from parent

  @Input() isPremium: boolean = false;
  @Input() onPremiumPlanClick!: () => void;
  @Input() blurImgSrc: string = '';
  @Input() requestState: RequestState = RequestState.LOADING;

  @Input() title: string = '';
  @Input() infoOnHover: string = '';

  maxInteractionLimit: number = 10;

  protected readonly Math = Math;
  protected readonly RequestState = RequestState;
}
