import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApexChart, ApexNonAxisChartSeries, ApexDataLabels, ApexLegend, ApexTitleSubtitle, NgApexchartsModule} from 'ng-apexcharts';
import {CohortStats} from "../../../models/cohort-stats";
import {DecimalPipe, NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase} from "@angular/common";
import {RequestState} from "../../../services/request-state.enum";
import {NgIcon} from "@ng-icons/core";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  colors: string[];
  title?: ApexTitleSubtitle;
  dataLabels?: ApexDataLabels;
  legend?: ApexLegend;
};

@Component({
  selector: 'app-sections-statistics',
  standalone: true,
  imports: [
    NgStyle,
    NgApexchartsModule,
    NgForOf,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    DecimalPipe,
    NgIcon
  ],
  templateUrl: './sections-statistics.component.html',
  styleUrl: './sections-statistics.component.css'
})
export class SectionsStatisticsComponent implements OnInit, OnChanges {
  @Input() sectionStats: CohortStats[] = []; // Input data from parent
  @Input() isPremium: boolean = false;
  @Input() onPremiumPlanClick!: () => void;
  @Input() requestState: RequestState = RequestState.LOADING;

  blurImgSrc: string = '/assets/images/dashboard/blur_section_performance.png';
  maxInteractionLimit: number = 20;
  public chartOptions: Partial<ChartOptions>[] = [];

  // Initialize charts
  ngOnInit(): void {
    this.initializeCharts();
  }

  // Respond to changes in input data
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sectionStats'] && changes['sectionStats'].currentValue) {
      this.initializeCharts();
    }
  }

  // Initialize charts based on the sectionStats data
  initializeCharts(): void {
    if (this.sectionStats.length === 0) {
      return; // No data
    }

    this.chartOptions = []; // Clear previous chart options
    this.sectionStats.forEach((section) => {
      this.chartOptions.push({
        series: [section.numberQuestionsCorrect, section.numberQuestionsIncorrect, section.numberQuestionsSkipped],
        chart: {
          type: 'donut',
        },
        labels: ['Correct', 'Incorrect', 'Skipped'],
        colors: ['#4CAF50', '#FF5722', '#FFC107'],

      });
    });

    console.log('chartOptions', this.chartOptions);
    console.log('sectionStats', this.sectionStats);
  }

    protected readonly Math = Math;
  protected readonly RequestState = RequestState;
}
