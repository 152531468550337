import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {ApiService} from "./api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiState} from "./api-state.interface";
import {ExamResponse} from "../models/exam-response";
import {Router} from "@angular/router";
import {ExamData} from "./exam-data-interface";

import {satExamData} from "./data/sat-data";
import {actExamData} from "./data/act-data";
import {gmatExamData} from "./data/gmat-data";
import {greExamData} from "./data/gre-data";
import {toeflExamData} from "./data/toefl-data";

import {lsatExamData} from "./data/lsat-data";
import {mcatExamData} from "./data/mcat-data";
import {psatExamData} from "./data/psat-data";
import {
  apEnglishLanguageExamData,
  apEnglishLiteratureExamData,
  apPsychologyExamData,
  apUsGovernmentExamData, apUsHistoryExamData
} from "./data/ap-data";
import {nclexRnExamData} from "./data/nclex-data";
import {cfaLevel1ExamData, cfaLevel2ExamData, cfaLevel3ExamData} from "./data/cfa-data";
import {ssatElemExamData, ssatMiddleExamData, ssatUpperExamData} from "./data/ssat-data";
import {iseeLowerExamData, iseeMiddleExamData, iseeUpperExamData} from "./data/isee-data";

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  private k8Exams: ExamData[] = [
    ssatElemExamData,
    ssatMiddleExamData,
    ssatUpperExamData,
    iseeLowerExamData,
    iseeMiddleExamData,
    iseeUpperExamData,
  ];

  private highSchoolExams: ExamData[] = [
    satExamData,
    actExamData,
    psatExamData,
    apEnglishLanguageExamData,
    apEnglishLiteratureExamData,
    apUsGovernmentExamData,
    apPsychologyExamData,
    apUsHistoryExamData
  ];

  private graduateExams: ExamData[] = [
    greExamData,
    gmatExamData,
    lsatExamData,
    mcatExamData
  ];

  private professionalExams: ExamData[] = [
    nclexRnExamData,
    cfaLevel1ExamData,
    cfaLevel2ExamData,
    cfaLevel3ExamData
  ];

  private languageExams: ExamData[] = [
    toeflExamData
  ];

  private examDataList: ExamData[] = [
    ...this.k8Exams,
    ...this.highSchoolExams,
    ...this.graduateExams,
    ...this.professionalExams,
    ...this.languageExams
  ];

  private currentExamIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public currentExamId$: Observable<string | null>;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private apiService: ApiService, private router: Router) {
    // On initialization, check localStorage for an existing examId. Do this only when not SSR.
    let storedExamId : string | null = null;
    if (isPlatformBrowser(this.platformId)) {
      storedExamId = localStorage.getItem('currentExamId');
    }

    // Initialize the BehaviorSubject with the stored examId or null
    this.currentExamIdSubject = new BehaviorSubject<string | null>(storedExamId ? storedExamId : null);
    this.currentExamId$ = this.currentExamIdSubject.asObservable();
  }

  // Method to update the current examId and store it in localStorage
  updateCurrentExamId(examId: string | null): void {
    // Update the BehaviorSubject
    this.currentExamIdSubject.next(examId);

    if (isPlatformBrowser(this.platformId)) {
      if (examId) {
        // Store the examId in localStorage
        localStorage.setItem('currentExamId', examId);
      } else {
        // Remove the examId from localStorage if null
        localStorage.removeItem('currentExamId');
      }
    }
  }

  // Method to fetch a question by questionId
  getExam(examId: string): Observable<ApiState<ExamResponse>> {
    const endpoint = `exam/${examId}`;
    return this.apiService.getData<ExamResponse>(endpoint);
  }

  getExamDataList() {
    return this.examDataList;
  }

  getK8Exams() {
    return this.k8Exams;
  }

  getHighSchoolExams() {
    return this.highSchoolExams;
  }

  getGraduateExams() {
    return this.graduateExams;
  }

  getProfessionalExams() {
    return this.professionalExams;
  }

  getLanguageExams() {
    return this.languageExams;
  }

  getLinkNameFromExamId(examId: string)  {
    const examData = this.examDataList.find(exam => exam.examId.toLowerCase() === examId.toLowerCase());

    if (examData != null) {
      return examData.link;
    }

    return '';
  }

  getExamDataFromExamLinkName(examLinkName: string) {
    return this.examDataList.find(exam => exam.link.toLowerCase() === examLinkName?.toLowerCase())!;
  }

  getExamDisplayFromExamLinkName(examLinkName: string) {
    const examData = this.examDataList.find(exam => exam.link.toLowerCase() === examLinkName?.toLowerCase())!;
    if (examData == null) {
      return ''
    }

    return examData.displayName;
  }

  getExamDataFromExamId(examId: string | null): ExamData | null {
    if (examId) {
      const examData = this.examDataList.find(exam => exam.examId.toLowerCase() === examId?.toLowerCase());
      if (examData) {
        return examData;
      }
    }

    return null;
  }


  navigateToDashboard(examId: string | null) {
    if (examId != null) {
      const linkName = this.getLinkNameFromExamId(examId);
      return this.router.navigate(['/dashboard', linkName]);
    } else {
      return this.router.navigate(['/exams'], {fragment: 'exams-list'});
    }
  }


}
