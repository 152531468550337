import { Component } from '@angular/core';
import { DashboardNavigationComponent } from "../../components/dashboard-navigation/dashboard-navigation.component";
import {RouterOutlet} from "@angular/router";
import {LandingFooterComponent} from "../../components/landing-footer/landing-footer.component";

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    DashboardNavigationComponent,
    LandingFooterComponent
  ],
  templateUrl: './dashboard-layout.component.html',
})
export class DashboardLayoutComponent {}
