import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiState} from "./api-state.interface";
import {CheckoutRequest} from "../models/checkout-request";
import {CheckoutResponse} from "../models/checkout-response";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  constructor(private apiService: ApiService) {}

  getCheckoutSession(checkoutRequest: CheckoutRequest): Observable<ApiState<CheckoutResponse>> {
    const endpoint = `create-checkout-session`;
    return this.apiService.postData<CheckoutResponse>(endpoint, checkoutRequest);
  }

  refreshSubscriptionCache(): Observable<ApiState<boolean>> {
    const endpoint = `checkout-success`;
    return this.apiService.getData<boolean>(endpoint);
  }
}
