<div class="min-h-screen flex flex-col"> <!-- Ensures the page takes the full height of the screen -->
  <section class="relative bg-gray-100 overflow-hidden flex-grow h-full"> <!-- flex-grow and h-full to make it fill the height -->
    <img class="absolute left-0 top-0" src="assets/images/gradient6.svg" alt="">
    <div class="relative z-10 flex flex-wrap -m-8 min-h-full h-full"> <!-- min-h-full here to ensure it's tall enough -->
      <div class="w-full md:w-1/2 p-8 flex flex-col h-full"> <!-- Apply h-full here -->
        <div class="container px-4 mx-auto h-full"> <!-- Ensure full height for container -->
          <div class="flex flex-wrap h-full"> <!-- Ensure full height for flex container -->
            <div class="w-full h-full flex flex-col justify-between"> <!-- Ensure full height for inner flex container -->
              <div class="md:max-w-lg mx-auto pt-16 md:pb-32">
                <a class="mb-28 inline-block" href="#">
                  <img class="h-12" src="assets/logos/studious-logo-bold-10.svg" alt="logo">
                </a>
                <h2 class="mb-32 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">Welcome back. Let's boost your exam score.</h2>
                <h3 class="mb-9 text-xl font-bold font-heading leading-normal">Check out some of our latest dashboard updates!</h3>
                <ul class="md:max-w-xs">
                  <li class="mb-5 flex items-start justify-between">
                    <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2 self-center" style="width: 25px; height: 26px;">
                    <span class="flex-1 font-medium leading-relaxed">Added worst performing categories and subcategories</span>
                  </li>
                  <li class="mb-5 flex items-start justify-between">
                    <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2 self-center" style="width: 25px; height: 26px;">
                    <span class="flex-1 font-medium leading-relaxed">Personalized recommendations for score improvement</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right half - Center the sign-in form -->
      <div class="w-full md:w-1/2 p-8 flex justify-center items-center"> <!-- Centering the form container -->
        <div class="p-8 bg-white rounded-lg shadow-xl max-w-lg min-w-[450px] border border-gray-300 mr-10"> <!-- Floating Sign-In Form with right margin, wider box, and outline -->

          <!-- Conditional Message -->
          <div *ngIf="message" class="mb-6 p-4 bg-blue-100 border border-blue-400 text-blue-700 rounded-lg">
            {{ message }}
          </div>

          <form [formGroup]="form" (ngSubmit)="onLoginClick()" class="md:max-w-lg mx-auto">
            <!-- Email Address Input -->
            <label class="block mb-4 mt-4">
              <p class="mb-2 text-gray-900 font-semibold leading-normal">Email Address *</p>
              <input
                formControlName="email"
                [ngClass]="{'border-red-500': form.get('email')?.invalid && form.get('email')?.touched}"
                class="px-4 py-3.5 w-full text-black font-medium placeholder-gray-400 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-custom-blue-200"
                type="email" placeholder="Enter email address">
              <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="text-red-500 text-sm mt-1">
                <!-- Error messages -->
                <span *ngIf="f.email.errors?.['required']">Email is required.</span>
                <span *ngIf="f.email.errors?.['email']">Please enter a valid email address.</span>
              </div>
            </label>

            <!-- Password Input -->
            <label class="block mb-5">
              <p class="mb-2 text-gray-900 font-semibold leading-normal">Password *</p>
              <input
                formControlName="password"
                [ngClass]="{'border-red-500': form.get('password')?.invalid && form.get('password')?.touched}"
                class="px-4 py-3.5 w-full text-black font-medium placeholder-gray-400 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-custom-blue-200"
                type="password" placeholder="*********">

              <div *ngIf="form.get('password')?.invalid && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                <!-- Error messages -->
                <span *ngIf="form.get('password')?.errors?.['required']">Password is required.</span>
              </div>
            </label>

            <!-- Forgot Password & Remember Me -->
            <div class="flex flex-wrap justify-between -m-2 mb-4">
              <div class="w-auto p-2">
                <div class="flex items-center">
                  <input formControlName="rememberMe" class="w-4 h-4" id="rememberMe" type="checkbox">
                  <label class="ml-2 text-sm text-gray-900 font-medium" for="rememberMe">Remember Me</label>
                </div>
              </div>
              <div class="w-auto p-2">
                <a (click)="onForgotPassword($event)" class="text-sm text-custom-blue-500 hover:text-custom-blue-600 font-medium cursor-pointer">Forgot Password?</a>
              </div>
            </div>

            <!-- Submit Button -->
            <button
              class="mb-4 py-4 px-9 w-full text-white font-semibold border border-custom-blue-600 rounded-xl shadow-4xl focus:ring focus:ring-custom-blue-200 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200"
              type="submit" [disabled]="isSubmitting">
              Sign In
            </button>

            <!-- Display Success and Error Messages -->
            <div *ngIf="successMessage" class="text-green-500 font-medium mb-4">{{ successMessage }}</div>
            <div *ngIf="errorMessage" class="text-red-500 font-medium mb-4">{{ errorMessage }}</div>

            <p class="text-center text-sm text-gray-500 mb-6">
              Don't have an account?
              <a routerLink="/register" class="text-custom-blue-500 hover:text-custom-blue-600 font-medium">
                Sign Up
              </a>
            </p>

          </form>

          <p class="mb-5 text-sm text-gray-500 font-medium text-center">Or continue with</p>
          <div class="flex flex-wrap justify-center -m-2">
            <div class="w-auto p-2">
              <button (click)="onGoogleLoginClick()" class="flex items-center p-4 bg-white hover:bg-gray-50 border rounded-lg transition ease-in-out duration-200">
                <img class="mr-3" ngSrc="assets/logos/brands/google.svg" alt="" height="20" width="19">
                <span class="font-semibold leading-normal">Sign in with Google</span>
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>
</div>
