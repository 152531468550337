// meta.service.ts

import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import {REQUEST} from "../shared/injection-tokens";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta,
              private titleService: Title,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              @Optional() @Inject(REQUEST) private request: any) {}

  updateMetaTags(metaData: {
    title?: string;
    description?: string;
    keywords?: string;
    author?: string;
    robots?: string;
    canonical?: string;
    image?: string;
  }) {
    if (metaData.title) {
      this.titleService.setTitle(metaData.title);
      this.meta.updateTag({ property: 'og:title', content: metaData.title });
      this.meta.updateTag({ name: 'twitter:title', content: metaData.title });
    }

    if (metaData.description) {
      this.meta.updateTag({ name: 'description', content: metaData.description });
      this.meta.updateTag({ property: 'og:description', content: metaData.description });
      this.meta.updateTag({ name: 'twitter:description', content: metaData.description });
    }

    if (metaData.keywords) {
      this.meta.updateTag({ name: 'keywords', content: metaData.keywords });
    }

    if (metaData.author) {
      this.meta.updateTag({ name: 'author', content: metaData.author });
    }

    if (metaData.robots) {
      this.meta.updateTag({ name: 'robots', content: metaData.robots });
    }

    if (metaData.canonical) {
      this.meta.updateTag({ rel: 'canonical', href: metaData.canonical });
      this.meta.updateTag({ property: 'og:url', content: metaData.canonical });
    }

    if (metaData.image) {
      this.meta.updateTag({ property: 'og:image', content: metaData.image });
      this.meta.updateTag({ name: 'twitter:image', content: metaData.image });
    }

    // Set default tags
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:locale', content: 'en_US' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  }

  generateCanonicalUrl(): string {
    if (isPlatformBrowser(this.platformId)) {
      // On the browser, use window.location
      return window.location.href;
    } else if (this.request) {
      // Use environment domain if fully specified, otherwise reconstruct with protocol
      const baseDomain = environment.domain.includes('://')
        ? environment.domain
        : `${this.request.protocol}://${environment.domain}`;

      // Ensure there's a slash between baseDomain and originalUrl
      const originalUrl = this.request.originalUrl.startsWith('/') ? this.request.originalUrl : `/${this.request.originalUrl}`;
      return `${baseDomain}${originalUrl}`;
    } else {
      // Fallback or default URL during SSR
      const fallbackUrl = this.router.url.startsWith('/') ? this.router.url : `/${this.router.url}`;
      return `${environment.domain}${fallbackUrl}`;
    }
  }
}
