import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {NgClass, NgIf} from "@angular/common";
import {UserService} from "../../../services/user.service";
import {ExamService} from "../../../services/exam.service";
import {QuestionService} from "../../../services/question-service";
import {NextQuestionRequest} from "../../../models/next-question-request";
import {ApiState} from "../../../services/api-state.interface";
import {NextQuestionResponse} from "../../../models/next-question-response";
import {RequestState} from "../../../services/request-state.enum";
import {QuestionState} from "../../../pages/question/question-state.enum";
import {Subscription} from "rxjs";
import {PlanService} from "../../../services/plan.service";
import {IconsModule} from "../../icons/icons.module";
import {ExamData} from "../../../services/exam-data-interface";

@Component({
  selector: 'app-dashboard-navigation',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    IconsModule
  ],
  templateUrl: './dashboard-navigation.component.html',
  styleUrl: './dashboard-navigation.component.css'
})
export class DashboardNavigationComponent implements OnInit, OnDestroy {

  examId: string | null = null;
  examDataList: ExamData[] = [];
  examData: ExamData | null = null;

  isLoggedIn: boolean = false;
  mobileNavOpen: boolean = false;
  profileMenuOpen: boolean = false;
  isPremium: boolean = false;

  private subscriptions: Subscription = new Subscription();

  constructor(private authService: AuthService,
              private userService: UserService,
              private examService: ExamService,
              private planService: PlanService,
              private questionService: QuestionService,
              public router: Router) {}

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    this.examDataList = this.examService.getExamDataList();

    const sub = this.examService.currentExamId$.subscribe(examId => {
      this.examId = examId;
      console.log('dashboard nav oninit currentExamId', this.examId )
      if (this.examId) {
        const sub2 = this.userService.isUserSubscribedToExam(this.examId)
          .subscribe(isSubscribed => {
          this.isPremium = isSubscribed;
        });
        this.subscriptions.add(sub2);
      }

      this.examData = this.examDataList.find(exam => exam.examId === this.examId)!;
      if (!this.examData) {
        // Handle the case where the exam is not found
        console.error('Exam not found');
        // Optionally, redirect to a 404 page or show a message
        this.router.navigate(['/error']);
      }
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleMobileNav() {
    this.mobileNavOpen = !this.mobileNavOpen;
  }

  toggleProfileMenu() {
    this.profileMenuOpen = !this.profileMenuOpen;
  }

  // Check if the current route starts with '/question/'
  isQuestionRoute(): boolean {
    return this.router.url.startsWith('/question/');
  }

  isDashboardRoute(): boolean {
    return this.router.url.startsWith('/dashboard/');
  }

  getNextQuestionAndNavigate(event: Event) {
    if (this.examId) {
      // Don't use the local storage of the selected exam section or difficulty, since this
      // can cause problems with changing exams
      this.questionService.nextQuestionAndNavigateToPractice(this.examId);
    } else {
      this.router.navigate(['/exams']);
    }
  }

  goToDashboard() {
    console.log('goToDashboard this.currentExamId=' + this.examId);
    if (this.examId) {
      this.examService.navigateToDashboard(this.examId);
    } else {
      this.router.navigate(['/exams'], {fragment: 'exams-list'});
    }
  }

  // Close the menu when clicking outside
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.relative')) {  // Check if click is outside the menu
      this.profileMenuOpen = false;
    }
  }

  onSettingsClick() {
    this.profileMenuOpen = false;
    this.router.navigate(['/profile']);  // Redirect to login page after logout
  }

  // Method to handle user logout
  onLogoutClick() {
    this.profileMenuOpen = false;
    this.authService.logout();
    this.router.navigate(['/']);  // Redirect to login page after logout
  }

  onPremiumPlanClick() {
    this.planService.onPremiumPlanClick(
      this.examId,
      this.isLoggedIn,
      this.isPremium,
      this.examData?.priceId
    );
  }

}
