import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  Auth, authState, signInWithEmailAndPassword, createUserWithEmailAndPassword,
  signInWithPopup, sendPasswordResetEmail, updateProfile, setPersistence,
  sendEmailVerification, GoogleAuthProvider, User, onAuthStateChanged
} from '@angular/fire/auth';
import { browserLocalPersistence, browserSessionPersistence} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { AuthenticationError, AuthenticationErrorName } from "../shared/util/error-types";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";
import { UserResponse } from "../models/user-response";
import { ApiState } from "./api-state.interface";
import { AnswerQuestionResponse } from "../models/answer-question-response";
import { RequestState } from "./request-state.enum";
import { QuestionState } from "../pages/question/question-state.enum";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { UserQuestionMetadataResponse } from "../models/user-question-metadata-response";
  /*
    Why not:
      this.user$ = this.afAuth.authState;
    While direct assignment is simpler, using BehaviorSubject provides additional control and benefits:

    1.	Initial Value: BehaviorSubject allows you to provide an initial value, which can be useful if you need a default state.
    2.	Explicit State Changes: With BehaviorSubject, you can explicitly set the user state from within the service. This is useful for actions like manual user updates or handling state changes that aren’t directly tied to the authentication state.
    3.	Immediate Subscription: BehaviorSubject ensures that new subscribers immediately receive the latest value, even if they subscribe after the value has been emitted.
  */

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Internal state management
  private firebaseUserSubject: BehaviorSubject<User | null | undefined> = new BehaviorSubject<
    User | null | undefined
  >(undefined); // Start with undefined to indicate loading, then if resolved, it becomes null.

  // Public observable for external use
  public firebaseUser$: Observable<User | null | undefined> = this.firebaseUserSubject.asObservable();

  // Internal state management for token
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public token$: Observable<string | null> = this.tokenSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private auth: Auth, private apiService: ApiService) {

    // Subscribe to Firebase auth state changes only in the browser
    if (isPlatformBrowser(this.platformId)) {
      // Subscribe to Firebase auth state changes
      onAuthStateChanged(this.auth, user => {
        this.firebaseUserSubject.next(user); // Update the subject with the user object
        if (user) {
          // this.setUserDataInLocalStorage(user);
          this.refreshTokenFromUser(user); // Fetch and store the token when the user logs in
        } else {
          this.tokenSubject.next(null); // Clear token on logout
          // this.clearUserDataFromLocalStorage(); // Clear local storage on logout
        }
      });
    } else {
      // On the server, set user as null
      this.firebaseUserSubject.next(null);
    }
  }

  // Fetch the token and update the tokenSubject.
  // We need to manage the token separately because user.getIdToken() returns a promise,
  // and we want to provide the token as an observable through tokenSubject.
  // This allows other parts of the application to access the current token when needed.
  private refreshTokenFromUser(user: User, forceRefresh: boolean = false): void {
    from(user.getIdToken(forceRefresh))
      .pipe(
        tap(token => {
          this.tokenSubject.next(token);
        }),
        catchError(err => {
          console.error('Error fetching token:', err);
          this.tokenSubject.next(null);
          return of(null);
        })
      )
      .subscribe();
  }

  // Method to get the current token
  public getToken(forceRefresh: boolean = false): Observable<string | null> {
    return this.firebaseUser$.pipe(
      filter(user => user !== undefined), // Wait until user is not undefined
      take(1),
      switchMap(user => {
        if (user) {
          return from(user.getIdToken(forceRefresh)).pipe(
            tap(token => {
              this.tokenSubject.next(token);
            }),
            catchError(err => {
              console.error('Error getting token:', err);
              this.tokenSubject.next(null);
              return of(null);
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  // Save user data in local storage (browser only)
  // private setUserDataInLocalStorage(user: User | null): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if (user) {
  //       localStorage.setItem('user', JSON.stringify(user));
  //     } else {
  //       this.clearUserDataFromLocalStorage();
  //     }
  //   }
  // }
  //
  // // Clear user data from local storage (browser only)
  // private clearUserDataFromLocalStorage(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     localStorage.removeItem('user');
  //     localStorage.removeItem('token');
  //   }
  // }

  // Check for stored user data in local storage when the app is initialized (browser only)
  // private checkStoredUserData(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const storedUser = localStorage.getItem('user');
  //     if (storedUser) {
  //       const user = JSON.parse(storedUser) as User;
  //       this.firebaseUserSubject.next(user);
  //       this.refreshTokenFromUser(user);
  //     } else {
  //       this.firebaseUserSubject.next(null);
  //     }
  //   } else {
  //     // On the server, set user as null
  //     this.firebaseUserSubject.next(null);
  //   }
  // }

  // Check if user is logged in by checking the Firebase auth state
  public isLoggedIn(): Observable<boolean> {
    return this.firebaseUser$.pipe(map(user => !!user));
  }

  // Log the user out
  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.auth
        .signOut()
        .then(() => {
          //this.clearUserDataFromLocalStorage(); // Clear user data from local storage
          this.firebaseUserSubject.next(null); // Clear the firebaseUserSubject
          this.tokenSubject.next(null); // Clear the token subject
          console.log('User logged out successfully');
        })
        .catch((error) => {
          console.error('Error during logout:', error);
        });
    }
  }

  // Firebase register with email
  firebaseRegisterWithEmail(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Observable<ApiState<UserResponse>> {
    if (isPlatformBrowser(this.platformId)) {
      // Convert Firebase createUserWithEmailAndPassword to an Observable
      return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
        switchMap((credential) => {
          const firebaseUser = credential.user;

          // Update user profile with first name and last name
          return from(
            updateProfile(firebaseUser, { displayName: `${firstName} ${lastName}` })
          ).pipe(
            switchMap(() => {
              if (firebaseUser) {
                this.firebaseUserSubject.next(firebaseUser);
                //this.setUserDataInLocalStorage(firebaseUser);
              }
              const url = `get-or-register-user`;
              return this.apiService.postData<UserResponse>(url, {});
            })
          );
        }),
        catchError((error) => {
          // Check if error is a Firebase error by looking for the `code` property
          if (error && error.code) {
            console.error('Firebase error:', error);
            return throwError(() => this.handleFirebaseUserCredentialsError(error));
          } else {
            console.error('Non-Firebase error:', error);
            return throwError(() => new Error('An error occurred while registering.'));
          }
        })
      );
    } else {
      return throwError(
        () => new Error('Cannot register on the server side.')
      );
    }
  }

  // Firebase login with email
  firebaseLoginWithEmail(
    email: string,
    password: string
  ): Observable<ApiState<UserResponse>> {
    if (isPlatformBrowser(this.platformId)) {
      return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
        switchMap((credential) => {
          // credential.user should always be valid after a successful authentication
          const firebaseUser = credential.user;

          this.firebaseUserSubject.next(firebaseUser);
          // this.setUserDataInLocalStorage(firebaseUser);
          const url = `get-or-register-user`;
          return this.apiService.postData<UserResponse>(url, {});
        }),
        catchError((error) => {
          // Check if error is a Firebase error by looking for the `code` property
          if (error && error.code) {
            console.error('Firebase error:', error);
            return throwError(() => this.handleFirebaseUserCredentialsError(error));
          } else {
            console.error('Non-Firebase error:', error);
            return throwError(() => new Error('An error occurred while logging in.'));
          }
        })
      );
    } else {
      return throwError(
        () => new Error('Cannot log in on the server side.')
      );
    }
  }

  handleFirebaseUserCredentialsError(error: FirebaseError): AuthenticationError {
    const errorCode = error.code;
    const errorName = this.mapErrorCodeToErrorName(errorCode); // Map Firebase code to AuthenticationErrorName type
    const errorMessage = this.getErrorMessageForCode(errorCode);

    console.error('Authentication error:', errorMessage);

    // Return the error with the mapped name and message
    return new AuthenticationError(errorName, errorMessage, error);
  }

  mapErrorCodeToErrorName(errorCode: string): AuthenticationErrorName {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'EMAIL_ALREADY_IN_USE';
      case 'auth/invalid-email':
        return 'INVALID_EMAIL';
      case 'auth/user-disabled':
        return 'USER_DISABLED';
      case 'auth/user-not-found':
        return 'USER_NOT_FOUND';
      case 'auth/wrong-password':
        return 'WRONG_PASSWORD';
      case 'auth/popup-closed-by-user':
        return 'POPUP_CLOSED_BY_USER';
      case 'auth/cancelled-popup-request':
        return 'CANCELLED_POPUP_REQUEST';
      case 'auth/invalid-api-key':
        return 'INVALID_API_KEY';
      case 'auth/network-request-failed':
        return 'NETWORK_REQUEST_FAILED';
      case 'auth/weak-password':
        return 'WEAK_PASSWORD';
      case 'auth/operation-not-allowed':
        return 'OPERATION_NOT_ALLOWED';
      default:
        return 'UNKNOWN';
    }
  }

  getErrorMessageForCode(errorCode: string): string {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please try logging in instead.';
      case 'auth/invalid-email':
        return 'The email address is not valid. Please enter a valid email.';
      case 'auth/user-disabled':
        return 'This user has been disabled. Please contact support.';
      case 'auth/user-not-found':
        return 'No user found with this email address.';
      case 'auth/wrong-password':
        return 'The password you entered is incorrect. Please try again.';
      case 'auth/popup-closed-by-user':
        return 'The login popup was closed before completing the login.';
      case 'auth/cancelled-popup-request':
        return 'The popup request was canceled. Please try again.';
      case 'auth/network-request-failed':
        return 'Network error occurred. Please check your connection and try again.';
      case 'auth/weak-password':
        return 'The password is too weak. Please choose a stronger password.';
      case 'auth/operation-not-allowed':
        return 'This operation is not allowed. Please contact support.';
      default:
        return 'An unknown error occurred. Please try again later.';
    }
  }

  // Firebase sign in with Google
  firebaseSignInWithGoogle(): Observable<ApiState<UserResponse>> {
    if (isPlatformBrowser(this.platformId)) {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });

      return from(signInWithPopup(this.auth, provider)).pipe(
        switchMap((credential) => {
          // credential.user should always be valid after a successful authentication
          const firebaseUser = credential.user;
          this.firebaseUserSubject.next(firebaseUser);
          // this.setUserDataInLocalStorage(firebaseUser);
          const url = `get-or-register-user`;
          return this.apiService.postData<UserResponse>(url, {});
        }),
        catchError((error) => {
          // Check if error is a Firebase error by looking for the `code` property
          if (error && error.code) {
            console.error('Firebase error:', error);
            return throwError(() => this.handleFirebaseUserCredentialsError(error));
          } else {
            console.error('Non-Firebase error:', error);
            return throwError(() => new Error('An error occurred while logging in.'));
          }
        })
      );
    } else {
      return throwError(
        () => new Error('Cannot sign in with Google on the server side.')
      );
    }
  }

  // Set Firebase auth persistence
  setAuthPersistence(rememberMe: boolean): Observable<void> {
    if (isPlatformBrowser(this.platformId)) {
      const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
      return from(this.auth.setPersistence(persistence));
    } else {
      return throwError(
        () => new Error('Cannot set auth persistence on the server side.')
      );
    }
  }

  // Send a password reset email
  sendPasswordResetEmail(email: string): Observable<void> {
    if (isPlatformBrowser(this.platformId)) {
      return from(sendPasswordResetEmail(this.auth, email));
    } else {
      return throwError(
        () => new Error('Cannot send password reset email on the server side.')
      );
    }
  }

  // Method to get the current Firebase user
  // •	This is suitable when you need an immediate snapshot of the current value.
  // •	It is often used in cases where you are not interested in tracking future changes, just want the value right now.
  // •	Since it returns the value synchronously, you don’t need to subscribe to it.
  getCurrentUser(): User | null | undefined {
    return this.firebaseUserSubject.getValue();
  }

  // Method to send a verification email to the current user
  sendVerificationEmail(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        return sendEmailVerification(currentUser); // Use sendEmailVerification function from Firebase v9
      } else {
        return Promise.reject('No user is currently logged in.');
      }
    } else {
      return Promise.reject('Cannot send email verification on the server side.');
    }
  }

  getUserDataFromUser(user: User | null) {
    if (user) {
      const displayName = user?.displayName ?? '';
      const [firstName, ...lastNameArray] = displayName.split(' ');
      const lastName = lastNameArray.join(' ');
      const email = user?.email != null ? user.email : "";
      const emailVerified = user?.emailVerified != null ? user.emailVerified : false;

      if (user.uid != null) {
        const userData = {
          uid: user.uid,
          email: email,
          firstName: firstName,
          lastName: lastName,
          emailVerified: emailVerified,
        };

        return userData;
      }
    }
    return null;
  }
}
