import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { ApiService } from './api.service';  // Assume ApiService is already created
import { ApiState } from './api-state.interface';    // Import ApiState interface
import { QuestionResponse } from '../models/question-response';
import {AnswerQuestionRequest} from "../models/answer-question-request";
import {AnswerQuestionResponse} from "../models/answer-question-response";
import {NextQuestionRequest} from "../models/next-question-request";
import {NextQuestionResponse} from "../models/next-question-response";
import {ActionQuestionRequest} from "../models/action-question-request";
import {ActionQuestionResponse} from "../models/action-question-response";
import {ExamSectionsSummaryResponse} from "../models/exam-sections-summary-response";
import {UserQuestionMetadataResponse} from "../models/user-question-metadata-response";
import {UserQuestionInteractionResponse} from "../models/user-question-interaction-response";
import {RequestState} from "./request-state.enum";
import {CheckoutService} from "./checkout.service";
import {Router} from "@angular/router";
import {ExamService} from "./exam.service";
import {HttpClient} from "@angular/common/http";
import {SampleQuestionData} from "./exam-data-interface";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http: HttpClient,
              private apiService: ApiService,
              private examService: ExamService,
              private router: Router) {}

  // Method to fetch a question by questionId
  getQuestion(questionId: string): Observable<ApiState<QuestionResponse>> {
    const endpoint = `question/${questionId}`;
    return this.apiService.getData<QuestionResponse>(endpoint);
  }

  // Method to submit an answer for the question
  submitAnswer(answerRequest: AnswerQuestionRequest): Observable<ApiState<AnswerQuestionResponse>> {
    const endpoint = `answer-question`;
    return this.apiService.postData<AnswerQuestionResponse>(endpoint, answerRequest);
  }

  // Method to get the next question
  getNextQuestion(nextQuestionRequest: NextQuestionRequest): Observable<ApiState<NextQuestionResponse>> {
    const endpoint = `next-question`;
    return this.apiService.postData<NextQuestionResponse>(endpoint, nextQuestionRequest);
  }

  getNextQuestionPublic(nextQuestionRequest: NextQuestionRequest): Observable<ApiState<NextQuestionResponse>> {
    const endpoint = `public-next-question`;
    return this.apiService.postData<NextQuestionResponse>(endpoint, nextQuestionRequest);
  }

  // Method to perform an action on a question
  actionQuestion(actionRequest: ActionQuestionRequest): Observable<ApiState<ActionQuestionResponse>> {
    const endpoint = `action-question`;
    return this.apiService.postData<ActionQuestionResponse>(endpoint, actionRequest);
  }

  // (U) GET: /exam-sections-summary-by-question-id/{question-id}
  // Use this for dropdown for sections on question page
  getExamSectionsSummary(questionId: string): Observable<ApiState<ExamSectionsSummaryResponse>> {
    const endpoint = `exam-sections-summary-by-question-id/${questionId}`;
    return this.apiService.getData<ExamSectionsSummaryResponse>(endpoint);
  }

  // (A) GET: /user-question-metadata/{question-id}
  // Refresh metadata after answering the question
  getUserQuestionMetadata(questionId: string): Observable<ApiState<UserQuestionMetadataResponse>> {
    const endpoint = `user-question-metadata/${questionId}`;
    return this.apiService.getData<UserQuestionMetadataResponse>(endpoint);
  }

  // (A) GET: /latest-user-question-interaction/{question-id}
  // Get latest interaction after answering the question
  getLatestUserQuestionInteraction(questionId: string): Observable<ApiState<UserQuestionInteractionResponse>> {
    const endpoint = `latest-user-question-interaction/${questionId}`;
    return this.apiService.getData<UserQuestionInteractionResponse>(endpoint);
  }

  // (A) GET: /latest-answer-question-response/{question-id}
  getLatestAnswerQuestionResponse(questionId: string): Observable<ApiState<AnswerQuestionResponse>> {
    const endpoint = `latest-answer-question-response/${questionId}`;
    return this.apiService.getData<AnswerQuestionResponse>(endpoint);
  }

  // (A) GET: /clear-question/{question-id}
  // For "try again" functionality, sets the "reset" field in UserQuestionMetadata
  clearQuestion(questionId: string): Observable<ApiState<boolean>> {
    const endpoint = `clear-question/${questionId}`;
    return this.apiService.postData<boolean>(endpoint, {});
  }

  // // (U) GET: /comments/{question-id}
  // // Get list of comments for this question
  // getQuestionComments(questionId: string): Observable<ApiState<QuestionCommentsResponse>> {
  //   const endpoint = `comments/${questionId}`;
  //   return this.apiService.getData<QuestionCommentsResponse>(endpoint);
  // }

  nextQuestionAndNavigateToPractice(examId: string){
    const nextQuestionRequest: NextQuestionRequest = {
      examId: examId,
      smart: true,
    };

    this.getNextQuestion(nextQuestionRequest).subscribe({
      next: (nextQuestionResponseState: ApiState<NextQuestionResponse>) => {
        if (
          nextQuestionResponseState.state === RequestState.SUCCESS &&
          nextQuestionResponseState.data?.questionId
        ) {
          this.router.navigate(['/question', nextQuestionResponseState.data.questionId]);
        } else if (nextQuestionResponseState.state === RequestState.ERROR) {
          this.examService.navigateToDashboard(examId);
        }
      },
      error: (err) => {
        this.router.navigate(['/error']);
      },
    });
  }

  getSampleQuestionsForExam(examId: string) {
    // return this.http.get<SampleQuestionData[]>(`/assets/data/sample_questions.json`);
    return this.http.get<{ [examId: string]: { [categoryName: string]: SampleQuestionData[] } }>(`/assets/data/sample_questions.json`);
  }

}
