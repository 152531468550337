import {ExamData} from "../exam-data-interface";

// SAT Exam Data
export const satExamData: ExamData = {
  examId: 'DIGITAL_SAT_2024',
  displayName: 'SAT',
  description:
    'The SAT is a standardized test for U.S. college admissions, evaluating mathematical, critical reading, writing skills, and readiness for higher education.',
  logo: 'assets/images/exams/sat_logo.png',
  link: 'sat',
  zoom: 1.1,
  priceId: 'DIGITAL_SAT_2024_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  marketingTitle: 'Boost your SAT Score with Adaptive Learning',
  marketingSubtitles: [
    'Our AI will analyze your performance and provide you with questions that target your weaknesses.',
    'Access a question bank of more than 50,000 questions.',
    'Increase your SAT score by 200+ points with personalized strategies',
    'Identify and conquer your weakest SAT categories with targeted practice'
  ],
  pricingTitle: 'Unlimited Practice with detailed solutions',
  pricingSubtitle: [
    'Boost your scores with high-quality questions and personalized insights at a price you can afford.',
  ],
  otherTitles: [],

  pricingDescription:
    'Subscribe to get full access to SAT practice materials, including detailed sections, categories, and subcategories.',
  sections: [
    {
      examSectionId: 'DIGITAL_SAT_2024_MATH_NOCALCULATOR',
      displayName: 'Digital SAT Mathematics - No Calculator Section',
      description:
        'This section tests your ability to solve mathematical problems without using a calculator. It covers key areas like Algebra, Ratios and Proportions, Geometry, and Number Operations. You’ll need to solve linear equations, work with ratios, calculate areas, and perform arithmetic. This section, combined with the Math Calculator section, contributes to your overall Math score, ranging from 200 to 800.',
      icon: 'remixFunctionLine', // ng-icons Remix Icon for math without calculator
      categories: [
        {
          categoryId: 'DIGITAL_SAT_2024_MATH_NOCALCULATOR_HEART_OF_ALGEBRA',
          displayName: 'Heart of Algebra',
          description:
            'Focuses on mastering linear equations, systems of linear equations, and linear functions. It emphasizes analyzing, solving, and creating these types of equations.',
          icon: 'remixHeartLine', // ng-icons Remix Icon representing 'Heart'
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_HEART_OF_ALGEBRA_LINEAR_EQUATIONS',
              displayName: 'Linear Equations and Inequalities',
              description:
                'Understanding and solving linear equations and inequalities in one or more variables.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_HEART_OF_ALGEBRA_SYSTEM_LINEAR_EQUATIONS',
              displayName: 'Systems of Linear Equations and Inequalities',
              description:
                'Solving systems of linear equations and inequalities, both algebraically and graphically.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_HEART_OF_ALGEBRA_LINEAR_FUNCTIONS',
              displayName: 'Linear Functions',
              description:
                'Interpreting and building linear functions from different representations like tables, graphs, and equations.',
            },
          ],
        },
        {
          categoryId: 'DIGITAL_SAT_2024_MATH_NOCALCULATOR_PROBLEM_SOLVING',
          displayName: 'Problem Solving and Data Analysis',
          description:
            'Emphasizes quantitative literacy, including understanding and analyzing data, ratios, percentages, and proportional reasoning.',
          icon: 'remixBarChartLine', // ng-icons Remix Icon representing data analysis
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_PROBLEM_SOLVING_RATIOS_RATES',
              displayName: 'Ratios, Rates, and Proportional Relationships',
              description:
                'Solving problems involving ratios, rates, and proportional relationships.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_PROBLEM_SOLVING_PERCENTAGES',
              displayName: 'Percentages',
              description:
                'Working with percentages, including increases, decreases, and finding the whole from a percentage.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_PROBLEM_SOLVING_DATA_INTERPRETATION',
              displayName: 'Data Interpretation and Analysis',
              description:
                'Analyzing and interpreting data from tables, graphs, and charts.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_PROBLEM_SOLVING_PROBABILITY_STATISTICS',
              displayName: 'Probability and Statistics',
              description:
                'Understanding basic concepts of probability and statistical measures.',
            },
          ],
        },
        {
          categoryId: 'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADVANCED_MATH',
          displayName: 'Passport to Advanced Math',
          description:
            'Tests understanding of complex equations and functions, focusing on analyzing, manipulating, and solving higher-order equations.',
          icon: 'remixPassportLine', // ng-icons Remix Icon representing 'Passport'
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADVANCED_MATH_QUADRATIC_EQUATIONS',
              displayName: 'Quadratic Equations',
              description:
                'Solving quadratic equations by factoring, completing the square, and quadratic formula.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADVANCED_MATH_HIGHER_ORDER_EQUATIONS',
              displayName: 'Higher-Order Equations',
              description:
                'Solving and interpreting polynomial equations of degree greater than two.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADVANCED_MATH_EXPONENTIAL_FUNCTIONS',
              displayName: 'Exponential Functions',
              description:
                'Understanding and solving problems involving exponential growth and decay.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADVANCED_MATH_MANIPULATING_POLYNOMIALS',
              displayName: 'Manipulating Polynomials',
              description:
                'Performing operations on polynomials, including addition, subtraction, multiplication, and division.',
            },
          ],
        },
        {
          categoryId: 'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADDITIONAL_TOPICS',
          displayName: 'Additional Topics in Math',
          description:
            'Covers other essential math topics, ensuring a well-rounded assessment of mathematical skills.',
          icon: 'remixAddCircleLine', // ng-icons Remix Icon representing 'Additional' topics
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADDITIONAL_TOPICS_GEOMETRY',
              displayName: 'Geometry',
              description:
                'Understanding and solving problems involving geometric shapes, properties, and theorems.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADDITIONAL_TOPICS_TRIGONOMETRY',
              displayName: 'Trigonometry',
              description:
                'Understanding relationships between sides and angles of triangles, solving problems with trigonometric functions.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADDITIONAL_TOPICS_COMPLEX_NUMBERS',
              displayName: 'Complex Numbers',
              description:
                'Understanding and performing operations with complex numbers.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_NOCALCULATOR_ADDITIONAL_TOPICS_FUNCTIONS',
              displayName: 'Functions',
              description:
                'Understanding and analyzing various types of functions, including properties and transformations.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'DIGITAL_SAT_2024_MATH_CALCULATOR',
      displayName: 'Digital SAT Mathematics - Calculator Section',
      description:
        'The Math With Calculator section assesses mathematical problem-solving skills using a calculator, covering more complex concepts and techniques. Key components include Advanced Algebra, Problem Solving and Data Analysis, Complex Numbers and Geometry, and Statistics. This section is scored on a scale of 200 to 800, combined with the Math No Calculator section for the overall Math score.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for calculator
      categories: [
        {
          categoryId: 'DIGITAL_SAT_2024_MATH_CALCULATOR_ADVANCED_MATH',
          displayName: 'Advanced Math',
          description:
            'Focuses on complex algebraic concepts, including quadratic equations, functions, and polynomials. Emphasizes manipulating and solving challenging expressions.',
          icon: 'remixGraduationCapLine', // ng-icons Remix Icon representing advanced education
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADVANCED_MATH_QUADRATIC_EQUATIONS',
              displayName: 'Quadratic Equations',
              description:
                'Understanding and solving quadratic equations algebraically and graphically.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADVANCED_MATH_POLYNOMIAL_EXPRESSIONS',
              displayName: 'Polynomial Expressions',
              description:
                'Understanding and manipulating polynomial expressions, including operations.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADVANCED_MATH_RATIONAL_EXPRESSIONS',
              displayName: 'Rational Expressions',
              description:
                'Solving problems involving rational expressions, including simplification and operations.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADVANCED_MATH_EXPONENTIAL_FUNCTIONS',
              displayName: 'Exponential Functions',
              description:
                'Understanding and analyzing exponential functions, including growth and decay.',
            },
          ],
        },
        {
          categoryId:
            'DIGITAL_SAT_2024_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS',
          displayName: 'Problem Solving and Data Analysis',
          description:
            'Applies math skills to real-world problems, focusing on data analysis, interpreting ratios and percentages, and proportional reasoning.',
          icon: 'remixLineChartLine', // ng-icons Remix Icon representing problem solving over time
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description:
                'Interpreting and analyzing data from tables, graphs, and charts to solve problems.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_RATIOS_PROPORTIONS',
              displayName: 'Ratios and Proportions',
              description:
                'Solving problems involving ratios, proportions, and percentages.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_PROBABILITY_STATISTICS',
              displayName: 'Probability and Statistics',
              description:
                'Understanding and applying basic concepts of probability and statistics, including measures of central tendency and dispersion.',
            },
          ],
        },
        {
          categoryId:
            'DIGITAL_SAT_2024_MATH_CALCULATOR_ADDITIONAL_MATH_TOPICS',
          displayName: 'Additional Math Topics',
          description:
            'Covers topics beyond basic algebra and problem-solving skills, including geometry, trigonometry, and complex numbers.',
          icon: 'remixAddBoxLine', // ng-icons Remix Icon representing 'Additional' topics
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADDITIONAL_MATH_TOPICS_GEOMETRY',
              displayName: 'Geometry',
              description:
                'Understanding and applying principles of geometry, including angles, shapes, area, and volume.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADDITIONAL_MATH_TOPICS_TRIGONOMETRY',
              displayName: 'Trigonometry',
              description:
                'Understanding and applying trigonometric concepts, including sine, cosine, tangent, and their applications.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_ADDITIONAL_MATH_TOPICS_COMPLEX_NUMBERS',
              displayName: 'Complex Numbers',
              description:
                'Understanding and performing operations with complex numbers, including addition, subtraction, multiplication, and division.',
            },
          ],
        },
        {
          categoryId: 'DIGITAL_SAT_2024_MATH_CALCULATOR_FUNCTIONS_MODELING',
          displayName: 'Functions and Modeling',
          description:
            'Involves understanding and interpreting functions, and using mathematical models to represent real-world situations.',
          icon: 'remixFunctionLine', // ng-icons Remix Icon representing functions and modeling
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_FUNCTIONS_MODELING_FUNCTION_NOTATION',
              displayName: 'Function Notation and Interpretation',
              description:
                'Understanding and using function notation, interpreting function values in real-world contexts.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_FUNCTIONS_MODELING_MODELS',
              displayName: 'Linear, Quadratic, and Exponential Models',
              description:
                'Creating, interpreting, and comparing models to represent real-world situations.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_MATH_CALCULATOR_FUNCTIONS_MODELING_GEOMETRY_MODELING',
              displayName: 'Modeling with Geometry',
              description:
                'Applying geometric concepts to model real-world situations, using area, volume, and surface area formulas.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'DIGITAL_SAT_2024_READING_AND_WRITING',
      displayName: 'Digital SAT Reading and Writing Section',
      description:
        'Assesses the ability to comprehend, analyze, and interpret texts across genres. Evaluates reading skills through diverse passages including literature, historical documents, social sciences, and natural sciences. Key components include understanding main ideas, themes, author’s purpose, and analyzing textual evidence.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon representing reading
      categories: [
        {
          categoryId:
            'DIGITAL_SAT_2024_READING_AND_WRITING_INFORMATION_AND_IDEAS',
          displayName: 'Information and Ideas',
          description:
            'Focuses on understanding, interpreting, and drawing inferences from informational texts.',
          icon: 'remixLightbulbLine', // ng-icons Remix Icon representing ideas
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_INFORMATION_AND_IDEAS_READING_CLOSELY',
              displayName: 'Reading Closely',
              description:
                'Understanding and interpreting the explicit meaning of the text.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_INFORMATION_AND_IDEAS_CENTRAL_IDEAS_THEMES',
              displayName: 'Determining Central Ideas and Themes',
              description:
                'Analyzing and understanding the central themes and ideas in a passage.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_INFORMATION_AND_IDEAS_SUMMARIZING',
              displayName: 'Summarizing',
              description:
                'Condensing information from texts to capture the essential points.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_INFORMATION_AND_IDEAS_UNDERSTANDING_RELATIONSHIPS',
              displayName: 'Understanding Relationships',
              description:
                'Recognizing and analyzing relationships between individuals, events, and ideas in texts.',
            },
          ],
        },
        {
          categoryId:
            'DIGITAL_SAT_2024_READING_AND_WRITING_CRAFT_AND_STRUCTURE',
          displayName: 'Craft and Structure',
          description:
            "Focuses on understanding the author's craft, including word choice, text structure, point of view, and purpose.",
          icon: 'remixToolsLine', // ng-icons Remix Icon representing 'Craft'
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_CRAFT_AND_STRUCTURE_ANALYZING_WORD_CHOICE',
              displayName: 'Analyzing Word Choice',
              description:
                'Understanding the effect of specific words and phrases used by the author.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_CRAFT_AND_STRUCTURE_ANALYZING_TEXT_STRUCTURE',
              displayName: 'Analyzing Text Structure',
              description:
                'Understanding how the structure of a text contributes to its meaning and effectiveness.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_CRAFT_AND_STRUCTURE_ANALYZING_POV_PURPOSE',
              displayName: 'Analyzing Point of View and Purpose',
              description:
                "Understanding the author's perspective, purpose, and how they influence the text.",
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_CRAFT_AND_STRUCTURE_ANALYZING_ARGUMENTS',
              displayName: 'Analyzing Arguments',
              description:
                'Evaluating the effectiveness of arguments in a text.',
            },
          ],
        },
        {
          categoryId:
            'DIGITAL_SAT_2024_READING_AND_WRITING_EXPRESSION_OF_IDEAS',
          displayName: 'Expression of Ideas',
          description:
            'Focuses on improving the effectiveness of language use in text, including development, organization, and effective language use.',
          icon: 'remixChat1Line', // ng-icons Remix Icon representing expression
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_EXPRESSION_OF_IDEAS_DEVELOPMENT',
              displayName: 'Development',
              description:
                'Improving the development of ideas in a text through appropriate detail, support, and focus.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_EXPRESSION_OF_IDEAS_ORGANIZATION',
              displayName: 'Organization',
              description:
                'Improving the structure and coherence of a text.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_EXPRESSION_OF_IDEAS_EFFECTIVE_LANGUAGE_USE',
              displayName: 'Effective Language Use',
              description:
                'Enhancing the clarity and impact of language through word choice, style, and tone.',
            },
          ],
        },
        {
          categoryId:
            'DIGITAL_SAT_2024_READING_AND_WRITING_STANDARD_ENGLISH_CONVENTIONS',
          displayName: 'Standard English Conventions',
          description:
            'Assesses knowledge of standard English grammar, usage, and punctuation.',
          icon: 'remixCheckLine', // ng-icons Remix Icon representing grammar check
          subcategories: [
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_STANDARD_ENGLISH_CONVENTIONS_CONVENTIONS_OF_USAGE',
              displayName: 'Conventions of Usage',
              description:
                'Applying correct usage of standard English conventions.',
            },
            {
              subcategoryId:
                'DIGITAL_SAT_2024_READING_AND_WRITING_STANDARD_ENGLISH_CONVENTIONS_CONVENTIONS_OF_PUNCTUATION',
              displayName: 'Conventions of Punctuation',
              description:
                'Applying correct punctuation rules to ensure clarity and readability.',
            },
          ],
        },
      ],
    },
  ],
};




