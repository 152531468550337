import {Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {NgClass, NgIf, NgOptimizedImage} from '@angular/common';

import {AuthenticationError} from "../../shared/util/error-types";
import {AuthService} from "../../services/auth.service";
import {NavigationService} from "../../services/navigation-service";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, FormsModule, ReactiveFormsModule, NgIf, NgOptimizedImage, NgClass],
  templateUrl: './login.component.html',
})
export class LoginComponent  implements OnInit{
  constructor(private router: Router, private route: ActivatedRoute, private metaService: MetaService,
              private authService: AuthService, private navigationService: NavigationService) {}

  isSubmitting = false;
  message: string | null = null;
  errorMessage: string = '';
  successMessage: string = '';
  returnUrl: string = '';

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.minLength(6)]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(false),
  });

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);

    // Uncomment when release:
    // Check if the user is already logged in
    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        // Redirect to exams page if user is already logged in
        this.router.navigate(['/exams']);
      }
    });

    // Retrieve the 'message' query parameter if it exists
    this.route.queryParams.subscribe(params => {
      if (params['message']) {
        this.message = params['message'];
      }
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  // Email/Password login method
  onLoginClick(): void {
    console.log("onLoginClick");
    if (this.form.invalid) {
      this.form.markAllAsTouched(); // Marks all controls as touched to trigger error messages
      return;
    }

    const email = this.form.get('email')?.value as string;
    const password = this.form.get('password')?.value as string;
    const rememberMe = this.form.get('rememberMe')?.value as boolean;

    // Handle case where email or password are empty (should not happen with form validation)
    if (!email || !password) {
      this.errorMessage = 'Both email and password are required.';
      this.form.get('email')?.markAsTouched();
      this.form.get('password')?.markAsTouched();
      return;
    }

    this.isSubmitting = true;

    // Set Firebase Auth persistence (SESSION/LOCAL) based on 'remember me' checkbox
    this.authService.setAuthPersistence(rememberMe);

    this.authService.firebaseLoginWithEmail(email, password).subscribe({
      next: (response) => {
        // Successful login
        this.errorMessage = '';
        const redirectUrl = this.returnUrl || this.navigationService.getPreviousUrl() || '/exams';
        this.router.navigateByUrl(redirectUrl);
      },
      error: (error) => {
        console.error('Login error:', error);
        this.isSubmitting = false; // Stop submitting state
        this.setErrorMessageFromError(error); // Set appropriate error message
      },
      complete: () => {
        this.isSubmitting = false; // Reset the flag after completion
      }
    });
  }

  // Error message handling
  setErrorMessageFromError(error: any): void {
    if (error instanceof AuthenticationError) {
      this.errorMessage = error.message; // Directly use the message from the error
    } else {
      this.errorMessage = 'An error occurred while logging in. Please try again later.';
    }
  }

  // Google login method
  onGoogleLoginClick(): void {
    // if (this.isSubmitting) {
    //   return; // Prevent multiple submissions
    // }
    this.isSubmitting = true;
    this.authService.logout()
    this.authService.firebaseSignInWithGoogle().subscribe({
      next: (response) => {
        // Successful login
        this.errorMessage = '';
        const redirectUrl = this.navigationService.getPreviousUrl() || '/exams';
        this.router.navigateByUrl(redirectUrl);
      },
      error: (error) => {
        console.error('Google sign-in error:', error);
        this.setErrorMessageFromError(error); // Handle and set error message
      },
      complete: () => {
        this.isSubmitting = false; // Reset the flag after completion
      }
    });
  }

  onForgotPassword(event: Event): void {
    event.preventDefault();  // Prevent form submission when this link is clicked

    const email = this.form.get('email')?.value as string;

    if (!email) {
      this.errorMessage = 'Please enter your email address to reset your password.';
      return;
    }

    this.authService.sendPasswordResetEmail(email).subscribe({
      next: () => {
        this.successMessage = 'Password reset email has been sent to your email address.';
        this.errorMessage = '';
      },
      error: (err) => {
        this.errorMessage = 'Failed to send password reset email. Please check your email or try again later.';
        this.successMessage = '';
      },
    });
  }

}
