import {AfterViewChecked, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnswerQuestionResponse} from "../../../../models/answer-question-response";
import {NgIf} from "@angular/common";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MathjaxService} from "../../../../services/mathjax.service";

@Component({
  selector: 'choice-explanation-solution',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './choice-explanation-solution.component.html',
  styleUrl: './choice-explanation-solution.component.css'
})
export class ChoiceExplanationSolutionComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() answerQuestionResponse: AnswerQuestionResponse | null = null;
  sanitizedExplanationParts: SafeHtml[] = [];  // Array of sanitized pieces

  constructor(private sanitizer: DomSanitizer, private mathjaxService: MathjaxService) {}

  // Called once when the component is initialized
  ngOnInit(): void {
    if (this.answerQuestionResponse?.questionSolution?.explanation) {
      this.splitAndSanitizeExplanation(this.answerQuestionResponse?.questionSolution?.explanation);
    }
    this.mathjaxService.renderMath();
  }

  // Called whenever the input property 'explanation' changes
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['answerQuestionResponse'] && changes['answerQuestionResponse'].currentValue ) {
      this.splitAndSanitizeExplanation(changes['answerQuestionResponse'].currentValue?.questionSolution?.explanation);
    }
  }

  ngAfterViewChecked(): void {
    // Re-render MathJax after view has been checked
    this.mathjaxService.renderMath();
  }

  get hasCorrect() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.correct != null;
  }

  get correct() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.correct;
  }

  get hasCorrectChoice() {
    return this.answerQuestionResponse?.correctChoiceResponse?.correct_choice != null;
  }

  get correctChoice() {
    return this.answerQuestionResponse?.correctChoiceResponse?.correct_choice.join(', ') ?? '';
  }

  get hasChoice() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.choiceResponse?.choice != null;
  }

  get choice() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.choiceResponse?.choice.join(', ') ?? '';
  }

  get hasExplanation(): boolean {
    return !!this.answerQuestionResponse?.questionSolution?.explanation
      && this.answerQuestionResponse?.questionSolution?.explanation.length > 0;
  }

  get sanitizedExplanation() : SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.answerQuestionResponse?.questionSolution?.explanation || '');
  }

  // Split the explanation string into an array of parts and sanitize each part
  splitAndSanitizeExplanation(explanation: string): void {
    // Split the string by sentences (you can change the delimiter as needed)
    const parts = explanation.split('.');  // Splits by periods (sentences)

    // Sanitize each part and store it in the array
    this.sanitizedExplanationParts = parts.map(part =>
      this.sanitizer.bypassSecurityTrustHtml(part.trim() + '.')
    );
  }
}
