<div>
  <section>
    <nav class="relative bg-white shadow">
      <div class="p-6 flex items-center">
        <!-- Logo -->
        <a class="flex-shrink-0 mr-12 text-2xl font-semibold" href="#">
          <img class="h-10" src="assets/logos/studious-logo-bold-10.svg" alt="Logo" width="auto">
        </a>

        <!-- Desktop Navigation Links -->
        <ul class="hidden lg:flex space-x-8">
          <li *ngIf="!isDashboardRoute()">
            <a
              class="flex items-center text-gray-500 hover:text-gray-800 text-sm lg:text-base cursor-pointer transition duration-300 hover:bg-gray-100 px-3 py-2 rounded-md"
              (click)="goToDashboard()"
            >
              <svg class="w-5 h-5 mr-2" fill="currentColor">
                <use href="assets/icons/dashboard-icon.svg#icon"></use>
              </svg>
              <span>Dashboard</span>
            </a>
          </li>
          <li *ngIf="!isQuestionRoute()">
            <a
              class="flex items-center text-gray-500 hover:text-gray-800 text-sm lg:text-base cursor-pointer transition duration-300 hover:bg-gray-100 px-3 py-2 rounded-md"
              (click)="getNextQuestionAndNavigate($event)"
            >
              <svg class="w-5 h-5 mr-2" fill="currentColor">
                <use href="assets/icons/practice-icon.svg#icon"></use>
              </svg>
              <span>Practice</span>
            </a>
          </li>
          <li *ngIf="isDashboardRoute()">
            <a
              href="/exams"
              class="flex items-center text-gray-500 hover:text-gray-800 text-sm lg:text-base cursor-pointer transition duration-300 hover:bg-gray-100 px-3 py-2 rounded-md"
            >
              <svg class="w-5 h-5 mr-2" fill="currentColor">
                <use href="assets/icons/exams-icon.svg#icon"></use>
              </svg>
              <span>Exams</span>
            </a>
          </li>
        </ul>

        <!-- Upgrade to Premium / Premium Badge & Profile Menu -->
        <div class="hidden lg:flex items-center space-x-6 ml-auto">
          <!-- Upgrade to Premium Button or Premium Badge -->
          <div *ngIf="!isPremium; else premiumBadge">
            <a
              (click)="onPremiumPlanClick()"
              class="inline-flex items-center px-4 py-2 bg-green-500 text-white text-sm font-semibold rounded-lg shadow hover:bg-green-600 transition duration-200 cursor-pointer"
            >
              Upgrade to Premium
            </a>
          </div>

          <ng-template #premiumBadge>
    <span
      class="inline-flex items-center px-3 py-1 text-sm font-semibold bg-yellow-100 text-yellow-700 rounded-lg border border-yellow-200"
    >
      Premium
    </span>
          </ng-template>

          <!-- Profile Dropdown -->
          <div *ngIf="isLoggedIn" class="relative">
            <button
              (click)="toggleProfileMenu()"
              class="flex items-center focus:outline-none cursor-pointer"
            >
              <!-- Account Circle Remix Icon -->
              <ng-icon
                name="remixAccountCircleFill"
                class="text-gray-500 flex-shrink-0"
                [style.width.px]="40"
                [style.height.px]="40"
              >
              </ng-icon>

              <!-- Dropdown Arrow -->
              <svg
                class="w-5 h-5 ml-2 text-gray-500 flex-shrink-0 self-center"
                fill="currentColor"
              >
                <use href="assets/icons/dropdown-arrow.svg#icon"></use>
              </svg>
            </button>

            <!-- Dropdown Menu -->
            <div
              *ngIf="profileMenuOpen"
              class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-20"
            >
              <a
                (click)="onSettingsClick()"
                class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
              >
                <svg
                  class="w-5 h-5 mr-3 text-gray-500"
                  fill="currentColor"
                >
                  <use href="assets/icons/settings-icon.svg#icon"></use>
                </svg>
                Settings
              </a>
              <a
                (click)="onLogoutClick()"
                class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
              >
                <svg
                  class="w-5 h-5 mr-3 text-gray-500"
                  fill="currentColor"
                >
                  <use href="assets/icons/logout-icon.svg#icon"></use>
                </svg>
                Log Out
              </a>
            </div>
          </div>
        </div>

        <!-- Mobile Menu Button -->
        <div class="ml-auto lg:hidden">
          <button (click)="toggleMobileNav()" class="flex items-center focus:outline-none">
            <svg class="text-gray-500 h-10 w-10 p-2 rounded hover:bg-gray-100 transition duration-200" fill="currentColor" viewBox="0 0 20 20">
              <title>Mobile menu</title>
              <path d="M3 6h14M3 10h14M3 14h14" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
            </svg>
          </button>
        </div>
      </div>
    </nav>

    <!-- Mobile Navigation Menu -->
    <div
      [ngClass]="{'block': mobileNavOpen, 'hidden': !mobileNavOpen}"
      class="hidden fixed inset-0 z-30"
    >
      <div (click)="toggleMobileNav()" class="fixed inset-0 bg-black opacity-50"></div>
      <nav
        class="relative bg-white w-64 h-full shadow-lg z-40"
      >
        <div class="flex items-center px-6 py-4 border-b">
          <a href="#" class="flex-shrink-0">
            <img class="h-8" src="assets/logos/studious-logo-bold-10.svg" alt="Logo" width="auto">
          </a>
        </div>
        <div class="px-4 py-6">
          <ul class="space-y-2">
            <li>
              <a
                class="flex items-center px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md cursor-pointer transition duration-200"
                (click)="goToDashboard(); toggleMobileNav()"
              >
                <svg class="w-5 h-5 mr-3" fill="currentColor">
                  <use href="assets/icons/dashboard-icon.svg#icon"></use>
                </svg>
                Dashboard
              </a>
            </li>
            <li>
              <a
                class="flex items-center px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md cursor-pointer transition duration-200"
                (click)="getNextQuestionAndNavigate($event); toggleMobileNav()"
              >
                <svg class="w-5 h-5 mr-3" fill="currentColor">
                  <use href="assets/icons/practice-icon.svg#icon"></use>
                </svg>
                Practice
              </a>
            </li>
            <li>
              <a
                href="/exams"
                class="flex items-center px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md cursor-pointer transition duration-200"
                (click)="toggleMobileNav()"
              >
                <svg class="w-5 h-5 mr-3" fill="currentColor">
                  <use href="assets/icons/exams-icon.svg#icon"></use>
                </svg>
                Exams
              </a>
            </li>
          </ul>

          <!-- Divider -->
          <div class="my-4 border-t"></div>

          <!-- Settings and Logout -->
          <ul class="space-y-2">
            <li>
              <a
                class="flex items-center px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md cursor-pointer transition duration-200"
                (click)="onSettingsClick(); toggleMobileNav()"
              >
                <svg class="w-5 h-5 mr-3" fill="currentColor">
                  <use href="assets/icons/settings-icon.svg#icon"></use>
                </svg>
                Settings
              </a>
            </li>
            <li>
              <a
                class="flex items-center px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md cursor-pointer transition duration-200"
                (click)="onLogoutClick(); toggleMobileNav()"
              >
                <svg class="w-5 h-5 mr-3" fill="currentColor">
                  <use href="assets/icons/logout-icon.svg#icon"></use>
                </svg>
                Log Out
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</div>
