import {Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {QuestionState} from "../question/question-state.enum";
import {DomSanitizer} from "@angular/platform-browser";
import {QuestionService} from "../../services/question-service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ExamService} from "../../services/exam.service";
import {DecimalPipe, isPlatformBrowser, NgClass, NgForOf, NgIf, SlicePipe, TitleCasePipe} from "@angular/common";
import {AuthService} from "../../services/auth.service";
import {ApiState} from "../../services/api-state.interface";
import {NextQuestionResponse} from "../../models/next-question-response";
import {RequestState} from "../../services/request-state.enum";
import {NextQuestionRequest} from "../../models/next-question-request";
import {UserService} from "../../services/user.service";
import {CheckoutService} from "../../services/checkout.service";
import {Subscription} from "rxjs";
import {PlanService} from "../../services/plan.service";
import { NgModule } from '@angular/core';
import { IconsModule } from '../../shared/icons/icons.module';
import {formatToDollars} from "../../shared/util/string-utils";
import {CategoryData, ExamData} from "../../services/exam-data-interface";
import {MetaService} from "../../services/meta.service";
import { REQUEST, RESPONSE } from '../../shared/injection-tokens';

@Component({
  selector: 'app-exam',
  standalone: true,
  imports: [
    DecimalPipe,
    NgClass,
    NgIf,
    NgForOf,
    IconsModule,
    RouterLink,
    SlicePipe,
    TitleCasePipe,
    // Import IconsModule here to use the icons
  ],
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css', '../../../additional-styles.scss']
})
export class ExamComponent implements OnInit, OnDestroy {
  examId: string | null = null;

  examData: ExamData | null = null;

  isPremium: boolean = false;
  isLoggedIn: boolean = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: any,
    private metaService: MetaService,
    private planService: PlanService,
    private authService: AuthService,
    private examService: ExamService,
    private userService: UserService,
    private questionService: QuestionService,
    private checkoutService: CheckoutService,
    private router: Router,  // Inject Router for navigation
    private route: ActivatedRoute  // Inject ActivatedRoute to access the route parameters
  ) {
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    // Extract the questionId from the route and load the question
    this.route.paramMap.subscribe({
      next: params => {
        const examLinkName = params.get('exam-link-name');

        if (examLinkName == null) {
          this.router.navigate(['/error']);
          return;
        }

        this.examData = this.examService.getExamDataFromExamLinkName(examLinkName);

        if (this.examData == null) {
          this.router.navigate(['/error']);
          return;
        }

        const displayName = this.examData.displayName;
        const canonicalUrl = this.metaService.generateCanonicalUrl();
        const baseMetaData = this.route.snapshot.data;
        // since this is dynamically set, this is set here instead of in the routes page.
        const metaData = {
          ...baseMetaData,
          title: `${displayName} Exam Prep | ${displayName} Study Guide | Studious`,
          description: `Get the best practice questions and exam prep for the ${displayName} exam. Use AI adaptive learning to improve your ${displayName} score.`,
          keywords: `${displayName} prep, ${displayName} practice tests, ${displayName} questions, ${displayName} free`,
          canonical: canonicalUrl
          // image: '/assets/logos/studious_open_graph_bg.png', // Update if you have dynamic images
        };

        this.metaService.updateMetaTags(metaData);

        this.examId = this.examData.examId;

        this.examService.updateCurrentExamId(this.examId);

        if (this.examId) {
          const sub2 = this.userService.isUserSubscribedToExam(this.examId)
            .subscribe(isSubscribed => {
              this.isPremium = isSubscribed;
            });
          this.subscriptions.add(sub2)
        }

        if (!this.examData) {
          // Handle the case where the exam is not found
          console.error('Exam not found, this.examId=' + this.examId);
          // Optionally, redirect to a 404 page or show a message
          this.router.navigate(['/error']);
        } else {
          this.examData.sections.forEach(section => {
            section.categories.forEach(category => {
              category.open = false;
            });
          });

          // Load questions per category from the generated JSON file
          this.loadQuestionsForCategories();
        }
      },
      error : err => {
        // set fallback metadata
        const metaData = this.route.snapshot.data;
        this.metaService.updateMetaTags(metaData);
      }
    });
  }

  loadQuestionsForCategories(): void {
    // Fetch questions from the JSON file using the QuestionService
    this.questionService.getSampleQuestionsForExam(this.examId!).subscribe((sampleQuestionsData) => {
      // Assuming questionsData is structured as { [examId]: { [categoryName]: [ {id, title}, ... ] } }
      const examQuestions = sampleQuestionsData[this.examId!];

      if (examQuestions) {
        this.examData!.sections.forEach(section => {
          section.categories.forEach(category => {
            const categoryQuestions = examQuestions[category.categoryId];
            if (categoryQuestions) {
              category.sampleQuestions = categoryQuestions;
            } else {
              category.sampleQuestions = [];
            }
          });
        });
      }
    });
  }

  // Method to get the appropriate icon classes
  getIconClasses(iconClass: string): string[] {
    // Split the iconClass string into parts
    const classes = iconClass.split(' ');
    return classes;
  }

  toggleCategory(category: CategoryData): void {
    category.open = !category.open;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPriceDisplay() {
    const priceUnitAmount = this.examData?.priceUnitAmount;
    const currency = this.examData?.currency;
    if (!priceUnitAmount || !currency) {
      return "";
    }

    if (currency == 'USD') {
      return formatToDollars(priceUnitAmount / 100.);
    }
    return "";
  }

  onFreePlanClick() {
    this.planService.onFreePlanClick(this.examId!, this.isLoggedIn);
  }

  onPremiumPlanClick() {
    this.planService.onPremiumPlanClick(
      this.examId!,
      this.isLoggedIn,
      this.isPremium,
      this.examData?.priceId
    );
  }

  onPracticeClick() {
    if (this.examId) {
      // this.questionService.nextQuestionAndNavigateToPractice(this.examId);
      this.planService.onFreePlanClick(this.examId, this.isLoggedIn);
    } else {
      this.router.navigate(['/exams']);
    }
  }

  getDifficultyCss(numericDifficultyLevel: number, maxDifficultyLevel: number, minDifficultyLevel: number): string {
    if (
      numericDifficultyLevel != null &&
      maxDifficultyLevel != null &&
      minDifficultyLevel != null
    ) {
      const factor =
        (numericDifficultyLevel - minDifficultyLevel) /
        (maxDifficultyLevel - minDifficultyLevel);
      if (factor < 1 / 3) {
        return 'easy';
      } else if (factor < 2 / 3) {
        return 'medium';
      }
      return 'hard';
    }
    return 'unknown';
  }
}
