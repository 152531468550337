import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {ApiState} from "./api-state.interface";
import {QuestionResponse} from "../models/question-response";
import {UserAnalyticsResponse} from "../models/user-analytics-response";
import {QuestionHistoryResponse} from "../models/question-history-response";
import {ActionedQuestionsResponse} from "../models/actioned-questions-response";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private apiService: ApiService) {}

  getUserAnalytics(examId: string): Observable<ApiState<UserAnalyticsResponse>> {
    const endpoint = `user-analytics/exam/${examId}`;
    return this.apiService.getData<UserAnalyticsResponse>(endpoint);
  }

  getQuestionHistory(examId: string, pageNumber: number, pageSize: number): Observable<ApiState<QuestionHistoryResponse>> {
    const endpoint = `question-history/exam/${examId}?page=${pageNumber}&size=${pageSize}`;
    return this.apiService.getData<QuestionHistoryResponse>(endpoint);
  }

  getActionedQuestions(examId: string, action: string, pageNumber: number, pageSize: number): Observable<ApiState<ActionedQuestionsResponse>> {
    const endpoint = `get-actioned-questions/exams/${examId}/${action}?page=${pageNumber}&size=${pageSize}`;
    return this.apiService.getData<ActionedQuestionsResponse>(endpoint);
  }

}
