<!-- upgrade-modal.component.html -->
<div
  class="fixed inset-0 z-50 overflow-auto bg-smoke-light flex items-center justify-center"
  (click)="closeModal()"
>
  <div
    class="relative bg-white w-full max-w-md m-auto rounded-lg shadow-lg overflow-hidden"
    (click)="$event.stopPropagation()"
  >
    <!-- Background Image -->
    <img
      class="absolute inset-0 w-full h-full object-cover"
      src="assets/images/home/gradient2.svg"
      alt=""
    />


    <!-- Content Wrapper -->
    <div class="relative z-10 pt-6 pb-6 px-6">
      <!-- Close Button -->
      <button
        (click)="closeModal()"
        class="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl font-bold focus:outline-none"
        aria-label="Close modal"
      >
        &times;
      </button>


      <!-- Modal Content -->
      <div class="mt-4">
        <!-- Message indicating the free quota was used up -->
        <p class="text-center text-gray-700 text-lg font-medium mb-6">
          You have reached the limits of your free access plan. To continue, subscribe to:
        </p>

        <!-- Pro Plan Box -->
        <div class="bg-white bg-opacity-90 border border-gray-400 rounded-xl p-6">
          <div class="text-center">
            <span class="inline-block font-medium text-custom-blue-500 mb-2">Pro</span>
            <h2 class="font-semibold text-2xl mb-4">
              {{ examData?.displayName }} Exam Subscription
            </h2>
            <h3 class="text-3xl font-bold mb-3">
              {{ getPriceDisplay() }}<span class="text-lg font-medium"> / month</span>
            </h3>
            <p class="text-gray-600 font-medium mb-6">
              Unlock all features and maximize your score. Cancel anytime.
            </p>
            <button
              (click)="onPremiumPlanClick()"
              class="mb-6 py-3 px-6 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:outline-none focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200"
              type="button"
            >
              Subscribe
            </button>
            <h3 class="font-semibold text-lg mb-4">Features:</h3>
            <!-- Features List -->
            <ul class="text-left">
              <li
                *ngFor="let feature of premiumFeatures"
                class="mb-2 text-gray-700 flex items-center"
              >
                <svg
                  class="w-5 h-5 text-custom-blue-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="font-medium">{{ feature }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
