import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QuestionState } from '../../question-state.enum';
import { UserQuestionInteractionResponse } from '../../../../models/user-question-interaction-response';

@Component({
  selector: 'grid-in',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './grid-in.component.html',
  styleUrls: ['./grid-in.component.scss'],
})
export class GridInComponent implements OnChanges {
  @Input() questionId: string | null = null;
  @Input() questionState: QuestionState | null = null;
  @Input() userQuestionInteractionResponse: UserQuestionInteractionResponse | null = null; // To show inline styles if correct
  @Output() onValidate = new EventEmitter<boolean>();

  gridInAnswer: string = ''; // User's input
  showError: boolean = false;
  errorMessage: string = '';

  // Regular expressions for validation
  private allowedCharactersRegex = /^[0-9\-./]*$/; // Only allow digits, -, ., and /
  private numberRegex = /^-?\d+(\.\d+)?$/; // Matches integers and decimals, including negative numbers
  private fractionRegex = /^-?\d+\/\d+$/;  // Matches fractions like -5/3

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['userQuestionInteractionResponse']) {
      if (
        this.isReviewState() &&
        (this.userQuestionInteractionResponse?.choiceResponse?.choice.length ?? 0)> 0 &&
        this.userQuestionInteractionResponse?.choiceResponse.choice[0]
      ) {
        this.gridInAnswer = this.getGridInValueFromUserQuestionInteractionResponse();
      }
    }

  }

  // Reset the component's state
  resetVariables(): void {
    this.gridInAnswer = '';
    this.showError = false;
    this.errorMessage = '';
  }

  // Retrieve the current grid-in answer from the response
  getGridInValueFromUserQuestionInteractionResponse(): string {
    return this.userQuestionInteractionResponse?.choiceResponse.choice?.[0] ?? '';
  }

  // Prevent invalid characters from being typed
  onKeyPress(event: KeyboardEvent): void {
    const char = event.key;
    // Allow control keys like Backspace, Tab, etc.
    if (event.ctrlKey || event.metaKey || event.altKey) {
      return;
    }
    // Only allow valid characters, prevent others
    if (!this.allowedCharactersRegex.test(char)) {
      event.preventDefault(); // Stop the invalid character from being entered
      this.showError = true;
      this.errorMessage = 'Only digits, decimal points, negative signs, and "/" are allowed.';
    } else {
      // Clear error if any
      this.showError = false;
      this.errorMessage = '';
    }
  }

  // Handle the input change event
  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.gridInAnswer = inputElement.value.trim();

    // Validate after each change
    if (this.isValidNumberOrFraction(this.gridInAnswer) || this.gridInAnswer === '') {
      this.showError = false;
      this.errorMessage = '';
    } else {
      this.showError = true;
      this.errorMessage = 'Please enter a valid number or fraction (e.g., "-5/3").';
    }
  }

  // Validate the user's input before submission
  validate(): void {
    const trimmedAnswer = this.gridInAnswer.trim();

    if (trimmedAnswer) {
      if (this.isValidNumberOrFraction(trimmedAnswer)) {
        this.onValidate.emit(true);
        this.showError = false;
      } else {
        this.onValidate.emit(false);
        this.showError = true;
        this.errorMessage = 'Please enter a valid number or fraction (e.g., "-5/3").';
      }
    } else {
      this.onValidate.emit(false);
      this.showError = true;
      this.errorMessage = 'Please enter an answer.';
    }
  }

  // Check if the input is a valid number or fraction and respects the character limit
  isValidNumberOrFraction(value: string): boolean {
    if (this.numberRegex.test(value)) {
      // Exclude the decimal point and negative sign when counting the digits
      const digitsOnly = value.replace('.', '').replace('-', '');
      return digitsOnly.length <= 6;
    }

    if (this.fractionRegex.test(value)) {
      // Exclude the negative sign but include the "/" when counting
      const fractionParts = value.split('/');
      if (fractionParts.length !== 2) return false;
      const numerator = fractionParts[0].replace('-', '');
      const denominator = fractionParts[1];
      return numerator.length + denominator.length <= 6;
    }

    return false;
  }

  // Determine if the input should be disabled based on the question state
  isReviewOrTransitState(): boolean {
    const disabledStates = [
      QuestionState.SUBMIT,
      QuestionState.TRY_AGAIN,
      QuestionState.SKIP,
      QuestionState.NEXT,
      QuestionState.REVIEW_INCORRECT,
      QuestionState.REVIEW,
    ];
    return disabledStates.includes(this.questionState as QuestionState);
  }

  // Check if the current state is a review state
  isReviewState(): boolean {
    return (
      this.questionState === QuestionState.REVIEW ||
      this.questionState === QuestionState.REVIEW_INCORRECT
    );
  }

  // Get CSS classes for the input box based on state
  getInputBoxCss(): string {
    if (this.isReviewState() && this.userQuestionInteractionResponse) {
      return this.userQuestionInteractionResponse?.correct
        ? 'bg-green-50 border-green-600'
        : 'bg-red-50 border-red-600';
    }

    return 'border-gray-300';
  }

  // Get CSS classes for the preview text based on state
  getPreviewCss(): string {
    if (this.isReviewState()) {
      if (this.isCorrect()) {
        return 'text-green-700';
      } else {
        return 'text-red-700';
      }
    }

    return 'text-gray-700';
  }

  // Determine if the answer is correct (for review state)
  isCorrect(): boolean {
    return this.userQuestionInteractionResponse?.correct ?? false;
  }
}
