import {AfterViewChecked, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnswerQuestionResponse} from "../../../../models/answer-question-response";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MathjaxService} from "../../../../services/mathjax.service";
import {NgIf} from "@angular/common";
import {IconsModule} from "../../../../shared/icons/icons.module";

@Component({
  selector: 'essay-solution',
  standalone: true,
  imports: [
    NgIf,
    IconsModule
  ],
  templateUrl: './essay-solution.component.html',
  styleUrl: './essay-solution.component.css'
})
export class EssaySolutionComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() answerQuestionResponse: AnswerQuestionResponse | null = null;

  sanitizedExplanation: SafeHtml = '';  // Sanitized sample essay
  sanitizedRubric: SafeHtml = '';       // Sanitized rubric

  constructor(private sanitizer: DomSanitizer, private mathjaxService: MathjaxService) {}

  ngOnInit(): void {
    if (this.answerQuestionResponse?.questionSolution) {
      this.sanitizeContent();
    }
    this.mathjaxService.renderMath();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['answerQuestionResponse'] && changes['answerQuestionResponse'].currentValue) {
      this.sanitizeContent();
    }
  }

  ngAfterViewChecked(): void {
    // Re-render MathJax after view has been checked
    this.mathjaxService.renderMath();
  }

  get hasExplanation(): boolean {
    return !!this.answerQuestionResponse?.questionSolution?.explanation
      && this.answerQuestionResponse?.questionSolution?.explanation.length > 0;
  }

  get hasRubric(): boolean {
    return !!this.answerQuestionResponse?.questionSolution?.rubric
      && this.answerQuestionResponse?.questionSolution?.rubric.length > 0;
  }

  private sanitizeContent(): void {
    if (this.answerQuestionResponse?.questionSolution?.explanation) {
      this.sanitizedExplanation = this.sanitizer.bypassSecurityTrustHtml(
        this.answerQuestionResponse.questionSolution.explanation
      );
    }

    if (this.answerQuestionResponse?.questionSolution?.rubric) {
      this.sanitizedRubric = this.sanitizer.bypassSecurityTrustHtml(
        this.answerQuestionResponse.questionSolution.rubric
      );
    }
  }
}
