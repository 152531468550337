import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';  // Import routing module
import { AppComponent } from './app.component';  // Root component
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { AuthInterceptor } from './shared/firebase-auth/auth.interceptor';  // Import the AuthInterceptor

// Auth Guard (if needed for protected routes)
import { AuthGuard } from './guards/auth.guard';
import { NgApexchartsModule } from 'ng-apexcharts';
import {environment} from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent,  // Root component
  ],
  imports: [
    BrowserModule,  // Required for any Angular app to run in a browser
    HttpClientModule,  // Ensure HttpClientModule is imported to use HTTP requests
    AppRoutingModule,
    NgApexchartsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    AuthGuard , // AuthGuard for protected routes
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,  // Register the interceptor
      multi: true  // Allows multiple interceptors if needed
    },
    provideHttpClient(
      withFetch()
    ),
    provideClientHydration()
  ],
  bootstrap: [AppComponent]  // Bootstrap the AppComponent
})
export class AppModule { }
