import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgForOf} from "@angular/common";
import {formatToDollars} from "../../util/string-utils";
import {PlanService} from "../../../services/plan.service";
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {ExamData} from "../../../services/exam-data-interface";

@Component({
  selector: 'app-upgrade-modal',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './upgrade-modal.component.html',
  styleUrl: './upgrade-modal.component.css'
})
export class UpgradeModalComponent implements OnInit {
  @Input() examData: ExamData | null = null;
  @Output() close = new EventEmitter<void>();

  premiumFeatures = [
    'Unlimited practice questions',
    'Access to premium questions tier',
    'Dashboard with detailed analytics',
    'Personalized score improvement tips',
    'Identify strengths & weaknesses',
    'Adaptive question selection algorithm',
    'AI-powered question explanations',
  ];

  isPremium: boolean = false;
  isLoggedIn: boolean = false;

  constructor(private router: Router, private planService: PlanService,
              private authService: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    if (this.examData) {
      const sub2 = this.userService.isUserSubscribedToExam(this.examData.examId)
        .subscribe(isSubscribed => {
          this.isPremium = isSubscribed;
        });
    }
  }

  getPriceDisplay(): string {
    // Replace with actual price retrieval logic
    if (this.examData) {
      const priceUnitAmount = this.examData?.priceUnitAmount;
      const currency = this.examData?.currency;
      if (!priceUnitAmount || !currency) {
        return "";
      }

      if (currency == 'USD') {
        return formatToDollars(priceUnitAmount / 100.);
      }
    }
    return '$9.99';
  }

  onPremiumPlanClick(): void {
    // Implement navigation logic when 'Get Started' button is clicked
    // For example, navigate to a checkout page with examData

    this.planService.onPremiumPlanClick(
      this.examData?.examId ?? null,
      this.isLoggedIn,
      this.isPremium,
      this.examData?.priceId
    );
    this.closeModal();
  }

  closeModal(): void {
    this.close.emit();
  }
}
