import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { UserResponse } from '../../models/user-response';
import { SubscriptionResponse } from '../../models/subscription-response';
import { UserService } from '../../services/user.service';
import { UpdateUsernameRequest } from '../../models/update-username-request';
import { CancelSubscriptionRequest } from '../../models/cancel-subscription-request';
import {CommonModule, DatePipe, NgIf, TitleCasePipe} from "@angular/common";
import {ContinueSubscriptionRequest} from "../../models/continue-subscription-request";
import {RequestState} from "../../services/request-state.enum";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    NgIf,
    TitleCasePipe,
    ReactiveFormsModule,
    DatePipe,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {
  userResponse!: UserResponse;
  subscriptions: SubscriptionResponse[] = [];
  isLoading: boolean = true;
  usernameForm!: FormGroup;

  // Modals control
  showUsernameModal: boolean = false;
  showCancelModal: boolean = false;
  showContinueModal: boolean = false;

  // Subscription ID for action
  subscriptionIdToModify: string | null = null;

  // Messages
  modalMessage: string = '';
  modalError: string | null = null;

  // Button text for modals
  confirmButtonText: string = 'Yes';

  // Loading state for modals
  modalLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private metaService: MetaService,
    private userService: UserService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);

    this.isLoading = true;

    // Subscribe to user data
    this.userService.getUserResponse().subscribe((user) => {
      if (user) {
        this.userResponse = user;
        this.initUsernameForm();
      }
      this.isLoading = false;
    });

    // Subscribe to subscription data
    this.userService.getSubscriptionResponse().subscribe((subscriptions) => {
      if (subscriptions) {
        this.subscriptions = subscriptions;
      }
    });
  }

  initUsernameForm(): void {
    this.usernameForm = this.fb.group({
      newUsername: [
        this.userResponse.username,
        [Validators.required, Validators.pattern('^[a-zA-Z0-9_]+$')],
      ],
    });
  }

  // Open the modal for updating username
  openUsernameModal(): void {
    this.modalMessage = '';
    this.modalError = null;
    this.confirmButtonText = 'Update';
    this.modalLoading = false;
    this.showUsernameModal = true;
  }

  // Close the username modal
  closeUsernameModal(): void {
    this.showUsernameModal = false;
    this.usernameForm.reset({
      newUsername: this.userResponse.username,
    });
  }

  // Update the username
  changeUsername(): void {
    if (this.usernameForm.invalid) {
      return;
    }

    const updateRequest: UpdateUsernameRequest = {
      newUsername: this.usernameForm.value.newUsername,
    };

    this.userService.updateUsername(updateRequest).subscribe((response) => {
      switch (response.state) {
        case RequestState.LOADING:
          this.modalLoading = true;
          this.modalError = null;
          this.modalMessage = '';
          this.confirmButtonText = '';
          this.usernameForm.disable();
          break;
        case RequestState.SUCCESS:
          this.modalLoading = false;
          this.confirmButtonText = 'Close';
          this.modalMessage = 'Username updated successfully!';
          this.usernameForm.enable();

          // Refresh user data
          this.userService.refreshUserResponse();
          break;
        case RequestState.ERROR:
          this.modalLoading = false;
          this.confirmButtonText = 'Close';
          this.modalError = 'Failed to update username.';
          this.usernameForm.enable();
          break;
      }
    });
  }

  // Open the modal for canceling subscription
  openCancelModal(subscriptionId: string): void {
    this.subscriptionIdToModify = subscriptionId;
    this.modalMessage = '';
    this.modalError = null;
    this.confirmButtonText = 'Yes';
    this.modalLoading = false;
    this.showCancelModal = true;
  }

  // Confirm cancellation of subscription
  confirmCancelSubscription(): void {
    if (!this.subscriptionIdToModify) {
      return;
    }

    const cancelRequest: CancelSubscriptionRequest = {
      subscriptionId: this.subscriptionIdToModify,
    };

    this.userService.cancelSubscription(cancelRequest).subscribe((response) => {
      switch (response.state) {
        case RequestState.LOADING:
          this.modalLoading = true;
          this.modalError = null;
          this.modalMessage = '';
          this.confirmButtonText = '';
          break;
        case RequestState.SUCCESS:
          this.modalLoading = false;
          this.confirmButtonText = 'Close';
          this.modalMessage = 'Subscription will be cancelled at the end of the period.';

          // Refresh user data and subscriptions
          this.userService.refreshUserResponse();
          this.userService.refreshSubscriptionResponse();
          break;
        case RequestState.ERROR:
          this.modalLoading = false;
          this.confirmButtonText = 'Close';
          this.modalError = 'Failed to cancel subscription.';
          break;
      }
    });
  }

  // Close the cancellation modal
  closeCancelModal(): void {
    this.showCancelModal = false;
    this.subscriptionIdToModify = null;
  }

  // Open the modal for continuing subscription
  openContinueModal(subscriptionId: string): void {
    this.subscriptionIdToModify = subscriptionId;
    this.modalMessage = '';
    this.modalError = null;
    this.confirmButtonText = 'Yes';
    this.modalLoading = false;
    this.showContinueModal = true;
  }

  // Confirm continuation of subscription
  confirmContinueSubscription(): void {
    if (!this.subscriptionIdToModify) {
      return;
    }

    const continueRequest: ContinueSubscriptionRequest = {
      subscriptionId: this.subscriptionIdToModify,
    };

    this.userService.continueSubscription(continueRequest).subscribe((response) => {
      switch (response.state) {
        case RequestState.LOADING:
          this.modalLoading = true;
          this.modalError = null;
          this.modalMessage = '';
          this.confirmButtonText = '';
          break;
        case RequestState.SUCCESS:
          this.modalLoading = false;
          this.confirmButtonText = 'Close';
          this.modalMessage = 'Subscription continued successfully.';

          // Refresh user data and subscriptions
          this.userService.refreshUserResponse();
          this.userService.refreshSubscriptionResponse();
          break;
        case RequestState.ERROR:
          this.modalLoading = false;
          this.confirmButtonText = 'Close';
          this.modalError = 'Failed to continue subscription.';
          break;
      }
    });
  }

  // Close the continue modal
  closeContinueModal(): void {
    this.showContinueModal = false;
    this.subscriptionIdToModify = null;
  }

  // Format the price for display, including recurring interval
  formatPrice(amount: number | undefined, currency: string | undefined, interval: string | undefined): string {
    if (amount == null || currency == null || interval == null) {
      return '';
    }
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount / 100); // Assuming amount is in cents
    return `${formattedAmount} / ${interval}`;
  }

  // Get the status of the subscription
  getSubscriptionStatus(sub: SubscriptionResponse): string {
    return sub.cancelAtPeriodEnd ? 'Cancelled' : 'Active';
  }

  // Get the pluralized subscription count
  getSubscriptionCountText(): string {
    const count = this.subscriptions.length;
    return `${count} Subscription${count !== 1 ? 's' : ''}`;
  }
}
