import {Component, Input, OnInit} from '@angular/core';
import {AnalyticsService} from "../../../services/analytics.service";
import {UserQuestionMetadataResponse} from "../../../models/user-question-metadata-response";
import {ActionedQuestionsResponse} from "../../../models/actioned-questions-response";
import {ApiState} from "../../../services/api-state.interface";
import {RequestState} from "../../../services/request-state.enum";
import {DatePipe, NgClass, NgForOf, NgIf, SlicePipe} from "@angular/common";
import {QuestionHistoryResponse} from "../../../models/question-history-response";
import {NgIcon} from "@ng-icons/core";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-actioned-questions',
  standalone: true,
  imports: [
    SlicePipe,
    DatePipe,
    NgClass,
    NgIf,
    NgForOf,
    NgIcon
  ],
  templateUrl: './actioned-questions.component.html',
  styleUrl: './actioned-questions.component.css'
})
export class ActionedQuestionsComponent implements OnInit {
  @Input() examId: string | null = null;

  @Input() isPremium: boolean = false;
  @Input() onPremiumPlanClick!: () => void;

  blurImgSrc: string = 'assets/images/dashboard/blur_bookmarked.png';

  requestState: RequestState = RequestState.LOADING;


  userQuestionMetadataResponseList: UserQuestionMetadataResponse[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;

  action: string = 'bookmark';

  error: string | null = null;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.requestState = RequestState.LOADING;
    this.fetchActionedQuestions();
  }

  ngOnInit2(): void {
    // Simulating data load with a timeout, replace with your real data loading logic
    if (!environment.production) {
      setTimeout(() => {
        import('../../../models/mock/mock-actioned-questions-response').then(module => {
          const actionedQuestionsResponse = module.mockActionedQuestionsResponse as ActionedQuestionsResponse;
          this.userQuestionMetadataResponseList = actionedQuestionsResponse.userQuestionMetadataResponseList || [];

          // If less than pageSize items are returned, we assume it's the last page
          if (this.userQuestionMetadataResponseList.length < this.pageSize) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
        });
      }, 2000);
    }
  }

  fetchActionedQuestions(): void {
    if (this.examId == null) {
      this.requestState = RequestState.ERROR;
      return;
    }

    this.requestState = RequestState.LOADING;

    this.error = null;

    this.analyticsService
      .getActionedQuestions(this.examId, this.action, this.pageNumber, this.pageSize)
      .subscribe({
        next: (response: ApiState<ActionedQuestionsResponse>) => {
          this.requestState = RequestState.SUCCESS;
          if (response.state == RequestState.SUCCESS && response.data) {
            const actionedQuestionsResponse: ActionedQuestionsResponse = response.data;
            this.userQuestionMetadataResponseList = actionedQuestionsResponse.userQuestionMetadataResponseList || [];
            this.error = ''
          } else if (response.state == RequestState.ERROR) {
            this.error = 'Failed to load actioned questions.';
            this.userQuestionMetadataResponseList = [];
          }

          // If less than pageSize items are returned, we assume it's the last page
          if (this.userQuestionMetadataResponseList.length < this.pageSize) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
        },
        error: (err) => {
          this.requestState = RequestState.ERROR;
          this.error = 'An error occurred while fetching actioned questions.';
          this.userQuestionMetadataResponseList = [];
          this.isLastPage = true;
          console.error(err);
        },
      });
  }

  isLastPage: boolean = false;

  goToPreviousPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.fetchActionedQuestions();
    }
  }

  goToNextPage(): void {
    if (!this.isLastPage) {
      this.pageNumber++;
      this.fetchActionedQuestions();
    }
  }

  getDifficultyColorClass(uqm: UserQuestionMetadataResponse): string {
    const difficulty = uqm.questionSummaryResponse?.difficultyLevelResponse;
    if (!difficulty) {
      return 'text-gray-500'; // Default color if difficulty info is missing
    }

    const { numericDifficultyLevel, minDifficultyLevel, maxDifficultyLevel } = difficulty;

    const range = maxDifficultyLevel - minDifficultyLevel || 1;
    const relativeDifficulty = numericDifficultyLevel - minDifficultyLevel;

    const percentage = (relativeDifficulty / range) * 100;

    // Map the percentage to color classes
    if (percentage <= 20) {
      return 'text-green-500'; // Easiest
    } else if (percentage <= 40) {
      return 'text-yellow-500'; // Easy
    } else if (percentage <= 60) {
      return 'text-orange-500'; // Medium
    } else if (percentage <= 80) {
      return 'text-red-500'; // Hard
    } else {
      return 'text-purple-500'; // Hardest
    }
  }
  protected readonly RequestState = RequestState;
}
