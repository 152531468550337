// NCLEX-RN Exam Data
import { ExamData } from "../exam-data-interface";

export const nclexRnExamData: ExamData = {
  examId: 'NCLEX_RN_2025',
  displayName: 'NCLEX-RN',
  description:
    'The NCLEX-RN is a standardized exam that each state board of nursing uses to determine whether or not a candidate is prepared for entry-level nursing practice as a Registered Nurse.',
  logo: 'assets/images/exams/nclex_logo.svg', // Ensure you have the correct logo path
  link: 'nclex-rn',
  zoom: 1.1,
  priceId: 'NCLEX_RN_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Ace the NCLEX-RN with Comprehensive Practice and Expert Guidance',
  marketingSubtitles: [
    'Extensive question bank tailored to NCLEX-RN exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable NCLEX-RN Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your nursing career.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'NCLEX_RN_SAFE_CARE',
      displayName: 'Safe and Effective Care Environment',
      description:
        'This section tests the nurse\'s ability to provide a safe environment for patients, staff, and healthcare workers. It is divided into two main categories: Management of Care and Safety and Infection Control.',
      icon: 'remixShieldLine', // Remix Icon for safety
      categories: [
        {
          categoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE',
          displayName: 'Management of Care',
          description: 'Providing and directing nursing care that enhances the care delivery setting to protect clients and health care personnel.',
          icon: 'remixTeamLine', // Remix Icon for management
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_ADVANCE_DIRECTIVES',
              displayName: 'Advance Directives',
              description: 'Understanding and applying legal documents that allow clients to convey their decisions about end-of-life care.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_CASE_MANAGEMENT',
              displayName: 'Case Management',
              description: 'Coordinating care to ensure continuity and quality of care.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_CLIENT_RIGHTS',
              displayName: 'Client Rights',
              description: 'Protecting and promoting clients\' rights.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_COLLABORATION_WITH_INTERDISCIPLINARY_TEAM',
              displayName: 'Collaboration with Interdisciplinary Team',
              description: 'Working with other healthcare professionals to provide optimal client care.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_CONFIDENTIALITY_INFORMATION_SECURITY',
              displayName: 'Confidentiality/Information Security',
              description: 'Maintaining client confidentiality and data security.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_DELEGATION_AND_SUPERVISION',
              displayName: 'Delegation and Supervision',
              description: 'Assigning tasks and supervising care provided by others.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_ETHICAL_PRACTICE',
              displayName: 'Ethical Practice',
              description: 'Applying ethical principles in decision-making.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_INFORMED_CONSENT',
              displayName: 'Informed Consent',
              description: 'Ensuring clients understand procedures and consent appropriately.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_LEGAL_RESPONSIBILITIES',
              displayName: 'Legal Responsibilities',
              description: 'Understanding legal aspects of nursing practice.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_PERFORMANCE_IMPROVEMENT',
              displayName: 'Performance Improvement (Quality Improvement)',
              description: 'Participating in activities that improve client care.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_PRIORITIZATION',
              displayName: 'Prioritization',
              description: 'Deciding which client needs require immediate action.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_MANAGEMENT_OF_CARE_REFERRALS',
              displayName: 'Referrals',
              description: 'Facilitating continuity of care by providing referrals to other healthcare professionals.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL',
          displayName: 'Safety and Infection Control',
          description: 'Protecting clients and healthcare personnel from health and environmental hazards.',
          icon: 'remixVirusLine', // Remix Icon for infection control
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_ACCIDENT_PREVENTION',
              displayName: 'Accident Prevention',
              description: 'Preventing injuries and accidents in the healthcare setting.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_ERROR_PREVENTION',
              displayName: 'Error Prevention',
              description: 'Minimizing the risk of errors in client care.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_EMERGENCY_RESPONSE_PLAN',
              displayName: 'Emergency Response Plan',
              description: 'Understanding and implementing emergency protocols.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_ERGONOMIC_PRINCIPLES',
              displayName: 'Ergonomic Principles',
              description: 'Applying principles to prevent injury and strain.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_HANDLING_HAZARDOUS_MATERIALS',
              displayName: 'Handling Hazardous Materials',
              description: 'Safely managing hazardous substances.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_INFECTION_CONTROL',
              displayName: 'Infection Control',
              description: 'Preventing and controlling infections.',
            },
            {
              subcategoryId: 'NCLEX_RN_SAFE_CARE_SAFETY_INFECTION_CONTROL_SECURITY_PLAN',
              displayName: 'Security Plan',
              description: 'Implementing measures to protect clients and staff.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'NCLEX_RN_HEALTH_PROMOTION',
      displayName: 'Health Promotion and Maintenance',
      description:
        'This section assesses the nurse\'s ability to provide and direct nursing care that promotes and supports the emotional, mental, and social well-being of clients and families experiencing stressful events, as well as promote and maintain health.',
      icon: 'remixHeartLine', // Remix Icon for health promotion
      categories: [
        {
          categoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES',
          displayName: 'Developmental Stages and Transitions',
          description: 'Understanding growth and development throughout the lifespan.',
          icon: 'remixUserHeartLine', // Remix Icon for developmental stages
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_NEWBORN',
              displayName: 'Newborn (0-1 month)',
              description: 'Growth and developmental milestones for newborns.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_INFANT',
              displayName: 'Infant (1 month-1 year)',
              description: 'Growth and developmental milestones for infants.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_TODDLER',
              displayName: 'Toddler (1-3 years)',
              description: 'Growth and developmental milestones for toddlers.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_PRESCHOOL',
              displayName: 'Preschool (3-6 years)',
              description: 'Growth and developmental milestones for preschoolers.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_SCHOOL_AGE',
              displayName: 'School Age (6-12 years)',
              description: 'Growth and developmental milestones for school-age children.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_ADOLESCENT',
              displayName: 'Adolescent (12-18 years)',
              description: 'Growth and developmental milestones for adolescents.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_ADULT',
              displayName: 'Adult (18-65 years)',
              description: 'Developmental tasks and transitions for adults.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_DEVELOPMENTAL_STAGES_OLDER_ADULT',
              displayName: 'Older Adult (65+ years)',
              description: 'Developmental tasks and considerations for older adults.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_HEALTH_PROMOTION_HEALTH_PROMOTION_DISEASE_PREVENTION',
          displayName: 'Health Promotion and Disease Prevention',
          description: 'Strategies to promote health and prevent disease across the lifespan.',
          icon: 'remixVirusFill', // Remix Icon for disease prevention
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_HEALTH_PROMOTION_DISEASE_PREVENTION_IMMUNIZATIONS',
              displayName: 'Immunizations',
              description: 'Understanding vaccination schedules and contraindications.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_HEALTH_PROMOTION_DISEASE_PREVENTION_SCREENINGS',
              displayName: 'Screenings',
              description: 'Knowledge of recommended health screenings and their frequency.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_HEALTH_PROMOTION_DISEASE_PREVENTION_LIFESTYLE_CHOICES',
              displayName: 'Lifestyle Choices',
              description: 'Impact of nutrition, exercise, and habits on health.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_HEALTH_PROMOTION_DISEASE_PREVENTION_RISK_FACTORS',
              displayName: 'Risk Factors',
              description: 'Identifying and managing modifiable and non-modifiable risk factors.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_HEALTH_PROMOTION_ANTE_INTRA_POSTPARTUM_NEWBORN',
          displayName: 'Ante/Intra/Postpartum and Newborn Care',
          description: 'Care of the mother and newborn during the prenatal, intrapartum, and postpartum periods.',
          icon: 'remixUserHeartLine', // Remix Icon for maternal care
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_ANTE_INTRA_POSTPARTUM_NEWBORN_PRENATAL_CARE',
              displayName: 'Prenatal Care',
              description: 'Assessment and interventions during pregnancy.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_ANTE_INTRA_POSTPARTUM_NEWBORN_LABOR_DELIVERY',
              displayName: 'Labor and Delivery',
              description: 'Management of labor processes and complications.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_ANTE_INTRA_POSTPARTUM_NEWBORN_POSTPARTUM_CARE',
              displayName: 'Postpartum Care',
              description: 'Care of the mother after delivery.',
            },
            {
              subcategoryId: 'NCLEX_RN_HEALTH_PROMOTION_ANTE_INTRA_POSTPARTUM_NEWBORN_NEWBORN_CARE',
              displayName: 'Newborn Care',
              description: 'Assessment and care of the newborn infant.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'NCLEX_RN_PSYCHOSOCIAL',
      displayName: 'Psychosocial Integrity',
      description:
        'This section evaluates the nurse\'s ability to promote and support the emotional, mental, and social well-being of clients experiencing stressful events, as well as clients with acute or chronic mental illness.',
      icon: 'remixEmotionLine', // Remix Icon for psychosocial
      categories: [
        {
          categoryId: 'NCLEX_RN_PSYCHOSOCIAL_MENTAL_HEALTH_CONCEPTS',
          displayName: 'Mental Health Concepts',
          description: 'Understanding foundational mental health concepts and applying therapeutic communication.',
          icon: 'remixBrainLine', // Remix Icon for mental health
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_MENTAL_HEALTH_CONCEPTS_THERAPEUTIC_COMMUNICATION',
              displayName: 'Therapeutic Communication',
              description: 'Techniques for effective communication with clients.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_MENTAL_HEALTH_CONCEPTS_ANXIETY_DISORDERS',
              displayName: 'Anxiety Disorders',
              description: 'Recognition and management of anxiety disorders.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_MENTAL_HEALTH_CONCEPTS_MOOD_DISORDERS',
              displayName: 'Mood Disorders',
              description: 'Understanding depression, bipolar disorder, and related conditions.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_MENTAL_HEALTH_CONCEPTS_PSYCHOTIC_DISORDERS',
              displayName: 'Psychotic Disorders',
              description: 'Assessment and care for clients with schizophrenia and other psychotic disorders.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_MENTAL_HEALTH_CONCEPTS_SUBSTANCE_USE_DISORDERS',
              displayName: 'Substance Use Disorders',
              description: 'Care for clients with alcohol and drug dependencies.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_PSYCHOSOCIAL_COPING_ADAPTATION',
          displayName: 'Coping and Adaptation',
          description: 'Assisting clients with coping mechanisms and adaptation strategies.',
          icon: 'remixEmotion2Line', // Remix Icon for coping
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_COPING_ADAPTATION_GRIEF_LOSS',
              displayName: 'Grief and Loss',
              description: 'Supporting clients experiencing loss and grief.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_COPING_ADAPTATION_STRESS_MANAGEMENT',
              displayName: 'Stress Management',
              description: 'Teaching techniques to manage stress effectively.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_COPING_ADAPTATION_CRISIS_INTERVENTION',
              displayName: 'Crisis Intervention',
              description: 'Providing immediate support during a crisis.',
            },
            {
              subcategoryId: 'NCLEX_RN_PSYCHOSOCIAL_COPING_ADAPTATION_ABUSE_NEGLECT',
              displayName: 'Abuse and Neglect',
              description: 'Identifying and intervening in cases of abuse or neglect.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'NCLEX_RN_PHYSIOLOGICAL',
      displayName: 'Physiological Integrity',
      description:
        'This section tests the nurse\'s ability to promote physical health and wellness by providing care and comfort, reducing risk potential, and managing health alterations.',
      icon: 'remixHeartLine', // Remix Icon for physiological integrity
      categories: [
        {
          categoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT',
          displayName: 'Basic Care and Comfort',
          description: 'Providing comfort and assistance in the performance of activities of daily living.',
          icon: 'remixHandSanitizerLine', // Remix Icon for basic care
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_ASSISTIVE_DEVICES',
              displayName: 'Assistive Devices',
              description: 'Use of equipment to enhance client mobility and independence.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_ELIMINATION',
              displayName: 'Elimination',
              description: 'Supporting urinary and bowel elimination needs.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_MOBILITY_IMMOBILITY',
              displayName: 'Mobility and Immobility',
              description: 'Maintaining mobility and preventing complications of immobility.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_NON_PHARMACOLOGICAL_COMFORT_MEASURES',
              displayName: 'Non-Pharmacological Comfort Measures',
              description: 'Techniques to alleviate discomfort without medications.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_NUTRITION_ORAL_HYDRATION',
              displayName: 'Nutrition and Oral Hydration',
              description: 'Ensuring adequate nutritional and fluid intake.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_PERSONAL_HYGIENE',
              displayName: 'Personal Hygiene',
              description: 'Assisting with bathing, grooming, and dressing.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_BASIC_CARE_COMFORT_REST_SLEEP',
              displayName: 'Rest and Sleep',
              description: 'Promoting adequate rest and sleep.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES',
          displayName: 'Pharmacological and Parenteral Therapies',
          description: 'Administering medications and monitoring clients receiving parenteral therapies.',
          icon: 'remixSyringeLine', // Remix Icon for pharmacological therapies
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES_MEDICATION_ADMINISTRATION',
              displayName: 'Medication Administration',
              description: 'Safe administration of medications.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES_ADVERSE_EFFECTS_CONTRAINDICATIONS',
              displayName: 'Adverse Effects and Contraindications',
              description: 'Identifying and managing medication side effects.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES_DOSAGE_CALCULATIONS',
              displayName: 'Dosage Calculations',
              description: 'Calculating correct medication dosages.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES_PHARMACOLOGICAL_PAIN_MANAGEMENT',
              displayName: 'Pharmacological Pain Management',
              description: 'Using medications to manage pain.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES_BLOOD_BLOOD_PRODUCTS',
              displayName: 'Blood and Blood Products',
              description: 'Administering and monitoring transfusions.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHARMACOLOGICAL_THERAPIES_TOTAL_PARENTERAL_NUTRITION',
              displayName: 'Total Parenteral Nutrition',
              description: 'Managing clients receiving TPN.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_PHYSIOLOGICAL_RISK_REDUCTION',
          displayName: 'Reduction of Risk Potential',
          description: 'Reducing the likelihood that clients will develop complications or health problems.',
          icon: 'remixBugLine', // Remix Icon for risk reduction
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_RISK_REDUCTION_DIAGNOSTIC_TESTS',
              displayName: 'Diagnostic Tests',
              description: 'Understanding and preparing clients for diagnostic procedures.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_RISK_REDUCTION_LABORATORY_VALUES',
              displayName: 'Laboratory Values',
              description: 'Interpreting lab results and recognizing abnormalities.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_RISK_REDUCTION_POTENTIAL_COMPLICATIONS',
              displayName: 'Potential Complications',
              description: 'Identifying and preventing possible complications.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_RISK_REDUCTION_THERAPEUTIC_PROCEDURES',
              displayName: 'Therapeutic Procedures',
              description: 'Assisting with and monitoring the outcomes of procedures.',
            },
          ],
        },
        {
          categoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHYSIOLOGICAL_ADAPTATION',
          displayName: 'Physiological Adaptation',
          description: 'Managing and providing care for clients with acute, chronic, or life-threatening physical health conditions.',
          icon: 'remixHeartPulseLine', // Remix Icon for physiological adaptation
          subcategories: [
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHYSIOLOGICAL_ADAPTATION_MEDICAL_EMERGENCIES',
              displayName: 'Medical Emergencies',
              description: 'Recognizing and responding to life-threatening situations.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHYSIOLOGICAL_ADAPTATION_FLUID_ELECTROLYTE_IMBALANCES',
              displayName: 'Fluid and Electrolyte Imbalances',
              description: 'Managing clients with imbalances.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHYSIOLOGICAL_ADAPTATION_HEMODYNAMICS',
              displayName: 'Hemodynamics',
              description: 'Monitoring and interpreting hemodynamic parameters.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHYSIOLOGICAL_ADAPTATION_PATHOPHYSIOLOGY',
              displayName: 'Pathophysiology',
              description: 'Understanding disease processes and their impact on the body.',
            },
            {
              subcategoryId: 'NCLEX_RN_PHYSIOLOGICAL_PHYSIOLOGICAL_ADAPTATION_RADIATION_THERAPY',
              displayName: 'Radiation Therapy',
              description: 'Caring for clients receiving radiation treatment.',
            },
          ],
        },
      ],
    },
  ],
};
