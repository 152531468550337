<section class="relative pt-10 pb-32 bg-transparent overflow-hidden flex items-center justify-center min-h-screen">
  <div class="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center border border-gray-200 ">
    <!-- Error Icon -->
    <div class="mb-6">
      <ng-icon
        name="remixErrorWarningLine"
        class="text-red-500 mb-4"
        style="width: 50px; height: 50px;"
      ></ng-icon>
    </div>
    <!-- Error Message -->
    <h1 class="text-3xl font-bold text-gray-800">Oops! Something went wrong.</h1>
    <p class="mt-4 text-gray-600 text-center">We couldn't load the data you requested. Please try again later.</p>
    <a routerLink="/" class="mt-6 inline-block px-6 py-3 bg-custom-blue-500 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-200">
      Go back to the homepage
    </a>
  </div>
</section>
