<div class="bg-white shadow-md rounded-lg p-4">
  <!-- Title -->
  <div class="mb-4 flex justify-between items-center">
    <h2 class="text-2xl font-bold text-gray-700 capitalize">
      Bookmarked Questions
    </h2>
  </div>

  <!-- Conditional Content -->
  <div class="relative">
    <!-- Non-Premium Users -->
    <ng-container *ngIf="!isPremium">
      <!-- Blurred Sample Chart -->
      <div class="relative">
        <img
          [src]="this.blurImgSrc"
          alt=""
          class="w-full h-auto object-cover"
          style="filter: blur(2px); opacity: 1.0; max-height: 600px;"
        />
        <!-- Overlay Content with Spotlight Effect -->
        <div
          class="absolute inset-0 flex flex-col items-center justify-center z-10"
          style="background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 30%);"
        >
          <ng-icon
            name="remixLockFill"
            class="text-gray-700 mb-4"
            style="width: 40px; height: 40px;"
          ></ng-icon>
          <p class="text-gray-800 mb-4 font-semibold">Upgrade to Premium to access this feature.</p>
          <button
            (click)="onPremiumPlanClick()"
            class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"
          >
            Upgrade to Premium
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Pro Users -->
    <ng-container *ngIf="isPremium">
      <!-- Loading State -->
      <div
        *ngIf="requestState === RequestState.LOADING"
        class="flex items-center justify-center"
        style="min-height: 16rem;"
      >
        <ng-icon
          name="remixLoader4Line"
          class="animate-spin text-blue-500"
          style="width: 40px; height: 40px;"
        ></ng-icon>
      </div>

      <!-- Success State -->
      <div *ngIf="requestState === RequestState.SUCCESS">

        <!-- Actioned Questions Table -->
        <div *ngIf="userQuestionMetadataResponseList.length > 0">
          <div class="overflow-x-auto">
            <table class="table-auto w-full">
              <thead>
              <tr class="bg-gray-100">
                <th class="px-4 py-2 text-left"></th>
                <th class="px-4 py-2 text-left">Title</th>
                <th class="px-4 py-2 text-left">Section</th>
                <th class="px-4 py-2 text-left">Category</th>
                <th class="px-4 py-2 text-left">Subcategory</th>
                <th class="px-4 py-2 text-left">Difficulty</th>
              </tr>
              </thead>
              <tbody>
              <tr
                *ngFor="let uqm of userQuestionMetadataResponseList"
                class="border-b border-gray-200 hover:bg-gray-50"
              >
                <td class="px-4 py-2">
                  <div class="flex space-x-2">
                    <!-- Bookmarked -->
                    <i
                      *ngIf="uqm.bookmarked"
                      class="fa-solid fa-bookmark text-yellow-500"
                      title="Bookmarked"
                    ></i>

                  </div>
                </td>

                <!-- Title -->
                <td class="px-4 py-2">
                  <a
                    [href]="'/question/' + uqm.questionSummaryResponse?.questionId"
                    class="text-blue-500 hover:underline"
                    title="{{ uqm.questionSummaryResponse?.title }}"
                  >
                    {{
                      (uqm.questionSummaryResponse?.title || 'Untitled') | slice: 0:20
                    }}
                    {{ uqm.questionSummaryResponse?.title?.length ?? 0 > 20 ? '...' : '' }}
                  </a>
                </td>

                <!-- Section -->
                <td class="px-4 py-2">
                  {{
                    uqm.questionSummaryResponse?.examSectionSummaryResponse?.examSectionDisplayName
                    || 'N/A'
                  }}
                </td>

                <!-- Category -->
                <td class="px-4 py-2">
                  {{
                    uqm.questionSummaryResponse?.categoriesResponse?.categories?.[0]?.categoryDisplayName
                    || 'N/A'
                  }}
                </td>

                <!-- Subcategory -->
                <td class="px-4 py-2">
                  {{
                    uqm.questionSummaryResponse?.subcategoriesResponse?.subcategories?.[0]?.subcategoryDisplayName
                    || 'N/A'
                  }}
                </td>

                <!-- Difficulty -->
                <td class="px-4 py-2">
                    <span [ngClass]="getDifficultyColorClass(uqm)">
                      {{
                        uqm.questionSummaryResponse?.difficultyLevelResponse?.difficultyDisplayName
                        || 'N/A'
                      }}
                    </span>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- No Data Message -->
        <div *ngIf="userQuestionMetadataResponseList.length === 0 && this.pageNumber === 1">
          <p class="text-gray-500 text-center">No bookmarked questions for review.</p>
        </div>

        <div *ngIf="userQuestionMetadataResponseList.length === 0 && this.pageNumber > 1">
          <p class="text-gray-500 text-center">No more bookmarked questions for review.</p>
        </div>


        <!-- Pagination -->
        <div class="mt-4 flex justify-center items-center space-x-2">
          <button
            (click)="goToPreviousPage()"
            [disabled]="pageNumber === 1"
            class="px-3 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>

          <span class="px-3 py-1 text-gray-700">
              Page {{ pageNumber }}
            </span>

          <button
            (click)="goToNextPage()"
            [disabled]="isLastPage"
            class="px-3 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
        </div>

      </div>

      <!-- Error State -->
      <div
        *ngIf="requestState === RequestState.ERROR"
        class="flex flex-col items-center justify-center"
        style="min-height: 16rem;"
      >
        <ng-icon
          name="remixErrorWarningLine"
          class="text-red-500 mb-4"
          style="width: 64px; height: 64px;"
        ></ng-icon>
        <p class="text-red-600">An error occurred while loading the data.</p>
      </div>
    </ng-container>
  </div>
</div>
