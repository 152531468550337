import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { User } from 'firebase/auth';
import {AuthService} from "../../services/auth.service";
import {NgIf} from "@angular/common";
import {MetaService} from "../../services/meta.service";


@Component({
  selector: 'app-email-verification',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './email-verification.component.html',
  styleUrl: './email-verification.component.css'
})
export class EmailVerificationComponent implements OnInit {
  userEmail: string | null = null;
  emailSent = false;
  errorMessage = '';

  constructor(private route: ActivatedRoute, private metaService: MetaService,
              private authService: AuthService, private router: Router) {}

  ngOnInit() {
    // Update the metadata tags
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);

    const user = this.authService.getCurrentUser();
    if (user) {
      this.userEmail = user.email;
      // Reload the user to check the latest email verification status
      user.reload().then(() => {
        if (user.emailVerified) {
          // If email is verified, redirect to another page (e.g., dashboard)
          this.router.navigate(['/exams']);
        }
      });
    } else {
      // Redirect to login page if not logged in
      this.router.navigate(['/login']);
    }
  }

  resendVerificationEmail() {
    this.authService.sendVerificationEmail()
      .then(() => {
        this.emailSent = true; // Display success message
      })
      .catch(error => {
        this.errorMessage = error.message; // Display error message
      });
  }
}
