<div class="flex flex-col space-y-4 max-w-lg mx-auto">
  <!-- Error Message -->
  <div *ngIf="showError" class="text-red-500 text-center">
    {{ errorMessage }}
  </div>

  <!-- Multiple Choice Options -->
  <div *ngFor="let option of questionOptions?.options" class="p-2">
    <label
      class="flex items-center select-none p-4 rounded-lg transition-colors shadow-sm border cursor-pointer"
      [ngClass]="getOptionCss(option)"
      role="radio"
      [attr.aria-disabled]="isOptionDisabled() ? true : null"
      [attr.aria-checked]="selectedOption === option.id"
    >
      <!-- Hidden Radio Input -->
      <input
        type="radio"
        name="radioGroup"
        [value]="option.id"
        class="hidden"
        (change)="onRadioChange(option.id)"
        [disabled]="isOptionDisabled()"
      />

      <!-- Custom Radio Button -->
      <div
        class="min-w-[2rem] min-h-[2rem] w-8 h-8 flex items-center justify-center rounded-full border-2 transition-colors"
        [ngClass]="getRadioCss(option)"
      >
        <span
          [ngClass]="getTextColor(option)"
          [innerHTML]="getItemLabel(option)"
        ></span>
      </div>

      <!-- Option Text -->
      <span class="ml-4 text-gray-700">{{ option.text }}</span>
    </label>
  </div>
</div>
