import {ExamData} from "../exam-data-interface";

export const ssatElemExamData: ExamData = {
  examId: 'SSAT_ELEM_2025',
  displayName: 'SSAT Elementary Level',
  description:
    'The SSAT Elementary Level is designed for students currently in grades 3 and 4 applying for admission to grades 4 and 5. The exam measures basic verbal, math, and reading skills that students need for successful performance in independent schools.',
  logo: 'assets/images/exams/ssat_elem_logo.png',
  link: 'ssat-elementary',
  zoom: 1.1,
  priceId: 'SSAT_ELEM_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Ace the SSAT Elementary Level with Personalized Practice',
  marketingSubtitles: [
    "Our adaptive platform focuses on your child's needs to boost their SSAT score.",
    'Access hundreds of practice questions tailored for elementary students.',
    'Build confidence with fun and engaging practice sessions.',
    'Identify and improve on areas where your child needs the most support.',
  ],
  pricingTitle: 'Comprehensive Prep at an Affordable Price',
  pricingSubtitle: [
    'Give your child the tools they need to succeed with quality practice and personalized feedback.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'SSAT_ELEM_QUANT',
      displayName: 'Quantitative (Math) Section',
      description:
        'Measures basic mathematical concepts and skills appropriate for elementary students. Assesses ability to solve problems involving number sense, operations, basic geometry, and measurement.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'SSAT_ELEM_QUANT_NUMBER_CONCEPTS',
          displayName: 'Number Concepts and Operations',
          description:
            'Understanding of basic number concepts and arithmetic operations.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId: 'SSAT_ELEM_QUANT_NUMBER_CONCEPTS_ADDITION',
              displayName: 'Addition',
              description: 'Simple addition problems and concepts.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_NUMBER_CONCEPTS_SUBTRACTION',
              displayName: 'Subtraction',
              description: 'Simple subtraction problems and concepts.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_NUMBER_CONCEPTS_MULTIPLICATION',
              displayName: 'Multiplication',
              description: 'Basic multiplication facts and concepts.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_NUMBER_CONCEPTS_DIVISION',
              displayName: 'Division',
              description: 'Basic division facts and concepts.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_NUMBER_CONCEPTS_FRACTIONS',
              displayName: 'Fractions',
              description:
                'Understanding parts of a whole, basic fraction concepts.',
            },
          ],
        },
        {
          categoryId: 'SSAT_ELEM_QUANT_GEOMETRY',
          displayName: 'Geometry and Spatial Sense',
          description: 'Basic geometric shapes and spatial understanding.',
          icon: 'remixShapeLine',
          subcategories: [
            {
              subcategoryId: 'SSAT_ELEM_QUANT_GEOMETRY_SHAPES',
              displayName: 'Shapes',
              description:
                'Identifying and understanding basic two-dimensional and three-dimensional shapes.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_GEOMETRY_SYMMETRY',
              displayName: 'Symmetry',
              description: 'Recognizing symmetrical shapes and patterns.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_GEOMETRY_POSITION_DIRECTION',
              displayName: 'Position and Direction',
              description:
                'Understanding concepts of position, direction, and movement.',
            },
          ],
        },
        {
          categoryId: 'SSAT_ELEM_QUANT_MEASUREMENT',
          displayName: 'Measurement',
          description: 'Understanding and applying measurement concepts.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId: 'SSAT_ELEM_QUANT_MEASUREMENT_LENGTH',
              displayName: 'Length',
              description: 'Measuring and comparing lengths.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_MEASUREMENT_WEIGHT_MASS',
              displayName: 'Weight and Mass',
              description: 'Understanding weight and mass measurements.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_MEASUREMENT_TIME',
              displayName: 'Time',
              description: 'Reading clocks and understanding time intervals.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_MEASUREMENT_VOLUME_CAPACITY',
              displayName: 'Volume and Capacity',
              description: 'Measuring liquids and understanding volume.',
            },
          ],
        },
        {
          categoryId: 'SSAT_ELEM_QUANT_PATTERNS_ALGEBRA',
          displayName: 'Patterns and Algebra',
          description: 'Recognizing patterns and basic algebraic concepts.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_ELEM_QUANT_PATTERNS_ALGEBRA_NUMBER_PATTERNS',
              displayName: 'Number Patterns',
              description: 'Identifying and continuing number sequences.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_PATTERNS_ALGEBRA_BASIC_EQUATIONS',
              displayName: 'Basic Equations',
              description: 'Understanding and solving simple equations.',
            },
            {
              subcategoryId: 'SSAT_ELEM_QUANT_PATTERNS_ALGEBRA_RELATIONSHIPS',
              displayName: 'Relationships',
              description:
                'Understanding relationships between numbers and operations.',
            },
          ],
        },
        {
          categoryId: 'SSAT_ELEM_QUANT_DATA_PROBABILITY',
          displayName: 'Data Analysis and Probability',
          description: 'Interpreting data and understanding basic probability.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'SSAT_ELEM_QUANT_DATA_PROBABILITY_GRAPHS',
              displayName: 'Graphs and Charts',
              description:
                'Reading and interpreting simple graphs and charts.',
            },
            {
              subcategoryId:
                'SSAT_ELEM_QUANT_DATA_PROBABILITY_BASIC_PROBABILITY',
              displayName: 'Basic Probability',
              description: 'Understanding simple probability concepts.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_ELEM_VERBAL',
      displayName: 'Verbal Section',
      description:
        'Measures vocabulary, verbal reasoning, and ability to relate ideas logically. Includes both synonyms and analogies appropriate for elementary students.',
      icon: 'remixChat3Line', // ng-icons Remix Icon for verbal
      categories: [
        {
          categoryId: 'SSAT_ELEM_VERBAL_SYNONYMS',
          displayName: 'Synonyms',
          description:
            'Testing vocabulary knowledge by identifying words with similar meanings.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId: 'SSAT_ELEM_VERBAL_SYNONYMS_BASIC_VOCABULARY',
              displayName: 'Basic Vocabulary',
              description: 'Common words appropriate for elementary students.',
            },
            {
              subcategoryId: 'SSAT_ELEM_VERBAL_SYNONYMS_ADVANCED_VOCABULARY',
              displayName: 'Advanced Vocabulary',
              description:
                'Challenging words to test higher-level vocabulary.',
            },
            {
              subcategoryId: 'SSAT_ELEM_VERBAL_SYNONYMS_CONTEXT_CLUES',
              displayName: 'Context Clues',
              description:
                'Using context to determine the meaning of unfamiliar words.',
            },
          ],
        },
        {
          categoryId: 'SSAT_ELEM_VERBAL_ANALOGIES',
          displayName: 'Analogies',
          description:
            'Testing ability to relate ideas and concepts logically.',
          icon: 'remixAttachmentLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_ELEM_VERBAL_ANALOGIES_SEMANTIC_RELATIONSHIPS',
              displayName: 'Semantic Relationships',
              description: 'Understanding relationships based on word meanings.',
            },
            {
              subcategoryId:
                'SSAT_ELEM_VERBAL_ANALOGIES_FUNCTIONAL_RELATIONSHIPS',
              displayName: 'Functional Relationships',
              description:
                'Understanding relationships based on function or use.',
            },
            {
              subcategoryId:
                'SSAT_ELEM_VERBAL_ANALOGIES_PART_WHOLE_RELATIONSHIPS',
              displayName: 'Part to Whole Relationships',
              description:
                'Understanding relationships where one term is part of the other.',
            },
            {
              subcategoryId:
                'SSAT_ELEM_VERBAL_ANALOGIES_CAUSE_EFFECT_RELATIONSHIPS',
              displayName: 'Cause and Effect Relationships',
              description:
                'Understanding relationships where one term causes the other.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_ELEM_READING',
      displayName: 'Reading Section',
      description:
        'Measures the ability to understand and interpret written material. Passages are appropriate for elementary students and cover a variety of genres.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading
      categories: [
        {
          categoryId: 'SSAT_ELEM_READING_MAIN_IDEA',
          displayName: 'Main Idea and Theme',
          description:
            'Identifying the central message or theme of the passage.',
          icon: 'remixFileTextLine',
          subcategories: [],
        },
        {
          categoryId: 'SSAT_ELEM_READING_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description: 'Recalling specific facts or details from the passage.',
          icon: 'remixListUnordered',
          subcategories: [],
        },
        {
          categoryId: 'SSAT_ELEM_READING_INFERENCE',
          displayName: 'Inference',
          description:
            'Drawing conclusions based on information implied in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [],
        },
        {
          categoryId: 'SSAT_ELEM_READING_VOCABULARY_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Determining the meaning of words based on context.',
          icon: 'remixBookletLine',
          subcategories: [],
        },
        {
          categoryId: 'SSAT_ELEM_READING_AUTHORS_PURPOSE',
          displayName: "Author's Purpose and Tone",
          description:
            'Understanding why the author wrote the passage and identifying tone.',
          icon: 'remixUserVoiceLine',
          subcategories: [],
        },
      ],
    },
    {
      examSectionId: 'SSAT_ELEM_WRITING',
      displayName: 'Writing Sample',
      description:
        'Requires students to produce a written response to a given prompt. Students will respond to a short passage. This section is unscored but sent to schools as a sample of the student\'s writing ability.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'SSAT_ELEM_WRITING_WRITING_SKILLS',
          displayName: 'Writing Skills',
          description:
            'Assessment of the student’s ability to write coherently and effectively.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId: 'SSAT_ELEM_WRITING_WRITING_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Logical structure and flow of the essay.',
            },
            {
              subcategoryId:
                'SSAT_ELEM_WRITING_WRITING_SKILLS_CONTENT_DEVELOPMENT',
              displayName: 'Content Development',
              description:
                'Development of ideas and use of supporting details.',
            },
            {
              subcategoryId: 'SSAT_ELEM_WRITING_WRITING_SKILLS_LANGUAGE_USE',
              displayName: 'Language Use',
              description: 'Grammar, vocabulary, and sentence structure.',
            },
            {
              subcategoryId: 'SSAT_ELEM_WRITING_WRITING_SKILLS_MECHANICS',
              displayName: 'Mechanics',
              description: 'Spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
  ],
};


// SSAT Middle Level Exam Data
export const ssatMiddleExamData: ExamData = {
  examId: 'SSAT_MIDDLE_2025',
  displayName: 'SSAT Middle Level',
  description:
    'The SSAT Middle Level is designed for students currently in grades 5 through 7 applying for admission to grades 6 through 8. The exam measures verbal, quantitative (math), and reading skills necessary for successful academic performance in independent schools.',
  logo: 'assets/images/exams/ssat_middle_logo.png',
  link: 'ssat-middle',
  zoom: 1.1,
  priceId: 'SSAT_MIDDLE_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Achieve Excellence on the SSAT Middle Level',
  marketingSubtitles: [
    'Personalized practice to enhance your SSAT Middle Level performance.',
    'Extensive question bank covering all sections of the exam.',
    'Adaptive learning to focus on areas needing improvement.',
    'Build confidence with effective practice and feedback.',
  ],
  pricingTitle: 'Comprehensive SSAT Prep at an Affordable Price',
  pricingSubtitle: [
    'Empower your academic journey with quality preparation and support.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'SSAT_MIDDLE_WRITING',
      displayName: 'Writing Sample',
      description:
        'Requires students to produce a written response to one of two provided creative prompts. This section is unscored but sent to schools as a sample of the student\'s writing ability.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'SSAT_MIDDLE_WRITING_WRITING_SKILLS',
          displayName: 'Writing Skills',
          description:
            'Assessment of the student’s ability to write coherently and effectively.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_WRITING_WRITING_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Logical structure and flow of the essay.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_WRITING_WRITING_SKILLS_CONTENT_DEVELOPMENT',
              displayName: 'Content Development',
              description:
                'Development of ideas and use of supporting details.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_WRITING_WRITING_SKILLS_LANGUAGE_USE',
              displayName: 'Language Use',
              description: 'Grammar, vocabulary, and sentence structure.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_WRITING_WRITING_SKILLS_MECHANICS',
              displayName: 'Mechanics',
              description: 'Spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_MIDDLE_QUANT',
      displayName: 'Quantitative (Math)',
      description:
        'Measures mathematical problem-solving ability appropriate for middle school students, assessing understanding of concepts and ability to apply mathematical knowledge to solve problems.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS',
          displayName: 'Number and Operations',
          description: 'Understanding of numbers and numerical operations.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_WHOLE_NUMBERS',
              displayName: 'Whole Numbers',
              description: 'Operations involving whole numbers.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_FRACTIONS',
              displayName: 'Fractions',
              description: 'Operations involving fractions.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_DECIMALS',
              displayName: 'Decimals',
              description: 'Operations involving decimals.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_PERCENTAGES',
              displayName: 'Percentages',
              description: 'Calculations involving percentages.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_INTEGERS',
              displayName: 'Integers',
              description:
                'Operations involving positive and negative integers.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_RATIONAL_NUMBERS',
              displayName: 'Rational Numbers',
              description:
                'Understanding and operating with rational numbers.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_NUMBER_PROPERTIES',
              displayName: 'Number Properties',
              description:
                'Properties such as divisibility, primes, factors, and multiples.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_NUMBER_OPERATIONS_ORDER_OF_OPERATIONS',
              displayName: 'Order of Operations',
              description:
                'Applying the correct order of operations (PEMDAS).',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_QUANT_ALGEBRA',
          displayName: 'Algebra',
          description: 'Understanding of algebraic concepts and techniques.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_ALGEBRA_EXPRESSIONS_EQUATIONS',
              displayName: 'Expressions and Equations',
              description:
                'Simplifying expressions and solving equations.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_ALGEBRA_INEQUALITIES',
              displayName: 'Inequalities',
              description: 'Solving and graphing inequalities.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_ALGEBRA_VARIABLES_CONSTANTS',
              displayName: 'Variables and Constants',
              description:
                'Understanding variables, constants, and coefficients.',
            },
            {
              subcategoryId: 'SSAT_MIDDLE_QUANT_ALGEBRA_WORD_PROBLEMS',
              displayName: 'Word Problems',
              description:
                'Translating word problems into mathematical expressions and solving.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_ALGEBRA_FUNCTIONS_PATTERNS',
              displayName: 'Functions and Patterns',
              description:
                'Understanding basic functions and identifying patterns.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_QUANT_GEOMETRY',
          displayName: 'Geometry and Measurement',
          description:
            'Understanding of geometric concepts and measurement.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_GEOMETRY_LINES_ANGLES',
              displayName: 'Lines and Angles',
              description:
                'Properties of lines, angles, and their relationships.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_GEOMETRY_TRIANGLES_POLYGONS',
              displayName: 'Triangles and Polygons',
              description:
                'Properties of triangles and other polygons.',
            },
            {
              subcategoryId: 'SSAT_MIDDLE_QUANT_GEOMETRY_CIRCLES',
              displayName: 'Circles',
              description:
                'Properties of circles including circumference and area.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_GEOMETRY_PERIMETER_AREA',
              displayName: 'Perimeter and Area',
              description:
                'Calculating the perimeter and area of various shapes.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_GEOMETRY_VOLUME_SURFACE_AREA',
              displayName: 'Volume and Surface Area',
              description:
                'Calculating volume and surface area of solids.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_GEOMETRY_COORDINATE_GEOMETRY',
              displayName: 'Coordinate Geometry',
              description:
                'Understanding and plotting points on the coordinate plane.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_GEOMETRY_TRANSFORMATIONS',
              displayName: 'Transformations',
              description:
                'Understanding transformations such as reflection, rotation, and translation.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_QUANT_DATA_PROBABILITY',
          displayName: 'Data Analysis and Probability',
          description: 'Interpreting data and understanding basic probability.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_DATA_PROBABILITY_GRAPHS_CHARTS',
              displayName: 'Graphs and Charts',
              description:
                'Reading and interpreting data from graphs and charts.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_DATA_PROBABILITY_MEAN_MEDIAN_MODE',
              displayName: 'Mean, Median, Mode',
              description:
                'Calculating and understanding measures of central tendency.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_DATA_PROBABILITY_BASIC_PROBABILITY',
              displayName: 'Basic Probability',
              description:
                'Understanding and calculating simple probabilities.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_DATA_PROBABILITY_COUNTING_PRINCIPLES',
              displayName: 'Counting Principles',
              description:
                'Understanding permutations and combinations.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_QUANT_DATA_PROBABILITY_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description: 'Analyzing data to draw conclusions.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_MIDDLE_READING',
      displayName: 'Reading Comprehension',
      description:
        'Measures the ability to understand and interpret written passages from various subjects.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading
      categories: [
        {
          categoryId: 'SSAT_MIDDLE_READING_MAIN_IDEA',
          displayName: 'Main Idea and Theme',
          description:
            'Identifying the central message or theme of the passage.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_MAIN_IDEA_CENTRAL_IDEA',
              displayName: 'Central Idea',
              description:
                'Determining the central idea of the passage.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_MAIN_IDEA_TITLE_SELECTION',
              displayName: 'Title Selection',
              description:
                'Choosing an appropriate title that reflects the main idea.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_READING_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description:
            'Recalling specific facts or details from the passage.',
          icon: 'remixListUnordered',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_SUPPORTING_DETAILS_FACT_RECALL',
              displayName: 'Fact Recall',
              description:
                'Remembering specific information stated in the passage.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_SUPPORTING_DETAILS_SEQUENCE_EVENTS',
              displayName: 'Sequence of Events',
              description:
                'Understanding the order in which events occur.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_READING_INFERENCE',
          displayName: 'Inference',
          description:
            'Drawing conclusions based on information implied in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_INFERENCE_CONCLUSIONS',
              displayName: 'Conclusions',
              description:
                'Making logical conclusions from the given information.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_INFERENCE_PREDICTIONS',
              displayName: 'Predictions',
              description:
                'Predicting future events based on the passage.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_READING_VOCABULARY_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Determining the meaning of words based on context.',
          icon: 'remixBookletLine',
          subcategories: [],
        },
        {
          categoryId: 'SSAT_MIDDLE_READING_AUTHORS_PURPOSE',
          displayName: "Author's Purpose and Tone",
          description:
            'Understanding why the author wrote the passage and identifying tone.',
          icon: 'remixUserVoiceLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_AUTHORS_PURPOSE_PURPOSE',
              displayName: "Author's Purpose",
              description:
                'Determining the author’s intent in writing the passage.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_AUTHORS_PURPOSE_TONE',
              displayName: "Author's Tone",
              description:
                'Identifying the author’s attitude or feelings conveyed in the passage.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_READING_FIGURATIVE_LANGUAGE',
          displayName: 'Figurative Language and Literary Devices',
          description:
            'Recognizing and interpreting literary devices and figurative language.',
          icon: 'remixBrushLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_FIGURATIVE_LANGUAGE_METAPHORS_SIMILES',
              displayName: 'Metaphors and Similes',
              description:
                'Understanding comparisons made using metaphors and similes.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_FIGURATIVE_LANGUAGE_PERSONIFICATION',
              displayName: 'Personification',
              description:
                'Recognizing when human qualities are attributed to non-human things.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_FIGURATIVE_LANGUAGE_IRONY',
              displayName: 'Irony',
              description:
                'Identifying irony and understanding its effect on the passage.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_READING_COMPARATIVE_ANALYSIS',
          displayName: 'Comparative Analysis',
          description:
            'Comparing and contrasting ideas or themes within the passage or between passages.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_COMPARATIVE_ANALYSIS_COMPARE_CONTRAST',
              displayName: 'Compare and Contrast',
              description:
                'Analyzing similarities and differences in concepts or ideas.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_READING_COMPARATIVE_ANALYSIS_CROSS_REFERENCE',
              displayName: 'Cross-Reference',
              description:
                'Connecting information from different parts of the passage.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_MIDDLE_VERBAL',
      displayName: 'Verbal',
      description:
        'Measures vocabulary, verbal reasoning, and ability to relate ideas logically through synonyms and analogies.',
      icon: 'remixChat3Line', // ng-icons Remix Icon for verbal
      categories: [
        {
          categoryId: 'SSAT_MIDDLE_VERBAL_SYNONYMS',
          displayName: 'Synonyms',
          description:
            'Testing vocabulary knowledge by identifying words with similar meanings.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_SYNONYMS_BASIC_VOCABULARY',
              displayName: 'Basic Vocabulary',
              description:
                'Common words appropriate for middle school students.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_SYNONYMS_ADVANCED_VOCABULARY',
              displayName: 'Advanced Vocabulary',
              description:
                'Challenging words to test higher-level vocabulary.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_SYNONYMS_CONTEXT_CLUES',
              displayName: 'Context Clues',
              description:
                'Using context to determine the meaning of unfamiliar words.',
            },
          ],
        },
        {
          categoryId: 'SSAT_MIDDLE_VERBAL_ANALOGIES',
          displayName: 'Analogies',
          description:
            'Testing ability to relate ideas and concepts logically.',
          icon: 'remixAttachmentLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_ANALOGIES_SEMANTIC_RELATIONSHIPS',
              displayName: 'Semantic Relationships',
              description:
                'Understanding relationships based on word meanings.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_ANALOGIES_FUNCTIONAL_RELATIONSHIPS',
              displayName: 'Functional Relationships',
              description:
                'Understanding relationships based on function or use.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_ANALOGIES_PART_WHOLE_RELATIONSHIPS',
              displayName: 'Part to Whole Relationships',
              description:
                'Understanding relationships where one term is part of the other.',
            },
            {
              subcategoryId:
                'SSAT_MIDDLE_VERBAL_ANALOGIES_CAUSE_EFFECT_RELATIONSHIPS',
              displayName: 'Cause and Effect Relationships',
              description:
                'Understanding relationships where one term causes the other.',
            },
          ],
        },
      ],
    },
  ],
};


// SSAT Upper Level Exam Data
export const ssatUpperExamData: ExamData = {
  examId: 'SSAT_UPPER_2025',
  displayName: 'SSAT Upper Level',
  description:
    'The SSAT Upper Level is designed for students currently in grades 8 through 11 applying for admission to grades 9 through 12. The exam measures verbal, quantitative (math), and reading skills necessary for successful academic performance in independent schools.',
  logo: 'assets/images/exams/ssat_upper_logo.png',
  link: 'ssat-upper',
  zoom: 1.1,
  priceId: 'SSAT_UPPER_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel on the SSAT Upper Level with Tailored Prep',
  marketingSubtitles: [
    'Personalized practice to boost your SSAT Upper Level score.',
    'Extensive question bank covering all advanced topics.',
    'Adaptive learning focusing on your areas for improvement.',
    'Gain confidence with thorough preparation and feedback.',
  ],
  pricingTitle: 'Premium SSAT Prep at a Competitive Price',
  pricingSubtitle: [
    'Achieve your academic goals with comprehensive support and practice.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'SSAT_UPPER_WRITING',
      displayName: 'Writing Sample',
      description:
        'Requires students to produce a written response to one of two provided prompts: one creative and one analytical essay prompt. This section is unscored but sent to schools as a sample of the student\'s writing ability.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'SSAT_UPPER_WRITING_WRITING_SKILLS',
          displayName: 'Writing Skills',
          description:
            'Assessment of the student’s ability to write coherently and effectively.',
          icon: 'remixEditLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_WRITING_WRITING_SKILLS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Logical structure and flow of the essay.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_WRITING_WRITING_SKILLS_CONTENT_DEVELOPMENT',
              displayName: 'Content Development',
              description:
                'Development of ideas and use of supporting details.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_WRITING_WRITING_SKILLS_LANGUAGE_USE',
              displayName: 'Language Use',
              description: 'Grammar, vocabulary, and sentence structure.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_WRITING_WRITING_SKILLS_MECHANICS',
              displayName: 'Mechanics',
              description: 'Spelling, punctuation, and capitalization.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_UPPER_QUANT',
      displayName: 'Quantitative (Math)',
      description:
        'Measures mathematical problem-solving ability appropriate for upper school students, assessing understanding of advanced mathematical concepts and ability to apply mathematical knowledge to solve complex problems.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for math
      categories: [
        {
          categoryId: 'SSAT_UPPER_QUANT_NUMBER_OPERATIONS',
          displayName: 'Number and Operations',
          description:
            'Advanced understanding of numbers and numerical operations.',
          icon: 'remixNumbersLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_NUMBER_OPERATIONS_REAL_NUMBERS',
              displayName: 'Real Numbers',
              description: 'Properties and operations of real numbers.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_NUMBER_OPERATIONS_RATIONAL_IRRATIONAL',
              displayName: 'Rational and Irrational Numbers',
              description:
                'Understanding and operating with rational and irrational numbers.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_NUMBER_OPERATIONS_EXPONENTS_ROOTS',
              displayName: 'Exponents and Roots',
              description:
                'Calculations involving exponents and radicals.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_NUMBER_OPERATIONS_SCIENTIFIC_NOTATION',
              displayName: 'Scientific Notation',
              description:
                'Using and calculating with scientific notation.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_QUANT_ALGEBRA',
          displayName: 'Algebra',
          description: 'Advanced algebraic concepts and techniques.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_ALGEBRA_LINEAR_EQUATIONS',
              displayName: 'Linear Equations and Inequalities',
              description:
                'Solving and graphing linear equations and inequalities.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_ALGEBRA_QUADRATIC_EQUATIONS',
              displayName: 'Quadratic Equations',
              description:
                'Solving quadratic equations using various methods.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_ALGEBRA_POLYNOMIALS',
              displayName: 'Polynomials',
              description:
                'Operations with polynomials, including factoring.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_ALGEBRA_SYSTEMS_EQUATIONS',
              displayName: 'Systems of Equations',
              description:
                'Solving systems of equations algebraically and graphically.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_ALGEBRA_FUNCTIONS',
              displayName: 'Functions',
              description:
                'Understanding and interpreting functions and their graphs.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_ALGEBRA_WORD_PROBLEMS',
              displayName: 'Word Problems',
              description:
                'Translating complex word problems into mathematical expressions and solving.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_QUANT_GEOMETRY',
          displayName: 'Geometry and Measurement',
          description: 'Advanced geometric concepts and measurement.',
          icon: 'remixRulerLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_GEOMETRY_LINES_ANGLES',
              displayName: 'Lines and Angles',
              description:
                'Properties of lines, angles, and their relationships.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_GEOMETRY_TRIANGLES',
              displayName: 'Triangles',
              description:
                'Properties of triangles, including the Pythagorean theorem.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_GEOMETRY_POLYGONS',
              displayName: 'Polygons',
              description:
                'Properties of various polygons and calculation of interior angles.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_GEOMETRY_CIRCLES',
              displayName: 'Circles',
              description:
                'Properties of circles, including arcs, chords, and sectors.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_GEOMETRY_COORDINATE_GEOMETRY',
              displayName: 'Coordinate Geometry',
              description:
                'Graphing and analyzing geometric shapes in the coordinate plane.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_GEOMETRY_SOLID_GEOMETRY',
              displayName: 'Solid Geometry',
              description:
                'Properties and volume/surface area calculations of three-dimensional figures.',
            },
            {
              subcategoryId: 'SSAT_UPPER_QUANT_GEOMETRY_TRIGONOMETRY',
              displayName: 'Trigonometry',
              description:
                'Basic trigonometric ratios and solving right triangles.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_QUANT_DATA_PROBABILITY',
          displayName: 'Data Analysis and Probability',
          description:
            'Advanced data interpretation and probability concepts.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_DATA_PROBABILITY_STATISTICS',
              displayName: 'Statistics',
              description:
                'Understanding mean, median, mode, range, and standard deviation.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_DATA_PROBABILITY_PROBABILITY',
              displayName: 'Probability',
              description:
                'Calculating probabilities of single and combined events.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_DATA_PROBABILITY_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description:
                'Analyzing and drawing conclusions from data sets and graphs.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_QUANT_DATA_PROBABILITY_COUNTING_METHODS',
              displayName: 'Counting Methods',
              description:
                'Understanding permutations, combinations, and the fundamental counting principle.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_UPPER_READING',
      displayName: 'Reading Comprehension',
      description:
        'Measures the ability to understand and interpret complex written passages from various subjects.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading
      categories: [
        {
          categoryId: 'SSAT_UPPER_READING_MAIN_IDEA',
          displayName: 'Main Idea and Theme',
          description:
            'Identifying the central message or theme of the passage.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_MAIN_IDEA_CENTRAL_IDEA',
              displayName: 'Central Idea',
              description:
                'Determining the central idea of the passage.',
            },
            {
              subcategoryId: 'SSAT_UPPER_READING_MAIN_IDEA_THEME',
              displayName: 'Theme',
              description:
                'Identifying overarching themes and messages.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_READING_SUPPORTING_DETAILS',
          displayName: 'Supporting Details',
          description:
            'Recalling specific facts or details from the passage.',
          icon: 'remixListUnordered',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_SUPPORTING_DETAILS_EVIDENCE',
              displayName: 'Evidence',
              description:
                'Identifying evidence that supports arguments or points.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_SUPPORTING_DETAILS_EXAMPLES',
              displayName: 'Examples',
              description:
                'Recognizing examples that illustrate concepts.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_READING_INFERENCE',
          displayName: 'Inference',
          description:
            'Drawing conclusions based on information implied in the passage.',
          icon: 'remixLightbulbLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_INFERENCE_LOGICAL_CONCLUSIONS',
              displayName: 'Logical Conclusions',
              description:
                'Making logical deductions from the text.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_INFERENCE_IMPLICIT_MEANING',
              displayName: 'Implicit Meaning',
              description:
                'Understanding meanings that are not directly stated.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_READING_VOCABULARY_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Determining the meaning of words based on context.',
          icon: 'remixBookletLine',
          subcategories: [],
        },
        {
          categoryId: 'SSAT_UPPER_READING_AUTHORS_PURPOSE',
          displayName: "Author's Purpose and Tone",
          description:
            'Understanding why the author wrote the passage and identifying tone.',
          icon: 'remixUserVoiceLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_AUTHORS_PURPOSE_PURPOSE',
              displayName: "Author's Purpose",
              description:
                'Determining the author’s intent in writing the passage.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_AUTHORS_PURPOSE_TONE',
              displayName: "Author's Tone",
              description:
                'Identifying the author’s attitude or feelings conveyed in the passage.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_READING_FIGURATIVE_LANGUAGE',
          displayName: 'Figurative Language and Literary Devices',
          description:
            'Recognizing and interpreting literary devices and figurative language.',
          icon: 'remixBrushLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_FIGURATIVE_LANGUAGE_METAPHORS_SIMILES',
              displayName: 'Metaphors and Similes',
              description:
                'Understanding comparisons made using metaphors and similes.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_FIGURATIVE_LANGUAGE_SYMBOLISM',
              displayName: 'Symbolism',
              description:
                'Identifying symbols and their meanings within the text.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_FIGURATIVE_LANGUAGE_IRONY',
              displayName: 'Irony',
              description:
                'Recognizing irony and understanding its effect on the passage.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_READING_COMPARATIVE_ANALYSIS',
          displayName: 'Comparative Analysis',
          description:
            'Comparing and contrasting ideas or themes within the passage or between passages.',
          icon: 'remixFunctionLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_COMPARATIVE_ANALYSIS_COMPARE_CONTRAST',
              displayName: 'Compare and Contrast',
              description:
                'Analyzing similarities and differences in concepts or ideas.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_COMPARATIVE_ANALYSIS_CROSS_REFERENCE',
              displayName: 'Cross-Reference',
              description:
                'Connecting information from different parts of the passage.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_READING_CRITICAL_ANALYSIS',
          displayName: 'Critical Analysis',
          description:
            'Evaluating arguments, reasoning, and effectiveness of the passage.',
          icon: 'remixBarChartLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_READING_CRITICAL_ANALYSIS_ARGUMENT_EVALUATION',
              displayName: 'Argument Evaluation',
              description:
                'Assessing the strength and validity of arguments presented.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_READING_CRITICAL_ANALYSIS_LOGICAL_FALLACIES',
              displayName: 'Logical Fallacies',
              description:
                'Identifying errors in reasoning or logical fallacies.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'SSAT_UPPER_VERBAL',
      displayName: 'Verbal',
      description:
        'Measures advanced vocabulary, verbal reasoning, and ability to relate complex ideas logically through synonyms and analogies.',
      icon: 'remixChat3Line', // ng-icons Remix Icon for verbal
      categories: [
        {
          categoryId: 'SSAT_UPPER_VERBAL_SYNONYMS',
          displayName: 'Synonyms',
          description:
            'Testing advanced vocabulary knowledge by identifying words with similar meanings.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_SYNONYMS_ADVANCED_VOCABULARY',
              displayName: 'Advanced Vocabulary',
              description:
                'Challenging words appropriate for upper school students.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_SYNONYMS_CONTEXTUAL_VOCABULARY',
              displayName: 'Contextual Vocabulary',
              description:
                'Determining word meanings based on context.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_SYNONYMS_NUANCED_MEANINGS',
              displayName: 'Nuanced Meanings',
              description:
                'Understanding subtle differences between similar words.',
            },
          ],
        },
        {
          categoryId: 'SSAT_UPPER_VERBAL_ANALOGIES',
          displayName: 'Analogies',
          description:
            'Testing ability to relate complex ideas and concepts logically.',
          icon: 'remixAttachmentLine',
          subcategories: [
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_ANALOGIES_SEMANTIC_RELATIONSHIPS',
              displayName: 'Semantic Relationships',
              description:
                'Understanding relationships based on word meanings.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_ANALOGIES_LOGICAL_RELATIONSHIPS',
              displayName: 'Logical Relationships',
              description:
                'Identifying logical connections between concepts.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_ANALOGIES_CLASSIFICATION_RELATIONSHIPS',
              displayName: 'Classification Relationships',
              description:
                'Understanding hierarchical or categorical relationships.',
            },
            {
              subcategoryId:
                'SSAT_UPPER_VERBAL_ANALOGIES_CAUSE_EFFECT_RELATIONSHIPS',
              displayName: 'Cause and Effect Relationships',
              description:
                'Understanding relationships where one term causes the other.',
            },
          ],
        },
      ],
    },
  ],
};
