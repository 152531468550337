import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export function trimAndMinLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Trim the value
    if (control.value && typeof control.value === 'string') {
      const trimmedValue = control.value.trim();
      if (trimmedValue !== control.value) {
        // Update the form control value with the trimmed value
        control.setValue(trimmedValue);
      }
    }

    // Apply Validators.minLength validation
    const minLengthValidation = Validators.minLength(minLength)(control);
    return minLengthValidation; // Return the validation result
  };
}