<div class="bg-white shadow-md rounded-lg p-4" style="min-width: 900px;">

  <!-- Title for the category performance -->
  <div class="mb-2 flex justify-between items-center">
    <h2 class="text-2xl font-bold text-gray-700">Performance by Sections (Past 3 Months)</h2>
    <!-- Info Icon -->
    <div class="relative group">
      <i class="fa-solid fa-circle-info text-gray-500 cursor-pointer"></i>
      <!-- Tooltip on hover -->
      <div class="absolute right-0 hidden group-hover:block bg-white text-gray-700 text-xs p-2 border border-gray-300 rounded shadow-lg w-64">
        This displays the performance by section for questions answered in the past 3 months.
      </div>
    </div>
  </div>

  <!-- Conditional Content -->
  <div class="relative">
    <!-- Non-Premium Users -->
    <ng-container *ngIf="!isPremium">
      <!-- Blurred Sample Chart -->
      <div class="relative">
        <img
          [src]="this.blurImgSrc"
          alt=""
          class="w-full h-auto object-cover"
          style="filter: blur(2px); opacity: 1.0; max-height: 600px;"
        />
        <!-- Overlay Content with Spotlight Effect -->
        <div
          class="absolute inset-0 flex flex-col items-center justify-center z-10"
          style="background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 30%);"
        >
          <ng-icon
            name="remixLockFill"
            class="text-gray-700 mb-4"
            style="width: 40px; height: 40px;"
          ></ng-icon>
          <p class="text-gray-800 mb-4 font-semibold">Upgrade to Premium to access this feature.</p>
          <button
            (click)="onPremiumPlanClick()"
            class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"
          >
            Upgrade to Premium
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Pro Users -->
    <ng-container *ngIf="isPremium">
      <!-- Loading State -->
      <div
        *ngIf="requestState === RequestState.LOADING"
        class="flex items-center justify-center"
        style="min-height: 16rem;"
      >
        <ng-icon
          name="remixLoader4Line"
          class="animate-spin text-blue-500"
          style="width: 40px; height: 40px;"
        ></ng-icon>
      </div>

      <!-- Success State -->
      <div *ngIf="requestState === RequestState.SUCCESS">

        <!-- Table for displaying section statistics -->
        <table *ngIf="sectionStats.length > 0; else noDataTemplate" class="table-auto w-full">
    <tbody>
    <!-- Loop through sectionStats -->
    <tr *ngFor="let section of sectionStats; let i = index" class="border-b border-gray-100">
      <!-- Left side: Donut Chart and Section Display Name -->
      <td class="px-6 align-middle">
        <div class="flex items-center">
          <div class="w-24 h-24 flex justify-center items-center">
            <!-- Donut Chart Component -->
            <apx-chart
              [series]="[section.numberQuestionsCorrect, section.numberQuestionsIncorrect, section.numberQuestionsSkipped]"
              [chart]="{ type: 'donut', width: 120, height: 120 }"
              [labels]="['Correct', 'Incorrect', 'Skipped']"
              [colors]="['#4CAF50', '#FF5722', '#FFC107']"
              [legend]="{ show: false }"
              [title]="{ text: '' }"
              [dataLabels]="{ enabled: false }"
              [tooltip]="{ enabled: false }"
              [states]="{ hover: { filter: { type: 'none' } } }">
            </apx-chart>
          </div>
          <!-- Section Info -->
          <div class="ml-4">
            <p class="text-lg font-bold break-words leading-tight">{{ section.cohortStatsLabel.cohortDisplayName }}</p>
          </div>
        </div>
      </td>

      <!-- Right side: Statistics and Recent Questions -->
      <td class="py-4">
        <table class="w-full">
          <!-- First row: Statistics -->
          <tr>
            <!-- Num Questions Correct -->
            <td class="py-4 px-2 text-center border-r border-gray-200">
              <p class="text-sm font-semibold text-gray-500 break-words">Questions Correct</p>
              <p class="text-lg font-bold text-green-500 mt-1">{{ section.numberQuestionsCorrect | number:'1.0-0' }}</p>
            </td>

            <!-- Num Questions Incorrect -->
            <td class="py-4 px-2 text-center border-r border-gray-200">
              <p class="text-sm font-semibold text-gray-500 break-words">Questions Incorrect</p>
              <p class="text-lg font-bold text-red-500 mt-1">{{ section.numberQuestionsIncorrect | number:'1.0-0' }}</p>
            </td>

            <!-- Num Questions Skipped -->
            <td class="py-4 px-2 text-center border-r border-gray-200">
              <p class="text-sm font-semibold text-gray-500 break-words">Questions Skipped</p>
              <p class="text-lg font-bold text-yellow-500 mt-1">{{ section.numberQuestionsSkipped | number:'1.0-0' }}</p>
            </td>

            <!-- Average Time Spent -->
            <td class="py-4 px-2 text-center border-r border-gray-200">
              <p class="text-sm font-semibold text-gray-500 break-words">Average Time Spent</p>
              <p class="text-lg font-bold text-orange-500 mt-1">
                {{ ((section?.averageTimeMillis ?? 0) / 1000) | number:'1.0-1' }} s
              </p>
            </td>

            <!-- Average Difficulty -->
            <td class="py-4 px-2 text-center border-r border-gray-200">
              <p class="text-sm font-semibold text-gray-500 break-words">Average Difficulty</p>
              <p class="text-lg font-bold text-indigo-500 mt-1">{{ section.averageDifficulty | number:'1.1-1' }}</p>
            </td>

            <!-- Percent Correct -->
            <td class="py-4 px-2 text-center">
              <p class="text-sm font-semibold text-gray-500 break-words">Percent Correct</p>
              <p class="text-lg font-bold text-blue-500 mt-1">{{ 100. * section.percentCorrect | number:'1.1-1' }}%</p>
            </td>
          </tr>

          <!-- Second row: Recent Questions (hidden on small screens) -->
          <tr class="hidden md:table-row">
            <td colspan="6" class="py-2">
              <!-- Remove "Recent Questions" label to save space -->
              <div class="flex space-x-2">
                <!-- Loop through the userQuestionInteractionResponses array of the current section -->
                <div *ngFor="let uqi of section.userQuestionInteractionResponses.slice(0, maxInteractionLimit); let i = index" class="relative group">
                  <a [href]="'/question/' + uqi.questionSummaryResponse?.questionId"
                     class="inline-block w-6 h-6 border border-gray-300 rounded flex justify-center items-center"
                     title="{{ uqi.questionSummaryResponse?.title }}">
                    <!-- Show Font Awesome icons based on the question result -->
                    <ng-container [ngSwitch]="true">
                      <!-- Correct: Use regular check icon -->
                      <i *ngSwitchCase="uqi.correct" class="fa-solid fa-check text-green-500"></i>

                      <!-- Incorrect: Use regular xmark icon -->
                      <i *ngSwitchCase="!uqi.correct && !uqi.skipped" class="fa-solid fa-xmark text-red-500"></i>

                      <!-- Skipped: Use regular circle icon -->
                      <i *ngSwitchCase="uqi.skipped && !uqi.correct" class="fa-regular fa-circle text-yellow-500"></i>
                    </ng-container>
                  </a>
                  <!-- Tooltip on hover -->
                  <div class="absolute hidden group-hover:flex flex-col items-start p-2 bg-white shadow-lg border border-gray-300 rounded text-xs text-gray-700">
                    <!-- Display question status: Correct, Incorrect, or Skipped -->
                    <ng-container [ngSwitch]="true">
                      <p *ngSwitchCase="uqi.correct"><strong>Status:</strong> Correct</p>
                      <p *ngSwitchCase="!uqi.correct && !uqi.skipped"><strong>Status:</strong> Incorrect</p>
                      <p *ngSwitchCase="uqi.skipped && !uqi.correct"><strong>Status:</strong> Skipped</p>
                    </ng-container>
                    <p><strong>Title:</strong> {{ uqi.questionSummaryResponse?.title }}</p>
                    <p><strong>Difficulty:</strong> {{ uqi.questionSummaryResponse?.difficultyLevelResponse?.difficultyDisplayName }}</p>
                  </div>
                </div>

                <!-- Fill remaining with placeholders up to 10 items -->
                <ng-container *ngIf="section.userQuestionInteractionResponses.length < maxInteractionLimit">
                  <ng-container *ngFor="let empty of [].constructor(Math.max(0, maxInteractionLimit - section.userQuestionInteractionResponses.length))">
                    <div class="inline-block w-6 h-6 border border-gray-300 rounded flex justify-center items-center">
                      <span class="text-gray-500">-</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    </tbody>
  </table>
      </div>

      <div
        *ngIf="requestState === RequestState.ERROR"
        class="flex flex-col items-center justify-center"
        style="min-height: 16rem;"
      >
        <ng-icon
          name="remixErrorWarningLine"
          class="text-red-500 mb-4"
          style="width: 64px; height: 64px;"
        ></ng-icon>
        <p class="text-red-600">An error occurred while loading the data.</p>
      </div>

      <!-- No Data Template -->
      <ng-template #noDataTemplate>
        <div class="text-center py-8">
          <p class="text-gray-500">No section data available.</p>
        </div>
      </ng-template>
    </ng-container>

  </div>
</div>
