export function joinWithCommaAndOr(items: string[]): string {
  if (items.length === 0) {
    return '';
  }

  if (items.length === 1) {
    return items[0];
  }

  const allExceptLast = items.slice(0, -1).join(', ');
  const lastItem = items[items.length - 1];

  return `${allExceptLast} or ${lastItem}`;
}

export function formatToDollars(amount: number): string {
  return amount % 1 === 0
    ? `$${amount}`
    : `$${amount.toFixed(2)}`;
}
