import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {formatToDollars} from "../../shared/util/string-utils";
import {PlanService} from "../../services/plan.service";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.css'
})
export class PricingComponent implements OnInit {

  constructor(private route: ActivatedRoute, private metaService: MetaService,
              private router: Router, private planService: PlanService) {}


  ngOnInit() {
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);
  }

  navigateToExams() {
    this.router.navigate(['/exams'], {fragment: 'exams-list'});
  }

  onFreePlanClick() {
    this.navigateToExams()
  }

  onPremiumPlanClick() {
    this.navigateToExams()
  }

  getPriceDisplay() {
    const priceUnitAmount = 999;
    const currency = 'USD';
    if (!priceUnitAmount || !currency) {
      return "";
    }

    if (currency == 'USD') {
      return formatToDollars(priceUnitAmount / 100.);
    }
    return "";
  }
}
