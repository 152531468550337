  <section class="py-20 bg-transparent overflow-hidden">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap items-center -m-8">
        <div class="w-full md:w-2/5 p-4">
          <h2 class="mb-9 text-6xl md:text-5xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">{{examData?.marketingTitle}}</h2>
          <p class="mb-10 text-lg text-gray-900 font-medium leading-relaxed md:max-w-md">{{examData?.marketingSubtitles?.[0]}}</p>
          <div class="mb-11 md:inline-block rounded-xl shadow-4xl">
            <button (click)="onPracticeClick()" class="py-4 px-6 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="button">Try Practice Questions</button>
          </div>

          <div class="flex flex-wrap -m-2">
            <ul class="md:max-w-lg">
              <li *ngIf="examData?.marketingSubtitles?.[1]" class="mb-5 flex items-start justify-between">
                <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2 self-center" style="width: 25px; height: 26px;">
                <span class="flex-1 font-medium leading-relaxed text-justify">{{ examData?.marketingSubtitles?.[1] }}</span>
              </li>
              <li *ngIf="examData?.marketingSubtitles?.[2]" class="mb-5 flex items-start justify-between">
                <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2 self-center" style="width: 25px; height: 26px;">
                <span class="flex-1 font-medium leading-relaxed text-justify">{{ examData?.marketingSubtitles?.[2] }}</span>
              </li>
              <li *ngIf="examData?.marketingSubtitles?.[3]" class="mb-5 flex items-start justify-between">
                <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2 self-center" style="width: 25px; height: 26px;">
                <span class="flex-1 font-medium leading-relaxed text-justify">{{ examData?.marketingSubtitles?.[3] }}</span>
              </li>
            </ul>
          </div>

        </div>
        <div class="w-full md:w-3/5 p-0">
          <img class="transform hover:-translate-x-4 transition ease-in-out duration-1000" src="assets/images/exams/laptop.png" alt="">
        </div>
      </div>
    </div>
  </section>

  <!-- Pricing -->
  <section class="relative py-20 bg-transparent overflow-hidden">
    <img class="absolute right-0 bottom-0" src="assets/images/gradient6.svg" alt="">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -m-8">
        <!-- Left Side: Title and Subtitle -->
        <div class="w-full md:w-2/5 p-8">
          <div class="flex flex-col justify-between h-full md:max-w-md">
            <div>
              <h2 class="mb-8 text-6xl md:text-6xl xl:text-6xl font-bold font-heading tracking-px-n leading-none">{{examData?.pricingTitle}}</h2>
              <p class="mb-16 font-medium text-gray-600 leading-relaxed">{{examData?.pricingSubtitle?.[0]}}</p>
            </div>
            <div>
              <h3 class="mb-3 text-gray-900 font-semibold">Trusted by 20,000+ students</h3>
              <div class="flex flex-wrap items-center -m-1">
                <div class="w-auto p-1">
                  <div class="flex flex-wrap -m-0.5">
                    <div class="w-auto p-0.5 text-yellow-400">
                      <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5 text-yellow-400">
                      <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5 text-yellow-400">
                      <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5 text-yellow-400">
                      <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5 text-yellow-400">
                      <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="w-auto p-1">
                  <div class="flex flex-wrap -m-0.5">
                    <div class="w-auto p-0.5">
                      <p class="text-gray-900 font-bold">4.6/5.0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Side: Pricing Plans -->
        <div class="w-full md:w-3/5 p-8">
          <div class="flex flex-wrap -m-3">
            <!-- Free Plan -->
            <div class="w-full xl:w-1/2 p-3">
              <div class="px-8 py-7 h-full bg-white bg-opacity-80 border border-blueGray-200 rounded-xl" style="backdrop-filter: blur(46px);">
                <span class="mb-2 inline-block font-medium leading-relaxed">Free</span>

                <div class="my-4">
                  <h2 class="font-semibold text-2xl">{{examData?.displayName}} Exam Free Access</h2>
                </div>

                <h3 class="mb-3 text-4xl md:text-4xl font-bold font-heading tracking-px-n leading-tight">$0</h3>
                <p class="mb-12 text-gray-600 font-medium leading-relaxed">Start practicing with no commitment by creating a free account. </p>
                <button (click)="onFreePlanClick()" class="mb-9 py-4 px-9 w-full font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-white hover:bg-gray-50 transition ease-in-out duration-200" type="button">Get Started</button>
                <h3 class="mb-5 font-semibold leading-normal">Features:</h3>
                <ul>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Up to 3 practice questions per day</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Access to free questions tier</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Basic question selection</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Premium Plan -->
            <div class="w-full xl:w-1/2 p-3">
              <div class="relative px-8 py-7 h-full bg-white bg-opacity-90 border border-blueGray-600 rounded-xl shadow-9xl" style="backdrop-filter: blur(46px);">
                <img class="absolute -top-11 left-[100px]" src="assets/images/popular.png" alt="Popular">
<!--                <img class="absolute bottom-0 left-1/2 transform -translate-x-1/2" src="assets/images/gradient3.svg" alt="">-->
                <div class="relative z-10">
                  <span class="mb-2 inline-block font-medium leading-relaxed">Premium</span>
                  <div class="my-4">
                    <h2 class="font-semibold text-2xl">{{examData?.displayName}} Exam Subscription</h2>
                  </div>
                  <h3 class="mb-3 text-3xl md:text-4xl font-bold font-heading tracking-px-n leading-tight">
                    {{ getPriceDisplay()}}<span class="text-lg font-medium"> / month</span>
                  </h3>
                  <p class="mb-12 text-gray-600 font-medium leading-relaxed">Unlock all features and maximize your score. Cancel anytime.</p>
                  <button (click)="onPremiumPlanClick()" class="mb-9 py-4 px-9 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="button">Get Started</button>
                  <h3 class="mb-5 font-semibold leading-normal">Features:</h3>
                  <ul>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">Unlimited practice questions</p>
                    </li>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">Access to premium questions tier</p>
                    </li>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">Dashboard with detailed analytics</p>
                    </li>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">Personalized score improvement tips</p>
                    </li>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">Identify strengths & weaknesses</p>
                    </li>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">Adaptive question selection algorithm</p>
                    </li>
                    <li class="mb-1.5 text-gray-600 flex items-center">
                      <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <p class="font-semibold">AI-powered question explanations</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Exam Sections -->
  <section class="relative py-16 bg-gray-50">
    <div class="container mx-auto px-4">
      <h2 class="text-5xl font-bold mb-12 text-center text-custom-blue-800">Master All Sections of the {{ examData?.displayName }} Exam</h2>
      <p class="text-xl text-center text-gray-600 mb-12">
        Access comprehensive practice materials for the most popular exams.
      </p>

      <div class="flex flex-wrap justify-center -mx-4">
        <!-- Loop through each exam section -->
        <div *ngFor="let section of examData?.sections; let i = index"
             [ngClass]="{
             'w-full md:w-1/3 lg:w-1/3': examData?.sections?.length ?? 0 <= 3,
             'w-full md:w-1/2 lg:w-1/2': examData?.sections?.length ?? 0 > 3
           }"
             class="px-4 mb-8">
          <div class="bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
            <div class="p-6 flex flex-col items-center justify-center h-full">
              <div class="flex items-center mb-4">
                <!-- Icon for the section -->
                <ng-icon [name]="section.icon" class="text-6xl text-custom-blue-500 mr-4"></ng-icon>
              </div>
              <h3 class="text-2xl font-semibold text-gray-800 text-center">{{ section.displayName }}</h3>
              <p class="text-gray-700 mt-4 text-justify">
                {{ section.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Call to Action -->
      <div class="text-center mt-12">
        <div class="mb-5 md:inline-block rounded-xl shadow-4xl">
          <button (click)="onFreePlanClick()" class="py-4 px-6 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="button">Get Started Today</button>
        </div>
      </div>
    </div>
  </section>

  <!-- Categories and Subcategories -->
  <section class="relative py-16">
    <img class="absolute right-0 top-0" src="assets/images/home/gradient4-top.svg" alt="">
    <div class="container mx-auto px-4">
      <h2 class="text-5xl font-bold mb-12 text-center text-custom-blue-600">Dive Deeper into Exam Categories</h2>

      <!-- Loop through each exam section -->
      <div *ngFor="let section of examData?.sections" class="mb-16">
        <h3 class="text-3xl font-bold mb-8 text-gray-800 text-center">{{ section.displayName }} Categories</h3>
        <div class="flex flex-wrap justify-center -mx-4">
          <!-- Loop through categories -->
          <div *ngFor="let category of section.categories" class="w-full md:w-1/2 px-4 mb-8">
            <div class="border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 h-full">

              <div class="px-6 pt-6 pb-4">
                <div class="flex items-center mb-4">
                  <!-- Icon for Category -->
                  <ng-icon [name]="category.icon" class="text-4xl text-custom-blue-500 mr-2"></ng-icon>
                  <span class="pl-5 text-2xl font-semibold text-gray-800">{{ category.displayName }}</span>
                </div>
                <p class="text-gray-600 mb-6">
                  {{ category.description }}
                </p>
                <!-- Subcategories -->
                <h4 class="text-lg font-semibold mb-2 text-gray-800">Subcategories:</h4>
                <ul class="list-disc list-inside space-y-1 mb-1">
                  <li *ngFor="let subcategory of category.subcategories">
                    <strong class="text-gray-800">{{ subcategory.displayName }}:</strong> {{ subcategory.description }}
                  </li>
                </ul>
              </div>

              <!-- Sample Questions -->
              <div class="px-6 py-4 bg-white">
                <h4 class="text-lg font-semibold mb-2 text-gray-800">Try an Example Question:</h4>
                <ul class="space-y-2">
                  <li *ngFor="let question of category.sampleQuestions">
                    <!-- Question Link wraps around the entire content -->
                    <a [routerLink]="['/question', question.questionId]" class="flex items-center text-gray-800 hover:text-custom-blue-600">
                      <ng-icon name="remixArrowRightCircleFill" class="text-custom-blue-500 text-xl mr-2"></ng-icon>
                      <span>{{ question.title }}</span>
                      <!-- Difficulty Chip -->
                      <span class="bg-gray-100 text-gray-700 px-2 py-1 ml-2 rounded-full text-sm"
                            [ngClass]="getDifficultyCss(question.numericDifficultyLevel, question.maxDifficultyLevel, question.minDifficultyLevel)">
                        {{ question.difficultyDisplayName | titlecase }}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Monthly Updates Note -->
      <div class="text-center mt-12">
        <p class="text-xl text-gray-700 font-semibold">
          And more! Questions and categories are updated monthly to keep you ahead.
        </p>
      </div>
      <!-- Call to Action -->
      <div class="text-center mt-12">
        <div class="mb-5 md:inline-block rounded-xl shadow-4xl">
          <button (click)="onFreePlanClick()" class="py-4 px-6 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="button">Join Now and Start Practicing</button>
        </div>
      </div>
    </div>
  </section>

