// CFA Level 1 Exam Data
import { ExamData } from "../exam-data-interface";
import {remixTreeLine} from "@ng-icons/remixicon";

export const cfaLevel1ExamData: ExamData = {
  examId: 'CFA_LEVEL1_2025',
  displayName: 'CFA Level 1',
  description:
    'The CFA Level 1 exam is the first of three exams offered by the CFA Institute to investment and finance professionals. The exam tests knowledge in a wide range of financial and investment topics.',
  logo: 'assets/images/exams/cfa_1_logo.png',
  link: 'cfa-level1',
  zoom: 1.1,
  priceId: 'CFA_LEVEL1_2025_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel on the CFA Level 1 with Comprehensive Preparation',
  marketingSubtitles: [
    'Tailored practice to enhance your CFA Level 1 performance.',
    'Extensive question bank covering all CFA Level 1 topics.',
    'Adaptive learning to focus on areas needing improvement.',
    'Build confidence with effective practice and detailed feedback.',
  ],
  pricingTitle: 'Premium CFA Level 1 Prep at a Competitive Price',
  pricingSubtitle: [
    'Achieve your finance career goals with comprehensive support and resources.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'CFA_LEVEL1_ETHICS',
      displayName: 'Ethical and Professional Standards',
      description:
        'This section covers the CFA Institute Code of Ethics and Standards of Professional Conduct, including the Global Investment Performance Standards (GIPS).',
      icon: 'remixEqualizerLine', // Remix Icon for ethics
      categories: [
        {
          categoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS',
          displayName: 'CFA Institute Code and Standards',
          description: 'Understanding ethical principles and standards.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_PROFESSIONALISM_LAW_KNOWLEDGE',
              displayName: 'Professionalism - Law Knowledge',
              description: 'Understanding applicable laws and rules.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_PROFESSIONALISM_INDEPENDENCE',
              displayName: 'Professionalism - Independence',
              description: 'Maintaining independence and objectivity.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_PROFESSIONALISM_MISREPRESENTATION',
              displayName: 'Professionalism - Misrepresentation',
              description: 'Avoiding service misrepresentation.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_PROFESSIONALISM_MISCONDUCT',
              displayName: 'Professionalism - Misconduct',
              description: 'Refraining from misconduct.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_INTEGRITY_NONPUBLIC_INFO',
              displayName: 'Integrity - Nonpublic Info',
              description: 'Not acting on nonpublic information.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_INTEGRITY_MARKET_MANIPULATION',
              displayName: 'Integrity - Market Manipulation',
              description: 'Avoiding market manipulation practices.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_DUTIES_LOYALTY_AND_CARE',
              displayName: 'Duties - Loyalty and Care',
              description: "Acting in clients' best interests.",
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_DUTIES_FAIR_DEALING',
              displayName: 'Duties - Fair Dealing',
              description: 'Dealing fairly with all clients.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_DUTIES_SUITABILITY',
              displayName: 'Duties - Suitability',
              description: 'Making suitable recommendations.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_DUTIES_PERFORMANCE_PRESENTATION',
              displayName: 'Duties - Performance Presentation',
              description: 'Fair and accurate performance info.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_DUTIES_CONFIDENTIALITY',
              displayName: 'Duties - Confidentiality',
              description: 'Preserving client confidentiality.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_EMPLOYER_DUTIES_LOYALTY',
              displayName: "Employer Duties - Loyalty",
              description: "Acting in employer's best interests.",
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_EMPLOYER_DUTIES_COMPENSATION',
              displayName: 'Employer Duties - Compensation',
              description: 'Avoiding conflicts from compensation.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_EMPLOYER_DUTIES_SUPERVISION',
              displayName: 'Employer Duties - Supervision',
              description: 'Ensuring compliance by subordinates.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_INVESTMENT_ANALYSIS_DILIGENCE',
              displayName: 'Investment Analysis - Diligence',
              description: 'Diligent investment recommendations.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_INVESTMENT_ANALYSIS_COMMUNICATION',
              displayName: 'Investment Analysis - Communication',
              description: 'Disclosing investment processes.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_INVESTMENT_ANALYSIS_RECORDS',
              displayName: 'Investment Analysis - Records',
              description: 'Maintaining proper records.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_CONFLICTS_DISCLOSURE',
              displayName: 'Conflicts - Disclosure',
              description: 'Disclosing conflicts of interest.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_CONFLICTS_PRIORITY_OF_TRANSACTIONS',
              displayName: 'Conflicts - Priority of Transactions',
              description: 'Prioritizing client transactions.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_CONFLICTS_REFERRAL_FEES',
              displayName: 'Conflicts - Referral Fees',
              description: 'Disclosing referral fees.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_RESPONSIBILITIES_CONDUCT',
              displayName: 'Responsibilities - Conduct',
              description: 'Upholding CFA Institute reputation.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_CODE_OF_ETHICS_RESPONSIBILITIES_REFERENCE_TO_CFA',
              displayName: 'Responsibilities - Reference to CFA',
              description: 'Proper use of CFA designation.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_ETHICS_GIPS',
          displayName: 'Global Investment Performance Standards',
          description: 'Understanding GIPS principles and objectives.',
          icon: 'remixGlobalLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_GIPS_OVERVIEW_OF_GIPS',
              displayName: 'Overview of GIPS',
              description: 'Introduction to GIPS purpose and scope.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_GIPS_KEY_CONCEPTS',
              displayName: 'Key Concepts',
              description: 'Composite construction and reporting.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_GIPS_COMPLIANCE',
              displayName: 'Compliance',
              description: 'Requirements for GIPS compliance.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ETHICS_GIPS_VERIFICATION',
              displayName: 'Verification',
              description: 'Process and benefits of verification.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_QUANT',
      displayName: 'Quantitative Methods',
      description:
        'This section covers quantitative techniques essential for financial analysis, including time value of money, statistical concepts, probability theory, and hypothesis testing.',
      icon: 'remixCalculatorLine', // Remix Icon for quantitative methods
      categories: [
        {
          categoryId: 'CFA_LEVEL1_QUANT_TIME_VALUE_MONEY',
          displayName: 'Time Value of Money',
          description: 'Principles of time value of money.',
          icon: 'remixTimeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_TIME_VALUE_MONEY_PRESENT_AND_FUTURE_VALUE',
              displayName: 'Present and Future Value',
              description: 'Calculating present and future values.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_TIME_VALUE_MONEY_ANNUITIES_AND_PERPETUITIES',
              displayName: 'Annuities and Perpetuities',
              description: 'Valuing annuities and perpetuities.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_TIME_VALUE_MONEY_UNEVEN_CASH_FLOWS',
              displayName: 'Uneven Cash Flows',
              description: 'Valuing uneven cash flow streams.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_TIME_VALUE_MONEY_COMPOUNDING_FREQUENCIES',
              displayName: 'Compounding Frequencies',
              description: 'Impact of compounding periods.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_QUANT_STATISTICAL_CONCEPTS',
          displayName: 'Statistical Concepts and Returns',
          description: 'Statistical concepts for investment analysis.',
          icon: 'remixBarChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_STATISTICAL_CONCEPTS_MEASURES_OF_CENTRAL_TENDENCY',
              displayName: 'Measures of Central Tendency',
              description: 'Mean, median, and mode.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_STATISTICAL_CONCEPTS_MEASURES_OF_DISPERSION',
              displayName: 'Measures of Dispersion',
              description: 'Range, variance, standard deviation.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_STATISTICAL_CONCEPTS_SKEWNESS_AND_KURTOSIS',
              displayName: 'Skewness and Kurtosis',
              description: 'Data distribution shapes.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_STATISTICAL_CONCEPTS_CORRELATION_AND_COVARIANCE',
              displayName: 'Correlation and Covariance',
              description: 'Relationship between variables.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_QUANT_PROBABILITY_CONCEPTS',
          displayName: 'Probability Concepts',
          description: 'Probability theory fundamentals.',
          icon: 'remixPercentLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_PROBABILITY_CONCEPTS_BASIC_PROBABILITY_PRINCIPLES',
              displayName: 'Basic Probability Principles',
              description: 'Definitions and rules.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_PROBABILITY_CONCEPTS_CONDITIONAL_PROBABILITY',
              displayName: 'Conditional Probability',
              description: 'Probabilities with conditions.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_PROBABILITY_CONCEPTS_BAYES_THEOREM',
              displayName: 'Bayes Theorem',
              description: 'Updating probabilities with info.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_PROBABILITY_CONCEPTS_EXPECTED_VALUE_AND_VARIANCE',
              displayName: 'Expected Value and Variance',
              description: 'Calculating returns and risk.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_QUANT_HYPOTHESIS_TESTING',
          displayName: 'Hypothesis Testing',
          description: 'Statistical inference methods.',
          icon: 'remixTestTubeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_HYPOTHESIS_TESTING_NULL_AND_ALTERNATIVE_HYPOTHESES',
              displayName: 'Null and Alternative Hypotheses',
              description: 'Formulating hypotheses.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_HYPOTHESIS_TESTING_TYPE_ONE_AND_TWO_ERRORS',
              displayName: 'Type I and II Errors',
              description: 'Understanding testing errors.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_HYPOTHESIS_TESTING_TEST_STATISTICS',
              displayName: 'Test Statistics',
              description: 'Interpreting z and t statistics.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_QUANT_HYPOTHESIS_TESTING_P_VALUES_AND_SIGNIFICANCE',
              displayName: 'P-values and Significance',
              description: 'Determining significance.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_ECON',
      displayName: 'Economics',
      description:
        'This section covers microeconomics, macroeconomics, international economics, and their applications in financial analysis.',
      icon: 'remixLineChartLine', // Remix Icon for economics
      categories: [
        {
          categoryId: 'CFA_LEVEL1_ECON_MICROECONOMICS',
          displayName: 'Microeconomics',
          description: 'Study of individual markets.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MICROECONOMICS_DEMAND_AND_SUPPLY_ANALYSIS',
              displayName: 'Demand and Supply Analysis',
              description: 'Market equilibrium and shifts.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MICROECONOMICS_ELASTICITY',
              displayName: 'Elasticity',
              description: 'Responsiveness of demand or supply.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MICROECONOMICS_MARKET_STRUCTURES',
              displayName: 'Market Structures',
              description: 'Types of market competition.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MICROECONOMICS_COST_OF_PRODUCTION',
              displayName: 'Cost of Production',
              description: 'Production costs analysis.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_ECON_MACROECONOMICS',
          displayName: 'Macroeconomics',
          description: 'Study of the economy as a whole.',
          icon: 'remixGlobalLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MACROECONOMICS_GDP_AND_GROWTH',
              displayName: 'GDP and Growth',
              description: 'National income measurement.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MACROECONOMICS_BUSINESS_CYCLES',
              displayName: 'Business Cycles',
              description: 'Economic expansion and contraction.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MACROECONOMICS_UNEMPLOYMENT_AND_INFLATION',
              displayName: 'Unemployment and Inflation',
              description: 'Labor markets and price levels.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_MACROECONOMICS_MONETARY_AND_FISCAL_POLICY',
              displayName: 'Monetary and Fiscal Policy',
              description: 'Government economic policies.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_ECON_INTERNATIONAL_ECONOMICS',
          displayName: 'International Economics',
          description: 'Trade theories and exchange rates.',
          icon: 'remixExchangeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_ECON_INTERNATIONAL_ECONOMICS_INTERNATIONAL_TRADE',
              displayName: 'International Trade',
              description: 'Benefits and theories of trade.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_INTERNATIONAL_ECONOMICS_EXCHANGE_RATES',
              displayName: 'Exchange Rates',
              description: 'Currency exchange rate effects.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_INTERNATIONAL_ECONOMICS_BALANCE_OF_PAYMENTS',
              displayName: 'Balance of Payments',
              description: 'Economic transactions recording.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_ECON_INTERNATIONAL_ECONOMICS_TRADE_RESTRICTIONS',
              displayName: 'Trade Restrictions',
              description: 'Tariffs and trade agreements.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_FINREP',
      displayName: 'Financial Reporting and Analysis',
      description:
        'This section covers financial reporting procedures and standards, financial statement analysis, and the application of various accounting techniques.',
      icon: 'remixFileTextLine', // Remix Icon for financial reporting
      categories: [
        {
          categoryId: 'CFA_LEVEL1_FINREP_FINANCIAL_STATEMENTS',
          displayName: 'Financial Statements',
          description: 'Understanding key financial statements.',
          icon: 'remixFileListLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_FINANCIAL_STATEMENTS_BALANCE_SHEET',
              displayName: 'Balance Sheet',
              description: 'Assets, liabilities, equity.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_FINANCIAL_STATEMENTS_INCOME_STATEMENT',
              displayName: 'Income Statement',
              description: 'Revenue and expenses.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_FINANCIAL_STATEMENTS_CASH_FLOW_STATEMENT',
              displayName: 'Cash Flow Statement',
              description: 'Cash inflows and outflows.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_FINANCIAL_STATEMENTS_STATEMENT_OF_CHANGES_IN_EQUITY',
              displayName: 'Statement of Changes in Equity',
              description: 'Equity changes over time.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_FINREP_REPORTING_STANDARDS',
          displayName: 'Financial Reporting Standards',
          description: 'Understanding IFRS and GAAP.',
          icon: 'remixCodeSSlashLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_REPORTING_STANDARDS_IFRS_FRAMEWORK',
              displayName: 'IFRS Framework',
              description: 'International standards overview.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_REPORTING_STANDARDS_GAAP_FRAMEWORK',
              displayName: 'GAAP Framework',
              description: 'US standards overview.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_REPORTING_STANDARDS_COMPARISON_OF_IFRS_AND_GAAP',
              displayName: 'Comparison of IFRS and GAAP',
              description: 'Key differences and impacts.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_REPORTING_STANDARDS_REPORTING_QUALITY',
              displayName: 'Reporting Quality',
              description: 'Assessing quality of reports.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL1_FINREP_ANALYSIS_TECHNIQUES',
          displayName: 'Analysis Techniques',
          description: 'Techniques for financial analysis.',
          icon: 'remixSearchLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_ANALYSIS_TECHNIQUES_RATIO_ANALYSIS',
              displayName: 'Ratio Analysis',
              description: 'Liquidity, solvency, profitability ratios.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_ANALYSIS_TECHNIQUES_COMMON_SIZE_STATEMENTS',
              displayName: 'Common-Size Statements',
              description: 'Standardizing financial statements.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_ANALYSIS_TECHNIQUES_TREND_ANALYSIS',
              displayName: 'Trend Analysis',
              description: 'Analyzing financial trends over time.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_FINREP_ANALYSIS_TECHNIQUES_DUPONT_ANALYSIS',
              displayName: 'DuPont Analysis',
              description: 'Breaking down return on equity.',
            },
          ],
        },
      ],
    },
    // Continue adding other sections in a similar format
    // For brevity, I will include the section titles and descriptions for the remaining sections

    {
      examSectionId: 'CFA_LEVEL1_CORPFIN',
      displayName: 'Corporate Finance',
      description:
        'This section covers corporate governance, capital budgeting, cost of capital, measures of leverage, and working capital management.',
      icon: 'remixBuildingLine', // Remix Icon for corporate finance
      categories: [
        {
          categoryId: 'CFA_LEVEL1_CORPFIN_CAPITAL_BUDGETING',
          displayName: 'Capital Budgeting',
          description: 'Evaluating investment projects.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_CORPFIN_CAPITAL_BUDGETING_INVESTMENT_DECISION_CRITERIA',
              displayName: 'Investment Decision Criteria',
              description: 'NPV, IRR, Payback Period.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_CORPFIN_CAPITAL_BUDGETING_CASH_FLOW_ESTIMATION',
              displayName: 'Cash Flow Estimation',
              description: 'Estimating project cash flows.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_CORPFIN_CAPITAL_BUDGETING_PROJECT_ANALYSIS',
              displayName: 'Project Analysis',
              description: 'Evaluating project risks.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_CORPFIN_CAPITAL_BUDGETING_CAPITAL_RATIONING',
              displayName: 'Capital Rationing',
              description: 'Allocating limited capital.',
            },
          ],
        },
        // Continue with other categories and subcategories
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_EQUITY',
      displayName: 'Equity Investments',
      description:
        'This section covers types of equity securities, stock markets, and valuation techniques for equities.',
      icon: 'remixStockLine', // Remix Icon for equity investments
      categories: [
        {
          categoryId: 'CFA_LEVEL1_EQUITY_EQUITY_MARKETS',
          displayName: 'Equity Markets and Instruments',
          description: 'Understanding equity securities and markets.',
          icon: 'remixExchangeFundsLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_EQUITY_EQUITY_MARKETS_COMMON_SHARES',
              displayName: 'Common Shares',
              description: 'Characteristics of common stock.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_EQUITY_EQUITY_MARKETS_PREFERRED_SHARES',
              displayName: 'Preferred Shares',
              description: 'Features of preferred stock.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_EQUITY_EQUITY_MARKETS_PRIVATE_EQUITY',
              displayName: 'Private Equity',
              description: 'Investing in private companies.',
            },
            {
              subcategoryId: 'CFA_LEVEL1_EQUITY_EQUITY_MARKETS_STOCK_EXCHANGES',
              displayName: 'Stock Exchanges',
              description: 'Functions of stock markets.',
            },
          ],
        },
        // Continue with other categories and subcategories
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_FIXED',
      displayName: 'Fixed Income',
      description:
        'This section covers fixed income securities, their markets, valuation, and analysis.',
      icon: 'remixMoneyDollarBoxLine', // Remix Icon for fixed income
      categories: [
        {
          categoryId: 'CFA_LEVEL1_FIXED_SECURITIES',
          displayName: 'Fixed Income Securities',
          description: 'Understanding bonds and debt instruments.',
          icon: 'remixBankLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_FIXED_SECURITIES_TYPES_OF_BONDS',
              displayName: 'Types of Bonds',
              description: 'Government, corporate, municipal bonds.',
            },
            // Continue with other subcategories
          ],
        },
        // Continue with other categories and subcategories
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_DERIV',
      displayName: 'Derivatives',
      description:
        'This section covers derivative instruments, including forwards, futures, options, and swaps, and their use in risk management.',
      icon: 'remixFunctionsLine', // Remix Icon for derivatives
      categories: [
        {
          categoryId: 'CFA_LEVEL1_DERIV_PRICING_VALUATION',
          displayName: 'Derivative Pricing and Valuation',
          description: 'Understanding pricing and valuation of derivatives.',
          icon: 'remixPriceTagLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_DERIV_PRICING_VALUATION_FORWARD_CONTRACTS',
              displayName: 'Forward Contracts',
              description: 'Pricing and valuation of forwards.',
            },
            // Continue with other subcategories
          ],
        },
        // Continue with other categories and subcategories
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_ALTINV',
      displayName: 'Alternative Investments',
      description:
        'This section covers alternative investment types, including real estate, hedge funds, private equity, commodities, and infrastructure.',
      icon: 'remixCommunityLine', // Remix Icon for alternative investments
      categories: [
        {
          categoryId: 'CFA_LEVEL1_ALTINV_TYPES_CHARACTERISTICS',
          displayName: 'Types of Alt Investments',
          description: 'Understanding different alternative investments.',
          icon: 'remixFolderChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_ALTINV_TYPES_CHARACTERISTICS_REAL_ESTATE',
              displayName: 'Real Estate',
              description: 'Investing in property assets.',
            },
            // Continue with other subcategories
          ],
        },
        // Continue with other categories and subcategories
      ],
    },
    {
      examSectionId: 'CFA_LEVEL1_PM',
      displayName: 'Portfolio Management',
      description:
        'This section covers the fundamentals of portfolio management, including portfolio risk and return, asset allocation, and investment policy statements.',
      icon: 'remixLayoutLine', // Remix Icon for portfolio management
      categories: [
        {
          categoryId: 'CFA_LEVEL1_PM_RISK_RETURN',
          displayName: 'Portfolio Risk and Return',
          description: 'Understanding risk and return in portfolios.',
          icon: 'remixShieldStarLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL1_PM_RISK_RETURN_MODERN_PORTFOLIO_THEORY',
              displayName: 'Modern Portfolio Theory',
              description: 'Diversification and efficient frontier.',
            },
            // Continue with other subcategories
          ],
        },
        // Continue with other categories and subcategories
      ],
    },
  ],
};


// CFA Level 2 Exam Data
export const cfaLevel2ExamData: ExamData = {
  examId: 'CFA_LEVEL2_2025',
  displayName: 'CFA Level 2',
  description:
    'The CFA Level 2 exam focuses on applying investment tools and concepts to asset valuation and portfolio management scenarios.',
  logo: 'assets/images/exams/cfa_2_logo.png',
  link: 'cfa-level2',
  zoom: 1.1,
  priceId: 'CFA_LEVEL2_2025_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Master CFA Level 2 with In-Depth Analysis and Application',
  marketingSubtitles: [
    'Advanced practice tailored to CFA Level 2 exam requirements.',
    'Extensive question bank covering all CFA Level 2 topics.',
    'Adaptive learning to target and strengthen weak areas.',
    'Build expertise with detailed explanations and feedback.',
  ],
  pricingTitle: 'Premium CFA Level 2 Prep at a Competitive Price',
  pricingSubtitle: [
    'Achieve your finance career goals with comprehensive support and resources.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'CFA_LEVEL2_ETHICS',
      displayName: 'Ethical and Professional Standards',
      description:
        'This section emphasizes the application of ethical principles and professional standards in investment scenarios.',
      icon: 'remixEqualizerLine', // Remix Icon for ethics
      categories: [
        {
          categoryId: 'CFA_LEVEL2_ETHICS_PROFESSIONAL_STANDARDS',
          displayName: 'Professional Standards Application',
          description: 'Applying the Code and Standards to real-world situations.',
          icon: 'remixBookLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_PROFESSIONAL_STANDARDS_CONFLICTS_OF_INTEREST',
              displayName: 'Conflicts of Interest',
              description: 'Identifying and managing conflicts.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_PROFESSIONAL_STANDARDS_ETHICAL_DECISION_MAKING',
              displayName: 'Ethical Decision-Making',
              description: 'Applying ethical frameworks.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_PROFESSIONAL_STANDARDS_RESPONSIBILITIES_TO_CLIENTS',
              displayName: 'Responsibilities to Clients',
              description: "Duties owed to clients.",
            },
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_PROFESSIONAL_STANDARDS_RESPONSIBILITIES_TO_EMPLOYERS',
              displayName: 'Responsibilities to Employers',
              description: "Duties owed to employers.",
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_ETHICS_RESEARCH_OBJECTIVITY',
          displayName: 'Research Objectivity Standards',
          description: 'Ensuring integrity in research reports.',
          icon: 'remixLineChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_RESEARCH_OBJECTIVITY_PUBLIC_APPEARANCES',
              displayName: 'Public Appearances',
              description: 'Standards for public communications.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_RESEARCH_OBJECTIVITY_INVESTMENT_BANKING_RELATIONSHIPS',
              displayName: 'Investment Banking Relationships',
              description: 'Managing potential conflicts.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_RESEARCH_OBJECTIVITY_PERSONAL_INVESTMENTS',
              displayName: 'Personal Investments',
              description: 'Rules regarding analyst investments.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ETHICS_RESEARCH_OBJECTIVITY_COMPENSATION_ARRANGEMENTS',
              displayName: 'Compensation Arrangements',
              description: 'Influence of compensation on objectivity.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_QUANT',
      displayName: 'Quantitative Methods',
      description:
        'Advanced quantitative techniques including regression analysis and time-series analysis applied to financial data.',
      icon: 'remixCalculatorLine', // Remix Icon for quantitative methods
      categories: [
        {
          categoryId: 'CFA_LEVEL2_QUANT_MULTIPLE_REGRESSION',
          displayName: 'Multiple Regression Analysis',
          description: 'Using multiple regression in financial modeling.',
          icon: 'remixBarChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_MULTIPLE_REGRESSION_REGRESSION_COEFFICIENTS',
              displayName: 'Regression Coefficients',
              description: 'Interpreting coefficients in models.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_MULTIPLE_REGRESSION_HYPOTHESIS_TESTING',
              displayName: 'Hypothesis Testing',
              description: 'Testing significance of variables.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_MULTIPLE_REGRESSION_MODEL_ASSUMPTIONS',
              displayName: 'Model Assumptions',
              description: 'Assumptions underlying regression.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_MULTIPLE_REGRESSION_MULTICOLLINEARITY',
              displayName: 'Multicollinearity',
              description: 'Impact and detection of multicollinearity.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_QUANT_TIME_SERIES',
          displayName: 'Time-Series Analysis',
          description: 'Analyzing data points collected over time.',
          icon: 'remixTimeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_TIME_SERIES_TREND_MODELS',
              displayName: 'Trend Models',
              description: 'Identifying trends in data.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_TIME_SERIES_AUTOREGRESSIVE_MODELS',
              displayName: 'Autoregressive Models',
              description: 'Using past values to predict future.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_TIME_SERIES_SEASONALITY',
              displayName: 'Seasonality',
              description: 'Adjusting for seasonal effects.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_QUANT_TIME_SERIES_MODEL_EVALUATION',
              displayName: 'Model Evaluation',
              description: 'Assessing model fit and forecasting.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_ECON',
      displayName: 'Economics',
      description:
        'Application of economic concepts in investment analysis, including currency exchange rates and economic growth theories.',
      icon: 'remixLineChartLine', // Remix Icon for economics
      categories: [
        {
          categoryId: 'CFA_LEVEL2_ECON_CURRENCY_EXCHANGE',
          displayName: 'Currency Exchange Rates',
          description: 'Understanding and forecasting exchange rates.',
          icon: 'remixPercentLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_ECON_CURRENCY_EXCHANGE_EXCHANGE_RATE_REGIMES',
              displayName: 'Exchange Rate Regimes',
              description: 'Fixed vs floating systems.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ECON_CURRENCY_EXCHANGE_PARITY_CONDITIONS',
              displayName: 'Parity Conditions',
              description: 'Purchasing power parity concepts.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ECON_CURRENCY_EXCHANGE_EXCHANGE_RATE_MODELS',
              displayName: 'Exchange Rate Models',
              description: 'Predicting currency movements.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ECON_CURRENCY_EXCHANGE_CARRY_TRADE',
              displayName: 'Carry Trade',
              description: 'Profiting from interest rate differentials.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_ECON_ECONOMIC_GROWTH',
          displayName: 'Economic Growth and Development',
          description: 'Factors influencing economic growth.',
          icon: 'remixGlobalLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_ECON_ECONOMIC_GROWTH_GROWTH_THEORIES',
              displayName: 'Growth Theories',
              description: 'Classical and endogenous growth.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ECON_ECONOMIC_GROWTH_PRODUCTIVITY',
              displayName: 'Productivity',
              description: 'Role of technology and innovation.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ECON_ECONOMIC_GROWTH_CAPITAL_DEEPENING',
              displayName: 'Capital Deepening',
              description: 'Impact of investment in capital.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ECON_ECONOMIC_GROWTH_GOVERNMENT_POLICIES',
              displayName: 'Government Policies',
              description: 'Influence of fiscal and monetary policy.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_FINREP',
      displayName: 'Financial Reporting and Analysis',
      description:
        'In-depth analysis of financial statements, accounting methods, and their impact on financial ratios and valuations.',
      icon: 'remixFileTextLine', // Remix Icon for financial reporting
      categories: [
        {
          categoryId: 'CFA_LEVEL2_FINREP_INTERCORPORATE_INVESTMENTS',
          displayName: 'Intercorporate Investments',
          description: 'Accounting for investments in other companies.',
          icon: 'remixBankLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_INTERCORPORATE_INVESTMENTS_EQUITY_METHOD',
              displayName: 'Equity Method',
              description: 'Accounting for significant influence.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_INTERCORPORATE_INVESTMENTS_ACQUISITION_METHOD',
              displayName: 'Acquisition Method',
              description: 'Consolidation of financial statements.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_INTERCORPORATE_INVESTMENTS_JOINT_VENTURES',
              displayName: 'Joint Ventures',
              description: 'Shared control investments.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_INTERCORPORATE_INVESTMENTS_IMPAIRMENT_OF_INVESTMENTS',
              displayName: 'Impairment of Investments',
              description: 'Recognizing investment losses.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_FINREP_EMPLOYEE_COMPENSATION',
          displayName: 'Employee Compensation',
          description: 'Accounting for pension plans and share-based compensation.',
          icon: 'remixMoneyDollarBoxLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_EMPLOYEE_COMPENSATION_DEFINED_BENEFIT_PLANS',
              displayName: 'Defined Benefit Plans',
              description: 'Accounting for pension obligations.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_EMPLOYEE_COMPENSATION_DEFINED_CONTRIBUTION_PLANS',
              displayName: 'Defined Contribution Plans',
              description: 'Employer contributions accounting.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_EMPLOYEE_COMPENSATION_SHARE_BASED_COMPENSATION',
              displayName: 'Share-Based Compensation',
              description: 'Accounting for stock options.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_EMPLOYEE_COMPENSATION_POST_EMPLOYMENT_BENEFITS',
              displayName: 'Post-Employment Benefits',
              description: 'Other long-term benefits.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_FINREP_MULTINATIONAL_OPERATIONS',
          displayName: 'Multinational Operations',
          description: 'Accounting for companies operating in multiple currencies.',
          icon: 'remixExchangeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_MULTINATIONAL_OPERATIONS_FOREIGN_CURRENCY_TRANSACTIONS',
              displayName: 'Foreign Currency Transactions',
              description: 'Recording transactions in foreign currencies.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_MULTINATIONAL_OPERATIONS_TRANSLATION_METHODS',
              displayName: 'Translation Methods',
              description: 'Temporal and current rate methods.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_MULTINATIONAL_OPERATIONS_IMPACT_ON_FINANCIAL_STATEMENTS',
              displayName: 'Impact on Financial Statements',
              description: 'Effects of currency fluctuations.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FINREP_MULTINATIONAL_OPERATIONS_HEDGING_CURRENCY_RISK',
              displayName: 'Hedging Currency Risk',
              description: 'Using derivatives to manage risk.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_CORPFIN',
      displayName: 'Corporate Finance',
      description:
        'Advanced topics in corporate finance including capital structure decisions, dividend policy, and corporate governance.',
      icon: 'remixBuildingLine', // Remix Icon for corporate finance
      categories: [
        {
          categoryId: 'CFA_LEVEL2_CORPFIN_CAPITAL_STRUCTURE',
          displayName: 'Capital Structure Decisions',
          description: 'Determining the optimal mix of debt and equity.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CAPITAL_STRUCTURE_MODIGLIANI_MILLER_PROPOSITIONS',
              displayName: 'Modigliani-Miller Propositions',
              description: 'Impact of taxes and bankruptcy.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CAPITAL_STRUCTURE_PECKING_ORDER_THEORY',
              displayName: 'Pecking Order Theory',
              description: 'Preference for internal financing.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CAPITAL_STRUCTURE_AGENCY_COSTS',
              displayName: 'Agency Costs',
              description: 'Conflicts between stakeholders.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CAPITAL_STRUCTURE_FINANCIAL_DISTRESS',
              displayName: 'Financial Distress',
              description: 'Risks associated with high leverage.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_CORPFIN_DIVIDENDS_REPURCHASES',
          displayName: 'Dividends and Repurchases',
          description: 'Decisions on returning capital to shareholders.',
          icon: 'remixMoneyLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_DIVIDENDS_REPURCHASES_DIVIDEND_POLICY_THEORIES',
              displayName: 'Dividend Policy Theories',
              description: 'Relevance of dividends to value.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_DIVIDENDS_REPURCHASES_FACTORS_AFFECTING_DIVIDENDS',
              displayName: 'Factors Affecting Dividends',
              description: 'Determinants of payout policy.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_DIVIDENDS_REPURCHASES_SHARE_REPURCHASE_METHODS',
              displayName: 'Share Repurchase Methods',
              description: 'Techniques for buybacks.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_DIVIDENDS_REPURCHASES_IMPACT_ON_FINANCIAL_RATIOS',
              displayName: 'Impact on Financial Ratios',
              description: 'Effects on EPS and ROE.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_CORPFIN_CORPORATE_GOVERNANCE',
          displayName: 'Corporate Governance',
          description: 'Structures and practices for effective management oversight.',
          icon: 'remixShieldLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CORPORATE_GOVERNANCE_BOARD_OF_DIRECTORS',
              displayName: 'Board of Directors',
              description: 'Roles and responsibilities.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CORPORATE_GOVERNANCE_SHAREHOLDER_RIGHTS',
              displayName: 'Shareholder Rights',
              description: 'Voting and ownership rights.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CORPORATE_GOVERNANCE_EXECUTIVE_COMPENSATION',
              displayName: 'Executive Compensation',
              description: 'Aligning management interests.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_CORPFIN_CORPORATE_GOVERNANCE_REGULATORY_ENVIRONMENT',
              displayName: 'Regulatory Environment',
              description: 'Laws and regulations affecting governance.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_EQUITY',
      displayName: 'Equity Investments',
      description:
        'This section covers advanced equity valuation techniques, industry and company analysis, and equity market structures.',
      icon: 'remixStockLine', // Remix Icon for equity investments
      categories: [
        {
          categoryId: 'CFA_LEVEL2_EQUITY_VALUATION',
          displayName: 'Equity Valuation Applications',
          description: 'Advanced techniques for valuing equity securities.',
          icon: 'remixPriceTagLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_VALUATION_DISCOUNTED_DIVIDEND_VALUATION',
              displayName: 'Discounted Dividend Valuation',
              description: 'Valuing stocks using dividend discount models.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_VALUATION_FREE_CASH_FLOW_VALUATION',
              displayName: 'Free Cash Flow Valuation',
              description: 'Valuation based on free cash flows to equity or firm.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_VALUATION_RESIDUAL_INCOME_VALUATION',
              displayName: 'Residual Income Valuation',
              description: 'Valuing equity using residual income models.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_VALUATION_MARKET_BASED_VALUATION',
              displayName: 'Market-Based Valuation',
              description: 'Using price multiples and enterprise value multiples.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_EQUITY_INDUSTRY_COMPANY_ANALYSIS',
          displayName: 'Industry and Company Analysis',
          description: 'Analyzing industries and companies for investment.',
          icon: 'remixExchangeFundsLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_INDUSTRY_COMPANY_ANALYSIS_INDUSTRY_CLASSIFICATION',
              displayName: 'Industry Classification',
              description: 'Understanding industry classification systems.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_INDUSTRY_COMPANY_ANALYSIS_COMPETITIVE_FORCES',
              displayName: 'Competitive Forces',
              description: "Analyzing industry competition using Porter's Five Forces.",
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_INDUSTRY_COMPANY_ANALYSIS_COMPANY_ANALYSIS',
              displayName: 'Company Analysis',
              description: 'Assessing company strategy and performance.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_INDUSTRY_COMPANY_ANALYSIS_GROWTH_ANALYSIS',
              displayName: 'Growth Analysis',
              description: 'Estimating company growth prospects.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_EQUITY_MARKET_STRUCTURE',
          displayName: 'Equity Market Structure',
          description: 'Understanding how equity markets are structured and how trading occurs.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_MARKET_STRUCTURE_MARKET_PARTICIPANTS',
              displayName: 'Market Participants',
              description: 'Roles of different market participants.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_MARKET_STRUCTURE_ORDER_TYPES_INSTRUCTIONS',
              displayName: 'Order Types and Instructions',
              description: 'Various order types used in trading.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_MARKET_STRUCTURE_MARKET_MICROSTRUCTURE',
              displayName: 'Market Microstructure',
              description: 'Mechanics of how markets operate.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_EQUITY_MARKET_STRUCTURE_ALGORITHMIC_TRADING',
              displayName: 'Algorithmic Trading',
              description: 'Use of algorithms in trading strategies.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_FIXED',
      displayName: 'Fixed Income',
      description:
        'This section delves into valuation and analysis of fixed income securities, including term structure models and credit analysis.',
      icon: 'remixMoneyDollarBoxLine', // Remix Icon for fixed income
      categories: [
        {
          categoryId: 'CFA_LEVEL2_FIXED_TERM_STRUCTURE',
          displayName: 'Term Structure Dynamics',
          description: 'Understanding the term structure of interest rates and modeling interest rate movements.',
          icon: 'remixTimeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_TERM_STRUCTURE_YIELD_CURVE_CONSTRUCTION',
              displayName: 'Yield Curve Construction',
              description: 'Building yield curves from market data.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_TERM_STRUCTURE_INTEREST_RATE_MODELS',
              displayName: 'Interest Rate Models',
              description: 'Short-rate and equilibrium models.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_TERM_STRUCTURE_FORWARD_RATES',
              displayName: 'Forward Rates',
              description: 'Calculating and interpreting forward rates.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_TERM_STRUCTURE_INTEREST_RATE_VOLATILITY',
              displayName: 'Interest Rate Volatility',
              description: 'Modeling and implications of rate volatility.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_FIXED_CREDIT_ANALYSIS',
          displayName: 'Credit Analysis and Valuation',
          description: 'Evaluating credit risk and valuing credit-sensitive instruments.',
          icon: 'remixShieldStarLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_CREDIT_ANALYSIS_CREDIT_RISK_MEASUREMENT',
              displayName: 'Credit Risk Measurement',
              description: 'Assessing probability of default and loss given default.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_CREDIT_ANALYSIS_STRUCTURAL_MODELS',
              displayName: 'Structural Models',
              description: 'Models based on company assets and liabilities.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_CREDIT_ANALYSIS_REDUCED_FORM_MODELS',
              displayName: 'Reduced Form Models',
              description: 'Statistical models of default risk.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_FIXED_CREDIT_ANALYSIS_CREDIT_DERIVATIVES',
              displayName: 'Credit Derivatives',
              description: 'Using derivatives to manage credit risk.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_DERIVATIVES',
      displayName: 'Derivatives',
      description:
        'Advanced concepts in derivatives including valuation of forwards, futures, options, swaps, and risk management strategies.',
      icon: 'remixFunctionsLine', // Remix Icon for derivatives
      categories: [
        {
          categoryId: 'CFA_LEVEL2_DERIVATIVES_FORWARD_PRICING',
          displayName: 'Forward Pricing and Valuation',
          description: 'Valuing forward contracts and futures.',
          icon: 'remixPriceTagLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_FORWARD_PRICING_EQUITY_FORWARDS',
              displayName: 'Equity Forwards',
              description: 'Valuing forwards on equities.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_FORWARD_PRICING_FIXED_INCOME_FORWARDS',
              displayName: 'Fixed Income Forwards',
              description: 'Valuing forwards on bonds.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_FORWARD_PRICING_CURRENCY_FORWARDS',
              displayName: 'Currency Forwards',
              description: 'Valuing currency forward contracts.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_FORWARD_PRICING_FUTURES_CONTRACTS',
              displayName: 'Futures Contracts',
              description: 'Pricing differences between futures and forwards.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_DERIVATIVES_OPTION_VALUATION',
          displayName: 'Option Valuation',
          description: 'Valuing options using different models.',
          icon: 'remixOptionsLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_OPTION_VALUATION_BINOMIAL_MODELS',
              displayName: 'Binomial Models',
              description: 'Valuing options with binomial trees.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_OPTION_VALUATION_BLACK_SCHOLES_MODEL',
              displayName: 'Black-Scholes Model',
              description: 'Using Black-Scholes formula for valuation.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_OPTION_VALUATION_GREEKS',
              displayName: 'Greeks',
              description: 'Sensitivity measures for options.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_DERIVATIVES_OPTION_VALUATION_IMPLIED_VOLATILITY',
              displayName: 'Implied Volatility',
              description: 'Volatility implied by market prices.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_ALTINV',
      displayName: 'Alternative Investments',
      description:
        'Advanced topics in alternative investments, including real estate valuation, private equity, and hedge fund strategies.',
      icon: 'remixCommunityLine', // Remix Icon for alternative investments
      categories: [
        {
          categoryId: 'CFA_LEVEL2_ALTINV_PRIVATE_EQUITY',
          displayName: 'Private Equity Valuation',
          description: 'Valuation methods for private equity investments.',
          icon: 'remixFlaskLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_PRIVATE_EQUITY_VENTURE_CAPITAL',
              displayName: 'Venture Capital',
              description: 'Valuing early-stage companies.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_PRIVATE_EQUITY_LEVERAGED_BUYOUTS',
              displayName: 'Leveraged Buyouts',
              description: 'Valuation in LBO transactions.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_PRIVATE_EQUITY_EXIT_STRATEGIES',
              displayName: 'Exit Strategies',
              description: 'Methods of exiting private equity investments.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_PRIVATE_EQUITY_PERFORMANCE_MEASUREMENT',
              displayName: 'Performance Measurement',
              description: 'Assessing private equity returns.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_ALTINV_HEDGE_FUNDS',
          displayName: 'Hedge Fund Strategies',
          description: 'Understanding various hedge fund strategies.',
          icon: 'remixShieldStarLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_HEDGE_FUNDS_EQUITY_HEDGE',
              displayName: 'Equity Hedge',
              description: 'Long/short equity strategies.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_HEDGE_FUNDS_EVENT_DRIVEN',
              displayName: 'Event-Driven',
              description: 'Strategies based on corporate events.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_HEDGE_FUNDS_RELATIVE_VALUE',
              displayName: 'Relative Value',
              description: 'Exploiting valuation discrepancies.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_ALTINV_HEDGE_FUNDS_GLOBAL_MACRO',
              displayName: 'Global Macro',
              description: 'Strategies based on global economic trends.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL2_PM',
      displayName: 'Portfolio Management',
      description:
        'Advanced portfolio management techniques, including risk management, asset allocation, and performance attribution.',
      icon: 'remixLayoutLine', // Remix Icon for portfolio management
      categories: [
        {
          categoryId: 'CFA_LEVEL2_PM_ASSET_ALLOCATION',
          displayName: 'Asset Allocation',
          description: 'Strategies for allocating assets in a portfolio.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_PM_ASSET_ALLOCATION_STRATEGIC_ASSET_ALLOCATION',
              displayName: 'Strategic Asset Allocation',
              description: 'Long-term asset mix decisions.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_ASSET_ALLOCATION_TACTICAL_ASSET_ALLOCATION',
              displayName: 'Tactical Asset Allocation',
              description: 'Short-term adjustments to asset mix.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_ASSET_ALLOCATION_GLOBAL_ASSET_ALLOCATION',
              displayName: 'Global Asset Allocation',
              description: 'Allocating assets across global markets.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_ASSET_ALLOCATION_CURRENCY_MANAGEMENT',
              displayName: 'Currency Management',
              description: 'Managing currency exposure in portfolios.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_PM_RISK_MANAGEMENT',
          displayName: 'Risk Management Applications',
          description: 'Applying risk management techniques in portfolios.',
          icon: 'remixShieldLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_PM_RISK_MANAGEMENT_VALUE_AT_RISK',
              displayName: 'Value at Risk',
              description: 'Measuring potential portfolio losses.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_RISK_MANAGEMENT_STRESS_TESTING',
              displayName: 'Stress Testing',
              description: 'Assessing portfolio under extreme conditions.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_RISK_MANAGEMENT_MANAGING_MARKET_RISK',
              displayName: 'Managing Market Risk',
              description: 'Techniques to manage exposure to market risk.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_RISK_MANAGEMENT_CREDIT_RISK_MANAGEMENT',
              displayName: 'Credit Risk Management',
              description: 'Managing exposure to credit risk.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL2_PM_PERFORMANCE_EVALUATION',
          displayName: 'Performance Evaluation',
          description: 'Evaluating and attributing portfolio performance.',
          icon: 'remixBarChartBoxLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL2_PM_PERFORMANCE_EVALUATION_ATTRIBUTION_ANALYSIS',
              displayName: 'Attribution Analysis',
              description: 'Determining sources of portfolio returns.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_PERFORMANCE_EVALUATION_BENCHMARK_SELECTION',
              displayName: 'Benchmark Selection',
              description: 'Choosing appropriate benchmarks.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_PERFORMANCE_EVALUATION_RISK_ADJUSTED_MEASURES',
              displayName: 'Risk-Adjusted Measures',
              description: 'Sharpe ratio, Information ratio.',
            },
            {
              subcategoryId: 'CFA_LEVEL2_PM_PERFORMANCE_EVALUATION_PERFORMANCE_APPRAISAL',
              displayName: 'Performance Appraisal',
              description: 'Assessing manager skill.',
            },
          ],
        },
      ],
    },
  ],
};


// CFA Level 3 Exam Data
export const cfaLevel3ExamData: ExamData = {
  examId: 'CFA_LEVEL3_2025',
  displayName: 'CFA Level 3',
  description:
    'The CFA Level 3 exam focuses on portfolio management and wealth planning, emphasizing the application of investment tools and concepts in portfolio management and private wealth management.',
  logo: 'assets/images/exams/cfa_3_logo.png',
  link: 'cfa-level3',
  zoom: 1.1,
  priceId: 'CFA_LEVEL3_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel in CFA Level 3 with Comprehensive Portfolio Management Tools',
  marketingSubtitles: [
    'Advanced practice tailored to CFA Level 3 exam requirements.',
    'Extensive question bank covering all CFA Level 3 topics.',
    'Adaptive learning to target and strengthen weak areas.',
    'Build expertise with detailed explanations and feedback.',
  ],
  pricingTitle: 'Premium CFA Level 3 Prep at a Competitive Price',
  pricingSubtitle: [
    'Achieve your finance career goals with comprehensive support and resources.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'CFA_LEVEL3_ETHICS',
      displayName: 'Ethical and Professional Standards',
      description:
        'This section emphasizes the application of ethical principles and professional standards in portfolio management and compliance.',
      icon: 'remixEqualizerLine', // Remix Icon for ethics
      categories: [
        {
          categoryId: 'CFA_LEVEL3_ETHICS_ETHICAL_DECISION',
          displayName: 'Ethical Decision-Making',
          description: 'Applying the Code and Standards to portfolio management scenarios.',
          icon: 'remixTreeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ETHICAL_DECISION_PROFESSIONALISM',
              displayName: 'Professionalism',
              description: 'Standards related to professionalism.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ETHICAL_DECISION_INTEGRITY_OF_CAPITAL_MARKETS',
              displayName: 'Integrity of Capital Markets',
              description: 'Maintaining market integrity.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ETHICAL_DECISION_DUTIES_TO_CLIENTS',
              displayName: 'Duties to Clients',
              description: 'Responsibilities towards clients.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ETHICAL_DECISION_INVESTMENT_ANALYSIS',
              displayName: 'Investment Analysis',
              description: 'Ethics in investment analysis.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_ETHICS_ASSET_MANAGER_CODE',
          displayName: 'Asset Manager Code',
          description: 'Understanding the Asset Manager Code and its application.',
          icon: 'remixLawLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ASSET_MANAGER_CODE_LOYALTY_TO_CLIENTS',
              displayName: 'Loyalty to Clients',
              description: 'Prioritizing client interests.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ASSET_MANAGER_CODE_INVESTMENT_PROCESS',
              displayName: 'Investment Process',
              description: 'Ensuring a fair investment process.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ASSET_MANAGER_CODE_RISK_MANAGEMENT',
              displayName: 'Risk Management',
              description: 'Implementing effective risk controls.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ETHICS_ASSET_MANAGER_CODE_PERFORMANCE_REPORTING',
              displayName: 'Performance Reporting',
              description: 'Accurate and transparent reporting.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL3_PM',
      displayName: 'Portfolio Management and Wealth Planning',
      description:
        'This core section covers portfolio management concepts, including asset allocation, risk management, and strategies for individual and institutional investors.',
      icon: 'remixPieChartLine', // Remix Icon for portfolio management
      categories: [
        {
          categoryId: 'CFA_LEVEL3_PM_BEHAVIORAL_FINANCE',
          displayName: 'Behavioral Finance',
          description: 'Understanding how psychology affects investment decisions.',
          icon: 'remixEmotionLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_BEHAVIORAL_FINANCE_COGNITIVE_ERRORS',
              displayName: 'Cognitive Errors',
              description: 'Biases affecting decision-making.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_BEHAVIORAL_FINANCE_EMOTIONAL_BIASES',
              displayName: 'Emotional Biases',
              description: 'Impact of emotions on investments.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_BEHAVIORAL_FINANCE_BEHAVIORAL_PORTFOLIO_THEORY',
              displayName: 'Behavioral Portfolio Theory',
              description: 'Alternative portfolio construction.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_BEHAVIORAL_FINANCE_APPLICATIONS_IN_WEALTH_MANAGEMENT',
              displayName: 'Applications in Wealth Management',
              description: 'Tailoring strategies to client biases.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_PRIVATE_WEALTH',
          displayName: 'Private Wealth Management',
          description: 'Strategies for managing individual investor portfolios.',
          icon: 'remixUserLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_PRIVATE_WEALTH_INVESTMENT_POLICY_STATEMENT',
              displayName: 'Investment Policy Statement',
              description: 'Creating IPS for individuals.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_PRIVATE_WEALTH_TAX_CONSIDERATIONS',
              displayName: 'Tax Considerations',
              description: 'Tax impacts on investment decisions.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_PRIVATE_WEALTH_ESTATE_PLANNING',
              displayName: 'Estate Planning',
              description: 'Transferring wealth efficiently.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_PRIVATE_WEALTH_BEHAVIORAL_FACTORS',
              displayName: 'Behavioral Factors',
              description: 'Incorporating client behaviors.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_INSTITUTIONAL_PM',
          displayName: 'Institutional Portfolio Management',
          description: 'Managing portfolios for institutional clients.',
          icon: 'remixBuilding2Line',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_INSTITUTIONAL_PM_PENSION_FUNDS',
              displayName: 'Pension Funds',
              description: 'Managing pension assets.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_INSTITUTIONAL_PM_FOUNDATIONS_ENDOWMENTS',
              displayName: 'Foundations and Endowments',
              description: 'Investment strategies for non-profits.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_INSTITUTIONAL_PM_INSURANCE_COMPANIES',
              displayName: 'Insurance Companies',
              description: 'Portfolio considerations for insurers.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_INSTITUTIONAL_PM_SOVEREIGN_WEALTH_FUNDS',
              displayName: 'Sovereign Wealth Funds',
              description: 'Government investment management.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_ASSET_ALLOCATION',
          displayName: 'Asset Allocation',
          description: 'Developing and implementing asset allocation strategies.',
          icon: 'remixPieChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_ASSET_ALLOCATION_STRATEGIC',
              displayName: 'Strategic Asset Allocation',
              description: 'Long-term allocation decisions.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_ASSET_ALLOCATION_TACTICAL',
              displayName: 'Tactical Asset Allocation',
              description: 'Short-term adjustments based on market conditions.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_ASSET_ALLOCATION_IMPLEMENTATION',
              displayName: 'Asset Allocation Implementation',
              description: 'Practical considerations and constraints.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_ASSET_ALLOCATION_GLOBAL_INTEGRATION',
              displayName: 'Global Integration',
              description: 'Allocating assets across global markets.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_RISK_MANAGEMENT',
          displayName: 'Risk Management',
          description: 'Identifying, measuring, and managing risks in portfolios.',
          icon: 'remixShieldLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_RISK_MANAGEMENT_MEASURING_RISK',
              displayName: 'Measuring Risk',
              description: 'Quantitative risk measures.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_RISK_MANAGEMENT_MANAGING_MARKET_RISK',
              displayName: 'Managing Market Risk',
              description: 'Strategies to mitigate market risk.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_RISK_MANAGEMENT_CREDIT_RISK_MANAGEMENT',
              displayName: 'Credit Risk Management',
              description: 'Handling credit exposure.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_RISK_MANAGEMENT_DERIVATIVES_IN_RISK_MANAGEMENT',
              displayName: 'Derivatives in Risk Management',
              description: 'Using derivatives to manage risk.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_PERFORMANCE_EVALUATION',
          displayName: 'Performance Evaluation',
          description: 'Assessing and attributing portfolio performance.',
          icon: 'remixBarChartBoxLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_PERFORMANCE_EVALUATION_ATTRIBUTION_ANALYSIS',
              displayName: 'Attribution Analysis',
              description: 'Breaking down performance sources.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_PERFORMANCE_EVALUATION_BENCHMARKING',
              displayName: 'Benchmarking',
              description: 'Selecting appropriate benchmarks.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_PERFORMANCE_EVALUATION_RISK_ADJUSTED_PERFORMANCE',
              displayName: 'Risk-Adjusted Performance',
              description: 'Metrics like Sharpe and Information ratios.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_PERFORMANCE_EVALUATION_PERFORMANCE_APPRAISAL',
              displayName: 'Performance Appraisal',
              description: 'Evaluating investment decisions.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_TRADING_MONITORING',
          displayName: 'Trading & Rebalancing',
          description: 'Executing trades and maintaining portfolio alignment.',
          icon: 'remixExchangeFundsLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_TRADING_MONITORING_EXECUTION_STRATEGIES',
              displayName: 'Execution Strategies',
              description: 'Methods of trade execution.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_TRADING_MONITORING_TRANSACTION_COSTS',
              displayName: 'Transaction Costs',
              description: 'Understanding and minimizing costs.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_TRADING_MONITORING_PORTFOLIO_MONITORING',
              displayName: 'Portfolio Monitoring',
              description: 'Ongoing assessment of portfolio.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_TRADING_MONITORING_REBALANCING_STRATEGIES',
              displayName: 'Rebalancing Strategies',
              description: 'Approaches to maintain asset allocation.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_PM_ECONOMIC_ANALYSIS',
          displayName: 'Economic Analysis',
          description: 'Analyzing economic conditions to set market expectations.',
          icon: 'remixLineChartLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_PM_ECONOMIC_ANALYSIS_BUSINESS_CYCLE_ANALYSIS',
              displayName: 'Business Cycle Analysis',
              description: 'Impact of economic cycles on investments.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_ECONOMIC_ANALYSIS_FORECASTING_TOOLS',
              displayName: 'Forecasting Tools',
              description: 'Models for economic forecasting.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_ECONOMIC_ANALYSIS_CAPITAL_MARKET_EXPECTATIONS',
              displayName: 'Capital Market Expectations',
              description: 'Setting return expectations.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_PM_ECONOMIC_ANALYSIS_MACRO_ANALYSIS',
              displayName: 'Macro Analysis',
              description: 'Assessing global economic trends.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL3_FIXED_PM',
      displayName: 'Fixed Income Portfolio Management',
      description:
        'This section focuses on managing fixed income portfolios, including strategies, immunization techniques, and risk considerations.',
      icon: 'remixMoneyDollarBoxLine', // Remix Icon for fixed income
      categories: [
        {
          categoryId: 'CFA_LEVEL3_FIXED_PM_MANAGING_PORTFOLIOS',
          displayName: 'Managing FI Portfolios',
          description: 'Strategies and considerations in managing fixed income portfolios.',
          icon: 'remixBarChart2Line',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_MANAGING_PORTFOLIOS_INTEREST_RATE_RISK',
              displayName: 'Interest Rate Risk',
              description: 'Managing duration and convexity.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_MANAGING_PORTFOLIOS_CREDIT_STRATEGIES',
              displayName: 'Credit Strategies',
              description: 'Assessing and managing credit risk.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_MANAGING_PORTFOLIOS_YIELD_CURVE_STRATEGIES',
              displayName: 'Yield Curve Strategies',
              description: 'Positioning along the yield curve.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_MANAGING_PORTFOLIOS_LIQUIDITY_MANAGEMENT',
              displayName: 'Liquidity Management',
              description: 'Ensuring adequate liquidity.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_FIXED_PM_LIABILITY_DRIVEN',
          displayName: 'Liability-Driven Strategies',
          description: 'Strategies focused on meeting liabilities and tracking benchmarks.',
          icon: 'remixShieldStarLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_LIABILITY_DRIVEN_IMMUNIZATION',
              displayName: 'Immunization',
              description: 'Techniques to immunize portfolios.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_LIABILITY_DRIVEN_CASH_FLOW_MATCHING',
              displayName: 'Cash Flow Matching',
              description: 'Matching asset and liability cash flows.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_LIABILITY_DRIVEN_INDEXING',
              displayName: 'Indexing',
              description: 'Replicating fixed income indices.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_FIXED_PM_LIABILITY_DRIVEN_DURATION_MATCHING',
              displayName: 'Duration Matching',
              description: 'Aligning portfolio duration with liabilities.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL3_EQUITY_PM',
      displayName: 'Equity Portfolio Management',
      description:
        'This section covers strategies and techniques for managing equity portfolios, including active and passive approaches.',
      icon: 'remixStockLine', // Remix Icon for equity investments
      categories: [
        {
          categoryId: 'CFA_LEVEL3_EQUITY_PM_PASSIVE_INVESTING',
          displayName: 'Passive Equity Investing',
          description: 'Strategies and techniques for passive equity management.',
          icon: 'remixArrowLeftLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_PASSIVE_INVESTING_INDEX_CONSTRUCTION',
              displayName: 'Index Construction',
              description: 'Methods of building equity indices.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_PASSIVE_INVESTING_TRACKING_ERROR',
              displayName: 'Tracking Error',
              description: 'Measuring deviation from benchmark.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_PASSIVE_INVESTING_REPLICATION_METHODS',
              displayName: 'Replication Methods',
              description: 'Full replication, sampling, optimization.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_PASSIVE_INVESTING_ETFS',
              displayName: 'Exchange-Traded Funds',
              description: 'Using ETFs in passive investing.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_EQUITY_PM_ACTIVE_INVESTING',
          displayName: 'Active Equity Investing',
          description: 'Approaches and considerations in active equity management.',
          icon: 'remixRocketLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_ACTIVE_INVESTING_FUNDAMENTAL_STRATEGIES',
              displayName: 'Fundamental Strategies',
              description: 'Value, growth, and GARP investing.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_ACTIVE_INVESTING_QUANTITATIVE_STRATEGIES',
              displayName: 'Quantitative Strategies',
              description: 'Using quantitative models for stock selection.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_ACTIVE_INVESTING_MARKET_ANOMALIES',
              displayName: 'Market Anomalies',
              description: 'Exploiting inefficiencies in the market.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_EQUITY_PM_ACTIVE_INVESTING_BEHAVIORAL_CONSIDERATIONS',
              displayName: 'Behavioral Considerations',
              description: 'Impact of investor behavior on markets.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL3_ALTINV',
      displayName: 'Alternative Investments',
      description:
        'This section focuses on managing alternative investments within a portfolio, including real estate, private equity, and commodities.',
      icon: 'remixCommunityLine', // Remix Icon for alternative investments
      categories: [
        {
          categoryId: 'CFA_LEVEL3_ALTINV_REAL_ESTATE',
          displayName: 'Real Estate Investment',
          description: 'Investing in real estate assets and securities.',
          icon: 'remixHomeLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_REAL_ESTATE_DIRECT_INVESTMENT',
              displayName: 'Direct Investment',
              description: 'Ownership of physical properties.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_REAL_ESTATE_REITS',
              displayName: 'REITs',
              description: 'Real Estate Investment Trusts.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_REAL_ESTATE_VALUATION_METHODS',
              displayName: 'Valuation Methods',
              description: 'Income, sales comparison, cost approaches.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_REAL_ESTATE_RISK_CONSIDERATIONS',
              displayName: 'Risk Considerations',
              description: 'Risks unique to real estate investing.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_ALTINV_COMMODITIES',
          displayName: 'Commodities Investment',
          description: 'Investing in commodities and related derivatives.',
          icon: 'remixCopperCoinLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_COMMODITIES_PHYSICAL_COMMODITIES',
              displayName: 'Physical Commodities',
              description: 'Investing in tangible commodities.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_COMMODITIES_COMMODITY_FUTURES',
              displayName: 'Commodity Futures',
              description: 'Using futures contracts for exposure.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_COMMODITIES_COMMODITY_INDICES',
              displayName: 'Commodity Indices',
              description: 'Investing via commodity index products.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_ALTINV_COMMODITIES_RISK_AND_RETURN',
              displayName: 'Risk and Return',
              description: 'Characteristics of commodity investments.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY',
      displayName: 'Derivatives & Currency Management',
      description:
        'This section covers the use of derivatives in portfolio management and strategies for managing currency exposure.',
      icon: 'remixFunctionsLine', // Remix Icon for derivatives
      categories: [
        {
          categoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_DERIVATIVE_STRATEGIES',
          displayName: 'Derivative Strategies',
          description: 'Using derivatives to achieve investment objectives.',
          icon: 'remixArrowUpDownLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_DERIVATIVE_STRATEGIES_HEDGING_STRATEGIES',
              displayName: 'Hedging Strategies',
              description: 'Reducing risk exposure.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_DERIVATIVE_STRATEGIES_OPTION_STRATEGIES',
              displayName: 'Option Strategies',
              description: 'Income generation and speculation.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_DERIVATIVE_STRATEGIES_SWAP_STRATEGIES',
              displayName: 'Swap Strategies',
              description: 'Using swaps for asset allocation.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_DERIVATIVE_STRATEGIES_FUTURES_AND_FORWARDS',
              displayName: 'Futures and Forwards',
              description: 'Managing exposure with futures and forwards.',
            },
          ],
        },
        {
          categoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_MANAGEMENT',
          displayName: 'Currency Management',
          description: 'Strategies for managing currency risk in portfolios.',
          icon: 'remixCurrencyLine',
          subcategories: [
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_MANAGEMENT_CURRENCY_HEDGING',
              displayName: 'Currency Hedging',
              description: 'Techniques to hedge currency exposure.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_MANAGEMENT_CURRENCY_OVERLAY',
              displayName: 'Currency Overlay',
              description: 'Separating currency management from asset allocation.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_MANAGEMENT_STRATEGIC_VS_TACTICAL',
              displayName: 'Strategic vs Tactical',
              description: 'Long-term vs short-term currency decisions.',
            },
            {
              subcategoryId: 'CFA_LEVEL3_DERIVATIVES_CURRENCY_MANAGEMENT_CURRENCY_RISK_FACTORS',
              displayName: 'Currency Risk Factors',
              description: 'Understanding factors affecting currency risk.',
            },
          ],
        },
      ],
    },
  ],
};
