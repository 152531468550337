// GMAT Exam Data
import {ExamData} from "../exam-data-interface";

export const gmatExamData: ExamData = {
  examId: 'GMAT_2024',
  displayName: 'GMAT',
  description:
    'The GMAT is a computer-adaptive exam assessing analytical writing, quantitative, verbal, and reading skills for graduate management admission.',
  logo: 'assets/images/exams/gmat_focus_logo.svg',
  link: 'gmat',
  zoom: 1.1,
  priceId: 'GMAT_2024_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Master the GMAT with Adaptive Learning',
  marketingSubtitles: [
    'Our AI tailors practice to your needs to maximize your GMAT score.',
    'Access over 30,000 GMAT questions with detailed explanations.',
    'Boost your GMAT score with a focused study plan for optimal results',
    'Find and tackle your toughest GMAT sections with expert guidance',
  ],
  pricingTitle: 'Unlimited Practice with Expert Solutions',
  pricingSubtitle: [
    'Elevate your GMAT preparation with high-quality questions and personalized insights at an affordable price.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'GMAT_2024_QUANTITATIVE',
      displayName: 'GMAT Quantitative Section',
      description:
        'Measures the ability to understand, interpret, and analyze quantitative data. Includes questions on arithmetic, algebra, and geometry. Tests problem-solving skills and application of mathematical concepts. Evaluates quantitative reasoning and mathematical understanding. Scores range from 0 to 60.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for calculator
      categories: [
        {
          categoryId: 'GMAT_2024_QUANTITATIVE_PROBLEM_SOLVING',
          displayName: 'Problem Solving',
          description:
            'Requires test-takers to use logic and analytical reasoning to solve quantitative problems, including arithmetic, algebra, and geometry concepts.',
          icon: 'remixLightbulbLine', // ng-icons Remix Icon for problem solving
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_QUANTITATIVE_PROBLEM_SOLVING_ARITHMETIC',
              displayName: 'Arithmetic',
              description:
                'Tests understanding of basic arithmetic concepts including addition, subtraction, multiplication, division, fractions, decimals, and percentages.',
            },
            {
              subcategoryId:
                'GMAT_2024_QUANTITATIVE_PROBLEM_SOLVING_ALGEBRA',
              displayName: 'Algebra',
              description:
                'Focuses on solving equations, inequalities, systems of equations, and understanding functions and algebraic expressions.',
            },
            {
              subcategoryId:
                'GMAT_2024_QUANTITATIVE_PROBLEM_SOLVING_GEOMETRY',
              displayName: 'Geometry',
              description:
                'Involves solving problems related to geometric shapes and properties, including angles, triangles, circles, and polygons.',
            },
          ],
        },
        {
          categoryId: 'GMAT_2024_QUANTITATIVE_DATA_SUFFICIENCY',
          displayName: 'Data Sufficiency',
          description:
            'Requires analyzing quantitative problems to determine if provided data is sufficient to solve them, focusing on analytical reasoning over computation.',
          icon: 'remixBarChartLine', // ng-icons Remix Icon for data sufficiency
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_QUANTITATIVE_DATA_SUFFICIENCY_ANALYZING_INFORMATION',
              displayName: 'Analyzing Information',
              description:
                'Tests ability to assess whether given data is sufficient to answer a question, involving logical and analytical reasoning.',
            },
            {
              subcategoryId:
                'GMAT_2024_QUANTITATIVE_DATA_SUFFICIENCY_DETERMINING_SUFFICIENCY',
              displayName: 'Determining Sufficiency',
              description:
                'Involves determining if data in multiple statements is sufficient to answer a question, considering each alone and in combination.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'GMAT_2024_VERBAL_REASONING',
      displayName: 'GMAT Verbal Reasoning Section',
      description:
        'Evaluates ability to read, understand, and analyze written material. Includes Reading Comprehension, Critical Reasoning, and Sentence Correction. Tests comprehension of complex texts, argument analysis, and grammatical accuracy. Scores range from 0 to 60.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for verbal reasoning
      categories: [
        {
          categoryId: 'GMAT_2024_VERBAL_REASONING_READING_COMPREHENSION',
          displayName: 'Reading Comprehension',
          description:
            'Assesses ability to understand, analyze, and apply information from written passages.',
          icon: 'remixFileTextLine', // ng-icons Remix Icon for reading comprehension
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_READING_COMPREHENSION_UNDERSTANDING_MAIN_IDEA',
              displayName: 'Understanding Main Idea',
              description:
                'Focuses on understanding the primary message or main idea of a passage.',
            },
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_READING_COMPREHENSION_INTERPRETING_DETAILS',
              displayName: 'Interpreting Details',
              description:
                'Tests ability to identify and interpret specific details or facts in a passage.',
            },
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_READING_COMPREHENSION_DRAWING_INFERENCES',
              displayName: 'Drawing Inferences',
              description:
                'Assesses ability to draw logical inferences or conclusions from provided information.',
            },
          ],
        },
        {
          categoryId: 'GMAT_2024_VERBAL_REASONING_CRITICAL_REASONING',
          displayName: 'Critical Reasoning',
          description:
            'Evaluates ability to analyze arguments and determine the strength and logic of reasoning presented.',
          icon: 'remixBrainLine', // ng-icons Remix Icon for critical reasoning
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_CRITICAL_REASONING_EVALUATING_ARGUMENTS',
              displayName: 'Evaluating Arguments',
              description:
                'Tests ability to evaluate the logical soundness of arguments and supporting evidence.',
            },
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_CRITICAL_REASONING_IDENTIFYING_FLAWS',
              displayName: 'Identifying Flaws',
              description:
                'Assesses ability to identify logical flaws or weaknesses in an argument.',
            },
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_CRITICAL_REASONING_STRENGTHENING_ARGUMENTS',
              displayName: 'Strengthening Arguments',
              description:
                'Tests ability to strengthen or support an argument with additional evidence or reasoning.',
            },
          ],
        },
        {
          categoryId: 'GMAT_2024_VERBAL_REASONING_SENTENCE_CORRECTION',
          displayName: 'Sentence Correction',
          description:
            'Assesses ability to correct grammatical errors and improve sentence structure according to standard written English.',
          icon: 'remixEditLine', // ng-icons Remix Icon for sentence correction
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_SENTENCE_CORRECTION_GRAMMAR_AND_USAGE',
              displayName: 'Grammar and Usage',
              description:
                'Focuses on correcting grammatical errors like subject-verb agreement, pronoun usage, modifiers, and parallelism.',
            },
            {
              subcategoryId:
                'GMAT_2024_VERBAL_REASONING_SENTENCE_CORRECTION_SENTENCE_STRUCTURE',
              displayName: 'Sentence Structure',
              description:
                'Assesses ability to improve sentence clarity and conciseness for logical flow and coherence.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'GMAT_2024_DATA_INSIGHTS',
      displayName: 'GMAT Data Insights Section',
      description:
        'Assesses ability to interpret and analyze complex data presented in various formats like charts, graphs, and tables. Tests skills in data interpretation, synthesis, and decision-making based on quantitative information. Scores range from 1 to 8.',
      icon: 'remixBarChartLine', // ng-icons Remix Icon for data insights
      categories: [
        {
          categoryId: 'GMAT_2024_DATA_INSIGHTS_DATA_INTERPRETATION',
          displayName: 'Data Interpretation',
          description:
            'Assesses ability to interpret and analyze data presented graphically or in tables.',
          icon: 'remixPieChartLine', // ng-icons Remix Icon for data interpretation
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_DATA_INSIGHTS_DATA_INTERPRETATION_ANALYZING_GRAPHS',
              displayName: 'Analyzing Graphs',
              description:
                'Focuses on interpreting data from graphs such as bar graphs, line graphs, and pie charts.',
            },
            {
              subcategoryId:
                'GMAT_2024_DATA_INSIGHTS_DATA_INTERPRETATION_INTERPRETING_TABLES',
              displayName: 'Interpreting Tables',
              description:
                'Tests ability to interpret and draw conclusions from data in tables.',
            },
          ],
        },
        {
          categoryId: 'GMAT_2024_DATA_INSIGHTS_CRITICAL_REASONING',
          displayName: 'Critical Reasoning',
          description:
            'Evaluates ability to analyze arguments and reasoning related to data.',
          icon: 'remixBrainLine', // ng-icons Remix Icon for critical reasoning
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_DATA_INSIGHTS_CRITICAL_REASONING_EVALUATING_DATA_ARGUMENTS',
              displayName: 'Evaluating Data Arguments',
              description:
                'Tests ability to evaluate the strength and validity of arguments based on data.',
            },
            {
              subcategoryId:
                'GMAT_2024_DATA_INSIGHTS_CRITICAL_REASONING_STRENGTHENING_DATA_ARGUMENTS',
              displayName: 'Strengthening Data Arguments',
              description:
                'Assesses ability to strengthen arguments using additional data or reasoning.',
            },
          ],
        },
        {
          categoryId: 'GMAT_2024_DATA_INSIGHTS_DATA_ANALYSIS',
          displayName: 'Data Analysis',
          description:
            'Assesses ability to analyze data to solve problems and make informed decisions.',
          icon: 'remixDatabaseLine', // ng-icons Remix Icon for data analysis
          subcategories: [
            {
              subcategoryId:
                'GMAT_2024_DATA_INSIGHTS_DATA_ANALYSIS_QUANTITATIVE_ANALYSIS',
              displayName: 'Quantitative Analysis',
              description:
                'Focuses on applying quantitative methods to analyze data and solve problems.',
            },
            {
              subcategoryId:
                'GMAT_2024_DATA_INSIGHTS_DATA_ANALYSIS_LOGICAL_ANALYSIS',
              displayName: 'Logical Analysis',
              description:
                'Tests ability to apply logical reasoning to analyze data and make decisions.',
            },
          ],
        },
      ],
    },
  ],
};
