import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {QuestionService} from '../../services/question-service'; // Inject QuestionService
import {QuestionResponse} from '../../models/question-response'; // Import QuestionResponse DTO
import {AnswerQuestionRequest} from '../../models/answer-question-request'; // Import AnswerQuestionRequest DTO
import {QuestionState} from './question-state.enum'; // Import the QuestionState enum
import {QuestionFormatType} from "../../models/question-format-type.enum"
import {ActivatedRoute, Router} from '@angular/router'; // Import ActivatedRoute
import {ApiState} from '../../services/api-state.interface';
import {RequestState} from "../../services/request-state.enum";
import {ExamSectionsSummaryResponse} from "../../models/exam-sections-summary-response";
import {forkJoin} from "rxjs";
import {NextQuestionRequest} from "../../models/next-question-request";
import {NextQuestionResponse} from "../../models/next-question-response";
import {MultipleChoiceComponent} from "./option-type/multiple-choice/multiple-choice.component";
import {EssayComponent} from "./option-type/essay/essay.component";
import {MultipleAnswerComponent} from "./option-type/multiple-answer/multiple-answer.component";
import {GridInComponent} from "./option-type/grid-in/grid-in.component";
import {LongFormEntryResponse} from "../../models/long-form-entry-response";
import {AnswerQuestionResponse} from "../../models/answer-question-response";
import {NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, TitleCasePipe} from "@angular/common";
import {ActionQuestionRequest} from "../../models/action-question-request";
import {ActionQuestionResponse} from "../../models/action-question-response";
import {
  ChoiceExplanationSolutionComponent
} from "./solution-type/choice-explanation-solution/choice-explanation-solution.component";
import {EssaySolutionComponent} from "./solution-type/essay-solution/essay-solution.component";
import {GridInSolutionComponent} from "./solution-type/grid-in-solution/grid-in-solution.component";
import {FormsModule} from "@angular/forms";
import {ExamSectionSummaryResponse} from "../../models/exam-section-summary-response";
import {DifficultyLevelResponse} from "../../models/difficulty-level-response";
import {ExamService} from "../../services/exam.service";
import {QuestionHintStatsComponent} from "./question-hint-stats-component/question-hint-stats-component.component";
import {MathjaxService} from "../../services/mathjax.service";
import {UpgradeModalComponent} from "../../shared/components/upgrade-modal/upgrade-modal.component";
import {HttpErrorResponse} from "@angular/common/http";
import {IconsModule} from '../../shared/icons/icons.module';
import {ExamData} from "../../services/exam-data-interface";
import {MetaService} from "../../services/meta.service";

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [
    NgClass,
    NgSwitch,
    GridInComponent,
    EssayComponent,
    MultipleAnswerComponent,
    MultipleChoiceComponent,
    TitleCasePipe,
    NgIf,
    NgForOf,
    NgSwitchCase,
    NgSwitchDefault,
    ChoiceExplanationSolutionComponent,
    EssaySolutionComponent,
    GridInSolutionComponent,
    FormsModule,
    QuestionHintStatsComponent,
    UpgradeModalComponent,
    IconsModule
  ],
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss', '../../../additional-styles.scss']
})
export class QuestionComponent implements OnInit, AfterViewChecked {
  questionId: string | null = null;
  questionState: QuestionState = QuestionState.LOADING;

  questionResponse: QuestionResponse | null = null;
  answerQuestionResponse: AnswerQuestionResponse | null = null;
  errorMessage: string | null = null;
  showUpgradeModal = false;

  examData: ExamData | null = null;

  // userQuestionMetadataResponse: UserQuestionMetadataResponse | null = null;  // Holds the user question metadata
  // userQuestionInteractionResponse: UserQuestionInteractionResponse | null = null;  // Holds the latest user interaction
  examSectionsSummaryResponse: ExamSectionsSummaryResponse | null = null;  // Store the exam sections summary for dropdown

  autoExamSectionSummaryResponse: ExamSectionSummaryResponse = {
    examSectionDisplayName: "Auto",
    examSectionId: "auto",
    examSectionName: "Auto",
    ordering: -1
  };

  autoDifficultyLevelResponse: DifficultyLevelResponse = {
    difficultyDisplayName: "Auto",
    difficultyLevelId: "auto",
    difficultyName: "Auto",
    maxDifficultyLevel: 0,
    minDifficultyLevel: 0,
    numericDifficultyLevel: -1,
  };

  selectedExamSection: ExamSectionSummaryResponse = this.autoExamSectionSummaryResponse;  // Track the selected section ID
  selectedDifficultyLevel: DifficultyLevelResponse = this.autoDifficultyLevelResponse;
  isSectionMenuOpen = false;  // Controls section dropdown visibility
  isDifficultyMenuOpen = false;  // Controls difficulty dropdown visibility

  startTime: Date | null = null;  // Store start time as Date object
  endTime: Date | null = null;    // Store end time as Date object
  isAnswerValid: boolean = false;  // Tracks if the answer is valid
  isBookmarked: boolean = false;  // Initial state of bookmarked to be fetched from db.
  isLiked: boolean = false;  // Initial state of bookmarked to be fetched from db.

  // ViewChild to access child components
  @ViewChild(MultipleChoiceComponent) multipleChoiceComponent: MultipleChoiceComponent | null = null;
  @ViewChild(EssayComponent) essayComponent: EssayComponent | null = null;
  @ViewChild(MultipleAnswerComponent) multipleAnswerComponent: MultipleAnswerComponent | null = null;
  @ViewChild(GridInComponent) gridInComponent: GridInComponent | null = null;

  constructor(
    private cd: ChangeDetectorRef,
    private mathjaxService: MathjaxService,
    private sanitizer: DomSanitizer,
    private questionService: QuestionService,
    private examService: ExamService,
    private router: Router,  // Inject Router for navigation
    private route: ActivatedRoute,  // Inject ActivatedRoute to access the route parameters
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: Object // Inject PLATFORM_ID
  ) {}

  ngOnInit(): void {
    // Extract the questionId from the route and load the question
    this.route.paramMap.subscribe(params => {
      this.questionId = params.get('question-id');
      if (this.questionId) {
        this.resetVariables();
        this.loadData(this.questionId);
      } else {
        // Use the default tags if not loaded.
        const metaData = this.route.snapshot.data;
        this.metaService.updateMetaTags(metaData);

        this.questionState = QuestionState.ERROR;
        this.errorMessage = 'Invalid question ID';
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      // Render MathJax when the component is initialized
      this.mathjaxService.renderMath();
    }
  }

  ngAfterViewChecked(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Re-render MathJax after view has been checked
      this.mathjaxService.renderMath();
    }
  }

  resetVariables() {
    this.questionState = QuestionState.LOADING;
    this.questionResponse = null;
    this.errorMessage = null;
    this.answerQuestionResponse = null;
    this.examSectionsSummaryResponse = null;

    // Resetting dropdown states
    this.isSectionMenuOpen = false;
    this.isDifficultyMenuOpen = false;

    // Resetting the time values
    this.startTime = null;  // No start time initially
    this.endTime = null;    // No end time initially
    this.isAnswerValid = false;
    this.isBookmarked = false;
    this.isLiked = false;

    // Resetting the ViewChild components to their default values (null)
    this.multipleChoiceComponent?.resetVariables();
    this.essayComponent?.resetVariables();
    this.multipleAnswerComponent?.resetVariables();
    this.gridInComponent?.resetVariables();

  }

  // Method to load question data, user metadata, user interaction, and exam sections summary
  loadData(questionId: string): void {
    console.log("loading data for questionId: " + questionId);
    this.questionState = QuestionState.LOADING;

    forkJoin({
      questionResponseState: this.questionService.getQuestion(questionId),
      examSectionsSummaryResponseState: this.questionService.getExamSectionsSummary(questionId),
    }).subscribe({
      next: result => {
        // Handle the results of the requests
        if (
          result.questionResponseState.state === RequestState.SUCCESS &&
          result.examSectionsSummaryResponseState.state === RequestState.SUCCESS
        ) {
          // Update component variables with successful data
          this.questionResponse = result.questionResponseState.data;
          this.examSectionsSummaryResponse = result.examSectionsSummaryResponseState.data;
          this.examService.updateCurrentExamId(this.questionResponse?.examId ?? null);
          this.examData = this.examService.getExamDataFromExamId(this.questionResponse?.examId ?? null);

          if (this.examData == null) {
            this.router.navigate(['/error']);
            return;
          }

          // Dynamically set the tags
          const displayName = this.examData.displayName;
          const canonicalUrl = this.metaService.generateCanonicalUrl();
          const baseMetaData = this.route.snapshot.data;
          const metaData = {
            ...baseMetaData,
            title: `${displayName} Exam Prep | ${displayName} Study Guide | Studious`,
            description: `Get the best practice questions and exam prep for the ${displayName} exam. Use AI adaptive learning to improve your ${displayName} score.`,
            keywords: `${displayName} prep, ${displayName} practice tests, ${displayName} questions, ${displayName} free`,
            canonical: canonicalUrl
          };
          this.metaService.updateMetaTags(metaData);

          let examSectionId = null;
          let difficultyLevelId = null;
          if (isPlatformBrowser(this.platformId)) {
            examSectionId = localStorage.getItem('examSectionId');
            difficultyLevelId = localStorage.getItem('difficultyLevelId');
          }

          if (examSectionId) {
            const matchingSection = this.examSectionsSummaryResponse?.examSectionSummaryResponses?.find(
              (section) => section.examSectionId === examSectionId
            );

            if (matchingSection) {
              this.selectedExamSection = matchingSection;
            }
          }

          if (difficultyLevelId) {
            const matchingDifficulty = this.examSectionsSummaryResponse?.examSummaryResponse.difficultyGroupResponse.difficultyLevelResponses.find(
              level => level.difficultyLevelId === difficultyLevelId);
            if (matchingDifficulty) {
              this.selectedDifficultyLevel = matchingDifficulty;
            }
          }

          let resetQuestion: boolean = this.questionResponse?.userQuestionMetadataResponse?.reset !== null && this.questionResponse?.userQuestionMetadataResponse?.reset === true;
          if (this.questionResponse?.answerQuestionResponse !== null && !resetQuestion) {
            this.answerQuestionResponse = this.questionResponse?.answerQuestionResponse ?? null;
          }

          if (this.questionResponse?.userQuestionMetadataResponse !== null) {
            if (this.questionResponse?.userQuestionMetadataResponse?.bookmarked != null &&
              this.questionResponse?.userQuestionMetadataResponse?.bookmarked == true) {
              this.isBookmarked = true;
            }

            if (this.questionResponse?.userQuestionMetadataResponse?.liked !== null &&
              this.questionResponse?.userQuestionMetadataResponse?.liked == true) {
              this.isLiked = true;
            }
          }

          if (resetQuestion) {
            // If the question metadata is set to reset, then show question.
            this.questionState = QuestionState.SHOW_QUESTION;
          } else if (this.answerQuestionResponse?.userQuestionInteractionResponse?.skipped
            && this.answerQuestionResponse?.userQuestionInteractionResponse?.skipped === true) {
            // If the last interaction was skip, then show question.
            this.questionState = QuestionState.SHOW_QUESTION;
          } else if (this.answerQuestionResponse !== null) {
            this.questionState = QuestionState.REVIEW;
          } else {
            this.questionState = QuestionState.SHOW_QUESTION;
          }
        } else {
          // Handle errors if question data or sections cannot be loaded.
          this.questionState = QuestionState.ERROR;
          this.errorMessage = 'Failed to load one or more components';
          console.log(this.errorMessage)
          // this.router.navigate(['/dashboard-error']);  // Navigate to error page if data fails to load
        }
        // Set start time as Date object when question is loaded
        this.startTime = new Date();

        console.log('questionResponse=', this.questionResponse);
        console.log('examSectionsSummaryResponse=', this.examSectionsSummaryResponse);
        console.log('answerQuestionResponse=', this.answerQuestionResponse);
      },
      error: (err) => {
        const metaData = this.route.snapshot.data;
        this.metaService.updateMetaTags(metaData);

        this.questionState = QuestionState.ERROR;
        this.errorMessage = 'Failed to load one or more components';
        console.error('An error occurred:', err);
        // this.router.navigate(['/dashboard-error']);  // Navigate to error page if data fails to load
      },
      complete: () => {
        console.log('All observables completed');
        // This shouldn't be in loading, but if it is, then make the state into error.
        if (this.questionState === QuestionState.LOADING) {
          this.questionState = QuestionState.ERROR;
        }
      }
    });
  }

  // Submit the selected answer using QuestionService
  submitAnswer(): void {
    console.log('submitAnswer');

    this.questionState = QuestionState.VALIDATE; // Set state to validation
    //this.cd.detectChanges();  // Force change detection here
    let questionType: string | undefined = this.questionResponse?.questionFormatResponse?.type;

    if (!questionType || !this.questionResponse) {
      this.questionState = QuestionState.ERROR;
      return;
    }

    this.validateAnswer();

    // Error messages will be displayed in the components themselves.
    if (!this.isAnswerValid) {
      console.log("answer is invalid");
      this.questionState = QuestionState.VALIDATE_FAIL;  // Set state to validation
      this.questionState = QuestionState.SHOW_QUESTION;  // Set state to validation, so that they can continue to answer the questions
      return;
    }

    this.questionState = QuestionState.VALIDATE_SUCCESS;
    // Set end time as Date object when answer is submitted
    this.endTime = new Date();

    const answerQuestionRequest: AnswerQuestionRequest = {
      questionId: this.questionResponse.questionId,
      elapsedTimeMilliseconds: this.calculateElapsedTime(),
      startTimestamp: this.startTime?.toISOString(),  // Convert start time to ISO string
      endTimestamp: this.endTime?.toISOString(),      // Convert end time to ISO string
      isSkip: false
    };

    if ((questionType === QuestionFormatType.MULTIPLE_CHOICE
        || questionType === QuestionFormatType.DATA_SUFFICIENCY_MULTIPLE_CHOICE
        || questionType === QuestionFormatType.QUANT_COMPARISON_MULTIPLE_CHOICE
        || questionType === QuestionFormatType.PASSAGE_BASED_MULTIPLE_CHOICE
        || questionType === QuestionFormatType.VIGNETTE_MULTIPLE_CHOICE)
      && this.multipleChoiceComponent?.selectedOption) {
      answerQuestionRequest.choiceResponse = {choice: [this.multipleChoiceComponent?.selectedOption]};
    }

    else if (questionType === QuestionFormatType.MULTIPLE_ANSWER
      && this.multipleAnswerComponent?.selectedOptions) {
      answerQuestionRequest.choiceResponse = {choice: this.multipleAnswerComponent?.selectedOptions};
    }

    // Written responses are in long form entry
    else if ((questionType === QuestionFormatType.ESSAY
        || questionType === QuestionFormatType.FREE_RESPONSE
        || questionType === QuestionFormatType.SHORT_ANSWER)
        && this.essayComponent?.essayText) {
      const longFormEntryResponse: LongFormEntryResponse = {
        essay: this.essayComponent?.essayText.trim(),
      };
      answerQuestionRequest.longFormEntryResponse = longFormEntryResponse;
    }

    else if ((questionType == QuestionFormatType.GRID_IN
        || questionType == QuestionFormatType.FILL_IN_NUMERICAL)
        && this.gridInComponent?.gridInAnswer) {
      answerQuestionRequest.choiceResponse = {choice: [this.gridInComponent?.gridInAnswer]};
    }
    else if ((questionType == QuestionFormatType.SPEAKING_TASK
      || questionType == QuestionFormatType.WRITING_TASK)
        && this.essayComponent?.essayText) {
        const longFormEntryResponse: LongFormEntryResponse = {
          essay: this.essayComponent?.essayText.trim(),
        };
        answerQuestionRequest.longFormEntryResponse = longFormEntryResponse;
    }
    else {
      this.questionState = QuestionState.ERROR;
      this.errorMessage = 'Unknown question option-type.';
      console.error("Unknown question option-type");
    }

    this.questionState = QuestionState.SUBMIT;

    console.log('answerQuestionRequest=', answerQuestionRequest);

    this.questionService.submitAnswer(answerQuestionRequest).subscribe({
      next: (answerQuestionResponseState: ApiState<AnswerQuestionResponse>) => {
        if (answerQuestionResponseState.state === RequestState.SUCCESS) {
          this.answerQuestionResponse = answerQuestionResponseState.data

          if (this.answerQuestionResponse?.userQuestionInteractionResponse?.correct != null
            && this.answerQuestionResponse?.userQuestionInteractionResponse?.correct == false) {
            // in the
            this.questionState = QuestionState.REVIEW_INCORRECT
          } else {
            this.questionState = QuestionState.REVIEW;
          }
        } else if (answerQuestionResponseState.state === RequestState.ERROR) {

          if (answerQuestionResponseState.error instanceof HttpErrorResponse &&
            answerQuestionResponseState.error.status === 429) {
            // Too Many Requests - Show upgrade modal
            this.showUpgradeModal = true;
            // don't go into a state of error if is too many requests, go back to show state
            this.questionState = QuestionState.SHOW_QUESTION;
          } else {
            this.questionState = QuestionState.ERROR;
            this.errorMessage = "Unable to submit answer.";
          }
        }
      },
      error: (err) => {
        console.log('err.status=', err.status);

        this.questionState = QuestionState.ERROR;
        this.errorMessage = "Error unable to submit answer.";
        console.log(this.errorMessage);

      }
    });
  }

  reviewQuestion(): void {
    this.questionState = QuestionState.REVIEW;
  }

  // Skip the current question
  async skipQuestion() {
    this.questionState = QuestionState.SKIP;
    // this.cd.detectChanges();  // Force change detection here

    // This forces the state to be picked up;
    await sleep(100);

    if (!this.questionResponse) {
      this.questionState = QuestionState.ERROR;  // Set state to validation
      return;
    }

    this.endTime = new Date();

    const answerRequest: AnswerQuestionRequest = {
      questionId: this.questionResponse.questionId,
      elapsedTimeMilliseconds: this.calculateElapsedTime(),
      startTimestamp: this.startTime?.toISOString(),  // Convert start time to ISO string
      endTimestamp: this.endTime?.toISOString(),      // Convert end time to ISO string
      isSkip: true
    };

    this.questionService.submitAnswer(answerRequest).subscribe({
      next: (answerQuestionResponseState: ApiState<AnswerQuestionResponse>) => {
          if (answerQuestionResponseState.state === RequestState.SUCCESS) {
            this.answerQuestionResponse = answerQuestionResponseState.data
            this.nextQuestion();
          } else if (answerQuestionResponseState.state === RequestState.ERROR) {
            if (answerQuestionResponseState.error instanceof HttpErrorResponse &&
              answerQuestionResponseState.error.status === 429) {
              // Too Many Requests - Show upgrade modal
              this.showUpgradeModal = true;
              // don't go into a state of error if is too many requests
              this.questionState = QuestionState.SHOW_QUESTION;
            } else {
              this.questionState = QuestionState.ERROR;
              this.errorMessage = "Unable to skip question.";
            }
          }
        },
      error: (err) => {
        this.questionState = QuestionState.ERROR;
        this.errorMessage = "Unable to skip answer.";
        console.log(this.errorMessage);
      }
    });
  }

  // Move to the next question
  nextQuestion(): void {
    // this.questionState = QuestionState.NEXT;
    // Set the state to LOADING to prevent the brief rendering of the previous question
    this.questionState = QuestionState.LOADING;

    // Ensure the UI is updated immediately
    this.cd.detectChanges();  // Force change detection

    const nextQuestionRequest: NextQuestionRequest = {
      examId: this.examSectionsSummaryResponse?.examSummaryResponse.examId,
      smart: true
    };

    if (this.selectedDifficultyLevel.difficultyLevelId != this.autoDifficultyLevelResponse.difficultyLevelId) {
      nextQuestionRequest.difficultyLevelId = this.selectedDifficultyLevel.difficultyLevelId;
    }

    if (this.selectedExamSection.examSectionId != this.autoExamSectionSummaryResponse.examSectionId) {
      nextQuestionRequest.examSectionId = this.selectedExamSection.examSectionId;
    }

    this.questionService.getNextQuestion(nextQuestionRequest).subscribe({
        next: (nextQuestionResponseState: ApiState<NextQuestionResponse>) => {
          if (nextQuestionResponseState.state === RequestState.SUCCESS &&
            nextQuestionResponseState.data?.questionId) {
            // Navigate to the next question using the questionId from the response
            this.router.navigate(['/question', nextQuestionResponseState.data?.questionId]);
          } else if (nextQuestionResponseState.state === RequestState.ERROR) {
            console.log('nextQuestionResponseState:', nextQuestionResponseState);
            this.errorMessage = 'There are no more questions that match the filter criteria.'
            this.questionState = QuestionState.ERROR;
          }
        },
        error: err => {
          console.log(err);
          this.errorMessage = 'There are no more questions that match the filter criteria.'
          this.questionState = QuestionState.ERROR;
        }
      });
  }

  // Try again (reset question)
  tryAgain(): void {
    this.questionState = QuestionState.TRY_AGAIN;
    if (this.questionId) {
      this.questionService.clearQuestion(this.questionId).subscribe((state: ApiState<boolean>) => {
        if (state.state === RequestState.SUCCESS && state.data === true) {
          this.resetVariables();
          if (this.questionId) {
            this.loadData(this.questionId);
          } else {
            this.questionState = QuestionState.ERROR;
            this.errorMessage = 'Failed to reset the question.';
          }
        } else if (state.state == RequestState.ERROR) {
          this.resetVariables();
          if (this.questionId) {
            this.loadData(this.questionId);
          }
          this.questionState = QuestionState.ERROR;
          this.errorMessage = 'Failed to reset the question.';
        }
      });
    }
  }

  // Calculate elapsed time in milliseconds
  calculateElapsedTime(): number {
    if (this.startTime && this.endTime) {
      return this.endTime.getTime() - this.startTime.getTime();
    }
    return 0;
  }

  validateAnswer(): void {
    let questionType: string | undefined = this.questionResponse?.questionFormatResponse?.type;

    console.log('questionType', questionType);
    if (!questionType || !this.questionResponse) {
      this.questionState = QuestionState.ERROR;  // Set state to validation
      return;
    }

    if (questionType === QuestionFormatType.MULTIPLE_CHOICE ||
        questionType === QuestionFormatType.DATA_SUFFICIENCY_MULTIPLE_CHOICE ||
        questionType === QuestionFormatType.QUANT_COMPARISON_MULTIPLE_CHOICE ||
        questionType === QuestionFormatType.PASSAGE_BASED_MULTIPLE_CHOICE ||
        questionType === QuestionFormatType.VIGNETTE_MULTIPLE_CHOICE) {
      this.multipleChoiceComponent?.validate();
    } else if (questionType === QuestionFormatType.MULTIPLE_ANSWER) {
      this.multipleAnswerComponent?.validate();
    } else if (questionType === QuestionFormatType.ESSAY ||
      questionType === QuestionFormatType.FREE_RESPONSE ||
      questionType === QuestionFormatType.SHORT_ANSWER) {
      this.essayComponent?.validate();
    } else if (questionType === QuestionFormatType.GRID_IN ||
      questionType === QuestionFormatType.FILL_IN_NUMERICAL) {
      this.gridInComponent?.validate();
    } else if ( questionType === QuestionFormatType.SPEAKING_TASK ||
      questionType === QuestionFormatType.WRITING_TASK) {
      this.essayComponent?.validate();
    }
  }

  // Handle validation result from child components
  handleValidation(isValid: boolean): void {
    this.isAnswerValid = isValid;
  }

  twoPanelLayout(): boolean {
    if (this.questionState == QuestionState.REVIEW || this.questionState == QuestionState.NEXT) {
      return true;
    }
    return false;
  }

  questionButtonsLoadingState(): boolean {
    return this.questionState === QuestionState.VALIDATE ||
      this.questionState === QuestionState.VALIDATE_SUCCESS ||
      this.questionState === QuestionState.SUBMIT ||
      this.questionState === QuestionState.SKIP ||
      this.questionState === QuestionState.TRY_AGAIN;
  }

  reloadQuestion() {
    console.log('reload question for url ' + this.router.url);
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate([this.router.url]);
    }
  }

  toggleBookmark() {
    if (!this.questionResponse || !this.questionResponse?.questionId) {
      this.questionState = QuestionState.ERROR;  // Set state to validation
      return;
    }

    this.isBookmarked = !this.isBookmarked;  // Toggle state in UI

    let actionQuestionRequest: ActionQuestionRequest = {
      questionId: this.questionResponse.questionId,
    };

    if (this.isBookmarked) {
      actionQuestionRequest.action = 'bookmark';
    } else {
      actionQuestionRequest.action = 'unbookmark';
    }

    // Make an API call to update the state in the database
    this.questionService.actionQuestion(actionQuestionRequest).subscribe(
      (response: ApiState<ActionQuestionResponse>) => {
        console.log('Bookmark state updated:', response?.data);
      });
  }

  navigateToDashboard(): void {
    this.examService.navigateToDashboard(this.examSectionsSummaryResponse?.examSummaryResponse?.examId ?? null)
      .then(success => {
        if (success) {
          console.log('Navigation to dashboard succeeded');
        } else {
          console.log('Navigation to dashboard failed');
        }
      })
      .catch(error => {
        console.error('Error during navigation:', error);
      });
  }

  getDifficultyCss(): string {
    if (
      this.questionResponse?.difficultyLevelResponse?.numericDifficultyLevel != null &&
      this.questionResponse?.difficultyLevelResponse?.maxDifficultyLevel != null &&
      this.questionResponse?.difficultyLevelResponse?.minDifficultyLevel != null
    ) {
      const factor =
        (this.questionResponse?.difficultyLevelResponse?.numericDifficultyLevel - this.questionResponse?.difficultyLevelResponse?.minDifficultyLevel) /
        (this.questionResponse?.difficultyLevelResponse?.maxDifficultyLevel - this.questionResponse?.difficultyLevelResponse?.minDifficultyLevel);
      if (factor < 1 / 3) {
        return 'easy';
      } else if (factor < 2 / 3) {
        return 'medium';
      }
      return 'hard';
    }
    return 'unknown';
  }

  // Some of the display fields are in the component instead of the html since there are angular
  // compiler errors
  get hasCategories(): boolean {
    return !!this.questionResponse?.categoriesResponse?.categories
      && this.questionResponse.categoriesResponse.categories.length > 0;
  }

  get firstCategoryDisplayName(): string {
    return this.questionResponse?.categoriesResponse?.categories[0]?.categoryDisplayName ?? '';
  }

  get hasSubcategories(): boolean {
    return !!this.questionResponse?.subcategoriesResponse?.subcategories
      && this.questionResponse.subcategoriesResponse.subcategories.length > 0;
  }

  get firstSubcategoryDisplayName(): string {
    return this.questionResponse?.subcategoriesResponse?.subcategories[0]?.subcategoryDisplayName ?? '';
  }

  get hasPrompt(): boolean {
    return !!this.questionResponse?.prompt?.prompt
      && this.questionResponse.prompt.prompt.length > 0;
  }

  get sanitizedPrompt() {
    const sanitizedPrompt = this.sanitizer.bypassSecurityTrustHtml(<string>this.questionResponse?.prompt?.prompt);
    return sanitizedPrompt;
  }

  get hasSections(): boolean {
    return this.examSectionsSummaryResponse?.examSectionSummaryResponses != null && this.examSectionsSummaryResponse?.examSectionSummaryResponses.length > 0;
  }

  get examSections(): ExamSectionSummaryResponse[] {
    console.log('examSections responses', this.examSectionsSummaryResponse?.examSectionSummaryResponses);

    // Prepend the "Auto" entry using array spread or concat
    const sections = [this.autoExamSectionSummaryResponse].concat(this.examSectionsSummaryResponse?.examSectionSummaryResponses ?? []);

    console.log('get sections:', sections.length);

    return sections;
  }

  get difficulties() {
    console.log('difficulties responses', this.examSectionsSummaryResponse?.examSummaryResponse.difficultyGroupResponse.difficultyLevelResponses);

    // Prepend "Auto" entry to the difficulties list
    const difficulty_levels = [this.autoDifficultyLevelResponse].concat(this.examSectionsSummaryResponse?.examSummaryResponse.difficultyGroupResponse.difficultyLevelResponses ?? []);

    console.log('get difficulties:', difficulty_levels.length);

    return difficulty_levels;
  }

  selectSection(examSection: ExamSectionSummaryResponse) {
    this.selectedExamSection = examSection;
    this.isSectionMenuOpen = false;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('examSectionId', this.selectedExamSection.examSectionId);
    }
  }

  selectDifficulty(difficulty: DifficultyLevelResponse) {
    this.selectedDifficultyLevel = difficulty;
    this.isDifficultyMenuOpen = false;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('difficultyLevelId', this.selectedDifficultyLevel.difficultyLevelId);
    }
  }

  toggleSectionMenu() {
    this.isSectionMenuOpen = !this.isSectionMenuOpen;
    this.isDifficultyMenuOpen = false;  // Close difficulty menu if open
  }

  toggleDifficultyMenu() {
    this.isDifficultyMenuOpen = !this.isDifficultyMenuOpen;
    this.isSectionMenuOpen = false;  // Close section menu if open
  }

  // Close menus when clicking outside
  @HostListener('document:click', ['$event'])
  closeMenuOnClickOutside(event: MouseEvent) {
    if (isPlatformBrowser(this.platformId)) {
      const target = event.target as HTMLElement;
      const sectionMenuButton = document.getElementById('section-menu-button');
      const difficultyMenuButton = document.getElementById('difficulty-menu-button');

      if (sectionMenuButton && !sectionMenuButton.contains(target)) {
        this.isSectionMenuOpen = false;
      }

      if (difficultyMenuButton && !difficultyMenuButton.contains(target)) {
        this.isDifficultyMenuOpen = false;
      }
    }
  }

  applyFilters() {
  }

  examName() {
    if (this.examSectionsSummaryResponse !== null) {
      return this.examSectionsSummaryResponse?.examSummaryResponse?.examDisplayName;
    }
    return null;
  }

  closeModal(): void {
    this.showUpgradeModal = false;
  }

  protected readonly QuestionState = QuestionState;

  protected readonly QuestionFormatType = QuestionFormatType;
}
