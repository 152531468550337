import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {IconsModule} from "../../shared/icons/icons.module";
import {MetaService} from "../../services/meta.service";


@Component({
  selector: 'app-error',
  standalone: true,
    imports: [
      RouterLink,
      IconsModule
    ],
  templateUrl: './error.component.html',
  styleUrl: './error.component.css'
})
export class ErrorComponent implements OnInit {
  errorMessage: string = 'Something went wrong.';

  constructor(private route: ActivatedRoute, private metaService: MetaService) {}

  ngOnInit(): void {
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);

    this.route.queryParams.subscribe(params => {
      if (params['message']) {
        this.errorMessage = params['message'];
      }
    });
  }
}
