import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnswerQuestionResponse} from "../../../../models/answer-question-response";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {NgForOf, NgIf} from "@angular/common";
import {joinWithCommaAndOr} from "../../../../shared/util/string-utils";

@Component({
  selector: 'grid-in-solution',
  standalone: true,
  imports: [
    NgIf,
    NgForOf
  ],
  templateUrl: './grid-in-solution.component.html',
  styleUrl: './grid-in-solution.component.css'
})
export class GridInSolutionComponent implements OnInit, OnChanges {
  @Input() answerQuestionResponse: AnswerQuestionResponse | null = null;
  sanitizedExplanationParts: SafeHtml[] = [];  // Array of sanitized pieces

  constructor(private sanitizer: DomSanitizer) {}

  // Called once when the component is initialized
  ngOnInit(): void {
    if (this.answerQuestionResponse?.questionSolution?.explanation) {
      this.splitAndSanitizeExplanation(this.answerQuestionResponse?.questionSolution?.explanation);
    }
  }

  // Called whenever the input property 'explanation' changes
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['answerQuestionResponse'] && changes['answerQuestionResponse'].currentValue ) {
      this.splitAndSanitizeExplanation(changes['answerQuestionResponse'].currentValue?.questionSolution?.explanation);
    }
  }

  get hasCorrect() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.correct != null;
  }

  get correct() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.correct;
  }

  get hasCorrectChoice() {
    return this.answerQuestionResponse?.correctChoiceResponse?.correct_choice != null;
  }

  get correctChoice() {
    const correctChoiceArray = this.answerQuestionResponse?.userQuestionInteractionResponse?.correctChoiceResponse?.correct_choice ?? [];
    return correctChoiceArray;
  }

  get hasChoice() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.choiceResponse?.choice != null;
  }

  get choice() {
    return this.answerQuestionResponse?.userQuestionInteractionResponse?.choiceResponse?.choice.join(', ') ?? '';
  }

  get hasExplanation(): boolean {
    return !!this.answerQuestionResponse?.questionSolution?.explanation
      && this.answerQuestionResponse?.questionSolution?.explanation.length > 0;
  }

  get sanitizedExplanation() : SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.answerQuestionResponse?.questionSolution?.explanation || '');
  }

  // Split the explanation string into an array of parts and sanitize each part
  splitAndSanitizeExplanation(explanation: string): void {
    // Split the string by sentences (you can change the delimiter as needed)
    const parts = explanation.split('.');  // Splits by periods (sentences)

    // Sanitize each part and store it in the array
    this.sanitizedExplanationParts = parts.map(part =>
      this.sanitizer.bypassSecurityTrustHtml(part.trim() + '.')
    );
  }
}
