// TOEFL Exam Data
import {ExamData} from "../exam-data-interface";

export const toeflExamData: ExamData = {
  examId: 'TOEFL_2024',
  displayName: 'TOEFL',
  description:
    'The TOEFL evaluates the ability to use and understand English at the university level, testing reading, listening, speaking, and writing skills.',
  logo: 'assets/images/exams/toefl_logo.webp',
  link: 'toefl',
  zoom: 1.1,
  priceId: 'TOEFL_2024_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Enhance Your English with TOEFL Prep',
  marketingSubtitles: [
    'Our adaptive platform helps you master English for academic success.',
    'Access a vast library of TOEFL practice questions with detailed feedback.',
    'Elevate your TOEFL score with customized language practice',
    'Uncover and enhance your weak areas in TOEFL listening, reading, speaking, and writing',
  ],
  pricingTitle: 'Unlimited Practice with Detailed Feedback',
  pricingSubtitle: [
    'Improve your English proficiency with high-quality questions and personalized insights at an affordable price.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'TOEFL_2024_READING',
      displayName: 'TOEFL Reading Section',
      description:
        'Assesses the ability to understand and analyze written academic texts. Includes 3 to 4 passages followed by questions. Evaluates skills like identifying main ideas, details, inferences, and understanding text structure and purpose. Measures reading comprehension necessary for academic success. Scores range from 0 to 30.',
      icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading
      categories: [
        {
          categoryId: 'TOEFL_2024_READING_READING_COMPREHENSION',
          displayName: 'Reading Comprehension',
          description:
            'Assesses the ability to understand and interpret information in academic texts.',
          icon: 'remixFileTextLine', // ng-icons Remix Icon for comprehension
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_READING_READING_COMPREHENSION_FACTUAL_INFORMATION',
              displayName: 'Factual Information',
              description:
                'Questions focus on identifying explicit information in the text.',
            },
            {
              subcategoryId:
                'TOEFL_2024_READING_READING_COMPREHENSION_INFERENCE',
              displayName: 'Inference',
              description:
                'Tests the ability to make logical inferences based on information in the text.',
            },
          ],
        },
        {
          categoryId: 'TOEFL_2024_READING_VOCABULARY_IN_CONTEXT',
          displayName: 'Vocabulary in Context',
          description:
            'Evaluates the ability to understand the meaning of words and phrases within the passage context.',
          icon: 'remixGlobalLine', // ng-icons Remix Icon for vocabulary
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_READING_VOCABULARY_IN_CONTEXT_WORD_MEANING',
              displayName: 'Word Meaning',
              description:
                'Assesses the ability to determine the meaning of specific words or phrases in context.',
            },
            {
              subcategoryId:
                'TOEFL_2024_READING_VOCABULARY_IN_CONTEXT_PHRASE_MEANING',
              displayName: 'Phrase Meaning',
              description:
                'Assesses the ability to determine the meaning of specific phrases in context.',
            },
          ],
        },
        {
          categoryId: 'TOEFL_2024_READING_SENTENCE_SIMPLIFICATION',
          displayName: 'Sentence Simplification',
          description:
            'Tests the ability to simplify complex sentences while retaining their essential meaning.',
          icon: 'remixSettings3Line', // ng-icons Remix Icon for simplification
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_READING_SENTENCE_SIMPLIFICATION_SIMPLIFYING_COMPLEX_SENTENCES',
              displayName: 'Simplifying Complex Sentences',
              description:
                'Tests the ability to simplify complex sentences without changing their essential meaning.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'TOEFL_2024_LISTENING',
      displayName: 'TOEFL Listening Section',
      description:
        'Measures the ability to understand spoken English in academic settings. Includes 4 to 6 listening passages such as conversations, discussions, and lectures. Evaluates comprehension of main ideas, details, and speaker intentions. Tests listening skills crucial for academic discourse. Scores range from 0 to 30.',
      icon: 'remixHeadphoneLine', // ng-icons Remix Icon for listening
      categories: [
        {
          categoryId: 'TOEFL_2024_LISTENING_BASIC_COMPREHENSION',
          displayName: 'Basic Comprehension',
          description:
            'Assesses the ability to understand main ideas and key details from listening passages.',
          icon: 'remixLightbulbLine', // ng-icons Remix Icon for comprehension
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_LISTENING_BASIC_COMPREHENSION_MAIN_IDEA',
              displayName: 'Main Idea',
              description:
                'Identifying the main idea or primary purpose of the listening passage.',
            },
            {
              subcategoryId:
                'TOEFL_2024_LISTENING_BASIC_COMPREHENSION_DETAIL_RECOGNITION',
              displayName: 'Detail Recognition',
              description:
                'Recognizing and recalling specific details from the listening passage.',
            },
          ],
        },
        {
          categoryId: 'TOEFL_2024_LISTENING_PRAGMATIC_UNDERSTANDING',
          displayName: 'Pragmatic Understanding',
          description:
            'Evaluates the ability to understand the speaker’s intentions, tone, and attitude.',
          icon: 'remixEmotionHappyLine', // ng-icons Remix Icon for understanding emotions
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_LISTENING_PRAGMATIC_UNDERSTANDING_PURPOSE_OF_SPEECH',
              displayName: 'Purpose of Speech',
              description:
                'Understanding why the speaker says something or their intention.',
            },
            {
              subcategoryId:
                'TOEFL_2024_LISTENING_PRAGMATIC_UNDERSTANDING_TONE_ATTITUDE',
              displayName: 'Tone and Attitude',
              description:
                'Determining the speaker’s tone or attitude toward a subject.',
            },
          ],
        },
        {
          categoryId: 'TOEFL_2024_LISTENING_CONNECTING_INFORMATION',
          displayName: 'Connecting Information',
          description:
            'Assesses the ability to synthesize and connect information from different parts of the listening passage.',
          icon: 'remixLink', // ng-icons Remix Icon for connecting
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_LISTENING_CONNECTING_INFORMATION_ORGANIZATION',
              displayName: 'Organization',
              description:
                'Understanding the organization and structure of the listening passage.',
            },
            {
              subcategoryId:
                'TOEFL_2024_LISTENING_CONNECTING_INFORMATION_CONNECTING_IDEAS',
              displayName: 'Connecting Ideas',
              description:
                'Connecting and synthesizing ideas from different parts of the listening passage.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'TOEFL_2024_SPEAKING',
      displayName: 'TOEFL Speaking Section',
      description:
        'Evaluates the ability to speak English clearly and effectively. Includes 4 tasks requiring responses to questions, expressing opinions on familiar topics, and integrating information from reading and listening passages. Measures fluency, pronunciation, and ability to organize and articulate ideas. Scores range from 0 to 30.',
      icon: 'remixMicLine', // ng-icons Remix Icon for speaking
      categories: [
        {
          categoryId: 'TOEFL_2024_SPEAKING_INDEPENDENT_SPEAKING_TASK',
          displayName: 'Independent Speaking Task',
          description:
            'Tasks where test-takers express their own opinions or experiences on a given topic.',
          icon: 'remixUserLine', // ng-icons Remix Icon for individual tasks
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_SPEAKING_INDEPENDENT_SPEAKING_TASK_EXPRESS_OPINION',
              displayName: 'Express Opinion',
              description:
                'Tasks asking test-takers to express their opinion on a familiar topic.',
            },
            {
              subcategoryId:
                'TOEFL_2024_SPEAKING_INDEPENDENT_SPEAKING_TASK_PERSONAL_EXPERIENCE',
              displayName: 'Personal Experience',
              description:
                'Tasks requiring test-takers to describe a personal experience or event.',
            },
          ],
        },
        {
          categoryId: 'TOEFL_2024_SPEAKING_INTEGRATED_SPEAKING_TASK',
          displayName: 'Integrated Speaking Task',
          description:
            'Tasks requiring test-takers to speak based on reading and listening materials.',
          icon: 'remixTeamLine', // ng-icons Remix Icon for integrated tasks
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_SPEAKING_INTEGRATED_SPEAKING_TASK_READ_LISTEN_SPEAK',
              displayName: 'Read-Listen-Speak',
              description:
                'Tasks involving reading a passage, listening to a recording, and then speaking in response.',
            },
            {
              subcategoryId:
                'TOEFL_2024_SPEAKING_INTEGRATED_SPEAKING_TASK_LISTEN_SPEAK',
              displayName: 'Listen-Speak',
              description:
                'Tasks requiring listening to a recording and then speaking in response.',
            },
            {
              subcategoryId:
                'TOEFL_2024_SPEAKING_INTEGRATED_SPEAKING_TASK_READ_SPEAK',
              displayName: 'Read-Speak',
              description:
                'Tasks involving reading a passage and then speaking in response.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'TOEFL_2024_WRITING',
      displayName: 'TOEFL Writing Section',
      description:
        'Assesses the ability to write clear and well-organized essays. Includes two tasks: an integrated writing task based on reading and listening materials, and an independent writing task expressing personal opinions. Evaluates writing skills such as grammar, coherence, and argument development. Scores range from 0 to 30.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'TOEFL_2024_WRITING_INTEGRATED_WRITING_TASK',
          displayName: 'Integrated Writing Task',
          description:
            'Tasks where test-takers read a passage, listen to a recording, and then write a response based on both.',
          icon: 'remixFileAddLine', // ng-icons Remix Icon for integrated writing
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_WRITING_INTEGRATED_WRITING_TASK_READ_LISTEN_WRITE',
              displayName: 'Read-Listen-Write',
              description:
                'Tasks requiring reading, listening, and then writing an essay integrating both sources.',
            },
          ],
        },
        {
          categoryId: 'TOEFL_2024_WRITING_INDEPENDENT_WRITING_TASK',
          displayName: 'Independent Writing Task',
          description:
            'Tasks where test-takers write an essay based on their own knowledge and experience on a given topic.',
          icon: 'remixFileTextLine', // ng-icons Remix Icon for independent writing
          subcategories: [
            {
              subcategoryId:
                'TOEFL_2024_WRITING_INDEPENDENT_WRITING_TASK_OPINION_ESSAY',
              displayName: 'Opinion Essay',
              description:
                'Tasks requiring an essay expressing an opinion on a given topic.',
            },
            {
              subcategoryId:
                'TOEFL_2024_WRITING_INDEPENDENT_WRITING_TASK_EXPERIENCE_ESSAY',
              displayName: 'Experience Essay',
              description:
                'Tasks requiring an essay describing personal experiences related to a topic.',
            },
          ],
        },
      ],
    },
  ],
};
