<div class="flex flex-col space-y-4 max-w-lg mx-auto">
  <!-- Error Message -->
  <div *ngIf="showError" class="text-red-500 text-center">
    {{ errorMessage }}
  </div>

  <!-- Essay Input -->
  <div class="p-2 relative">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="essayInput">
      Your Response:
    </label>
    <textarea
      #essayTextarea
      id="essayInput"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      [(ngModel)]="essayText"
      (keydown)="onKeyDown($event)"
      (input)="onTextChange()"
      [disabled]="isInputDisabled()"
      [readonly]="isReviewState()"
      [attr.maxlength]="maxChars"
      [style.resize]="'none'"
      [style.overflow-y]="isReviewState() ? 'hidden' : 'auto'"
      [style.height]="isReviewState() ? 'auto' : '200px'"
      placeholder="Write your essay here..."
    ></textarea>

    <!-- Limit Message -->
    <div *ngIf="limitMessage" class="text-red-500 mt-2">
      {{ limitMessage }}
    </div>

    <!-- Current Counts -->
    <div class="text-gray-600 text-sm mt-2 flex justify-end space-x-4">
      <div>Characters: {{ currentChars }}/{{ maxChars }}</div>
<!--      <div>Words: {{ currentWords }}/{{ maxWords }}</div>-->
<!--      <div>Lines: {{ currentLines }}/{{ maxLines }}</div>-->
    </div>
  </div>
</div>
