import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { UserResponse } from '../models/user-response';
import { SubscriptionResponse } from '../models/subscription-response';
import { CancelSubscriptionRequest } from '../models/cancel-subscription-request';
import { CancelSubscriptionResponse } from '../models/cancel-subscription-response';
import { ContinueSubscriptionRequest } from '../models/continue-subscription-request';
import { ContinueSubscriptionResponse } from '../models/continue-subscription-response';
import { UpdateUsernameRequest } from '../models/update-username-request';
import { ApiState } from './api-state.interface';
import { RequestState } from './request-state.enum';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  // For tracking user information
  private userResponseSubject: BehaviorSubject<UserResponse | null> = new BehaviorSubject<UserResponse | null>(null);
  public userResponse$: Observable<UserResponse | null> = this.userResponseSubject.asObservable();

  // For tracking subscription information
  private subscriptionResponseSubject: BehaviorSubject<SubscriptionResponse[] | null> = new BehaviorSubject<SubscriptionResponse[] | null>(null);
  public subscriptionResponse$: Observable<SubscriptionResponse[] | null> = this.subscriptionResponseSubject.asObservable();


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    // Only execute browser-specific code in the browser
    if (isPlatformBrowser(this.platformId)) {
      const storedUserResponse = this.getStoredUserResponse();

      // If there's data in localStorage, initialize with it
      if (storedUserResponse) {
        this.userResponseSubject.next(storedUserResponse);
      }

      const storedSubscriptionResponse = this.getStoredSubscriptionResponse();

      // If there's data in localStorage, initialize with it
      if (storedSubscriptionResponse) {
        this.subscriptionResponseSubject.next(storedSubscriptionResponse);
      }

      // Subscribe to the authService's firebaseUser$ observable
      this.authService.firebaseUser$
        .pipe(filter((user) => !!user)) // Only proceed if there is a logged-in user
        .subscribe(() => {
          this.fetchUserResponse(); // Fetch the user response only when authenticated
          this.fetchSubscriptionResponse(); // Fetch the subscription response only when authenticated
        });

      // Handle logouts to clear data
      this.authService.firebaseUser$
        .pipe(filter((user) => !user)) // Only proceed if the user is logged out
        .subscribe(() => {
          this.clearUserResponse(); // Clear user data on logout
          this.clearSubscriptionResponse();
        });
    } else {
      // On the server, set subjects to null
      this.userResponseSubject.next(null);
      this.subscriptionResponseSubject.next(null);
    }
  }

  // Method to fetch purchases and store in local storage
  fetchUserResponse(): void {
    const endpoint = 'user';  // Replace with your actual endpoint
    this.apiService.getData<UserResponse>(endpoint).subscribe(userResponseState => {
      if (userResponseState.state === RequestState.SUCCESS) {
        // Update BehaviorSubject with new data
        this.userResponseSubject.next(userResponseState.data);
        // Update localStorage with new data
        if (userResponseState.data) {
          this.setUserResponseInLocalStorage(userResponseState.data);
        }
      }
    });
  }

  // Helper method to get the UserResponse from local storage
  private getStoredUserResponse(): UserResponse | null {
    if (isPlatformBrowser(this.platformId)) {
      const storedUserResponse = localStorage.getItem('userResponse');
      return storedUserResponse
        ? (JSON.parse(storedUserResponse) as UserResponse)
        : null;
    } else {
      return null;
    }
  }

  // Save user response to local storage
  private setUserResponseInLocalStorage(userResponse: UserResponse): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('userResponse', JSON.stringify(userResponse));
    }
  }

  // Clear user response from both local storage and BehaviorSubject
  private clearUserResponse(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('userResponse');
    }
    this.userResponseSubject.next(null);
  }

  // Get the user response from the BehaviorSubject (first from local storage if available)
  getUserResponse(): Observable<UserResponse | null> {
    // this could be from local storage, since the local storage is nexted through the userResponseSubject.
    return this.userResponse$;  // This always returns the latest value, whether from API or localStorage
  }

  // Force refresh of user response from API (optional, if needed)
  refreshUserResponse(): void {
    this.fetchUserResponse();  // Triggers a new API call and updates both BehaviorSubject and localStorage
  }

  //////////////////////
  // Subscription methods
  //////////////////////

  // Method to fetch purchases and store them in local storage
  fetchSubscriptionResponse(): void {
    const endpoint = 'purchases';  // Replace with your actual endpoint
    this.apiService.getData<SubscriptionResponse[]>(endpoint).subscribe(subscriptionResponseState => {
      if (subscriptionResponseState.state === RequestState.SUCCESS) {
        console.log('fetchSubscriptionResponse with data=', subscriptionResponseState.data);

        // Update BehaviorSubject with new data
        this.subscriptionResponseSubject.next(subscriptionResponseState.data);
        // Update localStorage with new data
        if (subscriptionResponseState.data) {
          this.setSubscriptionResponseInLocalStorage(subscriptionResponseState.data);
        }
      }
    });
  }


  // Helper method to get the SubscriptionResponse list from local storage
  private getStoredSubscriptionResponse(): SubscriptionResponse[] | null {
    if (isPlatformBrowser(this.platformId)) {
      const storedSubscriptionResponse = localStorage.getItem('subscriptionResponse');
      return storedSubscriptionResponse
        ? (JSON.parse(storedSubscriptionResponse) as SubscriptionResponse[])
        : null;
    } else {
      return null;
    }
  }

  // Save subscription response to local storage
  private setSubscriptionResponseInLocalStorage(
    subscriptionResponse: SubscriptionResponse[]
  ): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(
        'subscriptionResponse',
        JSON.stringify(subscriptionResponse)
      );
    }
  }

  // Get the subscription response from the BehaviorSubject (first from local storage if available)
  getSubscriptionResponse(): Observable<SubscriptionResponse[] | null> {
    return this.subscriptionResponse$;  // This always returns the latest value, whether from API or localStorage
  }

  // Force refresh of subscription response from API (optional, if needed)
  refreshSubscriptionResponse(): void {
    this.fetchSubscriptionResponse();  // Triggers a new API call and updates both BehaviorSubject and localStorage
  }

  // Method to clear subscription response from local storage and BehaviorSubject
  private clearSubscriptionResponse(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('subscriptionResponse');
    }
    // Clear the BehaviorSubject
    this.subscriptionResponseSubject.next(null);
  }

  // Check if a given examId is in the subscription responses
  public isUserSubscribedToExam(examId: string): Observable<boolean> {
    return this.subscriptionResponse$.pipe(
      filter(subscriptionResponses => subscriptionResponses !== null),
      map(subscriptionResponses => {
        console.log('isUserSubscribedToExam subscriptionResponses=', subscriptionResponses);
        if (!subscriptionResponses) {
          return false;
        }

        // Iterate through the subscription responses
        for (const subscription of subscriptionResponses) {
          for (const item of subscription.items) {
            const product = item.productResponse;

            // Check if examId matches any relevant examId field in the product
            if (product.examId === examId || product.singleExamId === examId) {
              return true; // Return true if a match is found
            }
          }
        }

        return false; // Return false if no match is found
      })
    );
  }

  cancelSubscription(cancelSubscriptionRequest: CancelSubscriptionRequest) {
    const endpoint = `cancel-subscription`;
    return this.apiService.postData<CancelSubscriptionResponse>(endpoint, cancelSubscriptionRequest);
  }

  continueSubscription(continueSubscriptionRequest: ContinueSubscriptionRequest) {
    const endpoint = `continue-subscription`; // Replace with your actual endpoint
    return this.apiService.postData<ContinueSubscriptionResponse>(endpoint, continueSubscriptionRequest);
  }

  updateUsername(updateUsernameRequest: UpdateUsernameRequest) {
    const endpoint = `update-username`;
    return this.apiService.postData<UserResponse>(endpoint, updateUsernameRequest);
  }
}
