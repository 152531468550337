export enum QuestionState {
  LOADING = 'LOADING',
  SHOW_QUESTION = 'SHOW_QUESTION', // means show only the question, but not the interaction

  // this state is available only immediately after submit and was incorrect. If navigate to page again
  // and interaction was incorrect, then it will be in the "REVIEW" state
  REVIEW_INCORRECT = 'REVIEW_INCORRECT',
  REVIEW = 'REVIEW',

  // actions. We need this in the state, to disable the options when we submit.
  SUBMIT = 'SUBMIT',
  SKIP = 'SKIP',
  NEXT = 'NEXT',
  TRY_AGAIN = 'TRY_AGAIN',

  VALIDATE = 'VALIDATE',
  VALIDATE_SUCCESS = 'VALIDATE_SUCCESS',
  VALIDATE_FAIL = 'VALIDATE_FAIL',
  ERROR = 'ERROR',
}
