<div class="bg-white shadow-md rounded-lg p-4" style="min-width: 600px;">
  <!-- Title for the category performance -->
  <div class="mb-2 flex justify-between items-center">
    <h2 class="text-2xl font-bold text-gray-700">{{title}}</h2>
    <!-- Info Icon -->
    <div class="relative group">
      <i class="fa-solid fa-circle-info text-gray-500 cursor-pointer"></i>
      <!-- Tooltip on hover -->
      <div class="absolute right-0 hidden group-hover:block bg-white text-gray-700 text-xs p-2 border border-gray-300 rounded shadow-lg w-64">
        {{ infoOnHover }}
      </div>
    </div>
  </div>

  <!-- Conditional Content -->
  <div class="relative">
    <!-- Non-Premium Users -->
    <ng-container *ngIf="!isPremium">
      <!-- Blurred Sample Chart -->
      <div class="relative">
        <img
          [src]="this.blurImgSrc"
          alt=""
          class="w-full h-auto object-cover"
          style="filter: blur(2px); opacity: 1.0; max-height: 600px;"
        />
        <!-- Overlay Content with Spotlight Effect -->
        <div
          class="absolute inset-0 flex flex-col items-center justify-center z-10"
          style="background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);"
        >
          <ng-icon
            name="remixLockFill"
            class="text-gray-700 mb-4"
            style="width: 40px; height: 40px;"
          ></ng-icon>
          <p class="text-gray-800 mb-4 font-semibold">Upgrade to Premium to access this feature.</p>
          <button
            (click)="onPremiumPlanClick()"
            class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"
          >
            Upgrade to Premium
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Pro Users -->
    <ng-container *ngIf="isPremium">
      <!-- Loading State -->
      <div
        *ngIf="requestState === RequestState.LOADING"
        class="flex items-center justify-center"
        style="min-height: 16rem;"
      >
        <ng-icon
          name="remixLoader4Line"
          class="animate-spin text-blue-500"
          style="width: 40px; height: 40px;"
        ></ng-icon>
      </div>

      <!-- Success State -->
      <div *ngIf="requestState === RequestState.SUCCESS">
        <!-- Table Container -->
        <div class="overflow-x-auto">
          <!-- Table for displaying category statistics -->
          <table *ngIf="categoryStats.length > 0; else noDataTemplate" class="table-auto w-full">
            <tbody>
            <!-- Loop through categoryStats -->
            <ng-container *ngFor="let category of categoryStats; let i = index">
              <!-- Category Row -->
              <tr class="border-b border-gray-200">
                <!-- Category Display Name -->
                <td class="px-4 py-2 text-left align-top" style="width: 25%;">
                  <p class="text-base font-bold break-words leading-tight">{{ category.cohortStatsLabel.cohortDisplayName }}</p>
                </td>
                <!-- Statistics and Recent Questions -->
                <td colspan="5" class="px-4 py-2">
                  <div class="flex flex-col">
                    <!-- Top Row: Statistics -->
                    <div class="flex justify-around text-sm">
                      <!-- Questions Correct -->
                      <div class="text-center flex-1">
                        <p class="font-semibold text-gray-500">Correct</p>
                        <p class="font-bold text-green-500 mt-1">{{ category.numberQuestionsCorrect | number:'1.0-0' }}</p>
                      </div>
                      <!-- Questions Incorrect -->
                      <div class="text-center flex-1">
                        <p class="font-semibold text-gray-500">Incorrect</p>
                        <p class="font-bold text-red-500 mt-1">{{ category.numberQuestionsIncorrect | number:'1.0-0' }}</p>
                      </div>
                      <!-- Questions Skipped -->
                      <div class="text-center flex-1">
                        <p class="font-semibold text-gray-500">Skipped</p>
                        <p class="font-bold text-yellow-500 mt-1">{{ category.numberQuestionsSkipped | number:'1.0-0' }}</p>
                      </div>
                      <!-- Average Difficulty -->
                      <div class="text-center flex-1">
                        <p class="font-semibold text-gray-500">Difficulty</p>
                        <p class="font-bold text-custom-blue-500 mt-1">{{ category.averageDifficulty | number:'1.1-1' }}</p>
                      </div>
                      <!-- Percent Correct -->
                      <div class="text-center flex-1">
                        <p class="font-semibold text-gray-500">% Correct</p>
                        <p class="font-bold text-blue-500 mt-1">{{ 100. * category.percentCorrect | number:'1.1-1' }}%</p>
                      </div>
                    </div>
                    <!-- Bottom Row: Recent Questions -->
                    <div class="mt-2">
                      <div class="flex flex-wrap space-x-2">
                        <!-- Loop through the userQuestionInteractionResponses array -->
                        <div *ngFor="let uqi of category.userQuestionInteractionResponses.slice(0, maxInteractionLimit); let i = index" class="relative group">
                          <a [href]="'/question/' + uqi.questionSummaryResponse?.questionId"
                             class="inline-block w-5 h-5 border border-gray-300 rounded flex justify-center items-center"
                             title="{{ uqi.questionSummaryResponse?.title }}">
                            <!-- Show Font Awesome icons based on the question result -->
                            <ng-container [ngSwitch]="true">
                              <!-- Correct: Use check icon -->
                              <i *ngSwitchCase="uqi.correct" class="fa-solid fa-check text-green-500 text-xs"></i>
                              <!-- Incorrect: Use xmark icon -->
                              <i *ngSwitchCase="!uqi.correct && !uqi.skipped" class="fa-solid fa-xmark text-red-500 text-xs"></i>
                              <!-- Skipped: Use circle icon -->
                              <i *ngSwitchCase="uqi.skipped && !uqi.correct" class="fa-regular fa-circle text-yellow-500 text-xs"></i>
                            </ng-container>
                          </a>
                          <!-- Tooltip on hover -->
                          <div class="absolute z-10 hidden group-hover:flex flex-col items-start p-2 bg-white shadow-lg border border-gray-300 rounded text-xs text-gray-700">
                            <!-- Display question status: Correct, Incorrect, or Skipped -->
                            <ng-container [ngSwitch]="true">
                              <p *ngSwitchCase="uqi.correct"><strong>Status:</strong> Correct</p>
                              <p *ngSwitchCase="!uqi.correct && !uqi.skipped"><strong>Status:</strong> Incorrect</p>
                              <p *ngSwitchCase="uqi.skipped && !uqi.correct"><strong>Status:</strong> Skipped</p>
                            </ng-container>
                            <p><strong>Title:</strong> {{ uqi.questionSummaryResponse?.title }}</p>
                            <p><strong>Difficulty:</strong> {{ uqi.questionSummaryResponse?.difficultyLevelResponse?.difficultyDisplayName }}</p>
                          </div>
                        </div>

                        <!-- Fill remaining with placeholders up to 10 items -->
                        <ng-container *ngIf="category.userQuestionInteractionResponses.length < maxInteractionLimit">
                        <ng-container *ngFor="let empty of [].constructor(Math.max(maxInteractionLimit - category.userQuestionInteractionResponses.length))">
                          <div class="inline-block w-5 h-5 border border-gray-300 rounded flex justify-center items-center">
                            <span class="text-gray-500 text-xs">-</span>
                          </div>
                        </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
  </div>
      </div>

      <!-- Error State -->
      <div
        *ngIf="requestState === RequestState.ERROR"
        class="flex flex-col items-center justify-center"
        style="min-height: 16rem;"
      >
        <ng-icon
          name="remixErrorWarningLine"
          class="text-red-500 mb-4"
          style="width: 64px; height: 64px;"
        ></ng-icon>
        <p class="text-red-600">An error occurred while loading the data.</p>
      </div>

      <!-- No Data Template -->
      <ng-template #noDataTemplate>
        <div class="text-center py-8">
          <p class="text-gray-500">No category data available.</p>
        </div>
      </ng-template>
    </ng-container>
  </div>


</div>
