import {Component, Input, OnInit} from '@angular/core';
import {UserQuestionInteractionResponse} from "../../../models/user-question-interaction-response";
import {AnalyticsService} from "../../../services/analytics.service";
import {QuestionHistoryResponse} from "../../../models/question-history-response";
import {ApiState} from "../../../services/api-state.interface";
import {DatePipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, SlicePipe} from "@angular/common";
import {RequestState} from "../../../services/request-state.enum";
import {UserAnalyticsResponse} from "../../../models/user-analytics-response";
import {NgIcon} from "@ng-icons/core";

@Component({
  selector: 'app-question-history',
  standalone: true,
  imports: [
    SlicePipe,
    DatePipe,
    NgIf,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    NgIcon
  ],
  templateUrl: './question-history.component.html',
  styleUrl: './question-history.component.css'
})
export class QuestionHistoryComponent implements OnInit {
  @Input() examId: string | null = null;
  @Input() isPremium: boolean = false;
  @Input() onPremiumPlanClick!: () => void;

  blurImgSrc: string = 'assets/images/dashboard/blur_question_history.png';

  requestState: RequestState = RequestState.LOADING;

  questionHistory: UserQuestionInteractionResponse[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;

  error: string | null = null;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.requestState = RequestState.LOADING;
    this.fetchQuestionHistory();
  }

  ngOnInit2(): void {
    // Simulating data load with a timeout, replace with your real data loading logic
    setTimeout(() => {
      import('../../../models/mock/mock-question-history-response').then(module => {
        const questionHistoryResponse = module.mockQuestionHistoryResponse as QuestionHistoryResponse;

        this.questionHistory = questionHistoryResponse.userQuestionInteractionResponseList || [];

        // If less than pageSize items are returned, we assume it's the last page
        if (this.questionHistory.length < this.pageSize) {
          this.isLastPage = true;
        } else {
          this.isLastPage = false;
        }
      });
    }, 2000); // Mock delay, replace with actual data fetching logic
  }


  fetchQuestionHistory(): void {
    if (this.examId == null) {
      this.requestState = RequestState.ERROR;
      return;
    }

    this.requestState = RequestState.LOADING;

    this.error = null;

    this.analyticsService
      .getQuestionHistory(this.examId, this.pageNumber, this.pageSize)
      .subscribe({
        next: (response: ApiState<QuestionHistoryResponse>) => {
          if (response.state === RequestState.SUCCESS && response.data) {
            this.requestState = RequestState.SUCCESS;
            // Keep the QuestionHistoryResponse object
            const questionHistoryResponse: QuestionHistoryResponse = response.data;

            this.questionHistory = questionHistoryResponse.userQuestionInteractionResponseList || [];
            this.error = ''
          } else if (response.state === RequestState.ERROR) {
            this.requestState = RequestState.ERROR;
            this.error = 'Failed to load question history.';
            this.questionHistory = [];
          }
          // If less than pageSize items are returned, we assume it's the last page
          if (this.questionHistory.length < this.pageSize) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
        },
        error: (err) => {
          this.requestState = RequestState.ERROR;
          this.error = 'An error occurred while fetching question history.';
          this.questionHistory = [];
          this.isLastPage = true;
          console.error(err);
        },
      });
  }

  // Property to track if the current page is the last page
  isLastPage: boolean = false;

  goToPreviousPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.fetchQuestionHistory();
    }
  }

  goToNextPage(): void {
    if (!this.isLastPage) {
      this.pageNumber++;
      this.fetchQuestionHistory();
    }
  }

  getDifficultyColorClass(uqi: UserQuestionInteractionResponse): string {
    const difficulty = uqi.questionSummaryResponse?.difficultyLevelResponse;
    if (!difficulty) {
      return 'text-gray-500'; // Default color if difficulty info is missing
    }

    const { numericDifficultyLevel, minDifficultyLevel, maxDifficultyLevel } = difficulty;

    const range = maxDifficultyLevel - minDifficultyLevel;
    const relativeDifficulty = numericDifficultyLevel - minDifficultyLevel;

    const percentage = range > 0 ? (relativeDifficulty / range) * 100 : 0;

    // Map the percentage to color classes
    if (percentage <= 20) {
      return 'text-green-500'; // Easiest
    } else if (percentage <= 40) {
      return 'text-yellow-500'; // Easy
    } else if (percentage <= 60) {
      return 'text-orange-500'; // Medium
    } else if (percentage <= 80) {
      return 'text-red-500'; // Hard
    } else {
      return 'text-purple-500'; // Hardest
    }
  }

  protected readonly RequestState = RequestState;
}
