import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {QuestionResponse} from '../../../models/question-response';
import {NgClass, NgIf} from "@angular/common";
import {QuestionService} from "../../../services/question-service";
import {ActionQuestionRequest} from "../../../models/action-question-request";
import {ApiState} from "../../../services/api-state.interface";
import {ActionQuestionResponse} from "../../../models/action-question-response";
import {QuestionState} from "../question-state.enum";
import {max} from "rxjs"; // Import QuestionResponse DTO

@Component({
  selector: 'app-question-hint-stats-component',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './question-hint-stats-component.component.html',
  styleUrl: './question-hint-stats-component.component.css'
})
export class QuestionHintStatsComponent implements OnInit, OnChanges {

  @Input() questionResponse: QuestionResponse | null = null;

  numSubmitted: number = 0;
  numCorrect: number = 0;
  percentCorrect: number = 0;

  showInfo: boolean = false;
  showHint: boolean = false;
  showCopiedModal: boolean = false;
  isLiked: boolean = false;
  isDisliked: boolean = false;
  numLiked: number = 0;

  constructor(
    private questionService: QuestionService,
    private eRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.calculatePercentCorrect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['questionResponse']) {
      this.calculatePercentCorrect();
      this.setLiked();
      this.setDisliked();
    }
  }

  calculatePercentCorrect(): void {
    const numCorrect = this.questionResponse?.numCorrect ?? 0;
    const numIncorrect = this.questionResponse?.numIncorrect ?? 0;
    const numSkipped = this.questionResponse?.numSkipped ?? 0;

    this.numSubmitted = numCorrect + numIncorrect + numSkipped;
    this.numCorrect = numCorrect;

    if (this.numSubmitted > 0) {
      this.percentCorrect = Math.min(Math.round((this.numCorrect / (this.numSubmitted * 1.0) ) * 100), 100);
    }
  }

  toggleInfo(): void {
    this.showInfo = !this.showInfo;
  }

  toggleHint(): void {
    this.showHint = !this.showHint;
  }

  shareLink(): void {
    const link = window.location.href;
    navigator.clipboard.writeText(link).then(() => {
      this.showCopiedModal = true;
      setTimeout(() => {
        this.showCopiedModal = false;
      }, 3000); // Modal disappears after 3 seconds
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  setLiked() {
    if (this.questionResponse?.userQuestionMetadataResponse?.liked !== null) {
      this.isLiked = this.questionResponse?.userQuestionMetadataResponse?.liked ?? false;
    }
  }

  setDisliked() {
    if (this.questionResponse?.userQuestionMetadataResponse?.disliked !== null) {
      this.isDisliked = this.questionResponse?.userQuestionMetadataResponse?.disliked ?? false;
    }
  }

  likeQuestion(): void {
    if (this.questionResponse) {
      this.isLiked = !this.isLiked;  // Toggle state in UI

      let actionQuestionRequest: ActionQuestionRequest = {
        questionId: this.questionResponse.questionId,
      };

      if (this.isLiked) {
        actionQuestionRequest.action = 'like';
        this.questionResponse!.likes = (this.questionResponse!.likes || 0) + 1;
      } else {
        actionQuestionRequest.action = 'unlike';
        this.questionResponse!.likes = Math.max((this.questionResponse!.likes || 0) - 1, 0);
      }

      // Make an API call to update the state in the database
      this.questionService.actionQuestion(actionQuestionRequest).subscribe(
        (response: ApiState<ActionQuestionResponse>) => {
          console.log('like state updated:', response?.data);
        });

    }
  }

  dislikeQuestion(): void {
    if (this.questionResponse) {
      this.isDisliked = !this.isDisliked;  // Toggle state in UI

      let actionQuestionRequest: ActionQuestionRequest = {
        questionId: this.questionResponse.questionId,
      };

      if (this.isDisliked) {
        actionQuestionRequest.action = 'dislike';
        this.questionResponse!.dislikes = (this.questionResponse!.dislikes || 0) + 1;
      } else {
        actionQuestionRequest.action = 'undislike';
        this.questionResponse!.dislikes = Math.max((this.questionResponse!.dislikes || 0) - 1, 0);
      }

      // Make an API call to update the state in the database
      this.questionService.actionQuestion(actionQuestionRequest).subscribe(
        (response: ApiState<ActionQuestionResponse>) => {
          console.log('dislike state updated:', response?.data);
        });

    }
  }

  // Close the info tooltip if clicked outside
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (this.showInfo && !this.eRef.nativeElement.contains(event.target)) {
      this.showInfo = false;
    }
  }

  // Utility method to format numbers
  formatNumber(num: number | null): string {
    if (num == null) {
      return '';
    }

    if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }

  // Utility method to round to specified decimal places
  roundToDecimal(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  }

  protected readonly Math = Math;
}
