import { NgModule } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

import {
  remixFileTextLine,
  remixBookOpenLine,
  remixEditLine,
  remixEqualizerLine,
  remixCalculatorLine,
  remixNumbersLine,
  remixFunctionLine,
  remixShapeLine,
  remixBarChartLine,
  remixPencilLine,
  remixQuestionLine,
  remixDiscussLine,
  remixHeartLine,
  remixPassportLine,
  remixAddCircleLine,
  remixGraduationCapLine,
  remixLineChartLine,
  remixAddBoxLine,
  remixLightbulbLine,
  remixToolsLine,
  remixChat1Line,
  remixCheckLine,
  remixQuillPenLine,
  remixSettingsLine,
  remixChat3Line,
  remixPuzzleLine,
  remixOrganizationChart,
  remixKeyLine,
  remixFlaskLine,
  remixExchangeLine,
  remixHandHeartLine,
  remixPieChartLine,
  remixBrainLine,
  remixDatabaseLine,
  remixGlobalLine,
  remixSettings3Line,
  remixEmotionHappyLine,
  remixLink,
  remixHeadphoneLine,
  remixMicLine,
  remixUserLine,
  remixTeamLine,
  remixFileAddLine,
  remixArrowDownSLine,
  remixLogoutBoxLine,
  remixAccountCircleFill,
  remixErrorWarningLine,
  remixLockFill,
  remixLoader4Line,
  remixBookLine,
  remixInformationLine,
  remixSpeakLine,
  remixSlideshowLine,
  remixMergeCellsHorizontal,
  remixPenNibLine,
  remixShakeHandsLine,
  remixImageLine,
  remixMovieLine,
  remixGlobeLine,
  remixHammerLine,
  remixFlagLine,
  remixTimeLine,
  remixPlantLine,
  remixGovernmentLine,
  remixRulerLine,
  remixCompassLine,
  remixTrophyLine,
  remixBriefcaseLine,
  remixArchiveLine,
  remixFileListLine, remixChatVoiceLine, remixTreeLine,
  remixEyeLine,
  remixTestTubeLine,
  remixShieldLine,
  remixMoonLine,
  remixHospitalLine,
  remixEmotionLine,
  remixRocketLine,
  remixStarLine,
  remixArrowRightLine,
  remixArrowRightCircleFill,
  remixChatQuoteLine,
  remixBuildingLine,
  remixUserHeartLine,


  remixSearchLine,
  remixCodeSSlashLine,
  remixStockLine,
  remixPriceTagLine,

  remixShieldStarLine,
  remixBuilding2Line,
  remixCommunityLine,
  remixArrowUpDownLine,
  remixCopperCoinLine,
  remixArrowLeftLine,

  remixQuestionAnswerLine,
  remixListUnordered,
  remixBookletLine,
  remixUserVoiceLine,

  remixBrushLine,
  remixAttachmentLine,
  remixLayoutLine,
  remixArrowUpLine,
  remixArrowDownLine,
  remixQuestionFill,
  remixShieldCheckLine,
  remixContrastLine,
  remixPulseLine,
  remixExternalLinkLine,
  remixMicroscopeLine,
  remixMentalHealthLine,
  remixHeartPulseLine,

  remixVirusLine,
  remixVirusFill,
  remixFunctionFill,
  remixBugLine,
  remixEmotion2Line,

  remixCheckboxCircleLine,
  remixHandSanitizerLine,
  remixSyringeLine

} from '@ng-icons/remixicon';

import {
  faSolidBook,
  faSolidCheck, faSolidCircleCheck, faSolidCircleInfo, faSolidExclamation, faSolidXmark
} from '@ng-icons/font-awesome/solid';

@NgModule({
  imports: [
    NgIconsModule.withIcons({
      remixFileTextLine,
      remixBookOpenLine,
      remixEditLine,
      remixEqualizerLine,
      remixCalculatorLine,
      remixNumbersLine,
      remixFunctionLine,
      remixShapeLine,
      remixBarChartLine,
      remixPencilLine,
      remixQuestionLine,
      remixDiscussLine,
      remixHeartLine,
      remixPassportLine,
      remixAddCircleLine,
      remixGraduationCapLine,
      remixLineChartLine,
      remixAddBoxLine,
      remixLightbulbLine,
      remixToolsLine,
      remixChat1Line,
      remixCheckLine,
      remixQuillPenLine,
      remixSettingsLine,
      remixChat3Line,
      remixPuzzleLine,
      remixOrganizationChart,
      remixKeyLine,
      remixFlaskLine,
      remixExchangeLine,
      remixHandHeartLine,
      remixPieChartLine,
      remixBrainLine,
      remixDatabaseLine,
      remixGlobalLine,
      remixSettings3Line,
      remixEmotionHappyLine,
      remixLink,
      remixHeadphoneLine,
      remixMicLine,
      remixUserLine,
      remixTeamLine,
      remixFileAddLine,
      remixArrowDownSLine,
      remixLogoutBoxLine,
      remixAccountCircleFill,
      remixErrorWarningLine,
      remixLockFill,
      remixLoader4Line,
      remixBookLine,
      remixInformationLine,
      remixSpeakLine,
      remixSlideshowLine,
      remixMergeCellsHorizontal,
      remixPenNibLine,
      remixShakeHandsLine,
      remixImageLine,
      remixMovieLine,

      remixGlobeLine,
      remixHammerLine,
      remixFlagLine,
      remixTimeLine,
      remixPlantLine,
      remixTreeLine,

      remixGovernmentLine,
      remixRulerLine,
      remixCompassLine,
      remixTrophyLine,
      remixArchiveLine,
      remixBriefcaseLine,

      remixFileListLine,

      remixChatVoiceLine,
      remixEyeLine,
      remixTestTubeLine,
      remixShieldLine,
      remixMoonLine,
      remixHospitalLine,
      remixEmotionLine,
      remixRocketLine,
      remixStarLine,
      remixArrowRightLine,
      remixArrowRightCircleFill,
      remixChatQuoteLine,

      remixBuildingLine,
      remixUserHeartLine,
      remixSearchLine,
      remixCodeSSlashLine,
      remixStockLine,
      remixPriceTagLine,

      remixShieldStarLine,
      remixBuilding2Line,
      remixCommunityLine,
      remixArrowUpDownLine,
      remixCopperCoinLine,
      remixArrowLeftLine,
      remixQuestionAnswerLine,
      remixListUnordered,
      remixBookletLine,
      remixUserVoiceLine,
      remixBrushLine,
      remixAttachmentLine,

      remixLayoutLine,
      remixArrowUpLine,
      remixArrowDownLine,
      remixQuestionFill,
      remixShieldCheckLine,
      remixContrastLine,
      remixPulseLine,
      remixExternalLinkLine,
      remixMicroscopeLine,
      remixMentalHealthLine,
      remixHeartPulseLine,
      remixVirusLine,
      remixVirusFill,
      remixFunctionFill,
      remixBugLine,
      remixEmotion2Line,

      remixCheckboxCircleLine,
      remixHandSanitizerLine,
      remixSyringeLine,

      faSolidCheck,
      faSolidCircleCheck,
      faSolidCircleInfo,
      faSolidXmark,
      faSolidExclamation,
      faSolidBook

    }),
  ],
  exports: [NgIconsModule], // Export the NgIconsModule to make icons available
})
export class IconsModule { }
