// GRE Exam Data
import {ExamData} from "../exam-data-interface";

export const greExamData: ExamData = {
  examId: 'GRE_2024',
  displayName: 'GRE',
  description:
    'The GRE is a standardized test for graduate admissions, measuring verbal reasoning, quantitative reasoning, and analytical writing abilities.',
  logo: 'assets/images/exams/gre_logo.webp',
  link: 'gre',
  zoom: 1.1,
  priceId: 'GRE_2024_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Conquer the GRE with Personalized Prep',
  marketingSubtitles: [
    'Our adaptive platform focuses on your weaknesses to boost your GRE score.',
    'Access thousands of GRE questions with comprehensive explanations.',
    'Achieve a higher GRE score with strategies personalized to your strengths',
    'Identify and master the GRE topics where you need the most improvement',
  ],
  pricingTitle: 'Unlimited Practice with Expert Insights',
  pricingSubtitle: [
    'Achieve your graduate school goals with quality questions and personalized feedback at a competitive price.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'GRE_2024_VERBAL_REASONING',
      displayName: 'GRE Verbal Reasoning Section',
      description:
        'Assesses ability to understand and analyze written material, including reading comprehension, text completion, and sentence equivalence questions. Tests ability to evaluate arguments, understand complex texts, and interpret meaning. Evaluates vocabulary, critical reading, and analytical skills. Scores range from 130 to 170.',
      icon: 'remixFileTextLine', // ng-icons Remix Icon for verbal reasoning
      categories: [
        {
          categoryId: 'GRE_2024_VERBAL_REASONING_READING_COMPREHENSION',
          displayName: 'Reading Comprehension',
          description:
            'Questions assess ability to understand, analyze, and interpret written passages, focusing on main ideas, details, inferences, and author’s intent.',
          icon: 'remixBookOpenLine', // ng-icons Remix Icon for reading comprehension
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_VERBAL_REASONING_READING_COMPREHENSION_PASSAGE_BASED',
              displayName: 'Passage-Based Questions',
              description:
                'Require reading passages and answering questions based on understanding of main ideas, supporting details, inferences, and author’s purpose.',
            },
            {
              subcategoryId:
                'GRE_2024_VERBAL_REASONING_READING_COMPREHENSION_SENTENCE_LEVEL',
              displayName: 'Sentence-Level Questions',
              description:
                'Focus on specific sentences within a passage, including vocabulary in context, sentence meaning, and structural role in the passage.',
            },
          ],
        },
        {
          categoryId: 'GRE_2024_VERBAL_REASONING_TEXT_COMPLETION',
          displayName: 'Text Completion',
          description:
            'Evaluates ability to fill in blanks in passages to create coherent and logical text, testing vocabulary and contextual understanding.',
          icon: 'remixEditLine', // ng-icons Remix Icon for text completion
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_VERBAL_REASONING_TEXT_COMPLETION_SINGLE_BLANK',
              displayName: 'Single-Blank Questions',
              description:
                'Passages with one blank to fill, choosing the word that best fits the context.',
            },
            {
              subcategoryId:
                'GRE_2024_VERBAL_REASONING_TEXT_COMPLETION_MULTIPLE_BLANK',
              displayName: 'Multiple-Blank Questions',
              description:
                'Passages with multiple blanks, selecting words for each blank to create coherent text.',
            },
          ],
        },
        {
          categoryId: 'GRE_2024_VERBAL_REASONING_SENTENCE_EQUIVALENCE',
          displayName: 'Sentence Equivalence',
          description:
            'Assesses ability to understand sentence meaning and select two choices that complete the sentence with similar meaning.',
          icon: 'remixEqualizerLine', // ng-icons Remix Icon for sentence equivalence
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_VERBAL_REASONING_SENTENCE_EQUIVALENCE_SINGLE_SENTENCE',
              displayName: 'Single-Sentence Questions',
              description:
                'Single sentence with one blank, selecting two choices that both fit and give similar meanings.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'GRE_2024_QUANTITATIVE_REASONING',
      displayName: 'GRE Quantitative Reasoning Section',
      description:
        'Measures ability to understand, interpret, and analyze quantitative information, including arithmetic, algebra, geometry, and data analysis. Tests problem-solving skills and application of mathematical concepts to real-world scenarios. Evaluates quantitative reasoning and mathematical understanding. Scores range from 130 to 170.',
      icon: 'remixCalculatorLine', // ng-icons Remix Icon for quantitative reasoning
      categories: [
        {
          categoryId: 'GRE_2024_QUANTITATIVE_REASONING_ARITHMETIC',
          displayName: 'Arithmetic',
          description:
            'Tests basic arithmetic skills including properties and types of integers, arithmetic operations, and number properties.',
          icon: 'remixNumbersLine', // ng-icons Remix Icon for arithmetic
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_ARITHMETIC_INTEGERS',
              displayName: 'Integers',
              description:
                'Properties and operations involving integers, including factors, multiples, and prime numbers.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_ARITHMETIC_FRACTIONS_DECIMALS_PERCENTS',
              displayName: 'Fractions, Decimals, and Percents',
              description:
                'Operations with fractions, decimals, and percents, including conversions and calculations.',
            },
          ],
        },
        {
          categoryId: 'GRE_2024_QUANTITATIVE_REASONING_ALGEBRA',
          displayName: 'Algebra',
          description:
            'Tests understanding and solving algebraic expressions, equations, and inequalities.',
          icon: 'remixFunctionLine', // ng-icons Remix Icon for algebra/functions
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_ALGEBRA_EXPRESSIONS_EQUATIONS_INEQUALITIES',
              displayName: 'Expressions, Equations, and Inequalities',
              description:
                'Simplifying algebraic expressions, solving linear and quadratic equations, working with inequalities.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_ALGEBRA_FUNCTIONS',
              displayName: 'Functions',
              description:
                'Understanding and interpreting functions, including function notation and evaluation.',
            },
          ],
        },
        {
          categoryId: 'GRE_2024_QUANTITATIVE_REASONING_GEOMETRY',
          displayName: 'Geometry',
          description:
            'Tests knowledge of geometric shapes, properties, and theorems, including lines, angles, circles, triangles, and polygons.',
          icon: 'remixShapeLine', // ng-icons Remix Icon for geometry
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_GEOMETRY_LINES_ANGLES',
              displayName: 'Lines and Angles',
              description:
                'Properties and relationships of lines and angles, including parallel lines and angle measures.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_GEOMETRY_TRIANGLES',
              displayName: 'Triangles',
              description:
                'Properties and theorems related to triangles, including Pythagorean theorem and congruence.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_GEOMETRY_CIRCLES',
              displayName: 'Circles',
              description:
                'Properties and measurements of circles, including radius, diameter, circumference, and area.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_GEOMETRY_POLYGONS',
              displayName: 'Polygons',
              description:
                'Properties and calculations related to polygons, including quadrilaterals and other multi-sided figures.',
            },
          ],
        },
        {
          categoryId: 'GRE_2024_QUANTITATIVE_REASONING_DATA_ANALYSIS',
          displayName: 'Data Analysis',
          description:
            'Tests ability to interpret and analyze data, including concepts of statistics, probability, and data interpretation.',
          icon: 'remixBarChartLine', // ng-icons Remix Icon for data analysis
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_DATA_ANALYSIS_DESCRIPTIVE_STATISTICS',
              displayName: 'Descriptive Statistics',
              description:
                'Summarizing and describing data sets, including measures of central tendency and dispersion.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_DATA_ANALYSIS_INTERPRETATION_OF_DATA',
              displayName: 'Interpretation of Data',
              description:
                'Interpreting and making inferences from data presented in graphs, tables, and other formats.',
            },
            {
              subcategoryId:
                'GRE_2024_QUANTITATIVE_REASONING_DATA_ANALYSIS_PROBABILITY',
              displayName: 'Probability',
              description:
                'Understanding basic probability principles, including calculating probabilities of events.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'GRE_2024_ANALYTICAL_WRITING',
      displayName: 'GRE Analytical Writing Section',
      description:
        'Measures ability to articulate complex ideas clearly and effectively, consisting of two tasks: the "Issue Task" and the "Argument Task." Evaluates critical thinking, organization, and writing clarity. Scores range from 0 to 6.',
      icon: 'remixPencilLine', // ng-icons Remix Icon for writing
      categories: [
        {
          categoryId: 'GRE_2024_ANALYTICAL_WRITING_ISSUE_TASK',
          displayName: 'Issue Task',
          description:
            'Requires writing an essay in response to a prompt that presents a statement or claim, developing a position with reasons and examples.',
          icon: 'remixQuestionLine', // ng-icons Remix Icon for issue
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_ANALYTICAL_WRITING_ISSUE_TASK_UNDERSTANDING_THE_ISSUE',
              displayName: 'Understanding the Issue',
              description:
                'Ability to clearly understand the issue presented in the prompt.',
            },
            {
              subcategoryId:
                'GRE_2024_ANALYTICAL_WRITING_ISSUE_TASK_DEVELOPING_A_POSITION',
              displayName: 'Developing a Position',
              description:
                'Developing a clear and coherent position on the issue, supported by relevant reasons and examples.',
            },
          ],
        },
        {
          categoryId: 'GRE_2024_ANALYTICAL_WRITING_ARGUMENT_TASK',
          displayName: 'Argument Task',
          description:
            'Requires writing an essay critiquing an argument presented in a prompt, evaluating the logical soundness without agreeing or disagreeing.',
          icon: 'remixChat1Line', // ng-icons Remix Icon for argument
          subcategories: [
            {
              subcategoryId:
                'GRE_2024_ANALYTICAL_WRITING_ARGUMENT_TASK_ANALYZING_THE_ARGUMENT',
              displayName: 'Analyzing the Argument',
              description:
                'Ability to understand, analyze, and critique the argument presented.',
            },
            {
              subcategoryId:
                'GRE_2024_ANALYTICAL_WRITING_ARGUMENT_TASK_DEVELOPING_A_CRITIQUE',
              displayName: 'Developing a Critique',
              description:
                'Developing a coherent critique of the argument, identifying logical flaws and providing relevant examples.',
            },
          ],
        },
      ],
    },
  ],
};
