import { Injectable } from '@angular/core';

declare var MathJax: any;

@Injectable({
  providedIn: 'root'
})
export class MathjaxService {

  constructor() {
    this.initMathJax();
  }

  // Initialize MathJax
  initMathJax(): void {
    if (typeof MathJax !== 'undefined') {
      MathJax = MathJax || {
        loader: { load: ['input/asciimath', 'output/chtml'] }
      };
      MathJax.startup = {
        typeset: false // We control when MathJax typesets
      };
      MathJax.config = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)']],
          displayMath: [['$$', '$$'], ['\\[', '\\]']]
        },
        startup: {
          ready: () => {
            MathJax.startup.defaultReady();
            MathJax.typesetPromise();
          }
        }
      };
    }
  }

  // Render the math expressions
  renderMath(): Promise<void> {
    if (typeof MathJax !== 'undefined') {
      return MathJax.typesetPromise();
    }
    return Promise.resolve();
  }
}
