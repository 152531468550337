<div class="p-6">
  <!-- Correct or Incorrect Choice Section -->
  <div *ngIf="hasCorrectChoice && hasChoice" class="mb-4">
    <div *ngIf="correct" class="p-4 bg-green-100 border-l-4 border-green-500 text-green-700">
      <p class="font-semibold">Your grid-in answer <span class="font-bold">{{ choice }}</span> is correct.</p>
    </div>
    <div *ngIf="!correct" class="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
      <p class="font-semibold">Your grid-in answer <span class="font-bold">{{ choice }}</span> is incorrect.</p>
      <p class="mt-2">The correct answer is
        <span *ngFor="let part of correctChoice; let i = index; let last = last">
          <span class="font-bold">{{ part }}</span>
          <ng-container *ngIf="!last">
            <span *ngIf="i < correctChoice.length - 2">, </span>
            <span *ngIf="i === correctChoice.length - 2"> or </span>
          </ng-container>
        </span>
      </p>
    </div>
  </div>

  <!-- Explanation Section -->
  <div *ngIf="hasExplanation" class="mt-4">
    <h3 class="text-lg font-semibold text-gray-900 mb-2">Explanation:</h3>
    <div class="bg-gray-50 p-4 border rounded-lg shadow-sm text-gray-700 text-justify">
      <div [innerHTML]="sanitizedExplanation" class="no-select no-right-click"></div>
      <!--      <div class="no-select no-right-click">-->
      <!--        <span *ngFor="let part of sanitizedExplanationParts" [innerHTML]="part"></span>-->
      <!--      </div>-->
    </div>
  </div>
</div>
