import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {first, Observable} from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service'; // Import your AuthService

@Injectable({
  providedIn: 'root',
})
export class EmailVerifiedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    // Use the firebaseUser$ observable from AuthService
    return this.authService.firebaseUser$.pipe(
      first((user) => user !== undefined),
      map((user) => {
        if (user && user.emailVerified) {
          // If the user is logged in and email is verified, allow activation
          return true;
        } else if (user && !user.emailVerified) {
          // If the user is logged in but email is not verified, redirect to email verification page
          this.router.navigate(['/email-verification']);
          return false;
        } else {
          // If no user is logged in, redirect to login page
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }
}
