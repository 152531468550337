<!--<div class="min-h-screen flex flex-col w-full"> &lt;!&ndash; Ensures the page takes the full height and width of the screen &ndash;&gt;-->
<!--  <section class="relative pt-24 pb-28 bg-white overflow-hidden w-full">-->
<!--    <img class="absolute right-0 bottom-0 w-full" src="assets/images/gradient_app.svg" alt="">-->
<!--    <div class="relative z-10 w-full px-4"> &lt;!&ndash; Use w-full to take the full width &ndash;&gt;-->
<!--      <div class="flex flex-wrap lg:items-center w-full"> &lt;!&ndash; Ensure flex containers take full width &ndash;&gt;-->
<!--        <div class="w-full md:w-1/2 p-8">-->
<!--          <div class="md:max-w-full"> &lt;!&ndash; Removed max width restriction &ndash;&gt;-->
<!--            <p class="mb-7 text-sm text-indigo-600 font-semibold uppercase">👋 Meet new Flaro</p>-->
<!--            <h2 class="mb-8 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">Start with ease, Grow your business</h2>-->
<!--            <p class="mb-10 text-lg text-gray-600 font-medium leading-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada tellus vestibulum, commodo pulvinar.</p>-->
<!--            <a class="mb-20 inline-block" href="#">-->
<!--              <img src="flaro-assets/images/applications/app-store-round.png" alt="">-->
<!--            </a>-->
<!--            <h3 class="mb-3 text-gray-900 font-bold font-heading">Trusted by 1M+ customers</h3>-->
<!--            <div class="flex flex-wrap items-center -m-1">-->
<!--              <div class="w-auto p-1">-->
<!--                <div class="flex flex-wrap -m-0.5">-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <svg width="17" height="16" viewbox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z" fill="#6366F1"></path>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <svg width="17" height="16" viewbox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z" fill="#6366F1"></path>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <svg width="17" height="16" viewbox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z" fill="#6366F1"></path>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <svg width="17" height="16" viewbox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z" fill="#6366F1"></path>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <svg width="17" height="16" viewbox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z" fill="#6366F1"></path>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="w-auto p-1">-->
<!--                <div class="flex flex-wrap -m-0.5">-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <p class="text-gray-900 font-bold">4.2/5</p>-->
<!--                  </div>-->
<!--                  <div class="w-auto p-0.5">-->
<!--                    <p class="text-gray-600 font-medium">(45k Reviews)</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="w-full md:w-1/2 p-8 self-end">-->
<!--          <img class="relative top-28 transform hover:scale-105 transition ease-in-out duration-1000" src="assets/images/iphone.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->

<section class="pt-16 pb-16 bg-transparent overflow-hidden">
  <img class="absolute top-0 left-0" src="assets/images/exams/gradient.svg" alt="">
  <img class="absolute bottom-0 right-0" src="assets/images/exams/gradient2.svg" alt="">
  <div class="relative container px-4 mx-auto" id="exams-list">
    <h2 class="mb-16 text-5xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight">
      Explore Our Exams
    </h2>

    <!-- K-8 Exams Section -->
    <h3 class="mb-8 text-3xl font-bold font-heading tracking-px-n leading-tight">
      Grades K-8 Exams
    </h3>
    <div class="flex flex-wrap -mx-3 mb-12">
      <ng-container *ngFor="let exam of k8Exams">
        <div class="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6 flex">
          <a (click)="navigateToExam(exam.link)" class="block group cursor-pointer flex-grow">
            <div class="bg-white border rounded-4xl overflow-hidden transform transition duration-300 group-hover:scale-105 flex flex-col h-full">
              <!-- Logo Container -->
              <div class="flex justify-center items-center p-6 h-48">
                <img [src]="exam.logo" [alt]="exam.displayName + ' Logo'"
                     class="max-h-32 w-auto object-contain">
              </div>
              <!-- Description and Arrow -->
              <div class="p-6 flex-grow flex flex-col">
                <div class="flex-grow">
                  <p class="text-gray-600">{{ exam.description }}</p>
                </div>
                <div class="flex items-center justify-end mt-4">
                  <svg class="w-6 h-6 text-gray-500 group-hover:text-blue-500 transition duration-300"
                       fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

    <!-- High School Exams Section -->
    <h3 class="mb-8 text-3xl font-bold font-heading tracking-px-n leading-tight">
      Grades 9-12 Exams
    </h3>
    <div class="flex flex-wrap -mx-3 mb-12">
      <ng-container *ngFor="let exam of highSchoolExams">
        <div class="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6 flex">
          <a (click)="navigateToExam(exam.link)" class="block group cursor-pointer flex-grow">
            <div class="bg-white border rounded-4xl overflow-hidden transform transition duration-300 group-hover:scale-105 flex flex-col h-full">
              <!-- Logo Container -->
              <div class="flex justify-center items-center p-6 h-48">
                <img [src]="exam.logo" [alt]="exam.displayName + ' Logo'"
                     class="max-h-32 w-auto object-contain">
              </div>
              <!-- Description and Arrow -->
              <div class="p-6 flex-grow flex flex-col">
                <div class="flex-grow">
                  <p class="text-gray-600">{{ exam.description }}</p>
                </div>
                <div class="flex items-center justify-end mt-4">
                  <svg class="w-6 h-6 text-gray-500 group-hover:text-blue-500 transition duration-300"
                       fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

    <!-- Graduate Exams Section -->
    <h3 class="mb-8 text-3xl font-bold font-heading tracking-px-n leading-tight">
      Graduate Exams
    </h3>
    <div class="flex flex-wrap -mx-3 mb-12">
      <ng-container *ngFor="let exam of graduateExams">
        <div class="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6 flex">
          <a (click)="navigateToExam(exam.link)" class="block group cursor-pointer flex-grow">
            <div class="bg-white border rounded-4xl overflow-hidden transform transition duration-300 group-hover:scale-105 flex flex-col h-full">
              <!-- Logo Container -->
              <div class="flex justify-center items-center p-6 h-48">
                <img [src]="exam.logo" [alt]="exam.displayName + ' Logo'"
                     class="max-h-32 w-auto object-contain">
              </div>
              <!-- Description and Arrow -->
              <div class="p-6 flex-grow flex flex-col">
                <div class="flex-grow">
                  <p class="text-gray-600">{{ exam.description }}</p>
                </div>
                <div class="flex items-center justify-end mt-4">
                  <svg class="w-6 h-6 text-gray-500 group-hover:text-blue-500 transition duration-300"
                       fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

    <!-- Professional Exams Section -->
    <h3 class="mb-8 text-3xl font-bold font-heading tracking-px-n leading-tight">
      Professional Exams
    </h3>
    <div class="flex flex-wrap -mx-3 mb-12">
      <ng-container *ngFor="let exam of professionalExams">
        <div class="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6 flex">
          <a (click)="navigateToExam(exam.link)" class="block group cursor-pointer flex-grow">
            <div class="bg-white border rounded-4xl overflow-hidden transform transition duration-300 group-hover:scale-105 flex flex-col h-full">
              <!-- Logo Container -->
              <div class="flex justify-center items-center p-6 h-48">
                <img [src]="exam.logo" [alt]="exam.displayName + ' Logo'"
                     class="max-h-32 w-auto object-contain">
              </div>
              <!-- Description and Arrow -->
              <div class="p-6 flex-grow flex flex-col">
                <div class="flex-grow">
                  <p class="text-gray-600">{{ exam.description }}</p>
                </div>
                <div class="flex items-center justify-end mt-4">
                  <svg class="w-6 h-6 text-gray-500 group-hover:text-blue-500 transition duration-300"
                       fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

    <!-- Language Proficiency Exams Section -->
    <h3 class="mb-8 text-3xl font-bold font-heading tracking-px-n leading-tight">
      Language Proficiency Exams
    </h3>
    <div class="flex flex-wrap -mx-3 mb-12">
      <ng-container *ngFor="let exam of languageExams">
        <div class="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6 flex">
          <a (click)="navigateToExam(exam.link)" class="block group cursor-pointer flex-grow">
            <div class="bg-white border rounded-4xl overflow-hidden transform transition duration-300 group-hover:scale-105 flex flex-col h-full">
              <!-- Logo Container -->
              <div class="flex justify-center items-center p-6 h-48">
                <img [src]="exam.logo" [alt]="exam.displayName + ' Logo'"
                     class="max-h-32 w-auto object-contain">
              </div>
              <!-- Description and Arrow -->
              <div class="p-6 flex-grow flex flex-col">
                <div class="flex-grow">
                  <p class="text-gray-600">{{ exam.description }}</p>
                </div>
                <div class="flex items-center justify-end mt-4">
                  <svg class="w-6 h-6 text-gray-500 group-hover:text-blue-500 transition duration-300"
                       fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 5l7 7-7 7"></path>
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

  </div>
</section>

<section class="pt-12 pb-36 bg-blueGray-100 overflow-hidden">
  <div class="container px-4 mx-auto">
    <h2 class="mb-5 text-5xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight">See what others are saying</h2>
    <p class="mb-16 text-xl text-gray-600 font-large text-center mx-auto md:max-w-xl">Unlimited practice and quality question bank at an unbeatable price</p>
    <div class="flex flex-nowrap justify-center -m-1.5 mb-1.5">
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Studious helped me improve my SAT score by 150 points in 3 months. The focused question sets really made a difference.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold"> Emily R.</h3>
                  <p class="text-gray-500">Columbia University</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">After 2 months of consistent practice with Studious, my ACT score went up by 5 points. The structured approach was exactly what I needed.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Jack S.</h3>
                  <p class="text-gray-500">Stanford University</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">I spent 3 months with Studious and saw my TOEFL score increase by 20 points. The platform’s smart guidance kept me on track</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Maria L.</h3>
                  <p class="text-gray-500">NYU</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">I chose Studious for SAT prep because of its affordability, and it turned out to be just as effective as pricier programs.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">

                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Tom W.</h3>
                  <p class="text-gray-500">Northwestern University</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Using Studious for GRE prep helped me raise my score by 10 points. I liked how it focused on my weaker areas.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Kevin T.</h3>
                  <p class="text-gray-500">M.I.T.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">My GMAT score went up by 70 points thanks to Studious. The analytics showed me exactly where I needed more practice.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Lisa K.</h3>
                  <p class="text-gray-500">Columbia University</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-nowrap justify-center -m-1.5">
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Studious helped me increase my SAT math score by 250 points. I found the practice questions to be really well-targeted.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Alex B.</h3>
                  <p class="text-gray-500">Princeton University</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Studious was a far more affordable option for ACT prep compared to other courses I looked at, without sacrificing quality.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Sarah D.</h3>
                  <p class="text-gray-500">UCLA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">For TOEFL preparation, Studious was not only effective but also a lot cheaper than other courses. I appreciated the value it provided.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Wei S.</h3>
                  <p class="text-gray-500">University of Toronto</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Studious’s targeted question sets made my SAT prep more efficient. The questions were well-adapted to my needs.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Nina G.</h3>
                  <p class="text-gray-500">UC Irvine</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">The performance analytics in Studious really helped me understand where I was struggling in ACT prep and where I was improving.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Mike P.</h3>
                  <p class="text-gray-500">UC Berkeley</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Studious’s AI-driven assistance for GRE verbal prep helped me focus on exactly what I needed to work on. It felt personalized.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Daniel M.</h3>
                  <p class="text-gray-500">Duke University</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 max-w-xs p-1.5">
        <div class="p-6 h-full bg-white">
          <div class="flex flex-col justify-between h-full">
            <div class="pb-6">
              <div class="flex flex-wrap -m-0.5 mb-5">
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
                <div class="w-auto p-0.5">
                  <svg width="19" height="18"> <use href="assets/icons/star.svg#icon"></use> </svg>
                </div>
              </div>
              <p class="text-lg font-medium">Studious’s AI-driven assistance for GRE verbal prep helped me focus on exactly what I needed to work on. It felt personalized.</p>
            </div>
            <div>
              <div class="flex flex-wrap items-center -m-2">
                <div class="w-auto p-2">
                  <h3 class="text-base font-semibold">Priya R.</h3>
                  <p class="text-gray-500">University of Chicago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


