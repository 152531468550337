<div class="p-6">
  <!-- Introductory Text -->
  <div class="p-4 bg-green-100 border-l-4 border-green-500 text-green-700 mb-4 flex items-center">
<!--    <i class="fas fa-check-circle text-green-500 mr-2"></i>-->
    <ng-icon
      name="faSolidCircleCheck"
      class="text-green-500 mr-2 flex-shrink-0"
      style="width: 20px; height: 20px;"
    ></ng-icon>
    <p class="font-semibold">
      Below is an example of an excellent essay receiving a perfect score:
    </p>
  </div>

  <!-- Sample Essay Section -->
  <div *ngIf="hasExplanation" class="mt-4">
    <h3 class="text-lg font-semibold text-gray-900 mb-2">Sample Essay:</h3>
    <div class="bg-gray-50 p-4 border rounded-lg shadow-sm text-gray-700 text-justify">
      <div [innerHTML]="sanitizedExplanation" class="no-select no-right-click"></div>
    </div>
  </div>

  <!-- Rubric Section -->
  <div *ngIf="hasRubric" class="mt-4">
    <h3 class="text-lg font-semibold text-gray-900 mb-2">Rubric Explanation:</h3>
    <div class="bg-gray-50 p-4 border rounded-lg shadow-sm text-gray-700 text-justify">
      <div [innerHTML]="sanitizedRubric" class="no-select no-right-click"></div>
    </div>
  </div>
</div>
