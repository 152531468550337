<div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
  <div class="bg-white rounded-lg p-8 shadow-xl max-w-2xl w-full relative">
    <!-- Modal Header -->
    <div class="flex justify-between items-center">
      <h3 class="text-lg font-bold">Terms & Conditions</h3>
      <button (click)="onClose()" class="text-gray-500 hover:text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>

    <!-- Modal Content -->
    <div class="mt-4 max-h-96 overflow-y-auto">
      <p>Welcome to <strong>Studious</strong>. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, you must discontinue using the website immediately.</p>

      <h2>1. General Use</h2>
      <p><strong>Studious</strong> is an educational platform designed to provide learning resources for students. We grant you a limited, non-exclusive, non-transferable license to use the website for personal use only. Unauthorized use of the website, including scraping, framing, or copying content without permission, is strictly prohibited.</p>
      <p>We reserve the right to modify or discontinue any part of the website at any time without notice and are not liable for any consequences arising from such changes.</p>

      <h2>2. Account Registration and Responsibility</h2>
      <p>You may be required to create an account to access certain features of <strong>Studious</strong>. You agree to provide accurate and up-to-date information during registration and are responsible for maintaining the confidentiality of your account information.</p>
      <p><strong>Studious</strong> is not liable for any unauthorized access to your account due to your failure to safeguard your login credentials.</p>
      <p>We reserve the right to suspend or terminate accounts if we suspect a violation of these terms or unlawful activity.</p>

      <h2>3. Subscription Plans and Payments</h2>
      <p><strong>Studious</strong> offers free and premium subscription tiers. Premium subscribers gain access to additional features and content. Subscription fees are billed on a recurring basis and are non-refundable.</p>
      <p>We reserve the right to modify pricing at any time, and any changes will be communicated to you prior to your next billing cycle. You are responsible for managing your subscription, including cancellations.</p>

      <h2>4. Intellectual Property</h2>
      <p>All materials and content on <strong>Studious</strong>, including but not limited to text, graphics, logos, videos, and software, are owned by <strong>Studious</strong> or its licensors and are protected by copyright and intellectual property laws.</p>
      <p>You are not permitted to copy, reproduce, distribute, or create derivative works from the content without prior written consent from <strong>Studious</strong>.</p>
      <p>Any content you submit, such as comments or feedback, grants <strong>Studious</strong> a non-exclusive, royalty-free, worldwide license to use, reproduce, and modify the content for purposes related to the operation of the website.</p>

      <h2>5. User Conduct and Prohibited Activities</h2>
      <p>You agree to use <strong>Studious</strong> lawfully and not to:</p>
      <ul>
        <li>Harass, abuse, or harm other users;</li>
        <li>Post content that is defamatory, obscene, or otherwise inappropriate;</li>
        <li>Use automated means, such as bots or scripts, to access or interact with the website;</li>
        <li>Attempt to interfere with the website’s functionality or security.</li>
      </ul>
      <p><strong>Studious</strong> reserves the right to remove user content or suspend accounts that violate these terms.</p>

      <h2>6. Content Disclaimer</h2>
      <p><strong>Studious</strong> provides educational content, including practice questions and learning resources, which may not be fully representative of the official exams or their content. <strong>Studious</strong> is not affiliated with or endorsed by any official exam boards, including but not limited to SAT, ACT, GRE, GMAT, or any other standardized tests, nor by any colleges, universities, high schools, academies, or other educational institutions.</p>
      <p><strong>Studious</strong> does not guarantee that the content provided will prepare you for any specific exam or result in improved scores or academic performance. The use of the website’s resources is at your own discretion, and we do not warrant that the resources will meet your specific educational needs or expectations.</p>

      <h2>7. Limitation of Liability</h2>
      <p><strong>Studious</strong> provides the website and all content "as is" and makes no representations or warranties of any kind, express or implied, regarding the website’s operation, the information provided, or your results from using the platform.</p>
      <p><strong>Studious</strong> explicitly disclaims any liability for:</p>
      <ul>
        <li>The accuracy, completeness, or reliability of any content, including practice questions, study materials, or educational resources provided on the website;</li>
        <li>Any claims, losses, or damages resulting from your reliance on the content, including but not limited to failure to improve test scores, academic performance, or admission to any educational institution;</li>
        <li>Any disruptions or errors in the website’s operation, including data loss or system downtime.</li>
      </ul>
      <p>To the fullest extent permitted by law, <strong>Studious</strong> disclaims all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. <strong>Studious</strong> will not be held liable for any direct, indirect, incidental, punitive, or consequential damages, including but not limited to loss of data, revenue, or profits, arising out of or in connection with the use or inability to use the website or content, even if advised of the possibility of such damages.</p>
      <p><strong>Studious</strong> does not guarantee that the website will be free of viruses, malware, or harmful components, and you are responsible for maintaining appropriate security measures on your devices. Under no circumstances will <strong>Studious</strong> be liable for any damages exceeding the amount you have paid to use the website.</p>

      <h2>8. Arbitration and Dispute Resolution</h2>
      <p>Any disputes or claims arising out of or related to these terms or your use of the website must first be submitted to binding arbitration, in accordance with the rules of the American Arbitration Association, to be conducted in New York, New York. You agree to resolve any disputes on an individual basis and not as part of any class or representative action.</p>
      <p>If arbitration is not successful in resolving the dispute, you agree to submit to the exclusive jurisdiction of the state and federal courts located in New York, New York.</p>

      <h2>9. Indemnification</h2>
      <p>You agree to indemnify and hold <strong>Studious</strong>, its affiliates, licensors, and employees harmless from any claims, liabilities, damages, losses, or expenses (including reasonable legal fees) arising out of your violation of these Terms and Conditions or your use of the website.</p>

      <h2>10. Governing Law</h2>
      <p>These Terms and Conditions are governed by and construed in accordance with the laws of the State of New York. Any disputes arising from or related to these terms will be resolved exclusively in the courts of New York, and you consent to the jurisdiction of such courts.</p>

      <h2>11. Modifications to the Terms</h2>
      <p><strong>Studious</strong> reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting to the website, and your continued use of the website after changes are posted constitutes your acceptance of the modified terms.</p>

    </div>

    <!-- Modal Footer -->
    <div class="mt-4 flex justify-end">
      <button (click)="onClose()" class="px-4 py-2 bg-custom-blue-500 text-white rounded-lg hover:bg-custom-blue-600">
        Close
      </button>
    </div>
  </div>
</div>
