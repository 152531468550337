
<div class="">
  <section class="py-3">
    <div class="container mx-auto px-4">

      <!-- Main Grid -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 items-stretch">

        <!-- Left Column -->
        <div class="flex-1 flex flex-col space-y-6">
          <!-- Exam Name and Go to Practice Questions -->
          <div class="flex items-center bg-white shadow-lg rounded-lg p-6 flex-shrink-0">
            <!-- Loading State -->
            <div
              *ngIf="requestState === RequestState.LOADING"
              class="flex-1 flex items-center justify-center"
            >
              <ng-icon
                name="remixLoader4Line"
                class="animate-spin text-blue-500"
                style="width: 40px; height: 40px;"
              ></ng-icon>
            </div>

            <!-- Success State -->
            <div *ngIf="requestState !== RequestState.LOADING">
              <div class="flex-1">
                <h2 class="text-2xl font-bold text-gray-800">{{ getDisplayName() }}</h2>
                <p *ngIf="getLastActivityTime()!=null" class="text-sm text-gray-500">Last activity: {{ getLastActivityTime() }}</p>
              </div>
            </div>

            <!-- Go to Practice Questions Button (centered in remaining space) -->
            <div class="flex-1 flex justify-center">
              <button (click)="goToPracticeQuestions()" class="flex items-center bg-custom-blue-500 text-white px-4 py-2 rounded-lg hover:bg-custom-blue-600 focus:outline-none">
                <span>Go to Practice Questions</span>
                <i class="ri-arrow-right-line ml-2 text-lg"></i>
              </button>
            </div>
          </div>

          <!-- Statistics Cards -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 flex-shrink-0">
            <!-- Exam Mastery Card -->
            <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center min-w-0">
              <div class="flex flex-col items-center">
                <h3 class="text-gray-500 mb-2">Exam Mastery</h3>
                <div class="flex items-center justify-center">
                  <span class="text-3xl font-bold">{{ examMasteryScore() }}</span>
                  <span class="ml-2 py-1 px-2 text-xs rounded-full text-white"
                        [ngClass]="{
                          'bg-green-500': examMasteryDiff() > 0,
                          'bg-red-500': examMasteryDiff() < 0,
                          'bg-yellow-500': examMasteryDiff() === 0
                        }">
                    {{ formatDiffNumber(examMasteryDiff()) }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Weekly Questions Card -->
            <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center min-w-0">
              <div class="flex flex-col items-center">
                <h3 class="text-gray-500 mb-2">Weekly Questions</h3>
                <div class="flex items-center justify-center">
                  <span class="text-3xl font-bold">{{ weeklyNumQuestions() }}</span>
                  <span class="ml-2 py-1 px-2 text-xs rounded-full text-white"
                        [ngClass]="{
                          'bg-green-500': weeklyNumQuestionsDiff() > 0,
                          'bg-red-500': weeklyNumQuestionsDiff() < 0,
                          'bg-yellow-500': weeklyNumQuestionsDiff() === 0
                        }">
                    {{ formatDiffNumber(weeklyNumQuestionsDiff()) }}
                  </span>
                </div>
              </div>
            </div>

            <!-- All-Time Questions Card -->
            <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center min-w-0">
              <div class="flex flex-col items-center">
                <h3 class="text-gray-500 mb-2">All-Time Questions</h3>
                <div class="flex items-center justify-center">
                  <span class="text-3xl font-bold">{{ allTimeNumQuestions() }}</span>
                  <span class="ml-2 py-1 px-2 text-xs rounded-full text-white"
                        [ngClass]="{
                          'bg-green-500': allTimeNumQuestionsDiff() > 0,
                          'bg-red-500': allTimeNumQuestionsDiff() < 0,
                          'bg-yellow-500': allTimeNumQuestionsDiff() === 0
                        }">
                    {{ formatDiffNumber(allTimeNumQuestionsDiff()) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Weekly Exam Mastery Progress -->
          <div class="p-6 bg-white shadow-lg rounded-lg flex-1 flex flex-col relative overflow-hidden">
            <!-- Title with Info Icon -->
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-bold text-gray-800">Weekly Exam Mastery Progress</h2>

              <!-- Info Icon with Tooltip -->
              <div class="relative group">
                <i class="fas fa-info-circle text-gray-500 hover:text-gray-700" aria-label="Information"></i>
                <!-- Tooltip -->
                <div class="absolute right-0 mt-2 w-64 bg-white border border-gray-300 text-sm text-gray-700 p-2 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                  <p>
                    This chart displays your weekly mastery scores over time. The scores are calculated based on your performance each week.
                  </p>
                </div>
              </div>
            </div>

            <!-- Conditional Content -->
            <div class="relative flex-1 flex flex-col">
            <!-- Non-Premium Users -->
              <ng-container *ngIf="!isPremium">
                <!-- Blurred Sample Chart -->
                <div class="relative flex-1">
                  <img
                    src="assets/images/dashboard/blur_mastery.png"
                  alt=""
                  class="w-full h-full object-cover"
                  style="filter: blur(2px); opacity: 0.99;"
                  />
                  <!-- Overlay Content with Spotlight Effect -->
                  <div
                    class="absolute inset-0 flex flex-col items-center justify-center z-10"
                    style="background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);"
                  >
                    <ng-icon
                      name="remixLockFill"
                      class="text-gray-700 mb-4"
                      style="width: 40px; height: 40px;"
                    ></ng-icon>
                    <p class="text-gray-800 mb-4 font-semibold">Upgrade to Premium to access this feature.</p>
                    <button
                      (click)="onPremiumPlanClick()"
                      class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"
                    >
                      Upgrade to Premium
                    </button>
                  </div>
                </div>
              </ng-container>

              <!-- Premium Users -->
              <ng-container *ngIf="isPremium" class="flex-1 flex flex-col">
                <!-- Loading State -->
                <div
                  *ngIf="requestState === RequestState.LOADING"
                  class="flex-1 flex items-center justify-center"
                >
                  <ng-icon
                    name="remixLoader4Line"
                    class="animate-spin text-blue-500"
                    style="width: 40px; height: 40px;"
                  ></ng-icon>
                </div>

                <!-- Success State -->
                <div *ngIf="requestState === RequestState.SUCCESS" id="chart"
                     class="flex-1 flex flex-col">
                  <apx-chart
                    [series]="weeklyScoreChartOptions.series!"
                    [chart]="weeklyScoreChartOptions.chart!"
                    [xaxis]="weeklyScoreChartOptions.xaxis!"
                    [stroke]="weeklyScoreChartOptions.stroke!"
                    [fill]="weeklyScoreChartOptions.fill!"
                    [yaxis]="weeklyScoreChartOptions.yaxis!"
                    [tooltip]="weeklyScoreChartOptions.tooltip!"
                    class="w-full h-full"
                  >
                  </apx-chart>
                </div>

                <!-- Error State -->
                <div
                  *ngIf="requestState === RequestState.ERROR"
                  class="flex-1 flex flex-col items-center justify-center"
                  style="min-height: 16rem;"
                >
                  <ng-icon
                    name="remixErrorWarningLine"
                    class="text-red-500 mb-4"
                    style="width: 40px; height: 40px;"
                  ></ng-icon>
                  <p class="text-red-600">An error occurred while loading the data.</p>
                </div>
              </ng-container>
            </div>
          </div>

        </div>

        <!-- Right Column -->
        <div class="flex-1 flex flex-col space-y-6">

          <!-- Recommendations Card -->
          <div class="p-6 bg-white shadow-lg rounded-lg flex-shrink-0">
            <div class="flex items-center justify-between mb-4">
              <h2 class="text-2xl font-bold text-gray-800">Your Recommendations</h2>
            </div>

            <!-- Conditional Content -->
            <div class="relative flex-1 flex flex-col">
              <!-- Non-Premium Users -->
              <ng-container *ngIf="!isPremium">
                <!-- Blurred Sample Chart -->
                <div class="relative">
                  <img
                    src="assets/images/dashboard/blur_recommendations.png"
                    alt=""
                    class="w-full h-auto object-cover"
                    style="filter: blur(4px); opacity: 0.99;"
                  />
                  <!-- Overlay Content with Spotlight Effect -->
                  <div
                    class="absolute inset-0 flex flex-col items-center justify-center z-10"
                    style="background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);"
                  >
                    <ng-icon
                      name="remixLockFill"
                      class="text-gray-700 mb-4"
                      style="width: 40px; height: 40px;"
                    ></ng-icon>
                    <p class="text-gray-800 mb-4 font-semibold">Upgrade to Premium to access this feature.</p>
                    <button
                      (click)="onPremiumPlanClick()"
                      class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"
                    >
                      Upgrade to Premium
                    </button>
                  </div>
                </div>
              </ng-container>

              <!-- Pro Users -->
              <ng-container *ngIf="isPremium">
                <!-- Loading State -->
                <div
                  *ngIf="requestState === RequestState.LOADING"
                  class="flex-1 flex items-center justify-center"
                  style="min-height: 16rem;"
                >
                  <ng-icon
                    name="remixLoader4Line"
                    class="animate-spin text-blue-500"
                    style="width: 40px; height: 40px;"
                  ></ng-icon>
                </div>

                <!-- Success State -->
                <div *ngIf="requestState === RequestState.SUCCESS"
                     class="flex-1 flex flex-col">

                  <!-- Recommendations content Area -->
                  <div class="flex flex-col h-full">
                    <!-- No Recommendations Message -->
                    <div
                      *ngIf="!userAnalyticsResponse?.recommendations || userAnalyticsResponse?.recommendations?.length === 0"
                      class="flex items-center justify-center text-gray-500"
                    >
                      <p class="text-center">{{ getRecommendationsMessage() }}</p>
                    </div>

                    <!-- Recommendations List -->
                    <ul
                      *ngIf="userAnalyticsResponse?.recommendations && userAnalyticsResponse?.recommendations?.length ?? 0 > 0"
                      class="list-disc pl-5 space-y-3 text-justify"
                    >
                      <li
                        *ngFor="let recommendation of userAnalyticsResponse?.recommendations | slice: 0:3"
                      >
                        <p class="text-gray-700 text-base">{{ recommendation.text }}</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Error State -->
                <div
                  *ngIf="requestState === RequestState.ERROR"
                  class="flex-1 flex flex-col items-center justify-center"
                  style="min-height: 16rem;"
                >
                  <ng-icon
                    name="remixErrorWarningLine"
                    class="text-red-500 mb-4"
                    style="width: 40px; height: 40px;"
                  ></ng-icon>
                  <p class="text-red-600">An error occurred while loading the data.</p>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Questions Breakdown Chart -->
          <div class="p-6 bg-white shadow-lg rounded-lg flex-1 flex flex-col relative overflow-hidden">
            <!-- Title with Info Icon -->
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-bold text-gray-800">Questions Breakdown (Last 8 Days)</h2>
              <!-- Info Icon with Tooltip -->
              <div class="relative group">
                <i class="fas fa-info-circle text-gray-500 hover:text-gray-700" aria-label="Information"></i>
                <!-- Tooltip -->
                <div class="absolute right-0 mt-2 w-64 bg-white border border-gray-300 text-sm text-gray-700 p-2 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                  <p>
                    This chart shows the breakdown of questions answered over the past 8 days. It includes the number of correct, wrong, and skipped questions per day.
                  </p>
                </div>
              </div>
            </div>

            <!-- Conditional Content -->
            <div class="flex-1 flex flex-col">
              <!-- Non-Pro Users -->
              <ng-container *ngIf="!isPremium">
                <!-- Blurred Sample Chart -->
                <div class="relative flex-1">
                  <img
                    src="assets/images/dashboard/blur_breakdown.png"
                  alt=""
                  class="w-full h-full object-cover"
                  style="filter: blur(4px); opacity: 0.99;"
                  />
                  <!-- Overlay Content with Spotlight Effect -->
                  <div
                    class="absolute inset-0 flex flex-col items-center justify-center z-10"
                    style="background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);"
                  >
                    <ng-icon
                      name="remixLockFill"
                      class="text-gray-700 mb-4"
                      style="width: 40px; height: 40px;"
                    ></ng-icon>
                    <p class="text-gray-800 mb-4 font-semibold">Upgrade to Premium to access this feature.</p>
                    <button
                      (click)="onPremiumPlanClick()"
                      class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"
                    >
                      Upgrade to Premium
                    </button>
                  </div>
                </div>
              </ng-container>

              <!-- Pro Users -->
              <ng-container *ngIf="isPremium">

                <!-- Loading State -->
                <div
                  *ngIf="requestState === RequestState.LOADING"
                  class="flex-1 flex items-center justify-center"
                  style="min-height: 16rem;"
                >
                  <ng-icon
                    name="remixLoader4Line"
                    class="animate-spin text-blue-500"
                    style="width: 40px; height: 40px;"
                  ></ng-icon>
                </div>

                <!-- Success State -->
                <div *ngIf="requestState === RequestState.SUCCESS" id="chart2"
                     class="flex-1 flex flex-col">
                  <apx-chart
                    [series]="questionsBreakdownChartOptions.series!"
                    [chart]="questionsBreakdownChartOptions.chart!"
                    [xaxis]="questionsBreakdownChartOptions.xaxis!"
                    [yaxis]="questionsBreakdownChartOptions.yaxis!"
                    [tooltip]="questionsBreakdownChartOptions.tooltip!"
                    [plotOptions]="questionsBreakdownChartOptions.plotOptions!"
                    [dataLabels]="questionsBreakdownChartOptions.dataLabels!"
                    [colors]="questionsBreakdownChartOptions.colors!"
                    class="w-full h-full"
                  >
                  </apx-chart>
                </div>


                <!-- Error State -->
                <div
                  *ngIf="requestState === RequestState.ERROR"
                  class="flex-1 flex flex-col items-center justify-center"
                  style="min-height: 16rem;"
                >
                  <ng-icon
                    name="remixErrorWarningLine"
                    class="text-red-500 mb-4"
                    style="width: 64px; height: 64px;"
                  ></ng-icon>
                  <p class="text-red-600">An error occurred while loading the data.</p>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <section class="py-3">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">
        <!-- Adjust padding and margin for the section -->
        <div class="w-full px-4 mb-8 lg:mb-0">
            <!-- This is your child component that renders the donut chart -->
            <app-sections-statistics [sectionStats]="userAnalyticsResponse?.sectionStats || []"
                                     [isPremium]="isPremium"
                                     [onPremiumPlanClick]="onPremiumPlanClick"
                                     [requestState]= "requestState">

            </app-sections-statistics>

        </div>
      </div>
    </div>
  </section>

  <section class="py-3">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">

        <div class="w-full xl:w-1/2 px-4 mb-8">
            <app-categories-statistics title="Worst Categories (Past 3 months)"
                                       [isPremium]="isPremium"
                                       [onPremiumPlanClick]="onPremiumPlanClick"
                                       blurImgSrc="assets/images/dashboard/blur_worst_categories.png"
                                       [requestState]= "requestState"
                                       [categoryStats]="userAnalyticsResponse?.worstCategoryStats || []"
                                       infoOnHover="This displays the worst performing categories for questions answered in the past 3 months.">
            </app-categories-statistics>
        </div>


        <div class="w-full xl:w-1/2 px-4 mb-8">
            <app-categories-statistics title="Best Categories (Past 3 months)"
                                       [isPremium]="isPremium"
                                       [onPremiumPlanClick]="onPremiumPlanClick"
                                       blurImgSrc="assets/images/dashboard/blur_best_categories.png"
                                       [requestState]= "requestState"
                                       [categoryStats]="userAnalyticsResponse?.bestCategoryStats || []"
                                       infoOnHover="This displays the top performing categories for questions answered in the past 3 months.">
            </app-categories-statistics>

        </div>
      </div>
    </div>
  </section>

  <section class="py-3">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="w-full px-4 mb-8 lg:mb-0">
          <app-question-history [examId]="this.examId"
                                [isPremium]="isPremium"
                                [onPremiumPlanClick]="onPremiumPlanClick"></app-question-history>

        </div>
      </div>
    </div>
  </section>

  <section class="py-3">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="w-full px-4 mb-8 lg:mb-0">
          <app-actioned-questions [examId]="this.examId"
                                  [isPremium]="isPremium"
                                  [onPremiumPlanClick]="onPremiumPlanClick"></app-actioned-questions>
        </div>
      </div>
    </div>
  </section>


</div>





<!-- for ranking stats-->
<!--            <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center min-w-0">-->
<!--              <div class="flex flex-col items-center">-->
<!--                <h3 class="text-gray-500 mb-2">Cohort Rank</h3>-->
<!--                <div class="flex items-center justify-center">-->
<!--                  <span class="text-3xl font-bold">38 / 723</span>-->
<!--                  <span class="ml-2 py-1 px-2 text-xs rounded-full text-white bg-green-500">-->
<!--                    +127-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center min-w-0">-->
<!--              <div class="flex flex-col items-center">-->
<!--                <h3 class="text-gray-500 mb-2">Percentile</h3>-->
<!--                <div class="flex items-center justify-center">-->
<!--                  <span class="text-3xl font-bold">85.6%</span>-->
<!--                  <span class="ml-2 py-1 px-2 text-xs rounded-full text-white bg-green-500">-->
<!--                    +10.4%-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->


<!--            <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center min-w-0">-->
<!--              <div class="flex flex-col items-center">-->
<!--                <h3 class="text-gray-500 mb-2">Difficulty</h3>-->
<!--                <div class="flex items-center justify-center">-->
<!--                  <span class="text-3xl font-bold">6.4 / 10</span>-->
<!--                  <span class="ml-2 py-1 px-2 text-xs rounded-full text-white bg-red-500">-->
<!--                    {{ formatDiffNumber(-0.6) }}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->


<!-- working starting section-->

<!--<section class="py-3">-->
<!--  <div class="container px-4 mx-auto">-->
<!--    <div class="flex flex-wrap -m-4">-->
<!--      <div class="w-full lg:w-1/2 p-4">-->

<!--        &lt;!&ndash; Welcome messages &ndash;&gt;-->
<!--        <div class="p-6 mb-8 bg-white shadow-lg rounded-lg border border-gray-200">-->
<!--          <div class="flex items-center justify-between mb-4">-->
<!--            <h3 class="text-lg font-semibold text-indigo-600">{{ getRecommendationsMessage() }}</h3>-->
<!--          </div>-->

<!--          &lt;!&ndash; No recommendations message &ndash;&gt;-->
<!--          <div *ngIf="userAnalyticsResponse?.recommendations?.length === 0" class="text-center py-4 text-gray-500">-->
<!--            <p>{{ getRecommendationsMessage() }}</p>-->
<!--          </div>-->

<!--          &lt;!&ndash; Recommendations list with bullet points &ndash;&gt;-->
<!--          <ul class="list-disc list-inside space-y-3">-->
<!--            <li *ngFor="let recommendation of userAnalyticsResponse?.recommendations" class="bg-gray-50 p-4 rounded-lg shadow-sm">-->
<!--              <p class="text-gray-700 text-sm">{{ recommendation?.text }}</p>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

<!--        &lt;!&ndash; Score panel&ndash;&gt;-->
<!--        <div class="p-6 bg-white shadow rounded-lg">-->
<!--          &lt;!&ndash; Line Chart for Weekly Scores &ndash;&gt;-->
<!--          <div id="chart" class="mb-8">-->
<!--            <apx-chart-->
<!--              [series]="weeklyScoreChartOptions.series!"-->
<!--              [chart]="weeklyScoreChartOptions.chart!"-->
<!--              [xaxis]="weeklyScoreChartOptions.xaxis!"-->
<!--              [title]="weeklyScoreChartOptions.title!"-->
<!--              [stroke]="weeklyScoreChartOptions.stroke!"-->
<!--              [yaxis]="weeklyScoreChartOptions.yaxis!"-->
<!--              [tooltip]="weeklyScoreChartOptions.tooltip!">-->
<!--            </apx-chart>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->


<!--      <div class="w-full lg:w-1/2 p-4 flex flex-wrap justify-between">-->
<!--        &lt;!&ndash; Exam Mastery Card &ndash;&gt;-->
<!--        <div class="w-full md:w-1/3 p-2">-->
<!--          <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center h-auto">-->
<!--            <div class="flex flex-col items-center">-->
<!--              <h3 class="text-gray-500 mb-2">Exam Mastery</h3>-->
<!--              <div class="flex items-center justify-center">-->
<!--                <span class="text-3xl font-bold">{{ examMasteryScore() }}</span>-->
<!--                <span class="inline-block ml-2 py-1 px-2 text-xs rounded-full !text-white"-->
<!--                      [ngClass]="{ 'bg-green-500 text-white': examMasteryDiff() > 0,-->
<!--                  'bg-red-500 text-white': examMasteryDiff() < 0,-->
<!--                  'bg-yellow-500 text-white': examMasteryDiff() === 0-->
<!--                }">-->
<!--            {{ formatDiffNumber(examMasteryDiff()) }}-->
<!--          </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; Weekly Number of Questions Card &ndash;&gt;-->
<!--        <div class="w-full md:w-1/3 p-2">-->
<!--          <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center h-auto">-->
<!--            <div class="flex flex-col items-center">-->
<!--              <h3 class="text-gray-500 mb-2">Weekly Questions</h3>-->
<!--              <div class="flex items-center justify-center">-->
<!--                <span class="text-3xl font-bold">{{ weeklyNumQuestions() }}</span>-->
<!--                <span class="inline-block ml-2 py-1 px-2 text-xs rounded-full !text-white"-->
<!--                      [ngClass]="{ 'bg-green-500 text-white': weeklyNumQuestionsDiff() > 0,-->
<!--                  'bg-red-500 text-white': weeklyNumQuestionsDiff() < 0,-->
<!--                  'bg-yellow-500 text-white': weeklyNumQuestionsDiff() === 0-->
<!--                }">-->
<!--            {{ formatDiffNumber(weeklyNumQuestionsDiff()) }}-->
<!--          </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; All-Time Number of Questions Card &ndash;&gt;-->
<!--        <div class="w-full md:w-1/3 p-2">-->
<!--          <div class="p-4 bg-white shadow rounded-lg flex flex-col justify-center items-center h-auto">-->
<!--            <div class="flex flex-col items-center">-->
<!--              <h3 class="text-gray-500 mb-2">All-Time Questions</h3>-->
<!--              <div class="flex items-center justify-center">-->
<!--                <span class="text-3xl font-bold">{{ allTimeNumQuestions() }}</span>-->
<!--                <span class="inline-block ml-2 py-1 px-2 text-xs rounded-full !text-white"-->
<!--                      [ngClass]="{ 'bg-green-500 text-white': allTimeNumQuestionsDiff() > 0,-->
<!--                  'bg-red-500 text-white': allTimeNumQuestionsDiff() < 0,-->
<!--                  'bg-yellow-500 text-white': allTimeNumQuestionsDiff() === 0-->
<!--                }">-->
<!--            {{ formatDiffNumber(allTimeNumQuestionsDiff()) }}-->
<!--          </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; Questions Breakdown (Correct, Wrong, Skipped) &ndash;&gt;-->
<!--        <div class="w-full mt-8">-->
<!--          &lt;!&ndash; Chart Container &ndash;&gt;-->
<!--          <div class="p-4 bg-white shadow rounded-lg relative">-->
<!--            &lt;!&ndash; Title with Info Icon &ndash;&gt;-->
<!--            <div class="flex justify-between items-center mb-4">-->
<!--              <h3 class="font-bold text-lg text-gray-800">Questions Breakdown (Last 8 Days)</h3>-->

<!--              &lt;!&ndash; Info Icon with Tooltip on Hover &ndash;&gt;-->
<!--              <div class="relative group">-->
<!--                <i class="fas fa-info-circle text-gray-500 hover:text-gray-700"></i>-->

<!--                &lt;!&ndash; Tooltip box, shown on hover &ndash;&gt;-->
<!--                <div class="absolute right-0 mt-2 w-64 bg-gray-100 border border-gray-300 text-sm text-gray-700 p-2 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">-->
<!--                  <p>-->
<!--                    This chart shows the breakdown of questions answered over the past 8 days. It includes the number of correct, wrong, and skipped questions per day.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <apx-chart-->
<!--              [series]="questionsBreakdownChartOptions.series!"-->
<!--              [chart]="questionsBreakdownChartOptions.chart!"-->
<!--              [xaxis]="questionsBreakdownChartOptions.xaxis!"-->
<!--              [yaxis]="questionsBreakdownChartOptions.yaxis!"-->
<!--              [tooltip]="questionsBreakdownChartOptions.tooltip!"-->
<!--              [plotOptions]="questionsBreakdownChartOptions.plotOptions!"-->
<!--              [dataLabels]="questionsBreakdownChartOptions.dataLabels!"-->
<!--              [colors]="questionsBreakdownChartOptions.colors!">-->
<!--            </apx-chart>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->

<!--  </div>-->
<!--</section>-->


<!--        <div class="bg-white rounded">-->
<!--          <div class="flex items-center py-5 px-6 border-b">-->
<!--            <h3 class="text-2xl font-bold">Balance</h3>-->
<!--            <div class="ml-auto inline-block py-2 px-3 border rounded text-xs text-gray-500">-->
<!--              <select class="pr-1" name="">-->
<!--                <option value="1">Monthly</option>-->
<!--                <option value="1">Yearly</option>-->
<!--                <option value="1">Weekly</option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="pt-4 px-6">-->
<!--            <div class="flex flex-wrap -m-4 pb-16">-->
<!--              <div class="w-full md:w-1/2 p-4">-->
<!--                <div class="py-4 px-6 border rounded">-->
<!--                  <h4 class="text-xs text-gray-500">Earnings</h4>-->
<!--                  <div class="flex items-center">-->
<!--                    <span class="mr-2 text-3xl font-bold">43.41%</span>-->
<!--                    <span class="py-1 px-2 bg-green-500 text-xs text-white rounded-full">+2.5%</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="w-full md:w-1/2 p-4">-->
<!--                <div class="py-4 px-6 border rounded">-->
<!--                  <h4 class="text-xs text-gray-500">Sales Value</h4>-->
<!--                  <div class="flex items-center">-->
<!--                    <span class="mr-2 text-3xl font-bold">$95,422</span>-->
<!--                    <span class="py-1 px-2 bg-green-500 text-xs text-white rounded-full">+13.5%</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="chart" data-type="area"></div>-->
<!--        </div>-->




<!-- mastery progress working version-->

<!--            <div class="flex-1 flex items-center justify-center">-->
<!--              &lt;!&ndash; Blurred Background Image &ndash;&gt;-->
<!--              <img-->
<!--                *ngIf="!isPremium"-->
<!--                src="assets/images/dashboard/blur_mastery.png"-->
<!--                alt=""-->
<!--                class="absolute inset-0 w-full h-full object-cover"-->
<!--                [style.filter]="'blur(4px)'"-->
<!--                [style.opacity]="'0.7'"-->
<!--              />-->

<!--              &lt;!&ndash; Overlay Content for Non-Pro Users &ndash;&gt;-->
<!--              <div *ngIf="!isPremium" class="text-center relative z-10">-->
<!--                <ng-icon-->
<!--                  name="remixLockFill"-->
<!--                  class="text-gray-400 mb-4"-->
<!--                  style="width: 40px; height: 40px;"-->
<!--                ></ng-icon>-->
<!--                <p class="text-gray-600 mb-4">Upgrade to Premium to access this feature.</p>-->
<!--                <button-->
<!--                  (click)="onPremiumPlanClick()"-->
<!--                  class="bg-custom-blue-500 hover:bg-custom-blue-600 text-white font-semibold py-2 px-4 rounded"-->
<!--                >-->
<!--                  Upgrade to Premium-->
<!--                </button>-->
<!--              </div>-->

<!--              &lt;!&ndash; If loading, show spinner &ndash;&gt;-->
<!--              <div *ngIf="isPremium && requestState === RequestState.LOADING">-->
<!--                <ng-icon-->
<!--                  name="remixLoader4Line"-->
<!--                  class="animate-spin text-blue-500"-->
<!--                  style="width: 40px; height: 40px;"-->
<!--                ></ng-icon>-->
<!--              </div>-->

<!--              &lt;!&ndash; If success, show the chart &ndash;&gt;-->
<!--              <div *ngIf="isPremium && requestState === RequestState.SUCCESS" class="w-full h-full">-->

<!--                <div id="chart" class="flex-1">-->
<!--                  <apx-chart-->
<!--                    [series]="weeklyScoreChartOptions.series!"-->
<!--                    [chart]="weeklyScoreChartOptions.chart!"-->
<!--                    [xaxis]="weeklyScoreChartOptions.xaxis!"-->
<!--                    [stroke]="weeklyScoreChartOptions.stroke!"-->
<!--                    [fill]="weeklyScoreChartOptions.fill!"-->
<!--                    [yaxis]="weeklyScoreChartOptions.yaxis!"-->
<!--                    [tooltip]="weeklyScoreChartOptions.tooltip!">-->
<!--                  </apx-chart>-->
<!--                </div>-->
<!--              </div>-->

<!--              &lt;!&ndash; If error, show error icon &ndash;&gt;-->
<!--              <div *ngIf="isPremium && requestState === RequestState.ERROR" class="text-center">-->
<!--                <ng-icon-->
<!--                  name="remixErrorWarningLine"-->
<!--                  class="text-red-500 mb-4"-->
<!--                  style="width: 40px; height: 40px;"-->
<!--                ></ng-icon>-->
<!--                <p class="text-red-600">An error occurred while loading the data.</p>-->
<!--              </div>-->

<!--            </div>-->
