<div class="min-h-screen flex flex-col"> <!-- Ensures the page takes the full height of the screen -->
  <section class="relative bg-gray-100 overflow-hidden flex-grow h-full"> <!-- flex-grow and h-full to make it fill the height -->
    <img class="absolute left-0 top-0" src="assets/images/gradient6.svg" alt="">
    <div class="relative z-10 flex flex-wrap -m-8 min-h-full h-full"> <!-- min-h-full here to ensure it's tall enough -->
      <div class="w-full md:w-1/2 p-8 flex flex-col h-full"> <!-- Apply h-full here -->
        <div class="container px-4 mx-auto h-full"> <!-- Ensure full height for container -->
          <div class="flex flex-wrap h-full"> <!-- Ensure full height for flex container -->
            <div class="w-full h-full flex flex-col justify-between"> <!-- Ensure full height for inner flex container -->
              <div class="md:max-w-lg mx-auto pt-16 md:pb-32">
                <a class="mb-28 inline-block" href="#">
                  <img src="assets/logos/studious-logo-bold-10.svg" alt="logo" class="h-12">
                </a>
                <h2 class="mb-32 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">Create an account &amp; get started.</h2>
                <h3 class="mb-9 text-xl font-bold font-heading leading-normal">Improve your Exam Performance today</h3>
                <ul class="md:max-w-xs">
                  <li class="mb-5 flex items-start justify-between">
                    <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2" style="width: 25px; height: 26px;">
                    <span class="flex-1 font-medium leading-relaxed">Question bank of over 50,000 questions</span>
                  </li>
                  <li class="mb-5 flex items-start justify-between">
                    <img src="assets/icons/checkmark-circle.svg" alt="checkmark" class="mr-2" style="width: 25px; height: 26px;">
                    <span class="flex-1 font-medium leading-relaxed">Detailed analytics of your performance</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right half - Center the sign-in form -->
      <div class="w-full md:w-1/2 p-8 flex justify-center items-center"> <!-- Centering the form container -->
        <div class="p-8 bg-white rounded-lg shadow-xl max-w-lg min-w-[450px] border border-gray-300 mr-10"> <!-- Floating Sign-In Form with right margin, wider box, and outline -->

          <form [formGroup]="form" (ngSubmit)="onRegisterClick()" class="md:max-w-lg mx-auto">
            <label class="block mb-4 mt-4">
              <p class="mb-2 text-gray-900 font-semibold leading-normal">First Name *</p>
              <input formControlName="firstName" class="px-4 py-3.5 w-full text-gray-900 font-medium placeholder-gray-400 bg-white outline-none border rounded-lg focus:ring focus:ring-custom-blue-200"
                     type="text" placeholder="First name"
                     [ngClass]="{ 'border-red-500': form.get('firstName')?.invalid && form.get('firstName')?.touched }">
              <div *ngIf="form.get('firstName')?.invalid && form.get('firstName')?.touched" class="text-red-500 text-sm mt-1">First name is required and should be at least 2 characters.</div>
            </label>

            <label class="block mb-4">
              <p class="mb-2 text-gray-900 font-semibold leading-normal">Last Name *</p>
              <input formControlName="lastName" class="px-4 py-3.5 w-full text-gray-900 font-medium placeholder-gray-400 bg-white outline-none border rounded-lg focus:ring focus:ring-custom-blue-200"
                     type="text" placeholder="Last name"
                     [ngClass]="{ 'border-red-500': form.get('lastName')?.invalid && form.get('lastName')?.touched }">
              <div *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched" class="text-red-500 text-sm mt-1">Last name is required and should be at least 2 characters.</div>
            </label>

            <label class="block mb-4">
              <p class="mb-2 text-gray-900 font-semibold leading-normal">Email Address *</p>
              <input formControlName="email" class="px-4 py-3.5 w-full text-gray-900 font-medium placeholder-gray-400 bg-white outline-none border rounded-lg focus:ring focus:ring-custom-blue-200"
                     type="text" placeholder="Enter email address"
                     [ngClass]="{ 'border-red-500': form.get('email')?.invalid && form.get('email')?.touched }">
              <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="text-red-500 text-sm mt-1">Please enter a valid email address.</div>
            </label>

            <label class="block mb-5">
              <p class="mb-2 text-gray-900 font-semibold leading-normal">Password *</p>
              <input formControlName="password" class="px-4 py-3.5 w-full text-gray-900 font-medium placeholder-gray-400 bg-white outline-none border rounded-lg focus:ring focus:ring-custom-blue-200"
                     type="password" placeholder="********"
                     [ngClass]="{ 'border-red-500': form.get('password')?.invalid && form.get('password')?.touched }">
              <!-- Error Messages -->
              <div *ngIf="form.get('password')?.hasError('required') && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                Password is required.
              </div>
              <div *ngIf="form.get('password')?.hasError('minLength') && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                Password must be at least 8 characters long.
              </div>
              <div *ngIf="form.get('password')?.hasError('noUppercase') && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                Password must contain at least one uppercase letter.
              </div>
              <div *ngIf="form.get('password')?.hasError('noLowercase') && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                Password must contain at least one lowercase letter.
              </div>
              <div *ngIf="form.get('password')?.hasError('noNumber') && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                Password must contain at least one number.
              </div>
              <div *ngIf="form.get('password')?.hasError('invalidCharacter') && form.get('password')?.touched" class="text-red-500 text-sm mt-1">
                Password contains invalid characters.
              </div>
            </label>

            <div class="flex flex-wrap justify-between mb-4">
              <div class="w-full">
                <div class="flex items-center">
                  <input class="w-4 h-4" id="default-checkbox" type="checkbox" formControlName="terms">
                  <label class="ml-2 text-sm text-gray-900 font-medium" for="default-checkbox">
                    <span>By signing up, I agree to the </span>
                      <button type="button" (click)="openTermsModal()" class="text-custom-blue-500 hover:text-custom-blue-600">
                        Terms & Conditions
                      </button>
                  </label>
                </div>
                <div *ngIf="form.get('terms')?.invalid && form.get('terms')?.touched" class="text-red-500 text-sm mt-1">You must agree to the terms and conditions.</div>
              </div>
            </div>

            <button class="mb-4 py-4 px-9 w-full text-white font-semibold border rounded-xl shadow-4xl focus:ring focus:ring-custom-blue-200 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="submit" [disabled]="isSubmitting">
              Sign Up
            </button>

            <!-- Display error message from the backend -->
            <div *ngIf="errorMessage" class="text-red-500 text-sm mt-1 mb-4">
              {{ errorMessage }}
            </div>

            <p class="text-center text-sm text-gray-500 mb-6">
              Already have an account?
              <a routerLink="/login" class="text-custom-blue-500 hover:text-custom-blue-600 font-medium">
                Sign In
              </a>
            </p>
          </form>

          <p class="mb-5 text-sm text-gray-500 font-medium text-center">Or continue with</p>
          <div class="flex flex-wrap justify-center -m-2">
            <div class="w-auto p-2">
              <button (click)="onGoogleLoginClick()" class="flex items-center p-4 bg-white hover:bg-gray-50 border rounded-lg transition ease-in-out duration-200">
                <img class="mr-3" ngSrc="assets/logos/brands/google.svg" alt="" height="20" width="19">
                <span class="font-semibold leading-normal">Sign in with Google</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-terms-conditions-modal *ngIf="showTermsModal" (closeModal)="closeTermsModal()"></app-terms-conditions-modal>
