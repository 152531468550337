import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { first, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { User } from '@angular/fire/auth'; // Import User from Firebase v9

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.firebaseUser$.pipe(
      // Wait until the authentication state is not null (resolved)
      first((user) => user !== undefined),
      map((user: User | null) => { // Use the modular User type here
        if (user) {
          // User is authenticated
          return true;
        } else {
          // User is not authenticated
          return this.router.createUrlTree(['/login']);
        }
      })
    );
  }
}
