import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {NgForOf, NgStyle} from "@angular/common";
import {ExamService} from "../../services/exam.service";
import {ExamData} from "../../services/exam-data-interface";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-exams',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    NgStyle
  ],
  templateUrl: './exams.component.html',
  styleUrl: './exams.component.css'
})
export class ExamsComponent implements OnInit {
  // Declare arrays for each exam category
  k8Exams: ExamData[] = [];
  highSchoolExams: ExamData[] = [];
  graduateExams: ExamData[] = [];
  professionalExams: ExamData[] = [];
  languageExams: ExamData[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private examService: ExamService
  ) {}

  ngOnInit(): void {
    // Update the metadata tags
    const metaData = this.route.snapshot.data;
    this.metaService.updateMetaTags(metaData);

    // Populate the arrays with data from the service
    this.k8Exams = this.examService.getK8Exams();
    this.highSchoolExams = this.examService.getHighSchoolExams();
    this.graduateExams = this.examService.getGraduateExams();
    this.professionalExams = this.examService.getProfessionalExams();
    this.languageExams = this.examService.getLanguageExams();
  }

  navigateToExam(link: string) {
    this.router.navigate(['/exam', link]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
