import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-terms-conditions-modal',
  standalone: true,
  imports: [],
  templateUrl: './terms-conditions-modal.component.html',
  styleUrl: './terms-conditions-modal.component.css'
})
export class TermsConditionsModalComponent {
  @Output() closeModal = new EventEmitter<void>(); // To notify parent component to close modal

  // Method to close the modal
  onClose() {
    this.closeModal.emit(); // Emit event to parent to close the modal
  }
}
