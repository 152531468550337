<nav class="relative z-20 w-full bg-transparent">
<!--  <img class="absolute top-0 left-0" src="assets/images/home/gradient4.svg" alt="">-->
  <div class="container relative mx-auto flex items-center justify-between px-4 py-5">
    <!-- Logo -->
    <div class="flex items-center">
      <a routerLink="/">
        <img src="assets/logos/studious-logo-bold-10.svg" alt="Logo" class="h-12 max-w-none">
      </a>
    </div>

    <!-- Desktop Menu -->
    <div class="hidden lg:flex items-center z-20 ml-20">
      <ul class="flex items-center space-x-8">
        <!-- Grades K-8 Dropdown -->
        <li class="relative group">
          <a class="font-medium hover:text-gray-700 flex items-center cursor-pointer">
            Grades K-8
            <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 9l-7 7-7-7"/>
            </svg>
          </a>
          <!-- Dropdown Menu -->
          <div
            class="absolute left-0 w-48 bg-white shadow-lg rounded-md z-50 hidden group-hover:block hover:block">
            <ul class="py-1">
              <li *ngFor="let exam of k8Exams">
                <a [routerLink]="['/exam', exam.link]" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Grades 9-12 Dropdown -->
        <li class="relative group">
          <a class="font-medium hover:text-gray-700 flex items-center cursor-pointer">
            Grades 9-12
            <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 9l-7 7-7-7"/>
            </svg>
          </a>
          <!-- Dropdown Menu -->
          <div
            class="absolute left-0 w-48 bg-white shadow-lg rounded-md z-50 hidden group-hover:block hover:block">
            <ul class="py-1">
              <li *ngFor="let exam of highSchoolExams">
                <a [routerLink]="['/exam', exam.link]" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Graduate Dropdown -->
        <li class="relative group">
          <a class="font-medium hover:text-gray-700 flex items-center cursor-pointer">
            Graduate
            <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 9l-7 7-7-7"/>
            </svg>
          </a>
          <!-- Dropdown Menu -->
          <div
            class="absolute left-0 w-48 bg-white shadow-lg rounded-md z-50 hidden group-hover:block hover:block">
            <ul class="py-1">
              <li *ngFor="let exam of graduateExams">
                <a [routerLink]="['/exam', exam.link]" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Professional Dropdown -->
        <li class="relative group">
          <a class="font-medium hover:text-gray-700 flex items-center cursor-pointer">
            Professional
            <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 9l-7 7-7-7"/>
            </svg>
          </a>
          <!-- Dropdown Menu -->
          <div
            class="absolute left-0 w-48 bg-white shadow-lg rounded-md z-50 hidden group-hover:block hover:block">
            <ul class="py-1">
              <li *ngFor="let exam of professionalExams">
                <a [routerLink]="['/exam', exam.link]" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Language Proficiency Dropdown -->
        <li class="relative group">
          <a class="font-medium hover:text-gray-700 flex items-center cursor-pointer">
            Language
            <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 9l-7 7-7-7"/>
            </svg>
          </a>
          <!-- Dropdown Menu -->
          <div
            class="absolute left-0 w-48 bg-white shadow-lg rounded-md z-50 hidden group-hover:block hover:block">
            <ul class="py-1">
              <li *ngFor="let exam of languageExams">
                <a [routerLink]="['/exam', exam.link]" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </div>
        </li>

      </ul>

    </div>


    <!-- Right-hand Side of Navigation -->
    <div class="hidden lg:flex items-center space-x-6 ml-12"> <!-- Increased spacing -->
      <!-- Conditionally display login/register or profile info -->

      <ng-container *ngIf="isLoggedIn; else loginRegister">
        <!-- Navbar -->
        <nav class="bg-transparent">
          <!-- Removed 'border-b border-gray-200' to eliminate the grey line at the bottom -->
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
              <!-- Left side (optional: logo or navigation links) -->
              <div class="flex items-center">
                <!-- Add logo or navigation links here if needed -->
              </div>
              <!-- Right side -->
              <div class="flex items-center space-x-6"> <!-- Increased spacing -->
                <!-- Dashboard Button moved before the name and made more rounded -->
                <button
                  (click)="goToDashboard()"
                  class="bg-custom-blue-500 hover:bg-custom-blue-700 text-white font-medium py-2 px-4 rounded-xl transition duration-200"
                >
                  Dashboard
                </button>

                <!-- Profile dropdown combined with name -->
                <div class="relative">
                  <button
                    (click)="toggleProfileMenu()"
                    class="flex items-center focus:outline-none"
                    id="user-menu"
                    aria-haspopup="true"
                  >
                    <!-- Check if there's a name; if not, show profile icon -->
                    <ng-container *ngIf="getFirstname(); else noName">
                  <span class="text-gray-700 font-medium text-base">
                    {{ getFirstname() }}
                  </span>
                    </ng-container>
                    <ng-template #noName>
                      <ng-icon
                        name="remixUserLine"
                        class="h-6 w-6 text-gray-500"
                      ></ng-icon>
                    </ng-template>
                    <!-- Dropdown Arrow Icon -->
                    <ng-icon
                      name="remixArrowDownSLine"
                      class="ml-1 h-5 w-5 text-gray-500"
                    ></ng-icon>
                  </button>
                  <!-- Dropdown Menu styled like Tailwind UI -->
                  <div
                    *ngIf="profileMenuOpen"
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-20"
                  >
                    <div
                      class="py-1 rounded-md bg-white shadow-xs ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <a
                        (click)="onSettingsClick()"
                        class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
                        role="menuitem"
                      >
                        <!-- Settings Icon from @ng-icons/remixicon -->
                        <ng-icon
                          name="remixSettings3Line"
                          class="w-5 h-5 mr-3 text-gray-500"
                        ></ng-icon>
                        Settings
                      </a>
                      <a
                        (click)="onLogoutClick()"
                        class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
                        role="menuitem"
                      >
                        <!-- Logout Icon from @ng-icons/remixicon -->
                        <ng-icon
                          name="remixLogoutBoxLine"
                          class="w-5 h-5 mr-3 text-gray-500"
                        ></ng-icon>
                        Log Out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </ng-container>

      <ng-template #loginRegister>
        <!-- Login/Register Buttons -->

        <a
          routerLink="/login"
          class="py-2 px-4 text-black border border-gray-800 font-semibold rounded-lg hover:bg-gray-100 transition duration-200"
        >Login</a
        >
        <a
          routerLink="/register"
          class="py-3 px-5 w-full bg-custom-blue-500 text-white border hover:border-gray-300 font-semibold rounded-xl hover:bg-custom-blue-600"
        >Create a free account</a
        >
      </ng-template>
    </div>

    <!-- Mobile Menu Button -->
    <div class="lg:hidden">
      <button (click)="toggleMobileNav()">
        <svg class="text-custom-blue-500 h-8 w-8" fill="currentColor" viewBox="0 0 56 56">
          <rect width="56" height="56" rx="28"></rect>
          <path d="M37 32H19M37 24H19" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </svg>
      </button>
    </div>
  </div>


  <!-- Mobile Navigation -->
  <div *ngIf="mobileNavOpen" class="fixed inset-0 z-50">
    <div (click)="toggleMobileNav()" class="fixed inset-0 bg-gray-800 opacity-80"></div>
    <nav class="relative z-10 bg-white h-full w-4/6 sm:max-w-xs p-8 overflow-y-auto">
      <div class="flex flex-col h-full">
        <!-- Close Button -->
        <div class="flex items-center justify-between mb-8">
          <a routerLink="/">
            <img src="assets/logos/studious-logo-bold-10.svg" alt="Logo" class="h-10">
          </a>
          <button (click)="toggleMobileNav()">
            <svg class="h-6 w-6 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <!-- Menu Items -->
        <ul class="flex-1">
          <!-- Grades K-8 Dropdown -->
          <li class="mb-6">
            <div (click)="k8DropdownOpen = !k8DropdownOpen"
                 class="flex items-center justify-between font-medium hover:text-gray-700 cursor-pointer">
              <span>Grades K-8</span>
              <svg class="h-4 w-4 transform" [class.rotate-180]="k8DropdownOpen" xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 9l-7 7-7-7"/>
              </svg>
            </div>
            <!-- Dropdown Menu -->
            <ul *ngIf="k8DropdownOpen" class="mt-2 pl-4 border-l border-gray-200">
              <li *ngFor="let exam of k8Exams" class="mb-2">
                <a [routerLink]="['/exam', exam.link]" class="block text-gray-700 hover:text-gray-900">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </li>

          <!-- Grades 9-12 Dropdown -->
          <li class="mb-6">
            <div (click)="highSchoolDropdownOpen = !highSchoolDropdownOpen"
                 class="flex items-center justify-between font-medium hover:text-gray-700 cursor-pointer">
              <span>Grades 9-12</span>
              <svg class="h-4 w-4 transform" [class.rotate-180]="highSchoolDropdownOpen"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 9l-7 7-7-7"/>
              </svg>
            </div>
            <!-- Dropdown Menu -->
            <ul *ngIf="highSchoolDropdownOpen" class="mt-2 pl-4 border-l border-gray-200">
              <li *ngFor="let exam of highSchoolExams" class="mb-2">
                <a [routerLink]="['/exam', exam.link]" class="block text-gray-700 hover:text-gray-900">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </li>

          <!-- Graduate Dropdown -->
          <li class="mb-6">
            <div (click)="graduateDropdownOpen = !graduateDropdownOpen"
                 class="flex items-center justify-between font-medium hover:text-gray-700 cursor-pointer">
              <span>Graduate</span>
              <svg class="h-4 w-4 transform" [class.rotate-180]="graduateDropdownOpen" xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 9l-7 7-7-7"/>
              </svg>
            </div>
            <!-- Dropdown Menu -->
            <ul *ngIf="graduateDropdownOpen" class="mt-2 pl-4 border-l border-gray-200">
              <li *ngFor="let exam of graduateExams" class="mb-2">
                <a [routerLink]="['/exam', exam.link]" class="block text-gray-700 hover:text-gray-900">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </li>

          <!-- Professional Dropdown -->
          <li class="mb-6">
            <div (click)="professionalDropdownOpen = !professionalDropdownOpen"
                 class="flex items-center justify-between font-medium hover:text-gray-700 cursor-pointer">
              <span>Professional</span>
              <svg class="h-4 w-4 transform" [class.rotate-180]="professionalDropdownOpen"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 9l-7 7-7-7"/>
              </svg>
            </div>
            <!-- Dropdown Menu -->
            <ul *ngIf="professionalDropdownOpen" class="mt-2 pl-4 border-l border-gray-200">
              <li *ngFor="let exam of professionalExams" class="mb-2">
                <a [routerLink]="['/exam', exam.link]" class="block text-gray-700 hover:text-gray-900">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </li>

          <!-- Language Proficiency Dropdown -->
          <li class="mb-6">
            <div (click)="languageDropdownOpen = !languageDropdownOpen"
                 class="flex items-center justify-between font-medium hover:text-gray-700 cursor-pointer">
              <span>Language Proficiency</span>
              <svg class="h-4 w-4 transform" [class.rotate-180]="languageDropdownOpen"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 9l-7 7-7-7"/>
              </svg>
            </div>
            <!-- Dropdown Menu -->
            <ul *ngIf="languageDropdownOpen" class="mt-2 pl-4 border-l border-gray-200">
              <li *ngFor="let exam of languageExams" class="mb-2">
                <a [routerLink]="['/exam', exam.link]" class="block text-gray-700 hover:text-gray-900">
                  {{ exam.displayName }}
                </a>
              </li>
            </ul>
          </li>

          <!-- Pricing -->
          <li class="mb-6">
            <a routerLink="/pricing" class="font-medium hover:text-gray-700">Pricing</a>
          </li>
        </ul>


        <!-- User Actions -->
        <div>
          <ng-container *ngIf="isLoggedIn; else mobileLoginRegister">

            <a
              (click)="goToDashboard()"
              class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
            >
              <svg
                class="w-5 h-5 mr-3 text-gray-500"
                fill="currentColor"
              >
                <use href="assets/icons/dashboard-icon.svg#icon"></use>
              </svg>
              Dashboard
            </a>

            <a
              (click)="onSettingsClick()"
              class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
            >
              <svg
                class="w-5 h-5 mr-3 text-gray-500"
                fill="currentColor"
              >
                <use href="assets/icons/settings-icon.svg#icon"></use>
              </svg>
              Settings
            </a>
            <a
              (click)="onLogoutClick()"
              class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer transition duration-200"
            >
              <svg
                class="w-5 h-5 mr-3 text-gray-500"
                fill="currentColor"
              >
                <use href="assets/icons/logout-icon.svg#icon"></use>
              </svg>
              Log Out
            </a>


          </ng-container>
          <ng-template #mobileLoginRegister>
            <!-- Login/Register Buttons -->
            <a routerLink="/login" class="block py-3 px-5 w-full text-center text-custom-blue-700 font-semibold hover:text-custom-blue-700 mb-4 border border-gray-400 rounded-xl">Login</a>
            <a routerLink="/register" class="block py-3 px-5 w-full text-center bg-custom-blue-500 text-white font-semibold hover:bg-custom-blue-600 rounded-xl">Register</a>
          </ng-template>
        </div>

      </div>
    </nav>
  </div>
</nav>

<!--<section x-data="{ mobileNavOpen: false }" class="bg-blueGray-50">-->
<!--  <div class="container mx-auto overflow-hidden">-->
<!--    <div class="flex items-center justify-between px-4 py-5 bg-blueGray-50">-->
<!--      <div class="w-auto">-->
<!--        <div class="flex flex-wrap items-center">-->
<!--          <div class="w-auto mr-14">-->
<!--            <a href="#">-->
<!--              <img src="assets/logos/flaro-logo-black.svg" alt="">-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="w-auto">-->
<!--        <div class="flex flex-wrap items-center">-->
<!--          <div class="w-auto hidden lg:block">-->
<!--            <ul class="flex items-center mr-16">-->
<!--              <li class="mr-9 font-medium hover:text-gray-700"><a href="#">Exams</a></li>-->
<!--              <li class="mr-9 font-medium hover:text-gray-700"><a href="#">Pricing</a></li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="w-auto hidden lg:block">-->
<!--            <div class="inline-block">-->
<!--              <button class="py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Try 14 Days Free Trial</button>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="w-auto lg:hidden">-->
<!--            <button x-on:click="mobileNavOpen = !mobileNavOpen">-->
<!--              <svg class="text-indigo-600" width="51" height="51" viewbox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <rect width="56" height="56" rx="28" fill="currentColor"></rect>-->
<!--                <path d="M37 32H19M37 24H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>-->
<!--              </svg>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div :class="{'block': mobileNavOpen, 'hidden': !mobileNavOpen}" class="hidden fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">-->
<!--      <div x-on:click="mobileNavOpen = !mobileNavOpen" class="fixed inset-0 bg-gray-800 opacity-80"></div>-->
<!--      <nav class="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto">-->
<!--        <div class="flex flex-wrap justify-between h-full">-->
<!--          <div class="w-full">-->
<!--            <div class="flex items-center justify-between -m-2">-->
<!--              <div class="w-auto p-2">-->
<!--                <a class="inline-block" href="#">-->
<!--                  <img src="assets/logos/flaro-logo-black.svg" alt="">-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="w-auto p-2">-->
<!--                <button x-on:click="mobileNavOpen = !mobileNavOpen">-->
<!--                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M6 18L18 6M6 6L18 18" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex flex-col justify-center py-16 w-full">-->
<!--            <ul>-->
<!--              <li class="mb-12"><a class="font-medium hover:text-gray-700" href="#">Exams</a></li>-->
<!--              <li class="mb-12"><a class="font-medium hover:text-gray-700" href="#">Pricing</a></li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="flex flex-col justify-end w-full pb-8">-->
<!--            <div class="flex flex-wrap">-->
<!--              <div class="w-full">-->
<!--                <div class="block">-->
<!--                  <button class="py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Login</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </nav>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
