import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {ExamService} from "../../../services/exam.service";
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {Subscription} from "rxjs";
import {UserResponse} from "../../../models/user-response";
import {IconsModule} from "../../icons/icons.module";
import {ExamData} from "../../../services/exam-data-interface";

@Component({
  selector: 'app-landing-navigation',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgForOf,
    IconsModule
  ],
  templateUrl: './landing-navigation.component.html',
  styleUrl: './landing-navigation.component.css'
})
export class LandingNavigationComponent implements OnInit, OnDestroy {
  mobileNavOpen = false;

  // New dropdown states for mobile menu
  k8DropdownOpen: boolean = false;
  highSchoolDropdownOpen: boolean = false;
  graduateDropdownOpen: boolean = false;
  professionalDropdownOpen: boolean = false;
  languageDropdownOpen: boolean = false;

  examDataList: ExamData[] = [];
  k8Exams: ExamData[] = [];
  highSchoolExams: ExamData[] = [];
  graduateExams: ExamData[] = [];
  professionalExams: ExamData[] = [];
  languageExams: ExamData[] = [];

  isLoggedIn = false;

  profileMenuOpen: boolean = false;
  currentExamId: string | null = null;
  isPremium: boolean = false;
  userResponse: UserResponse | null = null;
  private subscriptions: Subscription = new Subscription();

  constructor(private router: Router, private examService: ExamService, private userService: UserService,
              private authService: AuthService) {}

  ngOnInit(): void {
    // Fetch exams from a service or define them here
    this.examDataList = this.examService.getExamDataList();

    this.k8Exams = this.examService.getK8Exams();

    this.highSchoolExams = this.examService.getHighSchoolExams();

    this.graduateExams = this.examService.getGraduateExams();

    this.professionalExams = this.examService.getProfessionalExams();

    this.languageExams = this.examService.getLanguageExams();

    this.authService.isLoggedIn().subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    const sub = this.examService.currentExamId$.subscribe(examId => {
      this.currentExamId = examId;
      //console.log('landing navigation init currentExamId = ', this.currentExamId )
      if (this.currentExamId) {
        const sub2 = this.userService.isUserSubscribedToExam(this.currentExamId)
          .subscribe(isSubscribed => {
            this.isPremium = isSubscribed;
          });
        this.subscriptions.add(sub2)
      }
    });
    this.subscriptions.add(sub);

    this.userService.getUserResponse().subscribe({
      next: (userResponse) => {
        this.userResponse = userResponse;
      },
      error: err => {
        this.userResponse = null;
      },
      complete: () => {
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleMobileNav() {
    this.mobileNavOpen = !this.mobileNavOpen;
  }


  toggleProfileMenu() {
    this.profileMenuOpen = !this.profileMenuOpen;
  }

  // Close the menu when clicking outside
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.relative')) {  // Check if click is outside the menu
      this.profileMenuOpen = false;
    }
  }

  onSettingsClick() {
    this.profileMenuOpen = false;
    this.router.navigate(['/profile']);  // Redirect to login page after logout
  }

  // Method to handle user logout
  onLogoutClick() {
    this.profileMenuOpen = false;
    this.authService.logout();
    this.router.navigate(['/']);  // Redirect to login page after logout
  }

  getFirstname() {
    if (this.userResponse?.firstName) {
      return this.userResponse?.firstName;
    }
    return '';
  }

  goToDashboard() {
    console.log('goToDashboard this.currentExamId=' + this.currentExamId);
    if (this.currentExamId) {
      this.examService.navigateToDashboard(this.currentExamId);
    } else {
      this.router.navigate(['/exams'], {fragment: 'exams-list'});
    }
  }

}
