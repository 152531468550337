
<!--  <img class="absolute top-0 left-0" src="assets/images/home/gradient4-top.svg" alt="">-->
  <section class="bg-transparent">
    <div class="overflow-hidden pt-4">
      <div class="container px-4 mx-auto">

        <div class="flex flex-wrap -m-8">

          <!-- Left-hand side -->
          <div class="w-full md:w-1/2 p-8">
            <div class="inline-block mb-6 px-2 py-1 font-semibold bg-custom-light-blue-400 rounded-full">
              <div class="flex flex-wrap items-center -m-1">
                <div class="w-auto p-1"><a class="text-sm" href="/exams">👋 Our premium plans are $9.99 per month </a></div>
                <div class="w-auto p-1">
                  <svg width="15" height="15" viewbox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66667 3.41675L12.75 7.50008M12.75 7.50008L8.66667 11.5834M12.75 7.50008L2.25 7.50008" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>

            <h1 class="mb-6 text-6xl md:text-6xl lg:text-6xl font-bold font-heading md:max-w-xl leading-none">Boost your exam score with adaptive learning</h1>
            <p class="mb-11 text-lg text-gray-900 font-medium md:max-w-md">Get the best-in-class question bank at the most affordable price</p>
            <div class="flex flex-wrap -m-2.5 mb-20">

              <div class="w-full md:w-auto p-2.5 z-10">
                <div class="block">
                  <button routerLink="/register" class="py-4 px-6 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="button">Create a Free Account</button>
                </div>
              </div>

              <div class="w-full md:w-auto p-2.5 z-10">
                <div class="block">
                  <button routerLink="/exams" class="py-4 px-9 w-full font-semibold border border-gray-300 hover:border-gray-400 rounded-xl focus:ring focus:ring-gray-50 bg-transparent hover:bg-gray-100 transition ease-in-out duration-200 flex justify-between items-center" type="button">
                    <div class="flex flex-wrap justify-center items-center -m-1">
                      <div class="w-auto p-1">
                        <!-- Use Remix Icon -->
                        <!-- <i class="ri-book-open-line text-xl"></i>-->
                      </div>
                      <div class="w-auto p-1">
                        <span>Our Exams</span>
                      </div>
                    </div>
                    <!-- Right arrow with hover animation -->
                    <i class="ri-arrow-right-s-line text-xl ml-2 transform transition-transform duration-200 hover:translate-x-2"></i>
                  </button>
                </div>
              </div>

            </div>

            <p class="mb-6 text-sm text-gray-500 font-semibold uppercase">Trusted and loved by 20,000+ students </p>
            <div class="flex flex-wrap -m-3">
<!--              <div class="w-auto p-3">-->
<!--                <img src="flaro-assets/logos/brands/brand.png" alt="">-->
<!--              </div>-->
<!--              <div class="w-auto p-3">-->
<!--                <img src="flaro-assets/logos/brands/brand2.png" alt="">-->
<!--              </div>-->
<!--              <div class="w-auto p-3">-->
<!--                <img src="flaro-assets/logos/brands/brand3.png" alt="">-->
<!--              </div>-->
            </div>
          </div>

          <!-- Right-hand side -->
          <div class="hidden md:flex md:w-1/2">
            <div class="p-6 lg:p-6 banner-slider">
              <div class="flex">
                <div>
                  <div class="slider-img">
                    <img
                      src="assets/images/home/1.png"
                      alt="img1"
                      width="403" height="810"
                    />
                  </div>
                  <div class="slider-img">
                    <img
                      src="assets/images/home/1.png"
                      alt="img1"
                      width="403" height="810"
                    />
                  </div>
                </div>
                <div>
                  <div class="slider-img2">
                    <img
                      src="assets/images/home/2.png"
                      alt="img2"
                      width="403" height="810"
                    />
                  </div>
                  <div class="slider-img2">
                    <img
                      src="assets/images/home/2.png"
                      alt="img2"
                      width="403" height="810"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>


  <section class="relative pt-12 pb-40 bg-blueGray-50 overflow-hidden">
    <!-- Background Image Layer -->
<!--    <img-->
<!--      class="absolute inset-0 w-full h-full object-cover opacity-50 z-0 pointer-events-none flip-vertical"-->
<!--      src="assets/images/home/gradient2.svg"-->
<!--      alt="Gradient Background"-->
<!--    >-->

    <div class="container px-4 mx-auto z-10">
      <h2 class="mb-10 text-5xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-none">Target your weaknesses</h2>
      <div class="flex flex-wrap -m-16 md:-m-3">
        <div class="w-full md:w-1/3 p-16 md:p-3">
          <div class="px-10 pt-11 text-center bg-gray-200 h-96 rounded-xl shadow-lg">
            <h3 class="mb-3 text-xl font-bold font-heading leading-normal">Detailed Explanations</h3>
            <p class="mb-10 text-gray-600 font-medium leading-relaxed">Our endless practice mode is the most effective way to master your exam.</p>
            <img class="mx-auto h-72 object-cover rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000 border border-gray-300" src="assets/images/home/math-question-cropped.png" alt="">
          </div>
        </div>
        <div class="w-full md:w-1/3 p-16 md:p-3">
          <div class="px-10 pt-11 text-center bg-gray-200 h-96 rounded-xl shadow-lg">
            <h3 class="mb-3 text-xl font-bold font-heading leading-normal">Strength & Weakness</h3>
            <p class="mb-10 text-gray-600 font-medium leading-relaxed">Breakdown your performance by categories and pinpoint your weaknesses.</p>
            <img class="mx-auto h-72 object-cover rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000 border border-gray-300" src="assets/images/home/worst-categories-cropped.png" alt="">
          </div>
        </div>
        <div class="w-full md:w-1/3 p-16 md:p-3">
          <div class="px-10 pt-11 text-center bg-gray-200 h-96 rounded-xl shadow-lg">
            <h3 class="mb-3 text-xl font-bold font-heading leading-normal">Adaptive Learning Algorithms</h3>
            <p class="mb-10 text-gray-600 font-medium leading-relaxed">Our algorithms use a 100,000+ question bank to identify performance gaps.</p>
            <img class="mx-auto h-72 object-cover rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000 border border-gray-300" src="assets/images/home/question-solution-cropped.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>

<section class="relative pt-18 pb-16 bg-gray-50 overflow-hidden ">
  <img
    class="absolute inset-0 w-full h-full object-cover opacity-100 z-0 pointer-events-none flip-vertical"
    src="assets/images/home/gradient-topleft.svg"
    alt="Gradient Background"
  >

  <!-- Testimonial Container with Higher Z-Index -->
  <div class="container relative px-4 mx-auto z-20">
    <div class="flex flex-wrap -m-8">
      <!--Ratings-->
      <div class="w-full p-3">

        <div class="flex flex-wrap justify-between items-end -m-2 mb-4">
          <div class="w-auto p-2">
            <h2 class="text-5xl md:text-5xl font-bold font-heading tracking-px-n leading-tight">See what our students are saying</h2>
          </div>
        </div>

        <!-- Flex container for ratings -->
        <div class="flex flex-wrap -m-2">
          <!-- Rating Box 1 -->
          <div class="w-full md:w-1/4 p-2">
            <div class="px-8 py-6 h-full bg-white rounded-3xl shadow-lg">

              <div class="flex flex-col justify-between h-full">
                <div class="mb-7 block">
                  <div class="flex flex-wrap -m-0.5 mb-6">
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="mb-6 text-lg font-bold font-heading">“The best practice platform for the SAT”</h3>
                  <p class="text-lg font-medium">Studious's extensive question bank and detailed explanations made preparing for the SAT less painful. The endless practice felt like a game, and I improved on my weak points.</p>
                </div>
                <div class="block">
                  <p class="font-bold">Alex M. - Princeton University</p>

                </div>
              </div>
            </div>
          </div>

          <!-- Rating Box 1 -->
          <div class="w-full md:w-1/4 p-2">
            <div class="px-8 py-6 h-full bg-white rounded-3xl shadow-lg">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-7 block">
                  <div class="flex flex-wrap -m-0.5 mb-6">
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="mb-6 text-lg font-bold font-heading">“Studious made acing the TOEFL possible”</h3>
                  <p class="text-lg font-medium">As an international student, I needed a reliable resource to improve my English skills. Studious provided comprehensive materials that were both engaging and affordable.</p>
                </div>
                <div class="block">
                  <p class="font-bold">Li W. - University of California, Berkeley</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Rating Box 1 -->
          <div class="w-full md:w-1/4 p-2">
            <div class="px-8 py-6 h-full bg-white rounded-3xl shadow-lg">

              <div class="flex flex-col justify-between h-full">
                <div class="mb-7 block">
                  <div class="flex flex-wrap -m-0.5 mb-6">
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="mb-6 text-lg font-bold font-heading">“An absolute game-changer for SAT prep”</h3>
                  <p class="text-lg font-medium">I increased my SAT score by 200 points after using Studious. It's so affordable compared to other prep courses.</p>

                </div>
                <div class="block">
                  <p class="font-bold">Michael R. - Ohio State University</p>
                </div>
              </div>
            </div>
          </div>



          <!-- Rating Box 1 -->
          <div class="w-full md:w-1/4 p-2">
            <div class="px-8 py-6 h-full bg-white rounded-3xl shadow-lg">

              <div class="flex flex-col justify-between h-full">
                <div class="mb-7 block">
                  <div class="flex flex-wrap -m-0.5 mb-6">
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                    <div class="w-auto p-0.5">
                      <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="mb-6 text-lg font-bold font-heading">“Boosted my GRE score significantly”</h3>
                  <p class="text-lg font-medium">I was struggling with the quantitative section of the GRE until I started using Studious. The AI-powered explanations clarified complex concepts, and I improved my score by 8 points.</p>
                </div>
                <div class="block">
                  <p class="font-bold">Sara K. - Massachusetts Institute of Technology</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>


<!-- Pricing -->
<section class="relative py-20 bg-transparent overflow-hidden">
  <img class="absolute right-0 bottom-0" src="assets/images/gradient6.svg" alt="">
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap -m-8">
      <!-- Left Side: Title and Subtitle -->
      <div class="w-full md:w-2/5 p-8">
        <div class="flex flex-col justify-between h-full md:max-w-md">
          <div>
            <h2 class="mb-8 text-6xl md:text-6xl xl:text-6xl font-bold font-heading tracking-px-n leading-none">Unlimited Practice Questions</h2>
            <p class="mb-16 font-medium text-gray-600 leading-relaxed">All our exams are priced at $9.99. Pick an exam to get started.</p>
          </div>
          <div>
            <h3 class="mb-3 text-gray-900 font-semibold">Trusted by 20,000+ students</h3>
            <div class="flex flex-wrap items-center -m-1">
              <div class="w-auto p-1">
                <div class="flex flex-wrap -m-0.5">
                  <div class="w-auto p-0.5 text-yellow-400">
                    <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                    </svg>
                  </div>
                  <div class="w-auto p-0.5 text-yellow-400">
                    <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                    </svg>
                  </div>
                  <div class="w-auto p-0.5 text-yellow-400">
                    <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                    </svg>
                  </div>
                  <div class="w-auto p-0.5 text-yellow-400">
                    <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                    </svg>
                  </div>
                  <div class="w-auto p-0.5 text-yellow-400">
                    <svg width="17" height="16" viewbox="0 0 17 16" class="fill-current" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="w-auto p-1">
                <div class="flex flex-wrap -m-0.5">
                  <div class="w-auto p-0.5">
                    <p class="text-gray-900 font-bold">4.6/5.0</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side: Pricing Plans -->
      <div class="w-full md:w-3/5 p-8">
        <div class="flex flex-wrap -m-3">
          <!-- Free Plan -->
          <div class="w-full xl:w-1/2 p-3">
            <div class="px-8 py-7 h-full bg-white bg-opacity-80 border border-blueGray-200 rounded-xl" style="backdrop-filter: blur(46px);">
              <span class="mb-2 inline-block font-medium leading-relaxed">Free</span>

              <!--              <div class="my-4">-->
              <!--                <h2 class="font-semibold text-2xl">{{examData?.displayName}} Exam Free Access</h2>-->
              <!--              </div>-->

              <h3 class="mb-3 text-4xl md:text-4xl font-bold font-heading tracking-px-n leading-tight">$0</h3>
              <p class="mb-12 text-gray-600 font-medium leading-relaxed">Start practicing with no commitment by creating a free account. </p>
              <button (click)="onFreePlanClick()" class="mb-9 py-4 px-9 w-full font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-white hover:bg-gray-50 transition ease-in-out duration-200" type="button">See all Exams</button>
              <h3 class="mb-5 font-semibold leading-normal">Features:</h3>
              <ul>
                <li class="mb-1.5 text-gray-600 flex items-center">
                  <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  <p class="font-semibold">Up to 3 practice questions per day</p>
                </li>
                <li class="mb-1.5 text-gray-600 flex items-center">
                  <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  <p class="font-semibold">Access to free questions tier</p>
                </li>
                <li class="mb-1.5 text-gray-600 flex items-center">
                  <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  <p class="font-semibold">Basic question selection</p>
                </li>
              </ul>
            </div>
          </div>
          <!-- Pro Plan -->
          <div class="w-full xl:w-1/2 p-3">
            <div class="relative px-8 py-7 h-full bg-white bg-opacity-90 border border-blueGray-600 rounded-xl shadow-9xl" style="backdrop-filter: blur(46px);">
              <img class="absolute -top-11 left-[100px]" src="assets/images/popular.png" alt="Popular">
              <!--                <img class="absolute bottom-0 left-1/2 transform -translate-x-1/2" src="assets/images/gradient3.svg" alt="">-->
              <div class="relative z-10">
                <span class="mb-2 inline-block font-medium leading-relaxed">Pro</span>
                <!--                <div class="my-4">-->
                <!--                  <h2 class="font-semibold text-2xl">{{examData?.displayName}} Exam Subscription</h2>-->
                <!--                </div>-->
                <h3 class="mb-3 text-3xl md:text-4xl font-bold font-heading tracking-px-n leading-tight">
                  {{ getPriceDisplay()}}<span class="text-lg font-medium"> / month</span>
                </h3>
                <p class="mb-12 text-gray-600 font-medium leading-relaxed">Unlock all features and maximize your score. Cancel anytime.</p>
                <button (click)="onPremiumPlanClick()" class="mb-9 py-4 px-9 w-full text-white font-semibold border border-custom-blue-600 rounded-xl focus:ring focus:ring-custom-blue-400 bg-custom-blue-500 hover:bg-custom-blue-600 transition ease-in-out duration-200" type="button">See all Exams</button>
                <h3 class="mb-5 font-semibold leading-normal">Features:</h3>
                <ul>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Unlimited practice questions</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Access to premium questions tier</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Dashboard with detailed analytics</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Personalized score improvement tips</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Identify strengths & weaknesses</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">Adaptive question selection algorithm</p>
                  </li>
                  <li class="mb-1.5 text-gray-600 flex items-center">
                    <svg class="mr-2" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325" stroke="#476bdb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <p class="font-semibold">AI-powered question explanations</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



  <section class="relative pt-18 pb-20 bg-gray-100 overflow-hidden">

    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -m-8">
        <div class="w-full p-3">
          <div class="relative z-10 container px-4 mx-auto">
            <div class="flex flex-wrap justify-between items-end -m-2 mb-4">
              <!-- Our Story Heading -->
              <div class="w-full md:w-4/5 mx-auto p-2">
                <h2 class="text-4xl md:text-5xl font-extrabold font-sans tracking-tight leading-tight text-gray-900 text-left">
                  Our Story
                </h2>
              </div>
            </div>

            <!-- Styled Box Around Text -->
            <!-- Width will be 100% by default and 80% on medium and larger screens -->
            <div class="bg-white bg-opacity-100 rounded-lg shadow-lg p-8 border border-gray-200 w-full md:w-4/5 mx-auto">
              <p class="text-lg font-medium text-gray-700 leading-relaxed mb-4">
                We are a team of students, test-takers, and teachers who have navigated the challenges of standardized testing throughout our academic careers—from high school to college to graduate school and beyond. We’ve experienced the frustrations of test prep for two main reasons: first, the endless repetition of practice questions, and second, the high costs. Free resources often lacked depth, while expensive prep materials felt unavoidable.
              </p>
              <p class="text-lg font-medium text-gray-700 leading-relaxed mb-4">
                Tired of spending thousands of dollars each semester on test prep materials and tutoring, we knew there had to be a better way—one that was both high-quality and affordable. That's why we created Studious.
              </p>
              <p class="text-lg font-medium text-gray-700 leading-relaxed">
                <strong>Studious</strong> is an AI-powered platform designed to support you through the longest and most challenging part of test prep: practice. Our adaptive learning system focuses on your weaknesses and helps you achieve results faster. While Studious isn’t a full end-to-end test preparation program, it is designed to take you 90% of the way—at a price that’s truly affordable for students.
              </p>
            </div>
          </div>
        </div>

        <!-- Centered Button -->
        <div class="w-full flex justify-center p-3">
          <button routerLink="/exams" class="py-4 px-9 w-auto font-semibold border border-gray-300 hover:border-gray-400 rounded-xl focus:ring focus:ring-gray-50 bg-transparent hover:bg-gray-100 transition ease-in-out duration-200 flex justify-between items-center" type="button">
            <div class="flex flex-wrap justify-center items-center -m-1">
              <div class="w-auto p-1">
                <!-- Use Remix Icon -->
                <!-- <i class="ri-book-open-line text-xl"></i>-->
              </div>
              <div class="w-auto p-1">
                <span>View Our Exams</span>
              </div>
            </div>
            <!-- Right arrow with hover animation -->
            <i class="ri-arrow-right-s-line text-xl ml-2 transform transition-transform duration-200 hover:translate-x-2"></i>
          </button>
        </div>
      </div>
    </div>

  </section>








<!--<div class="w-full md:w-1/2 p-2">-->
<!--  <div class="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">-->
<!--    <div class="flex flex-col justify-between h-full">-->
<!--      <div class="mb-7 block">-->
<!--        <div class="flex flex-wrap -m-0.5 mb-6">-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </div>-->
<!--        <h3 class="mb-6 text-lg font-bold font-heading">“Beautiful layouts”</h3>-->
<!--        <p class="text-lg font-medium">Very very easy for customer information to get secured if all orders are on one device to keep it.</p>-->
<!--      </div>-->
<!--      <div class="block">-->
<!--        <p class="font-bold">Jenny Wilson - Product Manager</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="w-full md:w-1/2 p-2">-->
<!--  <div class="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">-->
<!--    <div class="flex flex-col justify-between h-full">-->
<!--      <div class="mb-7 block">-->
<!--        <div class="flex flex-wrap -m-0.5 mb-6">-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </div>-->
<!--        <h3 class="mb-6 text-lg font-bold font-heading">“Excellent Support”</h3>-->
<!--        <p class="text-lg font-medium">Ease of use and efficiency of design tools. The ability for the integrated marketing team to see all of a project.</p>-->
<!--      </div>-->
<!--      <div class="block">-->
<!--        <p class="font-bold">Jenny Wilson - Product Manager</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="w-full md:w-1/2 p-2">-->
<!--  <div class="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl">-->
<!--    <div class="flex flex-col justify-between h-full">-->
<!--      <div class="mb-7 block">-->
<!--        <div class="flex flex-wrap -m-0.5 mb-6">-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="w-auto p-0.5">-->
<!--            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </div>-->
<!--        <h3 class="mb-6 text-lg font-bold font-heading">“Easy to setup”</h3>-->
<!--        <p class="text-lg font-medium">Very very easy for customer information to get secured if all orders are on one device to keep it.</p>-->
<!--      </div>-->
<!--      <div class="block">-->
<!--        <p class="font-bold">Jenny Wilson - Product Manager</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
