import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

// Import your components and layouts
import { LandingLayoutComponent } from './shared/layouts/landing-layout/landing-layout.component';
import { DashboardLayoutComponent } from './shared/layouts/dashboard-layout/dashboard-layout.component';
import { HomeComponent } from './pages/home/home.component';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AuthGuard } from './guards/auth.guard';
import {QuestionComponent} from "./pages/question/question.component";
import {ErrorComponent} from "./pages/error/error.component";
import {RegisterComponent} from "./pages/register/register.component";
import {ExamsComponent} from "./pages/exams/exams.component";
import {ExamComponent} from "./pages/exam/exam.component";
import {EmailVerificationComponent} from "./pages/email-verification/email-verification.component";
import {PricingComponent} from "./pages/pricing/pricing.component";
import {EmailVerifiedGuard} from "./guards/email-verified.guard";
import { environment } from '../environments/environment';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled', // Restores scroll to top on navigation
  anchorScrolling: 'enabled',           // Enables scrolling to anchors within the page
};

const routes: Routes = [
  {
    path: '',
    component: LandingLayoutComponent, // Landing layout
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          title: 'Studious - Ace Your Exams with the Best Online Test Prep',
          description: 'Join Studious today to access top-quality practice tests and personalized learning plans. Start acing your exams now!',
          keywords: 'online test prep, exam preparation, practice tests, personalized learning, Studious',
          author: 'Studious Team',
          robots: 'index, follow',
          canonical: `${environment.domain}`,
          backgroundImageUrl: 'assets/images/home/gradient4-top.svg',
        },
      },
      {
        path: 'exam/:exam-link-name',
        component: ExamComponent,
        data: {
          title: 'Prepare for your Exam - Studious Online Test Prep',
          description: 'Get the best resources, practice tests, and study guides for your exam. Start your success journey with Studious!',
          keywords: 'test prep, practice tests, online exam preparation, Studious',
          author: 'Studious Team',
          robots: 'index, follow',
          canonical: `${environment.domain}/exam/:exam-link-name`, // canonical should be set dynamically in the component
          // image: '/assets/images/exams/:exam-link-name-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'exams',
        component: ExamsComponent,
        data: {
          title: 'All Exams - Comprehensive Test Prep by Studious',
          description: 'Explore all the exams we cover and choose the best fit for your academic goals. Studious offers unmatched preparation for various tests.',
          keywords: 'exams list, test preparation, online learning, Studious exams',
          author: 'Studious Team',
          robots: 'index, follow',
          canonical: `${environment.domain}/exams`,
          // image: '/assets/images/exams/studious-exams-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'email-verification',
        component: EmailVerificationComponent,
        data: {
          title: 'Email Verification - Activate Your Studious Account',
          description: 'Verify your email address to activate your Studious account and unlock personalized study plans and progress tracking.',
          keywords: 'email verification, account activation, Studious',
          author: 'Studious Team',
          robots: 'noindex, nofollow',
          canonical: `${environment.domain}/email-verification`,
          // image: '/assets/images/email-verification-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'pricing',
        component: PricingComponent,
        data: {
          title: 'Studious Pricing Plans - Affordable Test Prep for Everyone',
          description: 'Choose from affordable pricing plans tailored to your learning needs. Get premium access to practice tests and study materials.',
          keywords: 'pricing plans, subscription, affordable test prep, Studious pricing',
          author: 'Studious Team',
          robots: 'index, follow',
          canonical: `${environment.domain}/pricing`,
          // image: '/assets/images/pricing/studious-pricing-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'error',
        component: ErrorComponent,
        data: {
          title: 'Error - Studious Online Test Prep',
          description: 'Oops! An unexpected error occurred. Please try again or contact our support team for assistance.',
          keywords: 'error page, Studious error, support',
          author: 'Studious Team',
          robots: 'noindex, nofollow',
          canonical: `${environment.domain}/error`,
          // image: '/assets/images/error/studious-error-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
    ],
  },
  {
    path: '',
    component: DashboardLayoutComponent, // Dashboard layout
    canActivate: [], // [AuthGuard],
    children: [
      {
        path: 'dashboard/:exam-link-name',
        component: DashboardComponent,
        canActivate: [EmailVerifiedGuard],
        data: {
          title: 'Dashboard - Track Your Progress with Studious',
          description: 'Access your personalized dashboard. Monitor your progress and optimize your study plan.',
          keywords: 'dashboard, progress tracking, exam prep, personalized study plan',
          author: 'Studious Team',
          robots: 'noindex, nofollow',
          canonical: `${environment.domain}/dashboard/:exam-link-name`, // canonical should be set dynamically in the component
          // image: '/assets/images/dashboard/studious-dashboard-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [EmailVerifiedGuard],
        data: {
          title: 'Your Profile - Manage Your Studious Account',
          description: 'Update your profile settings, manage subscriptions, and customize your learning preferences.',
          keywords: 'profile, account settings, learning preferences, Studious profile',
          author: 'Studious Team',
          robots: 'noindex, nofollow',
          canonical: `${environment.domain}/profile`,
          // image: '/assets/images/profile/studious-profile-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'question/:question-id',
        component: QuestionComponent,
        data: {
          title: 'Practice Question - Enhance Your Skills with Studious',
         description: 'Challenge yourself with our extensive library of practice questions and improve your exam readiness.',
          keywords: 'practice question, test prep, exam readiness, Studious questions',
          author: 'Studious Team',
          robots: 'index, follow',
          canonical: `${environment.domain}/question/:question-id`, // canonical should be set dynamically in the component
          // image: '/assets/images/questions/studious-question-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
      {
        path: 'dashboard-error',
        component: ErrorComponent,
        data: {
          title: 'Dashboard Error - Studious Online Test Prep',
          description: 'An error occurred in the dashboard. Please refresh the page or contact support for help.',
          keywords: 'dashboard error, Studious support, troubleshooting',
          author: 'Studious Team',
          robots: 'noindex, nofollow',
          canonical: `${environment.domain}/dashboard-error`,
          // image: '/assets/images/error/studious-error-og-image.jpg',
          // twitterCard: 'summary_large_image',
        },
      },
    ],
  },
  // Standalone pages without a layout
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login to Studious - Your Online Test Prep Hub',
      description: 'Sign in to your Studious account to continue your personalized learning journey and access premium features.',
      keywords: 'login, sign in, Studious account, online learning',
      author: 'Studious Team',
      robots: 'index, follow',
      canonical: `${environment.domain}/login`,
      // image: '/assets/images/login/studious-login-og-image.jpg',
      // twitterCard: 'summary_large_image',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Sign Up for Studious - Start Acing Your Exams',
      description: 'Create your free Studious account and gain access to the best online test preparation resources. Get started today!',
      keywords: 'register, sign up, create account, online test prep, Studious',
      author: 'Studious Team',
      robots: 'index, follow',
      canonical: `${environment.domain}/register`,
      // image: '/assets/images/register/studious-register-og-image.jpg',
      // twitterCard: 'summary_large_image',
    },
  },
  { path: '**', redirectTo: '' }, // Wildcard route redirects to home
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
