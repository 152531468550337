import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {QuestionState} from "../../question-state.enum";
import {LongFormEntryResponse} from "../../../../models/long-form-entry-response";
import {UserQuestionInteractionResponse} from "../../../../models/user-question-interaction-response";
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-essay',
  standalone: true,
  imports: [
    NgIf,
    FormsModule
  ],
  templateUrl: './essay.component.html',
  styleUrl: './essay.component.scss',
})
export class EssayComponent implements OnChanges {
  @Input() questionId: string | null = null;
  @Input() questionState: QuestionState | null = null;
  @Input() userQuestionInteractionResponse: UserQuestionInteractionResponse | null = null;
  @Output() onValidate = new EventEmitter<boolean>();

  @ViewChild('essayTextarea') essayTextarea!: ElementRef;

  essayText: string = '';
  showError: boolean = false;
  errorMessage: string = '';
  limitMessage: string = '';

  // Limits (Adjust these values as needed)
  maxChars: number = 2000;
  maxWords: number = 2000;
  maxLines: number = 100;

  // Current counts
  currentChars: number = 0;
  currentWords: number = 0;
  currentLines: number = 0;

  validate(): void {
    if (this.essayText.trim()) {
      this.onValidate.emit(true);
      this.showError = false;
    } else {
      this.showError = true;
      this.errorMessage = 'Please write your essay response.';
      this.onValidate.emit(false);
    }
  }

  resetVariables() {
    this.essayText = '';
    this.showError = false;
    this.errorMessage = '';
    this.limitMessage = '';
    this.currentChars = 0;
    this.currentWords = 0;
    this.currentLines = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['questionId']) {
      this.resetVariables();
    }

    if (changes['userQuestionInteractionResponse']) {
      if (this.isReviewState() && this.userQuestionInteractionResponse?.longFormEntryResponse?.essay) {
        this.essayText = this.userQuestionInteractionResponse.longFormEntryResponse.essay;
        this.updateCounts();
        // Adjust textarea height in review mode
        setTimeout(() => {
          this.adjustTextareaHeight();
        }, 0);
      }
    }
  }

  isInputDisabled(): boolean {
    const disabledStates = [
      QuestionState.SUBMIT,
      QuestionState.TRY_AGAIN,
      QuestionState.SKIP,
      QuestionState.NEXT,
      QuestionState.REVIEW_INCORRECT,
      QuestionState.REVIEW,
    ];
    return disabledStates.includes(this.questionState as QuestionState);
  }

  isReviewState(): boolean {
    return (
      this.questionState === QuestionState.REVIEW ||
      this.questionState === QuestionState.REVIEW_INCORRECT
    );
  }

  onKeyDown(event: KeyboardEvent): void {
    if (this.isInputDisabled()) {
      event.preventDefault();
      return;
    }

    const text = this.essayText;
    const lineCount = text.split('\n').length;

    // Allow navigation and editing keys
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Home',
      'End',
      'Shift',
      'Control',
      'Alt',
      'Meta',
      'Escape',
      'CapsLock',
      'PageUp',
      'PageDown',
      'Insert',
    ];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    // Prevent adding new lines if line limit is reached
    if (event.key === 'Enter' && lineCount >= this.maxLines) {
      event.preventDefault();
      this.limitMessage = `Line limit of ${this.maxLines} reached.`;
      return;
    }
  }

  onTextChange(): void {
    if (this.isInputDisabled()) {
      return;
    }

    let text = this.essayText;
    let hasChanged = false;

    // Enforce character limit
    if (text.length > this.maxChars) {
      text = text.substring(0, this.maxChars);
      this.limitMessage = `Character limit of ${this.maxChars} reached.`;
      hasChanged = true;
    }

    // Enforce line limit
    let lines = text.split('\n');
    if (lines.length > this.maxLines) {
      lines = lines.slice(0, this.maxLines);
      text = lines.join('\n');
      this.limitMessage = `Line limit of ${this.maxLines} reached.`;
      hasChanged = true;
    }

    // Enforce word limit
    let words = text.trim().split(/\s+/).filter(word => word);
    if (words.length > this.maxWords) {
      words = words.slice(0, this.maxWords);
      text = words.join(' ');
      this.limitMessage = `Word limit of ${this.maxWords} reached.`;
      hasChanged = true;
    }

    // Update essayText and counts
    if (hasChanged) {
      const textarea = this.essayTextarea.nativeElement as HTMLTextAreaElement;
      const cursorPosition = textarea.selectionStart;

      this.essayText = text;
      this.updateCounts();

      // Restore cursor position
      setTimeout(() => {
        textarea.selectionStart = cursorPosition > text.length ? text.length : cursorPosition;
        textarea.selectionEnd = textarea.selectionStart;
      }, 0);
    } else {
      this.updateCounts();
    }

    // Clear limit message if under limits
    if (
      this.currentChars <= this.maxChars &&
      this.currentWords <= this.maxWords &&
      this.currentLines <= this.maxLines
    ) {
      this.limitMessage = '';
    }
  }

  updateCounts(): void {
    this.currentChars = this.essayText.length;
    this.currentWords = this.essayText.trim().split(/\s+/).filter(word => word).length;
    this.currentLines = this.essayText.split('\n').length;
  }

  adjustTextareaHeight(): void {
    if (this.isReviewState() && this.essayTextarea) {
      const textarea = this.essayTextarea.nativeElement as HTMLTextAreaElement;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  onPaste(event: ClipboardEvent): void {
    if (this.isInputDisabled()) {
      event.preventDefault();
      return;
    }

    // Get the pasted content
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    // Get the current text before paste
    const textarea = this.essayTextarea.nativeElement as HTMLTextAreaElement;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const textBefore = this.essayText.substring(0, startPos);
    const textAfter = this.essayText.substring(endPos);

    // Concatenate the new text
    let newText = textBefore + pastedText + textAfter;

    // Enforce character limit
    if (newText.length > this.maxChars) {
      newText = newText.substring(0, this.maxChars);
      this.limitMessage = `Character limit of ${this.maxChars} reached.`;
    }

    // Enforce line limit
    let lines = newText.split('\n');
    if (lines.length > this.maxLines) {
      lines = lines.slice(0, this.maxLines);
      newText = lines.join('\n');
      this.limitMessage = `Line limit of ${this.maxLines} reached.`;
    }

    // Enforce word limit
    let words = newText.trim().split(/\s+/).filter(word => word);
    if (words.length > this.maxWords) {
      words = words.slice(0, this.maxWords);
      newText = words.join(' ');
      this.limitMessage = `Word limit of ${this.maxWords} reached.`;
    }

    // Update the textarea value
    this.essayText = newText;

    // Update counts
    this.updateCounts();

    // Set the cursor position after the pasted content
    const cursorPosition = textBefore.length + pastedText.length;
    setTimeout(() => {
      textarea.selectionStart = cursorPosition > newText.length ? newText.length : cursorPosition;
      textarea.selectionEnd = textarea.selectionStart;
    }, 0);

    // Prevent the default paste behavior
    event.preventDefault();
  }
}
