export const environment = {
  firebase: {
    apiKey: "AIzaSyBTACqtSs36QZaI71v_cEgdz9t9Z1y1QDE",
    authDomain: "sincere-surfer-437620-g6.firebaseapp.com",
    projectId: "sincere-surfer-437620-g6",
    storageBucket: "sincere-surfer-437620-g6.appspot.com",
    messagingSenderId: "897917705875",
    appId: "1:897917705875:web:f427cae3946918be70b766",
    measurementId: "G-LJY0SDFQ12"
  },
  production: true,
  apiBaseUrl: "/api", // relative url for production, which will be rewritten by firebase
  domain: 'https://www.usestudious.com' // set domain for ssr canonical url
};
