import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import {ExamService} from "./services/exam.service";

@Component({
  selector: 'app-root',  // This is the root selector, used in index.html
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private isBrowser: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: Meta,
    private titleService: Title,
    private examService: ExamService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // Check if we're running in the browser
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    // this.router.events
    //   .pipe(
    //     filter(event => event instanceof NavigationEnd),
    //     map(() => this.getChild(this.activatedRoute)),
    //     mergeMap((route: ActivatedRoute) => route.data),
    //     map(data => this.replaceDynamicParams(data))
    //   )
    //   .subscribe(data => {
    //     // Set the page title
    //     const title = data['title'] || 'Studious - Your Online Test Prep Hub';
    //     this.titleService.setTitle(title);
    //
    //     // Set meta description
    //     const description =
    //       data['description'] ||
    //       'Studious offers top-notch online test preparation resources to help you ace your exams.';
    //     this.metaService.updateTag({ name: 'description', content: description });
    //
    //     // Set meta keywords
    //     const keywords = data['keywords'] || 'online test prep, exam preparation, practice tests, Studious';
    //     this.metaService.updateTag({ name: 'keywords', content: keywords });
    //
    //     // Set meta author
    //     const author = data['author'] || 'Studious Team';
    //     this.metaService.updateTag({ name: 'author', content: author });
    //
    //     // Set robots
    //     const robots = data['robots'] || 'index, follow';
    //     this.metaService.updateTag({ name: 'robots', content: robots });
    //
    //     // Only set canonical URL and Open Graph tags in the browser
    //     if (this.isBrowser) {
    //       const canonicalUrl = data['canonical'] || window.location.href;
    //       this.updateCanonicalUrl(canonicalUrl);
    //
    //       // Set Open Graph meta tags
    //       this.metaService.updateTag({ property: 'og:title', content: title });
    //       this.metaService.updateTag({ property: 'og:description', content: description });
    //       const imageUrl = data['image'] || '/assets/logos/studious_open_graph_bg.png';
    //       this.metaService.updateTag({ property: 'og:image', content: imageUrl });
    //       this.metaService.updateTag({ property: 'og:url', content: canonicalUrl });
    //       this.metaService.updateTag({ property: 'og:type', content: 'website' });
    //       this.metaService.updateTag({ property: 'og:locale', content: 'en_US' });
    //
    //       // Set Twitter Card meta tags
    //       const twitterCard = data['twitterCard'] || '/assets/logos/studious_open_graph_bg.png';
    //       this.metaService.updateTag({ name: 'twitter:card', content: twitterCard });
    //       this.metaService.updateTag({ name: 'twitter:title', content: title });
    //       this.metaService.updateTag({ name: 'twitter:description', content: description });
    //       this.metaService.updateTag({ name: 'twitter:image', content: imageUrl });
    //     }
    //
    //     // Set viewport and charset
    //     this.metaService.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
    //     this.metaService.updateTag({ charset: 'UTF-8' });
    //
    //     // Optional: Set theme color
    //     this.metaService.updateTag({ name: 'theme-color', content: '#4285f4' });
    //   });
    //
    //   // Get the exam-link-name from the route params
    //   this.activatedRoute.paramMap.subscribe(params => {
    //     const examLinkName = params.get('exam-link-name');
    //     if (examLinkName) {
    //       // Fetch the display name from the service
    //       const displayName = this.examService.getExamDisplayFromExamLinkName(examLinkName);
    //
    //       // Set the dynamic title
    //       const dynamicTitle = `Prepare for ${displayName} Exam - Studious Online Test Prep`;
    //       this.titleService.setTitle(dynamicTitle);
    //
    //       // Set the dynamic description
    //       const dynamicDescription = `Get the best resources, practice tests, and study guides for the ${displayName} exam. Start your success journey with Studious!`;
    //       this.metaService.updateTag({ name: 'description', content: dynamicDescription });
    //
    //       // Set the dynamic keywords
    //       const dynamicKeywords = `${displayName} prep, ${displayName} practice tests, online exam preparation, Studious`;
    //       this.metaService.updateTag({ name: 'keywords', content: dynamicKeywords });
    //
    //       // Optionally update other meta tags, such as Open Graph meta tags for social sharing
    //       this.metaService.updateTag({ property: 'og:title', content: dynamicTitle });
    //       this.metaService.updateTag({ property: 'og:description', content: dynamicDescription });
    //     }
    //   });
  }

  // Helper function to get the deepest child route
  private getChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  // Helper function to replace dynamic route parameters in meta data
  private replaceDynamicParams(data: any): any {
    const params = this.activatedRoute.snapshot.firstChild?.params || {};
    Object.keys(params).forEach(key => {
      Object.keys(data).forEach(dataKey => {
        if (typeof data[dataKey] === 'string') {
          data[dataKey] = data[dataKey].replace(`:${key}`, params[key]);
        }
      });
    });
    return data;
  }

  // Helper function to update the canonical URL
  private updateCanonicalUrl(url: string) {
    if (this.isBrowser) {
      let link: HTMLLinkElement | null = document.querySelector("link[rel='canonical']");
      if (!link) {
        link = document.createElement('link') as HTMLLinkElement;
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
      }
      link.setAttribute('href', url);
    }
  }
}
