export enum QuestionFormatType {
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  DATA_SUFFICIENCY_MULTIPLE_CHOICE = "DATA_SUFFICIENCY_MULTIPLE_CHOICE",
  QUANT_COMPARISON_MULTIPLE_CHOICE = "QUANT_COMPARISON_MULTIPLE_CHOICE",
  PASSAGE_BASED_MULTIPLE_CHOICE = "PASSAGE_BASED_MULTIPLE_CHOICE",
  VIGNETTE_MULTIPLE_CHOICE = "VIGNETTE_MULTIPLE_CHOICE",
  MULTIPLE_ANSWER = "MULTIPLE_ANSWER",

  ESSAY = "ESSAY",
  FREE_RESPONSE = "FREE_RESPONSE",
  SHORT_ANSWER = "SHORT_ANSWER",

  GRID_IN = "GRID_IN",
  FILL_IN_NUMERICAL = "FILL_IN_NUMERICAL",

  SPEAKING_TASK = "SPEAKING_TASK",
  WRITING_TASK = "WRITING_TASK",
}
