// plan.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import {QuestionService} from "./question-service";
import {CheckoutService} from "./checkout.service";
import {NextQuestionRequest} from "../models/next-question-request";
import {ApiState} from "./api-state.interface";
import {NextQuestionResponse} from "../models/next-question-response";
import {RequestState} from "./request-state.enum";
import {CheckoutRequest} from "../models/checkout-request";
import {CheckoutResponse} from "../models/checkout-response";
import {ExamService} from "./exam.service";

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private questionService: QuestionService,
    private checkoutService: CheckoutService,
    private examService: ExamService,
    private router: Router
  ) {}

  onFreePlanClick(
    examId: string,
    isLoggedIn: boolean
  ): void {
    if (!examId) {
      this.router.navigate(['/error']);
      return;
    }

    const nextQuestionRequest: NextQuestionRequest = {
      examId: examId,
      smart: true,
    };

    this.questionService.getNextQuestionPublic(nextQuestionRequest).subscribe({
      next: (nextQuestionResponseState: ApiState<NextQuestionResponse>) => {
        if (
          nextQuestionResponseState.state === RequestState.SUCCESS &&
          nextQuestionResponseState.data?.questionId
        ) {
          this.router.navigate(['/question', nextQuestionResponseState.data.questionId]);
        } else if (nextQuestionResponseState.state === RequestState.ERROR) {
          this.router.navigate(['/login']);
        }
      },
      error: (err) => {
        this.router.navigate(['/login']);
      },
    });
  }

  getCurrentPath(): string {
    const currentPath = this.router.url; // This will give you the path part of the URL
    return currentPath.startsWith('/') ? currentPath.substring(1) : currentPath;
  }

  onPremiumPlanClick(
    examId: string | null,
    isLoggedIn: boolean,
    isPremium: boolean,
    priceId?: string
  ): void {
    if (!examId) {
      this.router.navigate(['/error']);
      return;
    }

    console.log('onPremiumPlanClick examId=' + examId + ' isPremium=' + isPremium + " isLoggedIn='" + isLoggedIn + " priceId='" + priceId + "'");

    if (!isLoggedIn) {
      const returnUrl = this.router.url;
      this.router.navigate(['/login'], { queryParams: { returnUrl } });
      return;
    }

    if (isPremium && isLoggedIn) {
      this.questionService.nextQuestionAndNavigateToPractice(examId);
    } else if (!isPremium && isLoggedIn && priceId) {

      const linkName = this.examService.getLinkNameFromExamId(examId);

      const checkoutRequest: CheckoutRequest = {
        priceId: priceId,
        originalUrlPath: this.getCurrentPath(),
        successUrlPath: 'dashboard/' + linkName,
        cancelUrlPath: this.getCurrentPath()
      };

      this.checkoutService.getCheckoutSession(checkoutRequest).subscribe({
        next: (checkoutResponseState) => {
          console.log('checkoutResponseState=', checkoutResponseState);
          if (
            checkoutResponseState.state === RequestState.SUCCESS &&
            checkoutResponseState.data?.sessionUrl
          ) {
            window.location.href = checkoutResponseState.data.sessionUrl;
          }
        },
        error: (err) => {
          // Handle error if necessary
          this.router.navigate(['/error']);
        },
      });
    }
  }
}
