<section class="py-8" *ngIf="!isLoading">
  <div class="container px-4 mx-auto">
    <div class="p-6 bg-white shadow rounded">
      <!-- User Information -->
      <h2 class="text-2xl font-bold mb-6">Profile Information</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
        <!-- Name -->
        <div>
          <label class="block text-sm font-bold text-gray-700">Name</label>
          <p class="mt-1 text-gray-900">
            {{ userResponse.firstName }} {{ userResponse.lastName }}
          </p>
        </div>
        <!-- Email Address -->
        <div>
          <label class="block text-sm font-bold text-gray-700">
            Email Address
          </label>
          <p class="mt-1 text-gray-900">{{ userResponse.email }}</p>
        </div>
        <!-- Username -->
        <div>
          <label class="block text-sm font-bold text-gray-700">Username</label>
          <p class="mt-1 text-gray-900">
            {{ userResponse.username ? userResponse.username : 'None' }}
          </p>
        </div>
        <!-- Date Created -->
        <div>
          <label class="block text-sm font-bold text-gray-700">
            Date Created
          </label>
          <p class="mt-1 text-gray-900">
            {{ userResponse.createdTimestamp | date: 'mediumDate' }}
          </p>
        </div>
      </div>
      <!-- Update Username Button -->
      <div class="mb-10">
        <button
          (click)="openUsernameModal()"
          class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          Update Username
        </button>
      </div>

      <!-- Subscriptions -->
      <div class="flex items-center mb-4">
        <h4 class="text-xl font-bold">Your Subscriptions</h4>
        <span
          class="ml-3 inline-block py-1 px-2 text-xs bg-blue-50 text-blue-500 rounded"
        >
          {{ getSubscriptionCountText() }}
        </span>
      </div>
      <div *ngIf="subscriptions.length > 0; else noSubscriptions">
        <div
          *ngFor="let sub of subscriptions"
          class="p-4 mb-6 border rounded-lg"
        >
          <!-- Display Name and Badge -->
          <div class="flex items-center mb-4">
            <h5 class="text-lg font-semibold mr-2">
              {{ sub.items[0].productResponse.productDisplayName }}
            </h5>
            <span class="ml-2 inline-block py-1 px-2 text-xs bg-blue-50 text-blue-500 rounded">
              Subscription
            </span>
          </div>
          <!-- Subscription Layout -->
          <div class="flex flex-wrap md:flex-nowrap items-start justify-between">
            <!-- Status -->
            <div class="w-full md:w-auto flex-1 mb-4 md:mb-0 md:mr-4">
              <div class="p-2 rounded">
                <label class="text-sm font-bold">Status:</label>
                <span [ngClass]="{
                  'text-green-600': getSubscriptionStatus(sub) === 'Active',
                  'text-red-600': getSubscriptionStatus(sub) === 'Cancelled'
                }">
                  {{ getSubscriptionStatus(sub) }}
                </span>
              </div>
            </div>
            <!-- Current Period -->
            <div class="w-full md:w-auto flex-1 mb-4 md:mb-0 md:mr-4">
              <div class="p-2 rounded">
                <label class="text-sm font-bold">Current Period:</label>
                <span class="text-sm">
        {{ sub.startDate | date: 'mediumDate' }} - {{ sub.currentPeriodEnd | date: 'mediumDate' }}
      </span>
              </div>
            </div>
            <!-- Renewal or Access Until Date -->
            <div class="w-full md:w-auto flex-1 mb-4 md:mb-0 md:mr-4">
              <div class="p-2 rounded">
                <ng-container *ngIf="!sub.cancelAtPeriodEnd">
                  <label class="text-sm font-bold">Renewal Date:</label>
                  <div class="text-sm">{{ sub.currentPeriodEnd | date: 'mediumDate' }}</div>
                </ng-container>
                <ng-container *ngIf="sub.cancelAtPeriodEnd">
                  <label class="text-sm font-bold">You have access until:</label>
                  <div class="text-sm">{{ sub.currentPeriodEnd | date: 'mediumDate' }}</div>
                </ng-container>
              </div>
            </div>
            <!-- Price -->
            <div class="w-full md:w-auto flex-1 mb-4 md:mb-0 md:mr-4">
              <div class="p-2 rounded">
                <label class="text-sm font-bold">Price:</label>
                <div class="text-sm">
                  {{
                    formatPrice(
                      sub.items[0].productResponse.priceResponse?.unitAmount,
                      sub.items[0].productResponse.priceResponse?.currency,
                      sub.items[0].productResponse.priceResponse?.recurringInterval
                    )
                  }}
                </div>
              </div>
            </div>
            <!-- Action Button -->
            <div class="w-full md:w-auto flex-1 mb-4 md:mb-0 md:mr-4">
              <ng-container *ngIf="!sub.cancelAtPeriodEnd">
                <button
                  (click)="openCancelModal(sub.subscriptionId)"
                  class="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  Cancel Subscription
                </button>
              </ng-container>
              <ng-container *ngIf="sub.cancelAtPeriodEnd">
                <button
                  (click)="openContinueModal(sub.subscriptionId)"
                  class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Continue Subscription
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- No Subscriptions -->
      <ng-template #noSubscriptions>
        <p>You have no active subscriptions.</p>
      </ng-template>
    </div>
  </div>
</section>

<!-- Modals (Update Username, Cancel Subscription, Continue Subscription) -->
<!-- Update the modals to handle the LOADING state and prevent premature error display -->
<!-- The rest of your profile.component.html remains the same up to the modals -->

<!-- Cancel Subscription Modal -->
<div
  class="fixed z-10 inset-0 overflow-y-auto"
  *ngIf="showCancelModal"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="flex items-center justify-center min-h-screen px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 font-bold text-gray-900">
          Cancel Subscription
        </h3>
        <div class="mt-4">
          <!-- Loading Spinner -->
          <div *ngIf="modalLoading" class="mt-4 flex justify-center">
            <div class="loader"></div>
          </div>
          <!-- Confirmation Message -->
          <p
            class="text-sm text-gray-700"
            *ngIf="!modalLoading && !modalMessage && !modalError"
          >
            Are you sure you want to cancel this subscription? It will remain
            active until the end of the current period.
          </p>
          <!-- Success or Error Message -->
          <div *ngIf="modalMessage" class="mt-4 text-green-600">
            {{ modalMessage }}
          </div>
          <div *ngIf="modalError" class="mt-4 text-red-600">
            {{ modalError }}
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div
        class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse"
        *ngIf="!modalLoading"
      >
        <button
          (click)="modalMessage || modalError ? closeCancelModal() : confirmCancelSubscription()"
          [disabled]="modalLoading"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {{ confirmButtonText }}
        </button>
        <button
          *ngIf="!modalMessage && !modalError"
          (click)="closeCancelModal()"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Similar adjustments for the Continue Subscription Modal and Update Username Modal -->

<!-- Continue Subscription Modal -->
<div
  class="fixed z-10 inset-0 overflow-y-auto"
  *ngIf="showContinueModal"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="flex items-center justify-center min-h-screen px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 font-bold text-gray-900">
          Continue Subscription
        </h3>
        <div class="mt-4">
          <!-- Loading Spinner -->
          <div *ngIf="modalLoading" class="mt-4 flex justify-center">
            <div class="loader"></div>
          </div>
          <!-- Confirmation Message -->
          <p
            class="text-sm text-gray-700"
            *ngIf="!modalLoading && !modalMessage && !modalError"
          >
            Are you sure you want to continue this subscription?
          </p>
          <!-- Success or Error Message -->
          <div *ngIf="modalMessage" class="mt-4 text-green-600">
            {{ modalMessage }}
          </div>
          <div *ngIf="modalError" class="mt-4 text-red-600">
            {{ modalError }}
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div
        class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse"
        *ngIf="!modalLoading"
      >
        <button
          (click)="modalMessage || modalError ? closeContinueModal() : confirmContinueSubscription()"
          [disabled]="modalLoading"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {{ confirmButtonText }}
        </button>
        <button
          *ngIf="!modalMessage && !modalError"
          (click)="closeContinueModal()"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Username Modal -->
<div
  class="fixed z-10 inset-0 overflow-y-auto"
  *ngIf="showUsernameModal"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="flex items-center justify-center min-h-screen px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 font-bold text-gray-900">
          Update Username
        </h3>
        <form [formGroup]="usernameForm" (ngSubmit)="changeUsername()">
          <div class="mt-4">
            <label
              for="username"
              class="block text-sm font-medium text-gray-700 font-bold"
            >
              New Username
            </label>
            <input
              type="text"
              id="username"
              formControlName="newUsername"
              class="mt-1 block w-full rounded-md sm:text-sm border-gray-300"
              [disabled]="modalLoading || modalMessage!=''"
            />
            <div
              *ngIf="
                usernameForm.controls['newUsername'].invalid &&
                usernameForm.controls['newUsername'].dirty
              "
              class="text-red-500 text-sm mt-1"
            >
              Username is required and must contain only alphanumeric characters
              and underscores.
            </div>
          </div>
        </form>
        <!-- Loading Spinner -->
        <div *ngIf="modalLoading" class="mt-4 flex justify-center">
          <div class="loader"></div>
        </div>
        <!-- Success or Error Message -->
        <div *ngIf="modalMessage" class="mt-4 text-green-600">
          {{ modalMessage }}
        </div>
        <div *ngIf="modalError" class="mt-4 text-red-600">
          {{ modalError }}
        </div>
      </div>
      <!-- Buttons -->
      <div
        class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse"
        *ngIf="!modalLoading"
      >
        <button
          (click)="modalMessage || modalError ? closeUsernameModal() : changeUsername()"
          [disabled]="(usernameForm.invalid && !modalMessage) || modalLoading"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {{ confirmButtonText }}
        </button>
        <button
          *ngIf="!modalMessage && !modalError"
          (click)="closeUsernameModal()"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
