// PSAT/NMSQT Exam Data
import { ExamData } from "../exam-data-interface";

export const psatExamData: ExamData = {
  examId: 'PSAT_NMSQT_2023',
  displayName: 'PSAT/NMSQT',
  description:
    'The PSAT/NMSQT (Preliminary SAT/National Merit Scholarship Qualifying Test) is a standardized test administered by the College Board. It serves as both practice for the SAT and as the qualifying test for the National Merit Scholarship Program.',
  logo: 'assets/images/exams/psat_logo.png', // Ensure you have the correct logo path
  link: 'psat-nmsqt',
  zoom: 1.1,
  priceId: 'PSAT_NMSQT_2023_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel in the PSAT/NMSQT with Comprehensive Practice and Expert Insights',
  marketingSubtitles: [
    'Extensive question bank tailored to PSAT/NMSQT exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable PSAT/NMSQT Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your academic performance.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'PSAT_NMSQT_2023_READING',
      displayName: 'PSAT Reading Test',
      description:
        'The PSAT Reading Test assesses reading comprehension and reasoning skills. Students read passages and interpret informational graphics to answer questions. The passages are drawn from literature, historical documents, social sciences, and natural sciences.',
      icon: 'remixBookLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'PSAT_NMSQT_2023_READING_INFORMATION_AND_IDEAS',
          displayName: 'Information and Ideas',
          description: 'Questions focusing on understanding, interpreting, and analyzing information and ideas presented in the text.',
          icon: 'remixInformationLine', // Remix Icon for information
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_INFORMATION_AND_IDEAS_CLOSE_READING',
              displayName: 'Close Reading',
              description: 'Understanding explicit information stated in the text.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_INFORMATION_AND_IDEAS_CENTRAL_IDEAS_THEMES',
              displayName: 'Central Ideas and Themes',
              description: 'Identifying main ideas and themes of a text.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_INFORMATION_AND_IDEAS_SUMMARIZING',
              displayName: 'Summarizing',
              description: 'Summarizing key details and ideas from the text.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_INFORMATION_AND_IDEAS_UNDERSTANDING_RELATIONSHIPS',
              displayName: 'Understanding Relationships',
              description: 'Analyzing relationships between individuals, events, and ideas.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_READING_RHETORIC',
          displayName: 'Rhetoric',
          description: 'Questions focusing on analyzing how authors use language to convey meaning and achieve their purposes.',
          icon: 'remixSpeakLine', // Remix Icon for rhetoric
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_RHETORIC_ANALYZING_WORD_CHOICE',
              displayName: 'Analyzing Word Choice',
              description: 'Understanding the impact of specific word choices on meaning and tone.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_RHETORIC_ANALYZING_TEXT_STRUCTURE',
              displayName: 'Analyzing Text Structure',
              description: "Analyzing how a text's structure contributes to its meaning.",
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_RHETORIC_ANALYZING_POV_PURPOSE',
              displayName: 'Analyzing Point of View and Purpose',
              description: "Analyzing the author's point of view and purpose, and how it shapes the content and style.",
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_RHETORIC_ANALYZING_ARGUMENTS',
              displayName: 'Analyzing Arguments',
              description: 'Evaluating the reasoning and evidence used to support an argument.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_READING_SYNTHESIS',
          displayName: 'Synthesis',
          description: 'Questions requiring synthesizing information from paired passages or from passages and informational graphics.',
          icon: 'remixLayoutLine', // Remix Icon for synthesis
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_SYNTHESIS_ANALYZING_MULTIPLE_TEXTS',
              displayName: 'Analyzing Multiple Texts',
              description: 'Comparing and contrasting ideas from paired passages.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_SYNTHESIS_INTERPRETING_GRAPHICS',
              displayName: 'Interpreting Data in Graphics',
              description: 'Integrating information from texts and accompanying graphics.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_READING_VOCABULARY_IN_CONTEXT',
          displayName: 'Vocabulary in Context',
          description: 'Understanding the meaning of words and phrases in context.',
          icon: 'remixBookOpenLine', // Remix Icon for vocabulary
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_READING_VOCABULARY_IN_CONTEXT_DETERMINING_MEANING',
              displayName: 'Determining Meaning from Context',
              description: 'Using context clues to determine the meaning of words and phrases.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE',
      displayName: 'PSAT Writing and Language Test',
      description:
        'The PSAT Writing and Language Test measures a student\'s skills in revising and editing texts to improve the expression of ideas and to correct errors in sentence structure, usage, and punctuation.',
      icon: 'remixPenNibLine', // Remix Icon for writing
      categories: [
        {
          categoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_EXPRESSION_OF_IDEAS',
          displayName: 'Expression of Ideas',
          description: 'Questions focusing on improving the development of the topic, the organization of information and ideas, and the effective use of language.',
          icon: 'remixLightbulbLine', // Remix Icon for ideas
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_EXPRESSION_OF_IDEAS_DEVELOPMENT',
              displayName: 'Development',
              description: 'Improving the way ideas and information are developed and presented in the text.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_EXPRESSION_OF_IDEAS_ORGANIZATION',
              displayName: 'Organization',
              description: 'Improving the structure of a text to enhance logic and clarity.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_EXPRESSION_OF_IDEAS_EFFECTIVE_LANGUAGE_USE',
              displayName: 'Effective Language Use',
              description: 'Ensuring precise word choice and maintaining an appropriate style and tone.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_STANDARD_ENGLISH_CONVENTIONS',
          displayName: 'Standard English Conventions',
          description: 'Questions assessing grammar, usage, and punctuation.',
          icon: 'remixGrammarLine', // Remix Icon for grammar
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_STANDARD_ENGLISH_CONVENTIONS_SENTENCE_STRUCTURE',
              displayName: 'Sentence Structure',
              description: 'Correcting errors in sentence formation and structure.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_STANDARD_ENGLISH_CONVENTIONS_CONVENTIONS_OF_USAGE',
              displayName: 'Conventions of Usage',
              description: 'Ensuring correct use of grammatical forms, such as subject-verb agreement and pronoun clarity.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_WRITING_AND_LANGUAGE_STANDARD_ENGLISH_CONVENTIONS_PUNCTUATION',
              displayName: 'Punctuation',
              description: 'Correcting errors in punctuation to clarify meaning.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR',
      displayName: 'PSAT Math Test - No Calculator Section',
      description:
        'The Math Test - No Calculator assesses students\' fluency with mathematical concepts and their ability to solve problems without the aid of a calculator. It focuses on algebra, problem-solving, data analysis, and some advanced math topics.',
      icon: 'remixCalculatorLine', // Remix Icon for math without calculator
      categories: [
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_HEART_OF_ALGEBRA',
          displayName: 'Heart of Algebra',
          description: 'Focuses on linear equations, inequalities, and systems of equations.',
          icon: 'remixFunctionLine', // Remix Icon for algebra
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_HEART_OF_ALGEBRA_LINEAR_EQUATIONS',
              displayName: 'Linear Equations and Inequalities',
              description: 'Solving and interpreting linear equations and inequalities.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_HEART_OF_ALGEBRA_SYSTEMS_LINEAR_EQUATIONS',
              displayName: 'Systems of Linear Equations',
              description: 'Solving systems of linear equations algebraically and graphically.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS',
          displayName: 'Problem Solving and Data Analysis',
          description: 'Focuses on quantitative literacy, including ratios, percentages, and data interpretation.',
          icon: 'remixChartBarLine', // Remix Icon for data analysis
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_RATIOS_PROPORTIONS',
              displayName: 'Ratios and Proportions',
              description: 'Solving problems involving ratios and proportional relationships.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_PERCENTAGES',
              displayName: 'Percentages',
              description: 'Working with percentages in various contexts.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description: 'Analyzing and interpreting data from tables and graphs.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_PROBABILITY_STATISTICS',
              displayName: 'Probability and Statistics',
              description: 'Understanding basic concepts of probability and statistics.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PASSPORT_TO_ADVANCED_MATH',
          displayName: 'Passport to Advanced Math',
          description: 'Focuses on complex equations and functions, including quadratics and exponentials.',
          icon: 'remixFunctionLine', // Remix Icon for advanced math
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PASSPORT_TO_ADVANCED_MATH_QUADRATIC_FUNCTIONS',
              displayName: 'Quadratic Functions',
              description: 'Understanding and solving quadratic equations and functions.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PASSPORT_TO_ADVANCED_MATH_EXPONENTIAL_FUNCTIONS',
              displayName: 'Exponential Functions',
              description: 'Working with exponential expressions and equations.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_PASSPORT_TO_ADVANCED_MATH_RATIONAL_RADICAL',
              displayName: 'Rational and Radical Equations',
              description: 'Solving equations involving rational expressions and radicals.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH',
          displayName: 'Additional Topics in Math',
          description: 'Includes geometry, trigonometry, and complex numbers.',
          icon: 'remixShapeLine', // Remix Icon for geometry
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH_GEOMETRY',
              displayName: 'Geometry',
              description: 'Solving problems involving geometric figures and their properties.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH_TRIGONOMETRY',
              displayName: 'Trigonometry',
              description: 'Understanding trigonometric relationships in right triangles.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_NO_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH_COMPLEX_NUMBERS',
              displayName: 'Complex Numbers',
              description: 'Working with complex numbers and their properties.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'PSAT_NMSQT_2023_MATH_CALCULATOR',
      displayName: 'PSAT Math Test - Calculator Section',
      description:
        'The Math Test - Calculator allows students to use a calculator to solve a broader range of mathematical problems, including advanced math concepts, problem-solving, data analysis, and some trigonometry and geometry.',
      icon: 'remixCalculatorFill', // Remix Icon for math with calculator
      categories: [
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_HEART_OF_ALGEBRA',
          displayName: 'Heart of Algebra',
          description: 'Focuses on linear equations, inequalities, and systems of equations.',
          icon: 'remixNumbersLine', // Remix Icon for algebra
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_HEART_OF_ALGEBRA_LINEAR_EQUATIONS',
              displayName: 'Linear Equations and Inequalities',
              description: 'Solving and interpreting linear equations and inequalities.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_HEART_OF_ALGEBRA_SYSTEMS_LINEAR_EQUATIONS',
              displayName: 'Systems of Linear Equations',
              description: 'Solving systems of linear equations using various methods.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS',
          displayName: 'Problem Solving and Data Analysis',
          description: 'Emphasizes quantitative literacy, including ratios, percentages, and data interpretation.',
          icon: 'remixBarChartLine', // Remix Icon for data analysis
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_RATIOS_PROPORTIONS',
              displayName: 'Ratios and Proportions',
              description: 'Solving complex problems involving ratios and proportional relationships.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_PERCENTAGES',
              displayName: 'Percentages',
              description: 'Working with percentages in various contexts, including growth and decay.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description: 'Analyzing and interpreting data from more complex tables and graphs.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PROBLEM_SOLVING_DATA_ANALYSIS_PROBABILITY_STATISTICS',
              displayName: 'Probability and Statistics',
              description: 'Understanding and applying concepts of probability and statistics.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PASSPORT_TO_ADVANCED_MATH',
          displayName: 'Passport to Advanced Math',
          description: 'Focuses on more complex equations and functions, including higher-degree polynomials.',
          icon: 'remixFunctionFill', // Remix Icon for advanced math
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PASSPORT_TO_ADVANCED_MATH_POLYNOMIALS',
              displayName: 'Polynomials',
              description: 'Manipulating and solving polynomial expressions and equations.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_PASSPORT_TO_ADVANCED_MATH_NON_LINEAR_EXPRESSIONS',
              displayName: 'Non-linear Expressions',
              description: 'Working with rational expressions and radical equations.',
            },
          ],
        },
        {
          categoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH',
          displayName: 'Additional Topics in Math',
          description: 'Includes geometry, trigonometry, and complex numbers.',
          icon: 'remixCompassLine', // Remix Icon for geometry
          subcategories: [
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH_GEOMETRY',
              displayName: 'Geometry',
              description: 'Solving more complex geometric problems.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH_TRIGONOMETRY',
              displayName: 'Trigonometry',
              description: 'Applying trigonometric functions to solve problems.',
            },
            {
              subcategoryId: 'PSAT_NMSQT_2023_MATH_CALCULATOR_ADDITIONAL_TOPICS_IN_MATH_COMPLEX_NUMBERS',
              displayName: 'Complex Numbers',
              description: 'Working with complex numbers in various contexts.',
            },
          ],
        },
      ],
    },
  ],
};
