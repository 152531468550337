import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private MAX_RETRIES = 1;       // Number of retries
  private RETRY_DELAY_MS = 1000; // Delay between retries in milliseconds

  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap(token => {
        const authReq = token
          ? req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          })
          : req;

        // Log request details
        if (token) {
          console.log('Token added to request:' + token + 'for url:' + req.url);
        } else {
          console.log('No token added to request for url:' + req.url);
        }

        if (token) {
          // User is authenticated, check email verification status
          return this.authService.firebaseUser$.pipe(
            take(1),
            switchMap(user => {
              if (user && !user.emailVerified && !this.isExcludedUrl(req.url)) {
                // Redirect to email verification page
                this.router.navigate(['/email-verification']);
                // Cancel the request by throwing an error
                return throwError('Email not verified');
              } else {
                // Email is verified or user is null (shouldn't happen here)
                // Proceed with the HTTP request
                return next.handle(authReq).pipe(
                  catchError(error => this.handleError(error, req, next, token))
                );
              }
            })
          );
        } else {
          // User is not authenticated, proceed with the request
          return next.handle(authReq).pipe(
            catchError(error => this.handleError(error, req, next, token))
          );
        }
      })
    );
  }

  private handleError(
    error: any,
    req: HttpRequest<any>,
    next: HttpHandler,
    token: string | null,
    retryCount: number = 0
  ): Observable<HttpEvent<any>> {
    if (
      error instanceof HttpErrorResponse &&
      error.status === 401 &&
      retryCount < this.MAX_RETRIES
    ) {
      // Retry the request after a delay
      return timer(this.RETRY_DELAY_MS).pipe(
        switchMap(() => this.authService.getToken(true)),
        switchMap(newToken => {
          const newAuthReq = newToken
            ? req.clone({
              headers: req.headers.set('Authorization', `Bearer ${newToken}`),
            })
            : req;

          return next.handle(newAuthReq).pipe(
            catchError(err => this.handleError(err, req, next, newToken, retryCount + 1))
          );
        })
      );
    } else if (error.status === 401) {
      // Max retries reached or other 401 error
      this.authService.logout();
      this.router.navigate(['/login'],{ queryParams: { message: 'To continue, you need to login or register.' } });
      return throwError(error);
    } else {
      // For other errors
      return throwError(error);
    }
  }

  private isExcludedUrl(url: string): boolean {
    // URLs to exclude from email verification check
    const excludedUrls = [
      '/email-verification',
      '/exams',
      '/exam',
      '/pricing',
      '/',
      '/api/send-verification-email',
      // Add more URLs if needed
    ];

    return excludedUrls.some(excludedUrl => url.includes(excludedUrl));
  }
}
