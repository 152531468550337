// MCAT Exam Data
import { ExamData } from "../exam-data-interface";

export const mcatExamData: ExamData = {
  examId: 'MCAT_2025',
  displayName: 'MCAT',
  description:
    'The Medical College Admission Test (MCAT) is a standardized, multiple-choice exam created to help medical school admissions offices assess problem-solving, critical thinking, and knowledge of natural, behavioral, and social science concepts and principles prerequisite to the study of medicine.',
  logo: 'assets/images/exams/mcat_logo.jpg',
  link: 'mcat',
  zoom: 1.1,
  priceId: 'MCAT_2025_month_subscription_price',
  priceUnitAmount: 999,
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel on the MCAT with Comprehensive Preparation',
  marketingSubtitles: [
    'Tailored practice to enhance your MCAT performance.',
    'Extensive question bank covering all MCAT sections.',
    'Adaptive learning to focus on areas needing improvement.',
    'Build confidence with effective practice and detailed feedback.',
  ],
  pricingTitle: 'Premium MCAT Prep at a Competitive Price',
  pricingSubtitle: [
    'Achieve your medical school goals with comprehensive support and resources.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'MCAT_2025_CHEM_PHYS',
      displayName: 'Chemical and Physical Foundations of Biological Systems',
      description:
        'Assesses your understanding of the chemical and physical principles that underlie the mechanisms operating in the human body and living organisms. Emphasizes the ability to apply these concepts in the context of biological systems.',
      icon: 'remixFlaskLine', // Remix Icon for chemistry
      categories: [
        {
          categoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY',
          displayName: 'Biochemistry',
          description:
            'Questions testing knowledge of biochemical concepts and principles relevant to living systems.',
          icon: 'remixPulseLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_AMINO_ACIDS_PROTEINS',
              displayName: 'Amino Acids and Proteins',
              description:
                'Structure, properties, and functions of amino acids and proteins.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_ENZYMES',
              displayName: 'Enzymes',
              description:
                'Enzyme structure, function, kinetics, and inhibition.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_METABOLISM',
              displayName: 'Metabolism',
              description: 'Metabolic pathways and their regulation.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_LIPIDS_MEMBRANES',
              displayName: 'Lipids and Membranes',
              description:
                'Structure and function of lipids and biological membranes.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_CARBOHYDRATES',
              displayName: 'Carbohydrates',
              description: 'Structure and function of carbohydrates.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_NUCLEIC_ACIDS',
              displayName: 'Nucleic Acids and DNA Replication',
              description:
                'Structure and function of nucleic acids; processes of DNA replication and repair.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOCHEMISTRY_BIOENERGETICS',
              displayName: 'Bioenergetics',
              description:
                'Principles of bioenergetics and thermodynamics in biological systems.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY',
          displayName: 'General Chemistry',
          description:
            'Questions testing knowledge of general chemistry concepts relevant to biological systems.',
          icon: 'remixTestTubeLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_ATOMIC_STRUCTURE',
              displayName: 'Atomic Structure and Periodic Trends',
              description:
                'Understanding the structure of atoms and periodic properties.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_CHEMICAL_BONDING',
              displayName: 'Chemical Bonding',
              description: 'Types of chemical bonds and molecular structure.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_STOICHIOMETRY',
              displayName: 'Stoichiometry and Chemical Reactions',
              description:
                'Quantitative relationships in chemical reactions.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_THERMODYNAMICS',
              displayName: 'Thermodynamics and Thermochemistry',
              description:
                'Energy changes in chemical reactions and laws of thermodynamics.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_KINETICS',
              displayName: 'Chemical Kinetics',
              description:
                'Rates of reactions and factors affecting them.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_EQUILIBRIUM',
              displayName: 'Chemical Equilibrium',
              description: 'Dynamic equilibrium in chemical systems.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_ACIDS_BASES',
              displayName: 'Acids and Bases',
              description:
                'Properties of acids and bases and pH calculations.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_ELECTROCHEMISTRY',
              displayName: 'Electrochemistry',
              description: 'Redox reactions and electrochemical cells.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_SOLUTION_CHEMISTRY',
              displayName: 'Solution Chemistry',
              description:
                'Properties of solutions, solubility, and concentration calculations.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_GENERAL_CHEMISTRY_GAS_PHASE',
              displayName: 'Gas Phase',
              description: 'Gas laws and behavior of gases.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY',
          displayName: 'Organic Chemistry',
          description:
            'Questions testing knowledge of organic chemistry concepts relevant to biological systems.',
          icon: 'remixFlaskLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_STRUCTURE_BONDING',
              displayName: 'Structure and Bonding',
              description:
                'Hybridization, molecular geometry, and electronic effects in organic molecules.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_STEREOCHEMISTRY',
              displayName: 'Stereochemistry',
              description:
                'Chirality, enantiomers, diastereomers, and optical activity.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_FUNCTIONAL_GROUPS',
              displayName: 'Functional Groups',
              description:
                'Identification and properties of organic functional groups.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_SUBSTITUTION_ELIMINATION',
              displayName: 'Nucleophilic Substitution and Elimination Reactions',
              description:
                'Mechanisms and factors affecting SN1, SN2, E1, and E2 reactions.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_ADDITION_REACTIONS',
              displayName: 'Addition Reactions',
              description: 'Addition mechanisms to alkenes and alkynes.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_CARBONYL_CHEMISTRY',
              displayName: 'Carbonyl Chemistry',
              description:
                'Reactions involving aldehydes, ketones, carboxylic acids, and derivatives.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_AROMATIC_COMPOUNDS',
              displayName: 'Aromatic Compounds',
              description:
                'Properties and reactions of benzene and substituted aromatics.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_ORGANIC_CHEMISTRY_BIOLOGICAL_MOLECULES',
              displayName: 'Biological Molecules',
              description:
                'Organic chemistry of amino acids, carbohydrates, lipids, and nucleic acids.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS',
          displayName: 'Physics',
          description:
            'Questions testing knowledge of physics concepts relevant to biological systems.',
          icon: 'remixPulseLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_KINEMATICS_DYNAMICS',
              displayName: 'Kinematics and Dynamics',
              description:
                "Motion, forces, Newton's laws, and circular motion.",
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_WORK_ENERGY',
              displayName: 'Work and Energy',
              description:
                'Work, kinetic and potential energy, conservation of energy.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_FLUID_MECHANICS',
              displayName: 'Fluid Mechanics',
              description:
                'Properties of fluids, buoyancy, fluid dynamics.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_THERMODYNAMICS',
              displayName: 'Thermodynamics',
              description:
                'Heat, temperature, laws of thermodynamics, kinetic theory of gases.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_ELECTROSTATICS',
              displayName: 'Electrostatics and Electromagnetism',
              description:
                'Electric fields, forces, potential, circuits, magnetism.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_OPTICS',
              displayName: 'Optics',
              description:
                'Reflection, refraction, lenses, mirrors, wave optics.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_WAVES_SOUND',
              displayName: 'Waves and Sound',
              description: 'Properties of waves, sound, Doppler effect.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_PHYSICS_ATOMIC_NUCLEAR',
              displayName: 'Atomic and Nuclear Physics',
              description:
                'Atomic structure, radioactivity, nuclear reactions.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_CHEM_PHYS_BIOLOGY',
          displayName: 'Biology',
          description:
            'Questions testing knowledge of biology concepts relevant to physical and chemical systems.',
          icon: 'remixHeartPulseLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOLOGY_CELL_STRUCTURE',
              displayName: 'Cell Structure and Function',
              description:
                'Structure and function of prokaryotic and eukaryotic cells.',
            },
            {
              subcategoryId: 'MCAT_2025_CHEM_PHYS_BIOLOGY_HOMEOSTASIS_TRANSPORT',
              displayName: 'Homeostasis and Transport',
              description:
                'Mechanisms of maintaining internal stability and transport across membranes.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'MCAT_2025_CARS',
      displayName: 'Critical Analysis and Reasoning Skills (CARS)',
      description:
        'Tests your ability to comprehend, analyze, and evaluate complex passages. Focuses on critical thinking and reasoning skills rather than specific content knowledge.',
      icon: 'remixBookOpenLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'MCAT_2025_CARS_FOUNDATIONS_OF_COMPREHENSION',
          displayName: 'Foundations of Comprehension',
          description:
            'Questions that test understanding of the basic components of the text, including main ideas and details.',
          icon: 'remixFileTextLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CARS_FOUNDATIONS_OF_COMPREHENSION_MAIN_IDEA',
              displayName: 'Main Idea',
              description:
                'Identifying the central thesis or primary purpose of the passage.',
            },
            {
              subcategoryId: 'MCAT_2025_CARS_FOUNDATIONS_OF_COMPREHENSION_DETAIL',
              displayName: 'Detail',
              description:
                'Recalling specific facts or information presented in the passage.',
            },
            {
              subcategoryId: 'MCAT_2025_CARS_FOUNDATIONS_OF_COMPREHENSION_VOCABULARY',
              displayName: 'Vocabulary',
              description:
                'Determining the meaning of words or phrases in context.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_CARS_REASONING_WITHIN_TEXT',
          displayName: 'Reasoning Within the Text',
          description:
            'Questions that test your ability to integrate and analyze components of the text to reach conclusions.',
          icon: 'remixDiscussLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CARS_REASONING_WITHIN_TEXT_INFERENCE',
              displayName: 'Inference',
              description:
                'Drawing conclusions or implications from the information provided.',
            },
            {
              subcategoryId: 'MCAT_2025_CARS_REASONING_WITHIN_TEXT_AUTHOR_TECHNIQUE',
              displayName: 'Author’s Technique',
              description:
                'Analyzing the author’s methods, style, and structure.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_CARS_REASONING_BEYOND_TEXT',
          displayName: 'Reasoning Beyond the Text',
          description:
            'Questions that test your ability to apply or extrapolate ideas from the passage to new contexts.',
          icon: 'remixExternalLinkLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_CARS_REASONING_BEYOND_TEXT_APPLY_NEW_CONTEXT',
              displayName: 'Application to New Contexts',
              description:
                'Applying concepts from the passage to different situations or scenarios.',
            },
            {
              subcategoryId: 'MCAT_2025_CARS_REASONING_BEYOND_TEXT_STRENGTHEN_WEAKEN',
              displayName: 'Strengthen and Weaken',
              description:
                'Evaluating how new information impacts arguments or ideas in the passage.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'MCAT_2025_BIO_BIOCHEM',
      displayName: 'Biological and Biochemical Foundations of Living Systems',
      description:
        'Tests your knowledge of biological and biochemical concepts, processes, and mechanisms that are foundational to the study of medicine.',
      icon: 'remixMicroscopeLine', // Remix Icon for biology
      categories: [
        {
          categoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY',
          displayName: 'Biology',
          description:
            'Questions testing knowledge of biological concepts and processes.',
          icon: 'remixHeartLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY_CELLULAR_BIOLOGY',
              displayName: 'Cellular Biology',
              description:
                'Cell structure, function, and processes including organelles, cytoskeleton, and cell cycle.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY_GENETICS',
              displayName: 'Genetics',
              description:
                'Inheritance patterns, Mendelian genetics, molecular genetics, and genetic technologies.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY_MOLECULAR_BIOLOGY',
              displayName: 'Molecular Biology',
              description:
                'DNA replication, transcription, translation, and regulation of gene expression.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY_MICROBIOLOGY',
              displayName: 'Microbiology',
              description: 'Viruses, bacteria, and the immune response.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY_HUMAN_PHYSIOLOGY',
              displayName: 'Human Physiology',
              description:
                'Organ systems, homeostasis, and physiological processes.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOLOGY_EVOLUTION',
              displayName: 'Evolution',
              description:
                'Mechanisms of evolution, natural selection, and speciation.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY',
          displayName: 'Biochemistry',
          description:
            'Questions testing knowledge of biochemical concepts and processes.',
          icon: 'remixPulseLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_AMINO_ACIDS_PROTEINS',
              displayName: 'Amino Acids and Proteins',
              description:
                'Structure, properties, and functions of amino acids and proteins.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_ENZYMES',
              displayName: 'Enzymes',
              description:
                'Enzyme structure, function, kinetics, and inhibition.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_METABOLISM',
              displayName: 'Metabolism',
              description: 'Metabolic pathways and their regulation.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_LIPIDS_MEMBRANES',
              displayName: 'Lipids and Membranes',
              description:
                'Structure and function of lipids and biological membranes.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_CARBOHYDRATES',
              displayName: 'Carbohydrates',
              description: 'Structure and function of carbohydrates.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_NUCLEIC_ACIDS',
              displayName: 'Nucleic Acids and DNA Replication',
              description:
                'Structure and function of nucleic acids; processes of DNA replication and repair.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_BIOCHEMISTRY_BIOENERGETICS',
              displayName: 'Bioenergetics',
              description:
                'Principles of bioenergetics and thermodynamics in biological systems.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_BIO_BIOCHEM_GENERAL_CHEMISTRY',
          displayName: 'General Chemistry',
          description:
            'General chemistry concepts relevant to biological systems.',
          icon: 'remixTestTubeLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_GENERAL_CHEMISTRY_EQUILIBRIUM',
              displayName: 'Chemical Equilibrium',
              description:
                'Dynamic equilibrium in chemical systems relevant to biological processes.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_GENERAL_CHEMISTRY_ACIDS_BASES',
              displayName: 'Acids and Bases',
              description:
                'Properties of acids and bases, buffers, and pH calculations in biological contexts.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_BIO_BIOCHEM_ORGANIC_CHEMISTRY',
          displayName: 'Organic Chemistry',
          description:
            'Organic chemistry concepts relevant to biological systems.',
          icon: 'remixFlaskLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_ORGANIC_CHEMISTRY_FUNCTIONAL_GROUPS',
              displayName: 'Functional Groups',
              description:
                'Identification and properties of organic functional groups relevant to biological molecules.',
            },
            {
              subcategoryId: 'MCAT_2025_BIO_BIOCHEM_ORGANIC_CHEMISTRY_BIOMOLECULES',
              displayName: 'Biomolecules',
              description:
                'Organic chemistry of amino acids, carbohydrates, lipids, and nucleic acids.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'MCAT_2025_PSYCH_SOCIAL',
      displayName:
        'Psychological, Social, and Biological Foundations of Behavior',
      description:
        'Tests your understanding of the psychological, social, and biological factors that influence perceptions and reactions to the world, behavior and behavior change, and how social stratification affects access to resources and well-being.',
      icon: 'remixMentalHealthLine', // Remix Icon for psychology
      categories: [
        {
          categoryId: 'MCAT_2025_PSYCH_SOCIAL_PSYCHOLOGY',
          displayName: 'Psychology',
          description:
            'Questions testing knowledge of psychological concepts and theories.',
          icon: 'remixBrainLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_PSYCHOLOGY_PERCEPTION_COGNITION',
              displayName: 'Perception and Cognition',
              description:
                'Processes of sensation, perception, learning, memory, and cognition.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_PSYCHOLOGY_EMOTION_STRESS',
              displayName: 'Emotion and Stress',
              description:
                'Emotional processes, theories of emotion, and the impact of stress.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_PSYCHOLOGY_PSYCHOLOGICAL_DISORDERS',
              displayName: 'Psychological Disorders',
              description:
                'Classification, symptoms, and treatments of psychological disorders.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_PSYCHOLOGY_MOTIVATION_IDENTITY',
              displayName: 'Motivation and Identity',
              description:
                'Theories of motivation, personality, and identity formation.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_PSYCHOLOGY_SOCIAL_PSYCHOLOGY',
              displayName: 'Social Psychology',
              description: 'Attitudes, group behavior, and social influence.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_PSYCH_SOCIAL_SOCIOLOGY',
          displayName: 'Sociology',
          description:
            'Questions testing knowledge of sociological concepts and theories.',
          icon: 'remixCommunityLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_SOCIOLOGY_SOCIAL_STRUCTURE',
              displayName: 'Social Structure',
              description:
                'Institutions, social groups, and stratification.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_SOCIOLOGY_DEMOGRAPHICS_INEQUALITY',
              displayName: 'Demographics and Inequality',
              description:
                'Population dynamics, social inequality, and health disparities.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_SOCIOLOGY_CULTURE',
              displayName: 'Culture',
              description: 'Cultural norms, values, and beliefs.',
            },
          ],
        },
        {
          categoryId: 'MCAT_2025_PSYCH_SOCIAL_BIOLOGY',
          displayName: 'Biology',
          description:
            'Biological concepts related to behavior and mental processes.',
          icon: 'remixHeartPulseLine',
          subcategories: [
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_BIOLOGY_NERVOUS_SYSTEM',
              displayName: 'Nervous System',
              description:
                'Structure and function of the nervous system, neurotransmitters, and brain regions.',
            },
            {
              subcategoryId: 'MCAT_2025_PSYCH_SOCIAL_BIOLOGY_ENDOCRINE_SYSTEM',
              displayName: 'Endocrine System',
              description: 'Hormones and their influence on behavior.',
            },
          ],
        },
      ],
    },
  ],
};
