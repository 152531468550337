// AP English Language and Composition Exam Data
import { ExamData } from "../exam-data-interface";
import {remixMovieLine} from "@ng-icons/remixicon";

export const apEnglishLanguageExamData: ExamData = {
  examId: 'AP_ENGLISH_LANGUAGE_2025',
  displayName: 'AP English Language and Composition',
  description:
    'The AP English Language and Composition Exam tests students\' abilities to read and analyze prose passages and to write analytical and argumentative essays.',
  logo: 'assets/images/exams/ap_english_lang_logo.png', // Ensure you have the correct logo path
  link: 'ap-english-language-composition',
  zoom: 1.1,
  priceId: 'AP_ENGLISH_LANGUAGE_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Master the AP English Language and Composition with Comprehensive Practice and Expert Insights',
  marketingSubtitles: [
    'Extensive question bank tailored to AP English Language and Composition exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable AP English Language and Composition Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your academic performance.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'AP_ENGLISH_LANGUAGE_MC',
      displayName: 'Multiple Choice',
      description:
        'This section tests reading comprehension of nonfiction texts, rhetorical analysis, and understanding of linguistic and rhetorical techniques.',
      icon: 'remixBookLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_MC_READING_COMPREHENSION',
          displayName: 'Reading Comprehension',
          description: 'Understanding and interpreting the main ideas and themes of passages.',
          icon: 'remixInformationLine', // Remix Icon for information
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_READING_COMPREHENSION_MAIN_IDEA',
              displayName: 'Main Idea',
              description: 'Identifying the central idea or thesis.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_READING_COMPREHENSION_SUPPORTING_DETAILS',
              displayName: 'Supporting Details',
              description: 'Understanding evidence and examples.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_READING_COMPREHENSION_INFERENCE',
              displayName: 'Inference',
              description: 'Drawing conclusions from the text.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_READING_COMPREHENSION_VOCABULARY_IN_CONTEXT',
              displayName: 'Vocabulary in Context',
              description: 'Determining the meaning of words and phrases.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_MC_RHETORICAL_ANALYSIS',
          displayName: 'Rhetorical Analysis',
          description: 'Analyzing how authors use rhetorical strategies to achieve their purpose.',
          icon: 'remixSpeakLine', // Remix Icon for rhetoric
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_RHETORICAL_ANALYSIS_PURPOSE_AND_AUDIENCE',
              displayName: 'Purpose and Audience',
              description: 'Understanding the author\'s intent and target audience.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_RHETORICAL_ANALYSIS_RHETORICAL_STRATEGIES',
              displayName: 'Rhetorical Strategies',
              description: 'Identifying techniques like ethos, pathos, logos.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_RHETORICAL_ANALYSIS_TONE_AND_STYLE',
              displayName: 'Tone and Style',
              description: 'Analyzing the author\'s tone and stylistic choices.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_RHETORICAL_ANALYSIS_ORGANIZATION_AND_STRUCTURE',
              displayName: 'Organization and Structure',
              description: 'Understanding how the text is organized.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_MC_LANGUAGE_COMPOSITION',
          displayName: 'Language and Composition',
          description: 'Understanding grammatical and syntactical elements.',
          icon: 'remixSlideshowLine', // Remix Icon for grammar
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_LANGUAGE_COMPOSITION_SYNTAX',
              displayName: 'Syntax',
              description: 'Analyzing sentence structure and its effect.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_LANGUAGE_COMPOSITION_DICTION',
              displayName: 'Diction',
              description: 'Understanding word choice and connotation.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_LANGUAGE_COMPOSITION_FIGURATIVE_LANGUAGE',
              displayName: 'Figurative Language',
              description: 'Identifying metaphors, similes, and imagery.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_LANGUAGE_COMPOSITION_GRAMMAR_AND_MECHANICS',
              displayName: 'Grammar and Mechanics',
              description: 'Understanding grammatical conventions.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_MC_SYNTHESIS_INFORMATION',
          displayName: 'Synthesis of Information',
          description: 'Combining ideas from multiple texts to draw conclusions.',
          icon: 'remixMergeCellsHorizontal', // Remix Icon for synthesis
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_SYNTHESIS_INFORMATION_COMPARATIVE_ANALYSIS',
              displayName: 'Comparative Analysis',
              description: 'Comparing themes across texts.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_SYNTHESIS_INFORMATION_EVALUATING_ARGUMENTS',
              displayName: 'Evaluating Arguments',
              description: 'Assessing the strength of arguments.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_SYNTHESIS_INFORMATION_INTEGRATING_SOURCES',
              displayName: 'Integrating Sources',
              description: 'Combining information from multiple sources.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_MC_SYNTHESIS_INFORMATION_CRITICAL_THINKING',
              displayName: 'Critical Thinking',
              description: 'Applying reasoning to synthesize ideas.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE',
      displayName: 'Free Response',
      description:
        'This section requires students to write three essays: a synthesis essay, a rhetorical analysis essay, and an argument essay.',
      icon: 'remixPenNibLine', // Remix Icon for writing
      categories: [
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_SYNTHESIS_ESSAY',
          displayName: 'Synthesis Essay',
          description: 'Creating an argument using multiple sources.',
          icon: 'remixLightbulbLine', // Remix Icon for ideas
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_SYNTHESIS_ESSAY_THESIS_DEVELOPMENT',
              displayName: 'Thesis Development',
              description: 'Crafting a clear, argumentative thesis.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_SYNTHESIS_ESSAY_SOURCE_INTEGRATION',
              displayName: 'Source Integration',
              description: 'Effectively incorporating sources.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_SYNTHESIS_ESSAY_COHESION_COHERENCE',
              displayName: 'Cohesion and Coherence',
              description: 'Logical flow of ideas.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_SYNTHESIS_ESSAY_CITATION',
              displayName: 'Citation',
              description: 'Properly attributing sources.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_RHETORICAL_ANALYSIS_ESSAY',
          displayName: 'Rhetorical Analysis Essay',
          description: 'Analyzing the rhetorical strategies used in a text.',
          icon: 'remixBarChartLine', // Remix Icon for analysis
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_RHETORICAL_ANALYSIS_ESSAY_ANALYSIS_OF_STRATEGIES',
              displayName: 'Analysis of Strategies',
              description: 'Identifying rhetorical techniques.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_RHETORICAL_ANALYSIS_ESSAY_PURPOSE_AND_EFFECT',
              displayName: 'Purpose and Effect',
              description: 'Explaining how strategies achieve purpose.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_RHETORICAL_ANALYSIS_ESSAY_TEXTUAL_EVIDENCE',
              displayName: 'Textual Evidence',
              description: 'Supporting analysis with evidence.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_RHETORICAL_ANALYSIS_ESSAY_WRITING_STYLE',
              displayName: 'Writing Style',
              description: 'Demonstrating control of language.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_ARGUMENT_ESSAY',
          displayName: 'Argument Essay',
          description: 'Developing an evidence-based argument on a given topic.',
          icon: 'remixShakeHandsLine', // Remix Icon for argument
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_ARGUMENT_ESSAY_CLAIM_AND_THESIS',
              displayName: 'Claim and Thesis',
              description: 'Stating a clear position.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_ARGUMENT_ESSAY_EVIDENCE_AND_SUPPORT',
              displayName: 'Evidence and Support',
              description: 'Providing logical reasoning and examples.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_ARGUMENT_ESSAY_COUNTERARGUMENT',
              displayName: 'Counterargument',
              description: 'Addressing opposing viewpoints.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LANGUAGE_FREE_RESPONSE_ARGUMENT_ESSAY_PERSUASIVE_TECHNIQUES',
              displayName: 'Persuasive Techniques',
              description: 'Using rhetoric to strengthen argument.',
            },
          ],
        },
      ],
    },
  ],
};

// AP English Literature and Composition Exam Data
export const apEnglishLiteratureExamData: ExamData = {
  examId: 'AP_ENGLISH_LIT_2025',
  displayName: 'AP English Literature and Composition',
  description:
    'The AP English Literature and Composition Exam tests students\' abilities to analyze and interpret literary texts and to write clear and effective essays using textual evidence.',
  logo: 'assets/images/exams/ap_english_lit_logo.png', // Ensure you have the correct logo path
  link: 'ap-english-literature-composition',
  zoom: 1.1,
  priceId: 'AP_ENGLISH_LIT_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel in AP English Literature and Composition with Comprehensive Practice and Expert Insights',
  marketingSubtitles: [
    'Extensive question bank tailored to AP English Literature and Composition exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable AP English Literature and Composition Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your academic performance.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'AP_ENGLISH_LIT_MC',
      displayName: 'Multiple Choice',
      description:
        'This section tests students\' abilities to read and analyze literary texts, including prose, poetry, and drama from various periods.',
      icon: 'remixBookLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS',
          displayName: 'Poetry Analysis',
          description: 'Analyzing themes, techniques, and structures in poetry.',
          icon: 'remixImageLine', // Remix Icon for imagery
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS_THEME_AND_MEANING',
              displayName: 'Theme and Meaning',
              description: 'Understanding the central ideas and messages.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS_IMAGERY',
              displayName: 'Imagery',
              description: 'Analyzing visual and sensory details.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS_FIGURATIVE_LANGUAGE',
              displayName: 'Figurative Language',
              description: 'Metaphor, simile, personification, symbolism.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS_SOUND_DEVICES',
              displayName: 'Sound Devices',
              description: 'Alliteration, assonance, rhyme, rhythm.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS_STRUCTURE_AND_FORM',
              displayName: 'Structure and Form',
              description: 'Stanza forms, meter, sonnet types.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_POETRY_ANALYSIS_TONE_AND_MOOD',
              displayName: 'Tone and Mood',
              description: 'Identifying the poet\'s attitude and emotional atmosphere.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS',
          displayName: 'Prose Analysis',
          description: 'Analyzing narrative techniques and elements in prose passages.',
          icon: 'remixPenNibLine', // Remix Icon for prose
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS_CHARACTERIZATION',
              displayName: 'Characterization',
              description: 'Understanding how characters are developed.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS_PLOT_AND_STRUCTURE',
              displayName: 'Plot and Structure',
              description: 'Analyzing the organization of events.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS_NARRATIVE_PERSPECTIVE',
              displayName: 'Narrative Perspective',
              description: 'Point of view and its effect.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS_SETTING',
              displayName: 'Setting',
              description: 'Role of time and place in the narrative.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS_DICTION_AND_SYNTAX',
              displayName: 'Diction and Syntax',
              description: 'Word choice and sentence structure effects.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_PROSE_ANALYSIS_IRONY_AND_SATIRE',
              displayName: 'Irony and Satire',
              description: 'Use of irony, sarcasm, and satire.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS',
          displayName: 'Drama Analysis',
          description: 'Analyzing elements and techniques in dramatic texts.',
          icon: 'remixMovieLine', // Remix Icon for drama/theater
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS_DIALOGUE_AND_MONOLOGUE',
              displayName: 'Dialogue and Monologue',
              description: 'Role in character and plot development.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS_STAGE_DIRECTIONS',
              displayName: 'Stage Directions',
              description: 'Understanding implied actions and settings.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS_TRAGEDY_AND_COMEDY',
              displayName: 'Tragedy and Comedy',
              description: 'Conventions of dramatic genres.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS_THEMES_AND_MOTIFS',
              displayName: 'Themes and Motifs',
              description: 'Recurring ideas and symbols in plays.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS_CHARACTER_RELATIONSHIPS',
              displayName: 'Character Relationships',
              description: 'Dynamics between characters.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_DRAMA_ANALYSIS_USE_OF_SOLILOQUY_AND_ASIDE',
              displayName: 'Use of Soliloquy and Aside',
              description: 'Revealing inner thoughts.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES',
          displayName: 'Literary Devices',
          description: 'Identifying and interpreting literary devices across texts.',
          icon: 'remixHammerLine', // Remix Icon for tools/literary devices
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES_SYMBOLISM',
              displayName: 'Symbolism',
              description: 'Objects representing ideas.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES_ALLUSION',
              displayName: 'Allusion',
              description: 'References to other texts or historical events.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES_FORESHADOWING',
              displayName: 'Foreshadowing',
              description: 'Hints about future events.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES_FLASHBACK',
              displayName: 'Flashback',
              description: 'Interruptions to show past events.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES_ALLEGORY',
              displayName: 'Allegory',
              description: 'Extended metaphors with deeper meanings.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_LITERARY_DEVICES_PARADOX_AND_OXYMORON',
              displayName: 'Paradox and Oxymoron',
              description: 'Contradictory statements revealing truths.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LIT_MC_READING_COMPREHENSION',
          displayName: 'Reading Comprehension',
          description: 'Understanding and interpreting the content of literary texts.',
          icon: 'remixReadLine', // Remix Icon for reading comprehension
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_READING_COMPREHENSION_MAIN_IDEA_AND_THEME',
              displayName: 'Main Idea and Theme',
              description: 'Identifying central messages.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_READING_COMPREHENSION_SUPPORTING_DETAILS',
              displayName: 'Supporting Details',
              description: 'Recognizing evidence and examples.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_READING_COMPREHENSION_INFERENCE',
              displayName: 'Inference',
              description: 'Drawing conclusions from the text.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_READING_COMPREHENSION_VOCABULARY_IN_CONTEXT',
              displayName: 'Vocabulary in Context',
              description: 'Understanding word meanings in passages.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LIT_MC_READING_COMPREHENSION_AUTHORS_PURPOSE',
              displayName: 'Author\'s Purpose',
              description: 'Determining why the text was written.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE',
      displayName: 'Free Response',
      description:
        'This section requires students to write three essays: a literary analysis of a poem, a literary analysis of a prose passage, and an analysis of a specific concept, issue, or element in a work of literary merit of their choice.',
      icon: 'remixPenNibLine', // Remix Icon for writing
      categories: [
        {
          categoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_POETRY_ESSAY',
          displayName: 'Poetry Essay',
          description: 'Analyzing a provided poem to explore its themes and techniques.',
          icon: 'remixImageLine', // Remix Icon for imagery
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_POETRY_ESSAY_THESIS_DEVELOPMENT',
              displayName: 'Thesis Development',
              description: 'Crafting a clear, analytical thesis.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_POETRY_ESSAY_TEXTUAL_EVIDENCE',
              displayName: 'Textual Evidence',
              description: 'Supporting analysis with specific references.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_POETRY_ESSAY_ANALYSIS_OF_TECHNIQUES',
              displayName: 'Analysis of Techniques',
              description: 'Exploring poetic devices and their effects.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_POETRY_ESSAY_ORGANIZATION_AND_COHERENCE',
              displayName: 'Organization and Coherence',
              description: 'Logical flow of ideas.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_POETRY_ESSAY_STYLE_AND_MECHANICS',
              displayName: 'Style and Mechanics',
              description: 'Command of language and grammar.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_PROSE_ESSAY',
          displayName: 'Prose Essay',
          description: 'Analyzing a provided prose passage to explore narrative techniques and meaning.',
          icon: 'remixPencilLine', // Remix Icon for prose writing
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_PROSE_ESSAY_THESIS_DEVELOPMENT',
              displayName: 'Thesis Development',
              description: 'Establishing a clear argument or interpretation.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_PROSE_ESSAY_CHARACTER_ANALYSIS',
              displayName: 'Character Analysis',
              description: 'Examining character development and relationships.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_PROSE_ESSAY_NARRATIVE_TECHNIQUES',
              displayName: 'Narrative Techniques',
              description: 'Analyzing point of view, structure, and style.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_PROSE_ESSAY_USE_OF_EVIDENCE',
              displayName: 'Use of Evidence',
              description: 'Incorporating relevant textual support.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_PROSE_ESSAY_WRITING_STYLE',
              displayName: 'Writing Style',
              description: 'Effective use of language and syntax.',
            },
          ],
        },
        {
          categoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT',
          displayName: 'Literary Argument',
          description: 'Writing an essay on a given topic using a literary work of the student\'s choice.',
          icon: 'remixTeamLine', // Remix Icon for argument
          subcategories: [
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT_SELECTION_OF_WORK',
              displayName: 'Selection of Work',
              description: 'Choosing an appropriate literary work.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT_ARGUMENT_DEVELOPMENT',
              displayName: 'Argument Development',
              description: 'Formulating a coherent argument related to the prompt.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT_THEME_ANALYSIS',
              displayName: 'Thematic Analysis',
              description: 'Exploring central themes and their significance.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT_TEXTUAL_EVIDENCE',
              displayName: 'Textual Evidence',
              description: 'Using specific examples from the chosen work.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT_ORGANIZATION_AND_STRUCTURE',
              displayName: 'Organization and Structure',
              description: 'Logical arrangement of ideas.',
            },
            {
              subcategoryId: 'AP_ENGLISH_LITERATURE_FREE_RESPONSE_LITERARY_ARGUMENT_LANGUAGE_AND_STYLE',
              displayName: 'Language and Style',
              description: 'Demonstrating a mature writing style.',
            },
          ],
        },
      ],
    },
  ],
};



export const apUsGovernmentExamData: ExamData = {
  examId: 'AP_US_GOV_2025',
  displayName: 'AP United States Government and Politics',
  description:
    'The AP United States Government and Politics Exam measures students\' understanding of the operations and structure of the U.S. government and political systems, as well as their ability to analyze and interpret data and texts.',
  logo: 'assets/images/exams/ap_us_govt_logo.png', // Ensure you have the correct logo path
  link: 'ap-united-states-government-and-politics',
  zoom: 1.1,
  priceId: 'AP_US_GOV_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Master AP United States Government and Politics with Comprehensive Practice and Expert Insights',
  marketingSubtitles: [
    'Extensive question bank tailored to AP US Government and Politics exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable AP US Government and Politics Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your academic performance.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'AP_US_GOV_MC',
      displayName: 'Multiple Choice',
      description:
        'This section tests students\' knowledge of U.S. government and politics, including foundational documents, Supreme Court cases, and political processes.',
      icon: 'remixBookLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'AP_US_GOV_MC_UNIT1',
          displayName: 'Unit 1: Foundations of Democracy',
          description: 'Understanding the philosophical and historical foundations of the U.S. political system.',
          icon: 'remixIdeaLine', // Remix Icon for ideas
          subcategories: [
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_DEMOCRATIC_IDEALS',
              displayName: 'Democratic Ideals',
              description: 'Natural rights, social contract, popular sovereignty, republicanism.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_DECLARATION_OF_INDEPENDENCE',
              displayName: 'Declaration of Independence',
              description: 'Purpose and key principles.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_ARTICLES_OF_CONFEDERATION',
              displayName: 'Articles of Confederation',
              description: 'Strengths and weaknesses of the first U.S. government.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_CONSTITUTIONAL_CONVENTION',
              displayName: 'Constitutional Convention',
              description: 'Compromises and debates over the new Constitution.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_FEDERALIST_PAPERS',
              displayName: 'Federalist Papers',
              description: 'Arguments for ratification, especially Federalist No. 10 and No. 51.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_ANTI_FEDERALIST_PAPERS',
              displayName: 'Anti-Federalist Papers',
              description: 'Concerns about the proposed Constitution.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_SEPARATION_OF_POWERS',
              displayName: 'Separation of Powers',
              description: 'Division of government roles into branches.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_CHECKS_AND_BALANCES',
              displayName: 'Checks and Balances',
              description: 'Mechanisms to prevent any one branch from becoming too powerful.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_FEDERALISM',
              displayName: 'Federalism',
              description: 'Division of power between national and state governments.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT1_AMENDMENT_PROCESS',
              displayName: 'Amendment Process',
              description: 'Procedures for changing the Constitution.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOV_MC_UNIT2',
          displayName: 'Unit 2: Branches of Government',
          description: 'Examining the structure, powers, and interactions of the legislative, executive, and judicial branches.',
          icon: 'remixGovernmentLine', // Remix Icon for government
          subcategories: [
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_CONGRESSIONAL_STRUCTURE',
              displayName: 'Congressional Structure',
              description: 'Bicameralism, House vs. Senate roles.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_LEGISLATIVE_PROCESS',
              displayName: 'Legislative Process',
              description: 'How a bill becomes a law, committees, filibuster.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_POWERS_OF_CONGRESS',
              displayName: 'Powers of Congress',
              description: 'Enumerated and implied powers.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_PRESIDENTIAL_POWERS',
              displayName: 'Presidential Powers',
              description: 'Formal and informal powers, executive orders.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_FEDERAL_BUREAUCRACY',
              displayName: 'Federal Bureaucracy',
              description: 'Role and functions of federal agencies.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_JUDICIAL_BRANCH',
              displayName: 'Judicial Branch',
              description: 'Structure of federal courts, Supreme Court jurisdiction.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_JUDICIAL_REVIEW',
              displayName: 'Judicial Review',
              description: 'Marbury v. Madison and the power to interpret the Constitution.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_CHECKS_AND_BALANCES',
              displayName: 'Checks and Balances',
              description: 'Interbranch interactions and limitations.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_APPOINTMENTS_AND_CONFIRMATIONS',
              displayName: 'Appointments and Confirmations',
              description: 'Process for selecting federal officials.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT2_BUDGETARY_PROCESS',
              displayName: 'Budgetary Process',
              description: 'Congressional budgeting and appropriations.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOV_MC_UNIT3',
          displayName: 'Unit 3: Civil Liberties & Rights',
          description: 'Understanding constitutional protections of civil liberties and civil rights.',
          icon: 'remixShieldLine', // Remix Icon for protection
          subcategories: [
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_BILL_OF_RIGHTS',
              displayName: 'Bill of Rights',
              description: 'First ten amendments and their protections.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_SELECTIVE_INCORPORATION',
              displayName: 'Selective Incorporation',
              description: 'Applying the Bill of Rights to the states via the 14th Amendment.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_FREEDOM_OF_SPEECH',
              displayName: 'Freedom of Speech',
              description: 'Limits and protections under the First Amendment.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_FREEDOM_OF_RELIGION',
              displayName: 'Freedom of Religion',
              description: 'Establishment and Free Exercise Clauses.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_RIGHT_TO_PRIVACY',
              displayName: 'Right to Privacy',
              description: 'Implied rights and landmark cases like Roe v. Wade.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_DUE_PROCESS_RIGHTS',
              displayName: 'Due Process Rights',
              description: 'Protections in criminal proceedings.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_EQUAL_PROTECTION',
              displayName: 'Equal Protection',
              description: '14th Amendment and anti-discrimination laws.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_CIVIL_RIGHTS_MOVEMENT',
              displayName: 'Civil Rights Movement',
              description: 'Key events and legislation.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_AFFIRMATIVE_ACTION',
              displayName: 'Affirmative Action',
              description: 'Policies and Supreme Court rulings.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT3_LANDMARK_SCOTUS_CASES',
              displayName: 'Landmark Supreme Court Cases',
              description: 'Significant decisions affecting liberties and rights.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOV_MC_UNIT4',
          displayName: 'Unit 4: Political Ideologies',
          description: 'Exploring how cultural factors influence political attitudes and ideologies.',
          icon: 'remixTreeLine', // Remix Icon for mind map
          subcategories: [
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT4_POLITICAL_SOCIALIZATION',
              displayName: 'Political Socialization',
              description: 'Factors that shape political beliefs.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT4_POLITICAL_IDEOLOGIES',
              displayName: 'Political Ideologies',
              description: 'Conservative, liberal, libertarian perspectives.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT4_PUBLIC_OPINION',
              displayName: 'Public Opinion',
              description: 'Measuring and interpreting polls.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT4_POLITICAL_CULTURE',
              displayName: 'Political Culture',
              description: 'Shared values and beliefs about government.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT4_DEMOGRAPHIC_INFLUENCES',
              displayName: 'Demographic Influences',
              description: 'Impact of age, race, gender, religion on politics.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT4_POLICY_PREFERENCES',
              displayName: 'Policy Preferences',
              description: 'Differences in views on economic and social issues.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOV_MC_UNIT5',
          displayName: 'Unit 5: Political Participation',
          description: 'Understanding how citizens engage with the political process.',
          icon: 'remixUserHeartLine', // Remix Icon for participation
          subcategories: [
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_VOTING_RIGHTS',
              displayName: 'Voting Rights',
              description: 'Expansion of suffrage and voting amendments.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_VOTER_BEHAVIOR',
              displayName: 'Voter Behavior',
              description: 'Factors influencing turnout and choices.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_ELECTIONS_AND_CAMPAIGNS',
              displayName: 'Elections and Campaigns',
              description: 'Election cycles, primaries, general elections.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_POLITICAL_PARTIES',
              displayName: 'Political Parties',
              description: 'Role and function in the political system.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_INTEREST_GROUPS',
              displayName: 'Interest Groups',
              description: 'Influence on policy and government.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_MEDIA_AND_POLITICS',
              displayName: 'Media and Politics',
              description: 'Impact of news and social media.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_CAMPAIGN_FINANCE',
              displayName: 'Campaign Finance',
              description: 'Regulations and Supreme Court rulings.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_ELECTORAL_SYSTEMS',
              displayName: 'Electoral Systems',
              description: 'Single-member districts, Electoral College.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_POLITICAL_ACTION_COMMITTEES',
              displayName: 'Political Action Committees',
              description: 'Role in funding campaigns.',
            },
            {
              subcategoryId: 'AP_US_GOV_MC_UNIT5_SOCIAL_MOVEMENTS',
              displayName: 'Social Movements',
              description: 'Collective efforts to promote change.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_US_GOVERNMENT_FREE_RESPONSE',
      displayName: 'Free Response',
      description:
        'This section requires students to write responses demonstrating their ability to analyze political concepts, interpret data, and develop evidence-based arguments.',
      icon: 'remixPenNibLine', // Remix Icon for writing
      categories: [
        {
          categoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_CONCEPT_APPLICATION',
          displayName: 'Concept Application',
          description: 'Applying political concepts to real-world scenarios.',
          icon: 'remixOrganizationChart', // Remix Icon for scenarios
          subcategories: [
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_CONCEPT_APPLICATION_SCENARIO_ANALYSIS',
              displayName: 'Scenario Analysis',
              description: 'Interpreting a scenario using political concepts.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_CONCEPT_APPLICATION_IDENTIFICATION',
              displayName: 'Identification',
              description: 'Recognizing key elements in a scenario.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_CONCEPT_APPLICATION_EXPLANATION',
              displayName: 'Explanation',
              description: 'Explaining how concepts apply to the scenario.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_CONCEPT_APPLICATION_IMPLICATIONS',
              displayName: 'Implications',
              description: 'Discussing potential outcomes or effects.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_QUANTITATIVE_ANALYSIS',
          displayName: 'Quantitative Analysis',
          description: 'Analyzing and interpreting data presented in various formats.',
          icon: 'remixLineChartLine', // Remix Icon for charts
          subcategories: [
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_QUANTITATIVE_ANALYSIS_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description: 'Reading and interpreting graphs, charts, and tables.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_QUANTITATIVE_ANALYSIS_TREND_ANALYSIS',
              displayName: 'Trend Analysis',
              description: 'Identifying patterns and trends in data.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_QUANTITATIVE_ANALYSIS_CAUSAL_RELATIONSHIPS',
              displayName: 'Causal Relationships',
              description: 'Explaining causes and effects shown in data.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_QUANTITATIVE_ANALYSIS_APPLICATION',
              displayName: 'Application',
              description: 'Applying data insights to political concepts.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_SCOTUS_COMPARISON',
          displayName: 'SCOTUS Comparison',
          description: 'Comparing a non-required Supreme Court case with a required one.',
          icon: 'remixBriefcaseLine', // Remix Icon for judiciary
          subcategories: [
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_SCOTUS_COMPARISON_CASE_FACTS',
              displayName: 'Case Facts',
              description: 'Identifying key facts of the cases.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_SCOTUS_COMPARISON_CONSTITUTIONAL_PRINCIPLES',
              displayName: 'Constitutional Principles',
              description: 'Discussing constitutional issues involved.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_SCOTUS_COMPARISON_REASONING_AND_DECISIONS',
              displayName: 'Reasoning and Decisions',
              description: 'Analyzing the Court\'s reasoning.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_SCOTUS_COMPARISON_COMPARISON',
              displayName: 'Comparison',
              description: 'Drawing parallels and contrasts between cases.',
            },
          ],
        },
        {
          categoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_ARGUMENT_ESSAY',
          displayName: 'Argument Essay',
          description: 'Developing an evidence-based argument on a political topic.',
          icon: 'remixTeamLine', // Remix Icon for argument
          subcategories: [
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_ARGUMENT_ESSAY_THESIS_DEVELOPMENT',
              displayName: 'Thesis Development',
              description: 'Crafting a defensible claim or position.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_ARGUMENT_ESSAY_USE_OF_EVIDENCE',
              displayName: 'Use of Evidence',
              description: 'Incorporating relevant foundational documents or court cases.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_ARGUMENT_ESSAY_REASONING',
              displayName: 'Reasoning',
              description: 'Connecting evidence to the thesis with logical explanations.',
            },
            {
              subcategoryId: 'AP_US_GOVERNMENT_FREE_RESPONSE_ARGUMENT_ESSAY_COUNTERARGUMENT',
              displayName: 'Counterargument',
              description: 'Addressing opposing viewpoints or limitations.',
            },
          ],
        },
      ],
    },
  ],
};


// AP Psychology Exam Data
export const apPsychologyExamData: ExamData = {
  examId: 'AP_PSYCHOLOGY_2025',
  displayName: 'AP Psychology',
  description:
    'The AP Psychology Exam measures students\' knowledge of the 14 key topics and fields of study in psychology and tests their ability to define, compare, and apply concepts and research findings.',
  logo: 'assets/images/exams/ap_psychology_logo.png', // Ensure you have the correct logo path
  link: 'ap-psychology',
  zoom: 1.1,
  priceId: 'AP_PSYCHOLOGY_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel in AP Psychology with Comprehensive Practice and Expert Insights',
  marketingSubtitles: [
    'Extensive question bank tailored to AP Psychology exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable AP Psychology Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your academic performance.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'AP_PSYCHOLOGY_MC',
      displayName: 'Multiple Choice',
      description:
        'This section tests students\' knowledge of psychological concepts, theories, terminology, and their ability to apply these concepts.',
      icon: 'remixBookLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT1',
          displayName: 'Unit 1: Scientific Foundations',
          description: 'Introduction to psychology, research methods, and statistics.',
          icon: 'remixTestTubeLine', // Remix Icon for scientific foundations
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT1_HISTORY_OF_PSYCHOLOGY',
              displayName: 'History of Psychology',
              description: 'Major historical figures and approaches.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT1_RESEARCH_METHODS',
              displayName: 'Research Methods',
              description: 'Experimental, correlational, and clinical research.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT1_STATISTICAL_ANALYSIS',
              displayName: 'Statistical Analysis',
              description: 'Descriptive and inferential statistics.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT1_ETHICAL_GUIDELINES',
              displayName: 'Ethical Guidelines',
              description: 'Ethics in psychological research and practice.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT2',
          displayName: 'Unit 2: Biological Bases',
          description: 'Understanding how biological processes influence behavior.',
          icon: 'remixPlantLine', // Remix Icon for biological bases
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT2_NEURAL_COMMUNICATION',
              displayName: 'Neural Communication',
              description: 'Structure and function of neurons.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT2_NEUROTRANSMITTERS',
              displayName: 'Neurotransmitters',
              description: 'Roles of chemical messengers in the brain.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT2_NERVOUS_SYSTEM',
              displayName: 'Nervous System',
              description: 'Central and peripheral nervous systems.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT2_ENDOCRINE_SYSTEM',
              displayName: 'Endocrine System',
              description: 'Hormones and their effects on behavior.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT2_BRAIN_STRUCTURES',
              displayName: 'Brain Structures',
              description: 'Functions of different brain regions.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT2_GENETICS_AND_BEHAVIOR',
              displayName: 'Genetics and Behavior',
              description: 'Influence of heredity on behavior.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT3',
          displayName: 'Unit 3: Sensation & Perception',
          description: 'Exploring how we sense and perceive the world around us.',
          icon: 'remixEyeLine', // Remix Icon for sensation & perception
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT3_SENSORY_PROCESSES',
              displayName: 'Sensory Processes',
              description: 'Thresholds and sensory adaptation.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT3_VISUAL_SYSTEM',
              displayName: 'Visual System',
              description: 'Structure and function of the eye.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT3_AUDITORY_SYSTEM',
              displayName: 'Auditory System',
              description: 'Hearing and the structure of the ear.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT3_OTHER_SENSES',
              displayName: 'Other Senses',
              description: 'Taste, smell, touch, and kinesthetic senses.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT3_PERCEPTION',
              displayName: 'Perception',
              description: 'Gestalt principles, depth perception.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT3_PERCEPTUAL_INTERPRETATION',
              displayName: 'Perceptual Interpretation',
              description: 'Influence of experience and culture.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT4',
          displayName: 'Unit 4: Learning',
          description: 'Understanding how we learn and how learning shapes behavior.',
          icon: 'remixBookOpenLine', // Remix Icon for learning
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT4_CLASSICAL_CONDITIONING',
              displayName: 'Classical Conditioning',
              description: 'Pavlovian conditioning principles.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT4_OPERANT_CONDITIONING',
              displayName: 'Operant Conditioning',
              description: 'Skinner\'s work and reinforcement.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT4_OBSERVATIONAL_LEARNING',
              displayName: 'Observational Learning',
              description: 'Bandura and social learning theory.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT4_COGNITIVE_PROCESSES_IN_LEARNING',
              displayName: 'Cognitive Processes in Learning',
              description: 'Role of cognition in learning.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT4_BIOLOGICAL_CONSTRAINTS',
              displayName: 'Biological Constraints',
              description: 'Limits on learning due to biology.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT5',
          displayName: 'Unit 5: Cognitive Psychology',
          description: 'Exploring memory, thinking, problem-solving, and language.',
          icon: 'remixBrainLine', // Remix Icon for cognitive psychology
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT5_MEMORY_MODELS',
              displayName: 'Memory Models',
              description: 'Information processing and memory structures.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT5_ENCODING_AND_RETRIEVAL',
              displayName: 'Encoding and Retrieval',
              description: 'Processes of storing and recalling information.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT5_FORGETTING_AND_MEMORY_CONSTRUCTION',
              displayName: 'Forgetting and Memory Construction',
              description: 'Reasons we forget and memory errors.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT5_THINKING_AND_PROBLEM_SOLVING',
              displayName: 'Thinking and Problem Solving',
              description: 'Heuristics, algorithms, and biases.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT5_LANGUAGE_DEVELOPMENT',
              displayName: 'Language Development',
              description: 'Acquisition and use of language.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT5_INTELLIGENCE',
              displayName: 'Intelligence',
              description: 'Theories and assessments of intelligence.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT6',
          displayName: 'Unit 6: Developmental Psychology',
          description: 'Examining physical, cognitive, and social development across the lifespan.',
          icon: 'remixUserHeartLine', // Remix Icon for developmental psychology
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT6_PRENATAL_DEVELOPMENT',
              displayName: 'Prenatal Development',
              description: 'Stages before birth and teratogens.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT6_COGNITIVE_DEVELOPMENT',
              displayName: 'Cognitive Development',
              description: 'Piaget\'s stages and beyond.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT6_SOCIAL_DEVELOPMENT',
              displayName: 'Social Development',
              description: 'Attachment, parenting styles, and Erikson\'s stages.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT6_ADOLESCENCE',
              displayName: 'Adolescence',
              description: 'Physical and psychological changes during teens.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT6_ADULTHOOD_AND_AGING',
              displayName: 'Adulthood and Aging',
              description: 'Changes in middle and late adulthood.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT6_MORAL_DEVELOPMENT',
              displayName: 'Moral Development',
              description: 'Kohlberg\'s theory and criticisms.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT7',
          displayName: 'Unit 7: Motivation, Emotion, Personality',
          description: 'Understanding what drives behavior, feelings, and personality formation.',
          icon: 'remixHeartLine', // Remix Icon for motivation and emotion
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT7_MOTIVATIONAL_CONCEPTS',
              displayName: 'Motivational Concepts',
              description: 'Theories like drive-reduction and Maslow\'s hierarchy.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT7_HUNGER_AND_EATING',
              displayName: 'Hunger and Eating',
              description: 'Biological and psychological factors.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT7_EMOTION_THEORIES',
              displayName: 'Emotion Theories',
              description: 'James-Lange, Cannon-Bard, Schachter-Singer.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT7_STRESS_AND_HEALTH',
              displayName: 'Stress and Health',
              description: 'Effects of stress and coping mechanisms.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT7_PERSONALITY_THEORIES',
              displayName: 'Personality Theories',
              description: 'Psychoanalytic, humanistic, trait, and social-cognitive.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT7_ASSESSMENT_OF_PERSONALITY',
              displayName: 'Assessment of Personality',
              description: 'Projective tests and inventories.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT8',
          displayName: 'Unit 8: Clinical Psychology',
          description: 'Studying psychological disorders and treatment approaches.',
          icon: 'remixHospitalLine', // Remix Icon for clinical psychology
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT8_UNDERSTANDING_PSYCHOLOGICAL_DISORDERS',
              displayName: 'Understanding Psychological Disorders',
              description: 'Classifying and diagnosing disorders.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT8_ANXIETY_DISORDERS',
              displayName: 'Anxiety Disorders',
              description: 'Generalized anxiety, phobias, panic disorder.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT8_OBSESSIVE_COMPULSIVE_AND_RELATED_DISORDERS',
              displayName: 'Obsessive-Compulsive and Related Disorders',
              description: 'Symptoms and treatments.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT8_TRAUMA_AND_STRESSOR_RELATED_DISORDERS',
              displayName: 'Trauma and Stressor-Related Disorders',
              description: 'PTSD and related conditions.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT8_SOMATIC_SYMPTOM_AND_RELATED_DISORDERS',
              displayName: 'Somatic Symptom and Related Disorders',
              description: 'Physical symptoms with psychological origins.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT8_DISSOCIATIVE_DISORDERS',
              displayName: 'Dissociative Disorders',
              description: 'Amnesia, identity disorders.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT9',
          displayName: 'Unit 9: Social Psychology',
          description: 'Exploring how individuals interact and influence one another.',
          icon: 'remixUsersLine', // Remix Icon for social psychology
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_ATTRIBUTION_THEORY',
              displayName: 'Attribution Theory',
              description: 'Explaining others\' behavior.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_ATTITUDES_AND_ACTIONS',
              displayName: 'Attitudes and Actions',
              description: 'How attitudes influence actions and vice versa.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_CONFORMITY_AND_OBEDIENCE',
              displayName: 'Conformity and Obedience',
              description: 'Asch and Milgram studies.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_GROUP_BEHAVIOR',
              displayName: 'Group Behavior',
              description: 'Groupthink, social facilitation, and loafing.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_PREJUDICE_AND_DISCRIMINATION',
              displayName: 'Prejudice and Discrimination',
              description: 'Causes and consequences.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_AGGRESSION_AND_ALTRUISM',
              displayName: 'Aggression and Altruism',
              description: 'Factors influencing aggressive and helping behaviors.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT9_INTERPERSONAL_ATTRACTION',
              displayName: 'Interpersonal Attraction',
              description: 'Factors that lead to attraction.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT10',
          displayName: 'Unit 10: States of Consciousness',
          description: 'Understanding different levels of consciousness.',
          icon: 'remixMoonLine', // Remix Icon for states of consciousness
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT10_SLEEP_AND_DREAMS',
              displayName: 'Sleep and Dreams',
              description: 'Sleep stages and dream theories.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT10_HYPNOSIS',
              displayName: 'Hypnosis',
              description: 'Phenomena and theories.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT10_PSYCHOACTIVE_DRUGS',
              displayName: 'Psychoactive Drugs',
              description: 'Effects of depressants, stimulants, hallucinogens.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT11',
          displayName: 'Unit 11: Testing & Individual Differences',
          description: 'Exploring how psychologists assess intelligence and other abilities.',
          icon: 'remixCalculatorLine', // Remix Icon for testing
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT11_INTELLIGENCE_TESTING',
              displayName: 'Intelligence Testing',
              description: 'History and types of intelligence tests.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT11_THEORIES_OF_INTELLIGENCE',
              displayName: 'Theories of Intelligence',
              description: 'Spearman, Gardner, Sternberg.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT11_GENETIC_AND_ENVIRONMENTAL_INFLUENCES',
              displayName: 'Genetic and Environmental Influences',
              description: 'Nature vs. nurture in intelligence.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT11_ASSESSING_INTELLIGENCE',
              displayName: 'Assessing Intelligence',
              description: 'Standardization, reliability, validity.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT11_GROUP_DIFFERENCES',
              displayName: 'Group Differences',
              description: 'Cultural and social factors affecting test scores.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT12',
          displayName: 'Unit 12: Abnormal Behavior',
          description: 'Understanding psychological disorders and their classifications.',
          icon: 'remixEmotionLine', // Remix Icon for abnormal behavior
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT12_DEFINING_PSYCHOLOGICAL_DISORDERS',
              displayName: 'Defining Psychological Disorders',
              description: 'Criteria and perspectives.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT12_ANXIETY_DISORDERS',
              displayName: 'Anxiety Disorders',
              description: 'Generalized anxiety, phobias, panic disorder.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT12_OBSESSIVE_COMPULSIVE_AND_RELATED_DISORDERS',
              displayName: 'Obsessive-Compulsive and Related Disorders',
              description: 'Symptoms and treatments.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT12_TRAUMA_AND_STRESSOR_RELATED_DISORDERS',
              displayName: 'Trauma and Stressor-Related Disorders',
              description: 'PTSD and related conditions.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT12_SOMATIC_SYMPTOM_AND_RELATED_DISORDERS',
              displayName: 'Somatic Symptom and Related Disorders',
              description: 'Physical symptoms with psychological origins.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT12_DISSOCIATIVE_DISORDERS',
              displayName: 'Dissociative Disorders',
              description: 'Amnesia, identity disorders.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT13',
          displayName: 'Unit 13: Treatment of Disorders',
          description: 'Approaches to treating psychological disorders.',
          icon: 'remixHealthLine', // Remix Icon for treatment
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT13_PSYCHOTHERAPY',
              displayName: 'Psychotherapy',
              description: 'Techniques and effectiveness.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT13_BIOMEDICAL_THERAPY',
              displayName: 'Biomedical Therapy',
              description: 'Medications and medical procedures.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT13_PREVENTIVE_MENTAL_HEALTH',
              displayName: 'Preventive Mental Health',
              description: 'Community and preventive approaches.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_MC_UNIT14',
          displayName: 'Unit 14: Social Psychology (Additional)',
          description: 'Further exploration of social influences on behavior.',
          icon: 'remixTeamLine', // Remix Icon for social influence
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT14_ATTRACTION_AND_RELATIONSHIPS',
              displayName: 'Attraction and Relationships',
              description: 'Factors influencing attraction.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT14_GROUP_DYNAMICS',
              displayName: 'Group Dynamics',
              description: 'Roles, norms, and leadership in groups.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_MC_UNIT14_CULTURAL_INFLUENCES',
              displayName: 'Cultural Influences',
              description: 'Impact of culture on behavior and thinking.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_PSYCHOLOGY_FREE_RESPONSE',
      displayName: 'Free Response',
      description:
        'This section requires students to write essays demonstrating their ability to apply psychological concepts and analyze scenarios using appropriate terminology.',
      icon: 'remixPenNibLine', // Remix Icon for writing
      categories: [
        {
          categoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_APPLICATION_CONCEPTS',
          displayName: 'Application of Concepts',
          description: 'Applying psychological theories and concepts to specific scenarios.',
          icon: 'remixPuzzleLine', // Remix Icon for application
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_APPLICATION_CONCEPTS_SCENARIO_ANALYSIS',
              displayName: 'Scenario Analysis',
              description: 'Analyzing given situations using psychological concepts.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_APPLICATION_CONCEPTS_CONCEPT_INTEGRATION',
              displayName: 'Concept Integration',
              description: 'Integrating multiple concepts into a cohesive response.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_APPLICATION_CONCEPTS_USE_OF_TERMINOLOGY',
              displayName: 'Use of Terminology',
              description: 'Accurate use of psychological terms and definitions.',
            },
          ],
        },
        {
          categoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_RESEARCH_DESIGN',
          displayName: 'Research Design and Analysis',
          description: 'Understanding and applying research methods in psychology.',
          icon: 'remixLineChartLine', // Remix Icon for research design
          subcategories: [
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_RESEARCH_DESIGN_EXPERIMENTAL_DESIGN',
              displayName: 'Experimental Design',
              description: 'Identifying independent and dependent variables, controls.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_RESEARCH_DESIGN_DATA_INTERPRETATION',
              displayName: 'Data Interpretation',
              description: 'Analyzing and interpreting data or results.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_RESEARCH_DESIGN_ETHICAL_CONSIDERATIONS',
              displayName: 'Ethical Considerations',
              description: 'Addressing ethical issues in research.',
            },
            {
              subcategoryId: 'AP_PSYCHOLOGY_FREE_RESPONSE_RESEARCH_DESIGN_STATISTICAL_CONCEPTS',
              displayName: 'Statistical Concepts',
              description: 'Applying statistical principles to research scenarios.',
            },
          ],
        },
      ],
    },
  ],
};


// AP United States History Exam Data
export const apUsHistoryExamData: ExamData = {
  examId: 'AP_US_HISTORY_2025',
  displayName: 'AP United States History',
  description:
    'The AP United States History Exam measures students\' understanding of historical concepts, themes, and their ability to analyze historical evidence and write analytical essays.',
  logo: 'assets/images/exams/ap_us_history_logo.png', // Ensure you have the correct logo path
  link: 'ap-united-states-history',
  zoom: 1.1,
  priceId: 'AP_US_HISTORY_2025_month_subscription_price',
  priceUnitAmount: 999, // Price in cents ($9.99)
  currency: 'USD',

  // Marketing Fields
  marketingTitle: 'Excel in AP United States History with Comprehensive Practice and Expert Insights',
  marketingSubtitles: [
    'Extensive question bank tailored to AP US History exam requirements.',
    'Adaptive learning to identify and strengthen weak areas.',
    'Detailed explanations and rationales for each question.',
    'User-friendly interface to track your progress effectively.',
  ],
  pricingTitle: 'Affordable AP US History Prep Plans to Suit Your Needs',
  pricingSubtitle: [
    'Unlock premium resources and support to enhance your academic performance.',
  ],
  otherTitles: [],
  pricingDescription: '',

  sections: [
    {
      examSectionId: 'AP_US_HISTORY_MC',
      displayName: 'Multiple Choice',
      description:
        'This section tests students\' knowledge of U.S. history from multiple time periods, their ability to analyze historical texts, interpretations, and evidence.',
      icon: 'remixBookLine', // Remix Icon for reading
      categories: [
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD1',
          displayName: 'Period 1: 1491–1607',
          description: 'Pre-Columbian societies, European exploration, and early interactions.',
          icon: 'remixGlobeLine', // Remix Icon for exploration
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD1_NATIVE_AMERICAN_CULTURES',
              displayName: 'Native American Cultures',
              description: 'Diversity and complexity of indigenous societies before contact.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD1_CAUSES_OF_EUROPEAN_EXPLORATION',
              displayName: 'Causes of European Exploration',
              description: 'Motivations for European nations to explore and colonize.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD1_COLUMBIAN_EXCHANGE',
              displayName: 'Columbian Exchange',
              description: 'Transfer of plants, animals, diseases, and technologies.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD1_SPANISH_COLONIZATION',
              displayName: 'Spanish Colonization',
              description: 'Conquistadors, encomienda system, and impact on Native Americans.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD1_INTERACTIONS_EUROPEANS_NATIVE_AMERICANS',
              displayName: 'Interactions between Europeans and Native Americans',
              description: 'Trade, conflict, and cultural exchanges.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD2',
          displayName: 'Period 2: 1607–1754',
          description: 'Colonial societies and the development of British North America.',
          icon: 'remixHomeLine', // Remix Icon for colonial homes
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_CHESAPEAKE_COLONIES',
              displayName: 'Chesapeake Colonies',
              description: 'Virginia, Maryland, and tobacco economy.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_NEW_ENGLAND_COLONIES',
              displayName: 'New England Colonies',
              description: 'Puritan settlements, religious motives, and community life.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_MIDDLE_COLONIES',
              displayName: 'Middle Colonies',
              description: 'Diversity, commerce, and religious tolerance.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_SOUTHERN_COLONIES',
              displayName: 'Southern Colonies',
              description: 'Plantation economy and slavery.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_COLONIAL_GOVERNMENT',
              displayName: 'Colonial Government',
              description: 'Development of self-government and colonial assemblies.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_TRANSATLANTIC_TRADE',
              displayName: 'Transatlantic Trade',
              description: 'Mercantilism and the Navigation Acts.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD2_FIRST_GREAT_AWAKENING',
              displayName: 'First Great Awakening',
              description: 'Religious revival and its impact on society.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD3',
          displayName: 'Period 3: 1754–1800',
          description: 'Revolutionary era, the formation of the United States, and early republic.',
          icon: 'remixFlagLine', // Remix Icon for revolution
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_FRENCH_AND_INDIAN_WAR',
              displayName: 'French and Indian War',
              description: 'Causes, key events, and consequences.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_ROAD_TO_REVOLUTION',
              displayName: 'Road to Revolution',
              description: 'Acts imposed by Britain, colonial resistance, and protests.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_DECLARATION_OF_INDEPENDENCE',
              displayName: 'Declaration of Independence',
              description: 'Philosophical foundations and significance.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_AMERICAN_REVOLUTION',
              displayName: 'American Revolution',
              description: 'Major battles, strategies, and turning points.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_ARTICLES_OF_CONFEDERATION',
              displayName: 'Articles of Confederation',
              description: 'Strengths, weaknesses, and challenges.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_CONSTITUTIONAL_CONVENTION',
              displayName: 'Constitutional Convention',
              description: 'Debates, compromises, and ratification.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_FEDERALISTS_VS_ANTI_FEDERALISTS',
              displayName: 'Federalists vs. Anti-Federalists',
              description: 'Arguments over the Constitution.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_EARLY_NATIONAL_PERIOD',
              displayName: 'Early National Period',
              description: 'Washington and Adams administrations.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD3_DEVELOPMENT_OF_POLITICAL_PARTIES',
              displayName: 'Development of Political Parties',
              description: 'Federalists and Democratic-Republicans.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD4',
          displayName: 'Period 4: 1800–1848',
          description: 'Expansion, democracy, and reform in the early 19th century.',
          icon: 'remixArrowRightLine', // Remix Icon for expansion
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_JEFFERSONIAN_DEMOCRACY',
              displayName: 'Jeffersonian Democracy',
              description: 'Policies, Louisiana Purchase, and challenges.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_WAR_OF_1812',
              displayName: 'War of 1812',
              description: 'Causes, key events, and consequences.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_ERA_OF_GOOD_FEELINGS',
              displayName: 'Era of Good Feelings',
              description: 'Nationalism and sectionalism.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_MARKET_REVOLUTION',
              displayName: 'Market Revolution',
              description: 'Industrialization, transportation, and economic changes.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_JACKSONIAN_DEMOCRACY',
              displayName: 'Jacksonian Democracy',
              description: 'Andrew Jackson\'s presidency and policies.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_SECOND_GREAT_AWAKENING',
              displayName: 'Second Great Awakening',
              description: 'Religious revival and social reforms.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_REFORM_MOVEMENTS',
              displayName: 'Reform Movements',
              description: 'Abolition, women\'s rights, temperance, education.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD4_MANIFEST_DESTINY',
              displayName: 'Manifest Destiny',
              description: 'Ideology and territorial expansion.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD5',
          displayName: 'Period 5: 1844–1877',
          description: 'Civil War and Reconstruction era.',
          icon: 'remixShieldLine', // Remix Icon for civil war
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_CAUSES_OF_THE_CIVIL_WAR',
              displayName: 'Causes of the Civil War',
              description: 'Slavery, states\' rights, sectional tensions.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_MEXICAN_AMERICAN_WAR',
              displayName: 'Mexican-American War',
              description: 'Causes, territorial gains, and impact on slavery debate.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_COMPROMISE_OF_1850',
              displayName: 'Compromise of 1850',
              description: 'Key provisions and significance.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_ABOLITIONIST_MOVEMENT',
              displayName: 'Abolitionist Movement',
              description: 'Leaders, publications, and actions.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_CIVIL_WAR',
              displayName: 'Civil War',
              description: 'Major battles, strategies, and turning points.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_EMANCIPATION_PROCLAMATION',
              displayName: 'Emancipation Proclamation',
              description: 'Purpose and effects.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_RECONSTRUCTION_PLANS',
              displayName: 'Reconstruction Plans',
              description: 'Lincoln, Johnson, and Radical Republicans\' approaches.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_13TH_14TH_15TH_AMENDMENTS',
              displayName: '13th, 14th, 15th Amendments',
              description: 'Civil rights advancements and limitations.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD5_END_OF_RECONSTRUCTION',
              displayName: 'End of Reconstruction',
              description: 'Compromise of 1877 and the rise of Jim Crow laws.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD6',
          displayName: 'Period 6: 1865–1898',
          description: 'Industrialization, urbanization, and the Gilded Age.',
          icon: 'remixBuildingLine', // Remix Icon for industry
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_INDUSTRIAL_CAPITALISM',
              displayName: 'Industrial Capitalism',
              description: 'Rise of big business and tycoons.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_LABOR_MOVEMENTS',
              displayName: 'Labor Movements',
              description: 'Unions, strikes, and labor leaders.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_IMMIGRATION',
              displayName: 'Immigration',
              description: 'New immigrants and nativist responses.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_URBANIZATION',
              displayName: 'Urbanization',
              description: 'Growth of cities and urban challenges.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_WESTWARD_EXPANSION',
              displayName: 'Westward Expansion',
              description: 'Homestead Act, railroads, conflicts with Native Americans.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_POPULIST_MOVEMENT',
              displayName: 'Populist Movement',
              description: 'Farmers\' challenges and political activism.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD6_POLITICS_OF_THE_GILDED_AGE',
              displayName: 'Politics of the Gilded Age',
              description: 'Corruption, political machines, and reform efforts.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD7',
          displayName: 'Period 7: 1890–1945',
          description: 'Progressive reforms, world wars, and the Great Depression.',
          icon: 'remixRocketLine', // Remix Icon for progress
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_PROGRESSIVE_ERA_REFORMS',
              displayName: 'Progressive Era Reforms',
              description: 'Muckrakers, regulations, and amendments.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_IMPERIALISM',
              displayName: 'Imperialism',
              description: 'Spanish-American War, Philippines, and debates over expansion.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_WORLD_WAR_I',
              displayName: 'World War I',
              description: 'Causes, U.S. involvement, and aftermath.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_ROARING_TWENTIES',
              displayName: 'Roaring Twenties',
              description: 'Cultural changes, Harlem Renaissance, Prohibition.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_GREAT_DEPRESSION',
              displayName: 'Great Depression',
              description: 'Causes, effects, and impact on society.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_NEW_DEAL',
              displayName: 'New Deal',
              description: 'FDR\'s programs and their effectiveness.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD7_WORLD_WAR_II',
              displayName: 'World War II',
              description: 'Causes, key events, homefront, and outcomes.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD8',
          displayName: 'Period 8: 1945–1980',
          description: 'Cold War, civil rights, and social changes.',
          icon: 'remixGlobeLine', // Remix Icon for global impact
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_COLD_WAR_ORIGINS',
              displayName: 'Cold War Origins',
              description: 'Containment policy, Truman Doctrine, Marshall Plan.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_DOMESTIC_POLICIES',
              displayName: 'Domestic Policies',
              description: 'Eisenhower\'s policies, McCarthyism, Red Scare.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_CIVIL_RIGHTS_MOVEMENT',
              displayName: 'Civil Rights Movement',
              description: 'Key events, legislation, and leaders.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_VIETNAM_WAR',
              displayName: 'Vietnam War',
              description: 'Causes, escalation, protests, and consequences.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_GREAT_SOCIETY',
              displayName: 'Great Society',
              description: 'LBJ\'s programs and their impact.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_WOMENS_RIGHTS_MOVEMENT',
              displayName: 'Women\'s Rights Movement',
              description: 'Feminism, key figures, and legislation.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_ENVIRONMENTAL_MOVEMENT',
              displayName: 'Environmental Movement',
              description: 'Awareness and policy changes.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD8_COUNTERCULTURE',
              displayName: 'Counterculture',
              description: 'Social changes and youth movements.',
            },
          ],
        },
        {
          categoryId: 'AP_US_HISTORY_MC_PERIOD9',
          displayName: 'Period 9: 1980–Present',
          description: 'Contemporary United States history.',
          icon: 'remixStarLine', // Remix Icon for contemporary times
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_REAGAN_ERA',
              displayName: 'Reagan Era',
              description: 'Conservative resurgence, economic policies, and foreign affairs.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_END_OF_THE_COLD_WAR',
              displayName: 'End of the Cold War',
              description: 'Fall of the Soviet Union and implications.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_TECHNOLOGICAL_ADVANCES',
              displayName: 'Technological Advances',
              description: 'Impact of the digital revolution.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_GLOBALIZATION',
              displayName: 'Globalization',
              description: 'Economic and cultural interconnectedness.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_TERRORISM_AND_911',
              displayName: 'Terrorism and 9/11',
              description: 'Events leading to and following the attacks.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_SOCIAL_AND_CULTURAL_CHANGES',
              displayName: 'Social and Cultural Changes',
              description: 'LGBTQ rights, immigration, and diversity issues.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_ENVIRONMENTAL_CONCERNS',
              displayName: 'Environmental Concerns',
              description: 'Climate change and policy responses.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_MC_PERIOD9_ECONOMIC_CHALLENGES',
              displayName: 'Economic Challenges',
              description: 'Recessions, financial crises, and recovery efforts.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_US_HISTORY_SA',
      displayName: 'Short Answer Questions',
      description:
        'This section requires students to analyze historical sources and use evidence to respond to questions in brief essays.',
      icon: 'remixChatQuoteLine', // Remix Icon for short answers
      categories: [
        {
          categoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING',
          displayName: 'Historical Thinking Skills',
          description: 'Applying historical reasoning skills to analyze prompts.',
          icon: 'remixBrainLine', // Remix Icon for thinking skills
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING_ANALYZING_PRIMARY_SOURCES',
              displayName: 'Analyzing Primary Sources',
              description: 'Interpreting documents, images, and artifacts.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING_CONTEXTUALIZATION',
              displayName: 'Contextualization',
              description: 'Placing events within broader historical settings.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING_COMPARISON',
              displayName: 'Comparison',
              description: 'Identifying similarities and differences across periods or regions.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING_CAUSATION',
              displayName: 'Causation',
              description: 'Understanding causes and effects of historical events.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING_CONTINUITY_AND_CHANGE',
              displayName: 'Continuity and Change',
              description: 'Examining patterns over time.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_SA_HISTORICAL_THINKING_SYNTHESIS',
              displayName: 'Synthesis',
              description: 'Connecting historical issues to other periods or themes.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_US_HISTORY_DBQ',
      displayName: 'Document-Based Question',
      description:
        'Students analyze and synthesize historical data from provided documents to write an analytical essay.',
      icon: 'remixFileTextLine', // Remix Icon for documents
      categories: [
        {
          categoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS',
          displayName: 'Document Analysis and Argumentation',
          description: 'Analyzing provided documents and crafting a coherent argument.',
          icon: 'remixBarChartLine', // Remix Icon for analysis
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS_THESIS_DEVELOPMENT',
              displayName: 'Thesis Development',
              description: 'Creating a clear, defensible thesis.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS_USE_OF_DOCUMENTS',
              displayName: 'Use of Documents',
              description: 'Incorporating evidence from provided sources.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS_SOURCING_THE_DOCUMENTS',
              displayName: 'Sourcing the Documents',
              description: 'Analyzing author\'s perspective, purpose, and context.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS_CONTEXTUALIZATION',
              displayName: 'Contextualization',
              description: 'Relating the topic to broader historical events.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS_OUTSIDE_EVIDENCE',
              displayName: 'Outside Evidence',
              description: 'Bringing in relevant information not included in documents.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_DBQ_DOCUMENT_ANALYSIS_COMPLEX_UNDERSTANDING',
              displayName: 'Complex Understanding',
              description: 'Demonstrating depth by analyzing multiple perspectives.',
            },
          ],
        },
      ],
    },
    {
      examSectionId: 'AP_US_HISTORY_LEQ',
      displayName: 'Long Essay Question',
      description:
        'Students choose one of three questions to write an in-depth essay demonstrating historical reasoning and evidence usage.',
      icon: 'remixPenNibLine', // Remix Icon for writing
      categories: [
        {
          categoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION',
          displayName: 'Historical Argumentation and Synthesis',
          description: 'Developing a coherent argument using historical evidence.',
          icon: 'remixPuzzleLine', // Remix Icon for argumentation
          subcategories: [
            {
              subcategoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION_THESIS_DEVELOPMENT',
              displayName: 'Thesis Development',
              description: 'Establishing a clear and analytical thesis.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION_USE_OF_EVIDENCE',
              displayName: 'Use of Evidence',
              description: 'Selecting and explaining relevant historical examples.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION_HISTORICAL_REASONING',
              displayName: 'Historical Reasoning',
              description: 'Using causation, comparison, or continuity and change over time.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION_COMPLEX_UNDERSTANDING',
              displayName: 'Complex Understanding',
              description: 'Demonstrating nuance and multiple perspectives.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION_SYNTHESIS',
              displayName: 'Synthesis',
              description: 'Connecting argument to different historical periods or themes.',
            },
            {
              subcategoryId: 'AP_US_HISTORY_LEQ_HISTORICAL_ARGUMENTATION_ORGANIZATION_AND_CLARITY',
              displayName: 'Organization and Clarity',
              description: 'Logical structure and clear writing.',
            },
          ],
        },
      ],
    },
  ],
};
